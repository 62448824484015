import React, { useEffect } from 'react'
import SearchComponent from '../components/SearchPage/SearchComponent'





const SearchPage = () => {

  return (
    <div>
      <SearchComponent/>
    </div>
  )
}

export default SearchPage