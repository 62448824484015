import styled from "styled-components";

export const WhoAreWeSection = styled.section`
  width: 100%;
  height: auto;
  @media only screen and (min-width: 1900px) {
    padding-top:162px;
    }
  @media only screen and (min-width: 1300px) and (max-width:1900px) {
    padding-top:103px;
    }
  @media only screen and (min-width: 900px) and (max-width:1300px) {
    padding:73px 0px;
    }
  @media only screen and  (max-width: 900px) {
    padding:26px 0px 61px 0px;
    }
`;
export const WhoWeAreContainer = styled.div`
  display: flex;
  align-items:center;
  width: 90%;
  margin: auto;
  gap: 5%;
  flex-direction: ${props=>props.wrap==='reverse'?'':'row-reverse'};
  @media (max-width: 900px) {
    flex-wrap: ${props=>props.wrap==='reverse'?'wrap-reverse':''};
  }
  img {
    @media only screen and (min-width: 900px) {
      width: 50%;
      height: auto;
    }
    @media only screen and (max-width: 900px) {
      width: 100%;
      height: auto;
    }
  }
`;
export const WhoWeAreTextContainer = styled.div`
  @media only screen and (min-width: 900px) {
    width: ${props=>props.image==='none'?'100%':'50%'};
    height: auto;
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
    height: auto;
  }
  h1 {
    font-family: "Didot";
    font-style: normal;
    color: #000000;
    @media only screen and (min-width: 1900px) {
      font-weight: 400;
      font-size: 64px;
      line-height: 109%;
    }
    @media only screen and (min-width: 1300px) and (max-width:1900px) {
      font-weight: 400;
      font-size: 50.1195px;
      line-height: 109%;
    }
    @media only screen and (min-width: 900px) and (max-width:1300px) {
      font-weight: 400;
      font-size: 38.6674px;
      line-height: 109%;
    }
    @media only screen and (max-width: 900px) {
      font-weight: 400;
      font-size: 30px;
      line-height: 109%;
      margin-top:49px;
    }
  }
  p {
    font-family: 'Montserrat';
font-style: normal;
color: #000000;
@media only screen and (min-width: 1900px) {
    font-weight: 300;
font-size: 20px;
line-height: 146%;
margin-top:56px;
    }
@media only screen and (min-width: 1300px) and (max-width:1900px){
    font-weight: 300;
font-size: 18px;
line-height: 146%;
margin-top:43.36px;
    }
@media only screen and (min-width: 900px) and (max-width:1300px){
    font-weight: 300;
font-size: 13.8871px;
line-height: 146%;
margin-top:33.46px;
    }
@media only screen and (max-width: 900px) {
    font-weight: 300;
font-size: 13px;
line-height: 146%;
margin-top:23.98px;
    }
  }
  button {
    color: ${props=>props.button==='background'?'#ffffff':'#000000'};
    display:flex;
    align-items:center;
    gap:20px;
    background: ${props=>props.button==='background'?'#12284C':'transparent'};
    border: ${props=>props.button==='border'?'0.75px solid #000000':'transparent'};
border-radius: 90px;
padding:11px 24px;
font-family: 'Montserrat';
font-style: normal;
letter-spacing: 0.125em;
@media only screen and (min-width: 1900px) {
    font-weight: 500;
font-size: 16px;
line-height: 20px;
margin-top:88px;
    }
@media only screen and (min-width: 1300px) and (max-width:1900px){
    font-weight: 500;
font-size: 16px;
line-height: 20px;
margin-top:42px;
    }
@media only screen and  (min-width: 900px) and (max-width:1300px){
    font-weight: 500;
font-size: 13.1307px;
line-height: 16px;
margin-top:33.51px;
    }
@media only screen and (max-width: 900px) {
   width:100%;
   justify-content:center;  
font-weight: 500;
font-size: 13.1307px;
line-height: 16px;
margin-top:44px;
    }
  }
`;
