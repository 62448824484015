import React, { useState } from 'react'
import { ButtonBackground, ButtonBackgroundText, ButtonDetailContainer, ContactAbsoluteConatiner, ContactBlockContainer, ContactContainer } from './contactButton.styled'
import { BsChat, BsTelephone, BsWhatsapp, BsThreeDots, BsFillChatLeftTextFill } from "react-icons/bs";
import { MdOutlineInsights } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from 'react-router-dom';


const ContactButton = () => {
  const [block, setBlock] = useState(false)
  
  return (
    <ContactContainer>
      <ButtonDetailContainer>
      <ContactAbsoluteConatiner visible={block}></ContactAbsoluteConatiner >
        <ButtonBackground onClick={()=>setBlock(!block)}>
        {block ? <AiOutlineClose size='28px'/>: <BsChat size='32px'/> }
        </ButtonBackground>
        <ContactBlockContainer visible={block}>
          <ButtonDetailContainer>
          <a href='tel:+971502731297'><ButtonBackground style={{padding:'12px 14px'}}> <BsTelephone size='22px'/></ButtonBackground></a>
            <ButtonBackgroundText>+971 502 731297</ButtonBackgroundText>
          </ButtonDetailContainer>
          <ButtonDetailContainer>
          <a href='https://wa.me/971502731297'><ButtonBackground style={{padding:'12px 14px'}}> <BsWhatsapp size='22px'/></ButtonBackground></a>
            <ButtonBackgroundText>+971 502 731297</ButtonBackgroundText>
          </ButtonDetailContainer>
          <ButtonDetailContainer>
          <a href='mailto:info@modern-living.ae'><ButtonBackground style={{padding:'14px 14px'}}> <BsFillChatLeftTextFill size='22px'/></ButtonBackground></a>
            <ButtonBackgroundText style={{padding:'12px 20px'}}>info@modern-living.ae</ButtonBackgroundText>
          </ButtonDetailContainer>
          <ButtonDetailContainer>
          <Link to = '/sell'><ButtonBackground style={{padding:'14px 14px'}}> <MdOutlineInsights size='22px'/></ButtonBackground></Link>
            <ButtonBackgroundText>Request property evaluation</ButtonBackgroundText>
          </ButtonDetailContainer>
        </ContactBlockContainer>
      </ButtonDetailContainer>
    </ContactContainer>
  )
}

export default ContactButton