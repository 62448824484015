import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Footer from '../components/Footer/Footer'
import FooterMobile from '../components/Footer/FooterMobile'
import Navbar from '../components/Navbar/Navbar'
import PodcastCard from '../components/PressCenter/PodcastCard'
import { PressCenterSection, PressContainer } from '../components/PressCenter/PressCenter.styled'
import Podcast from '../components/PressCenterTemplate/Podcast'
import SocialLinks from '../components/SocialLinks/SocialLinks'
import { useParams } from 'react-router-dom'
import { getPressCenter, getPressCenterDetail } from '../redux/slices/pressCenter/pressCenter'

const PodcastTemplate = () => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    window.scrollTo(0, 0);
  }, []);
  const {id} = useParams()
  const dispatch = useDispatch();
  const fetchHandler = () =>{
    const pressObject = {
      property:'podcast',
      page:1,
    }
    dispatch(getPressCenter(pressObject))
  }
  useEffect(()=>{
    dispatch(getPressCenterDetail(id))
    fetchHandler();
    window.scrollTo(0, 0);
  },[id, dispatch ])

  const data =  useSelector((state)=> state.presentReducer.pressCenterDetail.attributes);
  const {pressCenterData} =  useSelector((state)=> state.presentReducer);

  return (
    <PressCenterSection>
        <Navbar background='#12284C'/>
        {data && <Podcast data={data}/>}
        <SocialLinks/>
        <PressContainer>
           {pressCenterData[1] &&  <PodcastCard data = {pressCenterData[1]} />}
           {pressCenterData[2]  && <PodcastCard data = {pressCenterData[2]} />}
        </PressContainer>
        {(width < 1204)?<FooterMobile/>:<Footer/>}
    </PressCenterSection>
  )
}

export default PodcastTemplate