import React, { useEffect, useState } from "react";
import {
  MainCollageNav,
  MainCollageContainer,
  BackButtonDiv,
  BackText,
  BackIconImage,
  CollageMainContainer,
  MainCollageStarted,
  MainCollageFigureOne,
  CollageImage,
  MainCollageFigureTwo,
  MainCollageFigureThree,
} from "./PropertyDetail.styled";
import BackIcon from "../../assets/images/back_icon.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPropertiesDetails } from "../../redux/slices/propertiesSlices/getProperties";
import { getProjectsDetail } from "../../redux/slices/projectSlice/getProject";

const MainCollage = () => {
  const {id} = useParams();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const location = useLocation();
  const [property] = useState(location.pathname.split('/')[1]);
  useEffect(() => {
      property==='properties' ? dispatch(getPropertiesDetails(id)) : dispatch(getProjectsDetail(id))
    },[id]);
    const propertiesData = useSelector((state) => state.properties.propertiesDetail.data);
    const projectsData = useSelector((state) => state.project.projectDetail.data);
    const images = property === 'properties' ? propertiesData : projectsData;
const image = images ? images.attributes.image.data : [];
  const groups = [];

  for (let i = 0; i < image.length; i += 3) {
    groups.push(image.slice(i, i + 3));
  }
  return (
    <MainCollageContainer>
      <MainCollageNav>
        <BackButtonDiv onClick={()=>navigate(-1)}>
          <BackIconImage
            src={BackIcon}
            alt=""
            onClick={() => {
            }}
          />
          <BackText>
            Back
          </BackText>
        </BackButtonDiv>
      </MainCollageNav>
      <CollageMainContainer onClick={()=>navigate(`/${property}/detail/carousel/${id}`)}>
        {groups.map((group, index) => (
      
        <MainCollageStarted>
          { group[0] && <MainCollageFigureOne>
            <CollageImage src={group[0] && group[0].attributes.url} alt=""/>
          </MainCollageFigureOne> }
          {group[1] && <MainCollageFigureTwo>
            <CollageImage src={group[1].attributes.url} alt="" />
          </MainCollageFigureTwo>}
          {group[2] && <MainCollageFigureThree>
            <CollageImage src={group[2].attributes.url} alt="" />
          </MainCollageFigureThree>}
        </MainCollageStarted>
        
      ))}
      </CollageMainContainer>
    </MainCollageContainer>
  );
};

export default MainCollage;
