import React from "react";
import Step1 from "../../assets/images/handshake.svg";
import Step2 from "../../assets/images/Group708.svg";
import Step3 from "../../assets/images/Group709.svg";
import Step4 from "../../assets/images/Group710.svg";
import {
  AgencyContainer,
  AgencyContentContainer,
  AgencySection,
  AwardBox,
  SingleAward,
  SingleAwardText,
} from "./AgencyYouCanTrust.styled";
import { useTranslation } from "react-i18next";

const AgencyYouCanTrust = ({header , desc, data, number}) => {
  const { t } = useTranslation();
  return (
    <AgencySection>
      <AgencyContentContainer>
        <h1>{t(header)}</h1>
        <p>
          {t(desc)}
        </p>
        <AgencyContainer>
          <SingleAward>
            <AwardBox>
              {number==='display' && <h1>500+</h1>}
              <img src={Step1} alt="" />
            </AwardBox>
            <SingleAwardText>{t(data[0])}</SingleAwardText>
          </SingleAward>
          <SingleAward>
            <AwardBox>
              {number==='display' && <h1>90+</h1>
}              <img src={Step2} alt="" />
            </AwardBox>
            <SingleAwardText>{data[1]}</SingleAwardText>
          </SingleAward>
          <SingleAward>
            <AwardBox>
              {number==='display' && <h1>200+</h1>}
              <img src={Step3} alt="" />
            </AwardBox>
            <SingleAwardText>{data[2]}</SingleAwardText>
          </SingleAward>
          <SingleAward>
            <AwardBox>
              {number==='display' && <h1>30+</h1>
}              <img src={Step4} alt="" />
            </AwardBox>
            <SingleAwardText>{data[3]}</SingleAwardText>
          </SingleAward>
          {data[4] && <SingleAward>
            <AwardBox>
              {number==='display' && <h1>500+</h1>}
              <img src={Step1} alt="" />
            </AwardBox>
            <SingleAwardText>{data[4]}</SingleAwardText>
          </SingleAward>}
        </AgencyContainer>
      </AgencyContentContainer>
    </AgencySection>
  );
};

export default AgencyYouCanTrust;
