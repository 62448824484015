import axios from 'axios';
import API_URL from '../constant';

export async function contactUs(data) {
  const { full_name, email, phone, msg, document, unit_type, category, budget } = data;
  const dataStract = {
    full_name,
    email,
    phone,
    unit_type,
    category,
    budget,
    msg
  };

  var formData = new FormData();
  formData.append('data', JSON.stringify(dataStract));
  formData.append('files.file', document);

  try {
    const response = await axios.post(`${API_URL}contact-forms`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return response;
  } catch (error) {
    return error;
  }
}
