import React from "react";
import { useEffect } from "react";
import { PaddingTopSection } from "../components/SetupSteps/SetupStep.styled";
import { useState } from "react";
import Contact from "../components/ContactUs/Contact";
import CustomizedSnackbar from "../components/CustomizedSnackbars/CustomizedSnackbar";
import Footer from "../components/Footer/Footer";
import FooterMobile from "../components/Footer/FooterMobile";
import Navbar from "../components/Navbar/Navbar";
import SetupStepRealEstate from "../components/SetupSteps/SetupStepRealEstate";
import HeroImage from "../assets/images/refurhero.png";
import HeroBusiness from "../components/HeroLandingPage/HeroBusiness";
import Step1 from "../assets/images/partner1.svg";
import Step2 from "../assets/images/partner2.svg";
import Step3 from "../assets/images/partner3.svg";
import Step4 from "../assets/images/partner4.svg";
import Step5 from "../assets/images/partner5.svg";
import Step6 from "../assets/images/partner6.svg";
const TabletBreakpoint = 1024;

const text = [
  { text: "Modern Working", img: Step1 },
  { text: "Modern Life Club", img: Step2 },
  { text: "Smith Klein Group", img: Step3 },
  { text: "Stone Real Estate Investment", img: Step4 },
  { text: "Softversum", img: Step5 },
  { text: "YBeta", img: Step6 },
];
const BusinessPartner = () => {
  const [offset, setOffset] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [background, setBackground] = useState("transparent");
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  useEffect(() => {
    if (offset > 100) setBackground("#12284C");
    else setBackground("transparent");
  }, [offset]);
  return (
    <div>
      <Navbar background={background} />
      <HeroBusiness
        heroImage={HeroImage}
        header="Strategic Alliances for Endless Possibilities"
        desc="Together, we can leverage our collective strengths, expertise, and resources to achieve unprecedented growth and success."
      />
      <PaddingTopSection>
        <SetupStepRealEstate
          data={text}
          header={"Our collaboration partner network"}
        />
      </PaddingTopSection>

      <Contact displaymsg="block" displayupload="none" />
      {width < TabletBreakpoint ? <FooterMobile /> : <Footer />}
      <CustomizedSnackbar />
    </div>
  );
};

export default BusinessPartner;
