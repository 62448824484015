import styled from "styled-components";
import ReactPlayer from 'react-player'



export const VideoCard = styled.div`
  width: 100%;
  overflow: hidden;
  height: auto;
  padding-top: 100px;
  div {
    margin: auto;
    width: 90%;
  }
`;

export const VideoHeaderText = styled.h1`
  display: ${(props) => props.headerDisplay};
  font-family: "Didot";
  font-style: normal;
  color: #000000;
  text-align: ${(props) => props.align};
  @media only screen and (min-width: 1920px) {
    font-weight: 400;
    font-size: 64px;
    line-height: 129%;
    margin-top: ${(props) => (props.marginTop === "true" ? "145px" : "")};
    margin-bottom: 87px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 400;
    font-size: 48px;
    line-height: 129%;
    margin-top: ${(props) => (props.marginTop === "true" ? "128px" : "")};
    margin-bottom: 47px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 400;
    font-size: 32px;
    line-height: 129%;
    margin-top: ${(props) => (props.marginTop === "true" ? "80px" : "")};
    margin-bottom: 10px;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 400;
    font-size: 32px;
    line-height: 129%;
    margin-top: ${(props) => (props.marginTop === "true" ? "65px" : "")};
    margin-bottom: 32px;
  }
  @media only screen and (max-width: 400px) {
    font-weight: 400;
    font-size: 32px;
    line-height: 129%;
    margin-top: ${(props) => (props.marginTop === "true" ? "65px" : "")};
    margin-bottom: 32px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 400;
    font-size: 32px;
    line-height: 129%;
    margin-top: ${(props) => (props.marginTop === "true" ? "80px" : "")};
    margin-bottom: 10px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 400;
    font-size: 32px;
    line-height: 129%;
    margin-top: ${(props) => (props.marginTop === "true" ? "80px" : "")};
    margin-bottom: 10px;
  }
`;
export const VideoDesc = styled.p`
  display: ${(props) => props.descDisplay};
  font-family: "Montserrat";
  font-style: normal;
  color: #000000;
  text-align: justify;
  text-justify: inter-word;
  @media only screen and (min-width: 1920px) {
    font-weight: 300;
    font-size: 20px;
    line-height: 146%;
    margin-bottom: 75px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 300;
    font-size: 20px;
    line-height: 146%;
    margin-bottom: 83px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 300;
    font-size: 16px;
    line-height: 146%;
    margin-bottom: 80px;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 300;
    font-size: 13px;
    line-height: 146%;
    margin-bottom: 68px;
  }
  @media only screen and (max-width: 400px) {
    font-weight: 300;
    font-size: 13px;
    line-height: 146%;
    margin-bottom: 68px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 300;
    font-size: 16px;
    line-height: 146%;
    margin-bottom: 80px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 300;
    font-size: 16px;
    line-height: 146%;
    margin-bottom: 80px;
  }
`;
