
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AboutHeader,
} from "../AboutUs/AboutUs.styled";
import { ClientContainer, ClientDesc, ClientDescContainer, ClientName, ClientScrollContainer, ClientSection, ClientSlider, SliderButton } from "./TrustedClient.styled";

const Trustedclients = ({comments}) => {
  const [active,setActive] = useState(0);
  const [width, setWidth] = useState(window.innerWidth)
  const [index, setIndex] = React.useState(0);
  const SliderRef = useRef();
  const {t} = useTranslation()
  const delay = 2000;

  useEffect(()=>{
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  },[])

 useEffect(() => {
    setTimeout(() =>
        setIndex((prevIndex) =>
          prevIndex === comments.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => scroll(index);
  }, [index, comments.length]);

  const scroll = (id)=>{
    setActive(id);
    if(width>=1901){
      let number = (id*245)+1;
      if (SliderRef.current) {
        SliderRef.current.scrollTo(0,number)
      };
    }
    if(width<1901 && width>=1301){
      let number = (id*200)+1;
      if (SliderRef.current){
      SliderRef.current.scrollTo(0,number);
      }  
    }
    if(width<1301 && width>=901){
      let number = (id*180)+1;
      if (SliderRef.current) {
      SliderRef.current.scrollTo(0,number);
      }
    }
    if(width<900){
      let number = (id*width*0.85)+(id*10);
      if (SliderRef.current) {
      SliderRef.current.scrollTo(number,0);
      }
    }
  }
  
  return (
    <ClientSection>
      <ClientContainer>
      <AboutHeader>{t('What our clients say')}</AboutHeader>
      <ClientScrollContainer ref={SliderRef}>
        {comments.map((comment,i)=>{
          return(
            <ClientDescContainer key={i}>
        <ClientDesc>
          {comment.attributes.comments}
        </ClientDesc>
        <div>
        <ClientName>{comment.attributes.customer_name}</ClientName>
        {/* <ClientJob>{comment.attributes.job_title}</ClientJob> */}
        </div>
      </ClientDescContainer>
          )
        })}
      </ClientScrollContainer>
      <ClientSlider>
        {(() => {
          let td = [];
          for (let i = 0; i < comments.length; i++) {
            td.push(i);
          }
          return td.map((t, id) => {
            return (
              <SliderButton key={id} active={id===active} onClick={()=>scroll(id)}></SliderButton>
            );
          });
        })()}
      </ClientSlider>
      </ClientContainer>
    </ClientSection>
  );
};

export default Trustedclients;
