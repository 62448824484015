import axios from 'axios';
import API_URL from '../constant';

export function requestPressCenter(data) {
    const url = `${API_URL}press-centers?populate=*${data.page ? `&pagination[page]=${data.page}`:''}&pagination[pageSize]=8&filters[is_${data.property}]=true${data.filter ? `&filters[tags][value]=${data.filter}` :''}`
    return axios.request({
        method: 'get',
        url: url

    })
}

export function requestPressCenterDetail(data) {
    const url = `${API_URL}press-centers/${data}?populate=*`
    return axios.request({
        method: 'get',
        url: url

    })
}