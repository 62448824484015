import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar/Navbar";
import FooterMobile from "../components/Footer/FooterMobile";
import Footer from "../components/Footer/Footer";
import ProjectPageDetail from "../components/ProjectDetail/ProjectPageDetail";
import { DetailsBackground } from "../components/PropertyDetail/PropertyDetail.styled";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProjectsDetail } from "../redux/slices/projectSlice/getProject";


const TabletBreakpoint = 1024;
export const ProjectDetail  = () => {
  const {id} = useParams()
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProjectsDetail(id))
  }, [])


  const data = useSelector((state) => state.project.projectDetail.data)
 
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    window.scrollTo(0, 0);
  }, []);
  

return (
    <DetailsBackground>
      <Navbar background='#12284c'/>
      {data &&
        <ProjectPageDetail projectDetails={data} />
      }
      {width < TabletBreakpoint ? <FooterMobile /> : <Footer />}
    </DetailsBackground>
  );
};
