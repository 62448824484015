import styled from "styled-components";

export const CompanyHeroSection = styled.section`
  width: 100%;
  height: 100vh;
  background: ${(props) => `url(${props.img})`};
  background-repeat: no-repeat;
  background-size: cover;
`;
export const Overlay = styled.div`
  position: absolute;
  top: 0;
  background: rgba(18, 40, 76, 0.85);
  opacity: 1;
  width: 100%;
  height: 100%;
`;
export const CompanyHeroContentContainer = styled.div`
  width: 90%;
  margin: auto;
  height: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`;
export const HeroHeaderText = styled.h1`
  font-style: normal;
  text-align: center;
  color: #ece1ce;
  @media only screen and (min-width: 1920px) {
    font-weight: 400;
    font-size: 64px;
    line-height: 146%;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 400;
    font-size: 64px;
    line-height: 146%;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 400;
    font-size: 42px;
    line-height: 146%;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 400;
    font-size: 30px;
    line-height: 146%;
  }
  @media only screen and (max-width: 400px) {
    font-weight: 400;
    font-size: 30px;
    line-height: 146%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 400;
    font-size: 42px;
    line-height: 146%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 400;
    font-size: 42px;
    line-height: 146%;
  }
`;
export const HeroSubText = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  text-align: center;
  color: #ffffff;
  @media only screen and (min-width: 1920px) {
    font-weight: 300;
    font-size: 20px;
    line-height: 146%;
    margin-top: 50px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 300;
    font-size: 20px;
    line-height: 146%;
    margin-top: 50px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 300;
    font-size: 18px;
    line-height: 146%;
    margin-top: 36px;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 300;
    font-size: 18px;
    line-height: 146%;
    margin-top: 40px;
  }
  @media only screen and (max-width: 400px) {
    font-weight: 300;
    font-size: 18px;
    line-height: 146%;
    margin-top: 40px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 300;
    font-size: 18px;
    line-height: 146%;
    margin-top: 36px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 300;
    font-size: 18px;
    line-height: 146%;
    margin-top: 36px;
  }
`;
export const HeroButton = styled.button`
  display: flex;
  align-items: center;
  width: fit-content;
  background: #ece1ce;
  border-radius: 136.72px;
  border: transparent;
  margin: auto;
  @media only screen and (min-width: 1920px) {
    gap: 36.46px;
    padding: 10px 36px;
    margin-top: 45px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    gap: 36.46px;
    padding: 10px 36px;
    margin-top: 45px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    gap: 24px;
    padding: 6.5px 24px;
    margin-top: 28px;
  }
  @media only screen and (max-width: 900px) {
    gap: 24px;
    padding: 6.5px 24px;
    margin-top: 33px;
  }
  @media only screen and (max-width: 400px) {
    gap: 24px;
    padding: 6.5px 24px;
    margin-top: 33px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    gap: 24px;
    padding: 6.5px 24px;
    margin-top: 28px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    gap: 24px;
    padding: 6.5px 24px;
    margin-top: 28px;
  }

  img {
    @media only screen and (min-width: 1920px) {
      width: 43px;
      height: 41px;
    }
    @media only screen and (min-width: 1300px) and (max-width: 1920px) {
      width: 43px;
      height: 41px;
    }
    @media only screen and (min-width: 900px) and (max-width: 1300px) {
      width: 28px;
      height: 27px;
    }
    @media only screen and (max-width: 900px) {
      width: 28px;
      height: 27px;
    }
    @media only screen and (max-width: 400px) {
      width: 28px;
      height: 27px;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
      width: 28px;
      height: 27px;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
      width: 28px;
      height: 27px;
    }
  }

  h3 {
    font-family: "Montserrat";
    font-style: normal;
    display: flex;
    align-items: center;
    letter-spacing: 0.125em;
    color: #58431a;
    @media only screen and (min-width: 1920px) {
        font-weight: 500;
      font-size: 18px;
      line-height: 22px;
        }
    @media only screen and (min-width: 1300px) and (max-width:1920px){
        font-weight: 500;
      font-size: 18px;
      line-height: 22px;
        }
    @media only screen and (min-width: 900px) and (max-width:1300px){
        font-weight: 500;
      font-size: 11.8204px;
      line-height: 14px;
        }
    @media only screen and (max-width: 900px) {
        font-weight: 500;
      font-size: 11.8204px;
      line-height: 14px;
        }
    @media only screen and (max-width: 400px) {
        font-weight: 500;
      font-size: 11.8204px;
      line-height: 14px;
        }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
        font-weight: 500;
      font-size: 11.8204px;
      line-height: 14px;
        }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
        font-weight: 500;
      font-size: 11.8204px;
      line-height: 14px;
        }
  }
`;
