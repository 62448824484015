import React from "react";
import { AnchorTag, PreferencesHeading, PreferencesMainDiv } from "./SearchProject.styled";

export const Preferences = () => {
  return (
    <PreferencesMainDiv>
      <PreferencesHeading>Don’t want the hassle?</PreferencesHeading>
      <PreferencesHeading>
        <AnchorTag href="/preferences">Enter your preferences</AnchorTag>, let us handle everything.
      </PreferencesHeading>
    </PreferencesMainDiv>
  );
};
