import React, { useEffect, useState } from 'react'
import Footer from '../components/Footer/Footer'
import FooterMobile from '../components/Footer/FooterMobile'
import Navbar from '../components/Navbar/Navbar'
import PressCenterComponent from '../components/PressCenter/PressCenterComponent'
import CustomizedSnackbar from '../components/CustomizedSnackbars/CustomizedSnackbar'


const PressCenter = () => {
    const [width, setWidth] = useState(window.innerWidth)
    useEffect(()=>{
        window.addEventListener("resize", () => setWidth(window.innerWidth));
        window.scrollTo(0, 0);
      },[])
  return (
    <div>
        <Navbar background='#12284C'/>
        <PressCenterComponent/>
        {(width < 1204)?<FooterMobile/>:<Footer/>}
        <CustomizedSnackbar/>
    </div>
  )
}

export default PressCenter