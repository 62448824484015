import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BackGround,
  Link,
  MainHeadingProject,
  Para,
  ParaTwo,
  Preferences,
  ProjectImageDiv,
  ProjectPage,
} from "./Project.styled";

import { ProjectAbout } from "./ProjectAbout";
import { Faq } from "./Faq";
import { getFutureProjects } from "../../redux/slices/projectSlice/getFutureProject";
import { useNavigate, useParams } from "react-router-dom";
import { setProjectSearch } from "../../redux/slices/heroProjectSearch/heroProjectSearchSlices";
import { useTranslation } from "react-i18next";
import { getLocation } from "../../redux/slices/location/locationSlice";
import useDropdown from "../useDropdown";
import { SearchButtons } from "../SearchProjects/SearchButtons";
import { SearchOneDiv } from "../SearchProjects/SearchProject.styled";
import Latestprojects from "../LatestProjects/Latestprojects";

const ProjectHero = () => {
  const { id } = useParams();
  const [unit, setUnit] = useState("Unit Type");
  const [community, setCommunity] = useState("");
  const [area, setArea] = useState("");
  const [areaActive, setAreaActive] = useState(false);
  const [unitActive, setUnitActive] = useState(false);
  const [price, setPrice] = useState("Price");
  const [priceActive, setPriceActive] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const { t } = useTranslation();
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFutureProjects("ready"));
  }, []);

  const handleInput = (event) => {
    setAreaActive(true);
    const newValue = event.target.value;
    if (newValue.trim() === "") {
      setCommunity("");
    } else {
      setCommunity(newValue);
    }
  };

  useEffect(() => {
    let words = community.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
    const joinedWords = capitalizedWords.join(" ");
    setArea(joinedWords);
    dispatch(getLocation(joinedWords));
  }, [community, dispatch]);

  const priceRange = {
    Price: {
      lower: 0,
      higher: 0,
    },
    "0-100K": {
      lower: 0,
      higher: 100000,
    },
    "100K-500K": {
      lower: 100000,
      higher: 500000,
    },
    "500K-1M": {
      lower: 500000,
      higher: 1000000,
    },
    "1M-2M": {
      lower: 1000000,
      higher: 2000000,
    },
    "2M-5M": {
      lower: 2000000,
      higher: 5000000,
    },
    "5M+": {
      lower: 5000000,
      higher: 5000000,
    },
  };
  const navigate = useNavigate();
  const HandleSearch = (e) => {
    e.preventDefault();
    const searchObject = {
      type: id,
      community: community,
      unittype: unit === "Unit Type" ? "" : unit,
      price: price === "Price" ? "" : priceRange[price],
      page: 1,
    };
    dispatch(setProjectSearch(searchObject));
    navigate(`/projects/search/${id}`);
  };
  const { projectData } = useSelector((state) => state.project);
  const data = useSelector((state) => state.location);
  const [dropdownRefOne, isActiveOne, setIsActiveOne] = useDropdown();
  const [dropdownRefTwo, isActiveTwo, setIsActiveTwo] = useDropdown();
  const [dropdownRefThree, isActiveThree, setIsActiveThree] = useDropdown();
  return (
    <BackGround>
      <ProjectImageDiv>
        <MainHeadingProject>
          <Para>
            {" "}
            Search our {id} <br /> project listings!{" "}
          </Para>
        </MainHeadingProject>
        <SearchOneDiv>
          <SearchButtons
            border="1px solid #DECDAD"
            color="#DECDAD !important"
            menuback="transparent !important"
            menucolor="white !important"
            menuitemcolor="black !important"
            menuitembackgroundhover="#DECDAD !important"
            arrow="#DECDAD !important"
            activecolor="#ffffff"
          />
        </SearchOneDiv>
        {width > 900 && (
          <Preferences>
            <ParaTwo>
              Looking for something unique? <br />
              <Link href="/preferences">
                <b>Set your preferences</b>
              </Link>
              ,&nbsp; and our specialists will help you.
            </ParaTwo>
          </Preferences>
        )}
      </ProjectImageDiv>
      <ProjectAbout project={id} />
      <ProjectPage>
      {projectData.length > 0 && <Latestprojects nineteen="0px" thirteen="0px" nine="0px" four="0px" background="rgba(238, 242, 244, 0.7)" projects={projectData} />}
      </ProjectPage>
      <Faq />
    </BackGround>
  );
};

export default ProjectHero;
