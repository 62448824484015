import React from "react";
import {
  BackButtonContainer,
  BlogText,
  PathIcon,
  TemplateDateSpan,
  TemplateHeaderText,
  TemplateImageContainer,
  TemplatePathSpan,
  TemplateSection,
} from "./PressCenterTemplate.styled";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link } from "react-router-dom";


const PressRelease = ({data}) => {
  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
]; 

  var p = data ? data.date.split('T')[0].split('-'):'';
  const date = p[2] + ' ' + monthNames[Number(p[1]).toString()-1]  + ' ' + p[0];
  return (
    <TemplateSection>
      <BackButtonContainer>
        <Link to = '/press_release'>
        <button>
          <KeyboardBackspaceIcon />
        </button>
        </Link>
        <h4>Back</h4>
      </BackButtonContainer>
      <TemplatePathSpan>
      <Link to = '/'>Home</Link> &nbsp;&nbsp;<PathIcon/>&nbsp;&nbsp;<Link to = '/press_release'>Press release</Link> &nbsp;&nbsp;<PathIcon/> &nbsp;&nbsp;<span>{data.title}</span>
      </TemplatePathSpan>
      <TemplateHeaderText>
        {data? data.title: 'Invest in the heart of Dubai! Burj Khalifa Investment!'}
      </TemplateHeaderText>
      <TemplateDateSpan>
        <span>Press release</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{date}
      </TemplateDateSpan>
      {data.featured_image.data && <TemplateImageContainer img={data.featured_image.data.attributes.url}/>}
      {/* <TemplatePara1>{data.attributes.short_description}</TemplatePara1> */}
      <BlogText>{data? data.blogs :''}</BlogText>
    </TemplateSection>
  );
};

export default PressRelease;
