import React from 'react'
import { useTranslation } from 'react-i18next';
import { SingleStepContainer, StepContainer, StepHeader, StepIcon, StepSection, StepText } from './SetupStep.styled'
// import Step1 from '../../assets/images/Step1.svg'
// import Step2 from '../../assets/images/Step2.svg'
// import Step3 from '../../assets/images/Step3.svg'
// import Step4 from '../../assets/images/Step4.svg'
// import Step5 from '../../assets/images/Step5.svg'
// import Step6 from '../../assets/images/Step6.svg'

const SetupStepRealEstate = ({data, header}) => {
  const { t } = useTranslation();
  return (
    <StepSection>
        <StepHeader>{header}</StepHeader>
        <StepContainer>
            {data.map((i)=>{
                return (
                <SingleStepContainer>
                  <StepIcon src={i.img}/>
                <StepText>{t(i.text)}</StepText>
            </SingleStepContainer>)
            })}
            
                
               
        </StepContainer>
    </StepSection>
  )
}

export default SetupStepRealEstate