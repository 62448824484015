import React, { useEffect, useRef, useState } from "react";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import {
  BoxStyle,
  CtaButton,
  FilterContainer,
  FilterScrollDiv,
  HeaderContainer,
  HeaderText,
  PageContainer,
  PageIcon,
  PropertyListingSection,
  PropertyScrollContainer,
  ScrollButton,
  ScrollContainer,
  SinglePropertyCard,
  SubText,
} from "./PropertyListing.styled";
import { PropertyCard } from "./PropertyCard";
import { TfiArrowCircleLeft, TfiArrowCircleRight } from "react-icons/tfi";
import Modal from "@mui/material/Modal";
import { PressFilterButton } from "../PressCenter/PressCenter.styled";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import SubscribePopUp from "../PopUps/SubscribePopUp";
import { useTranslation } from "react-i18next";

const Propertylisting = ({ properties }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const containerRef = useRef();
  const singleContainerRef = useRef();
  const filterRef = useRef();
  const [active, setActive] = useState(0);
  const [scrollButton, setScrollButton] = useState(0);
  const [filterprop, setFilterProp] = useState("");
  const [filteredProperties, setFilteredProperties] = useState(
    properties.filter((i) => i.attributes.unit_type.includes(filterprop))
  );
  const [factor, setFactor] = useState(Math.ceil(containerRef.current ? containerRef.current.scrollLeft/width:0))
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {t} = useTranslation()


  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);
  
  useEffect(() => {
    const filterProperties = properties.filter((i) =>
      i.attributes.unit_type.includes(filterprop)
    );
    setFilteredProperties(filterProperties);
  }, [filterprop, properties]);
  useEffect(() => {
  
    const { scrollWidth } = containerRef.current;
    setScrollButton(Math.ceil(scrollWidth/width))
  }, [width, filteredProperties.length]);


  useEffect(()=>{
    setActive(factor);
  },[factor])

  const scrollHandler = (value) => {
    const { offsetWidth } = containerRef.current;
    setActive(value);
    const n = value * offsetWidth;
    if (containerRef.current) {
      containerRef.current.scrollTo(n, 0);
    }
  };
 

  const handleScroll = (e) => {
    const { scrollWidth, offsetWidth, scrollLeft } = containerRef.current;
    setFactor(Math.ceil(scrollLeft/width))
    if (scrollLeft === 0) setActive(0);
    if (scrollLeft >= scrollWidth - offsetWidth) setActive(Math.round(scrollButton));
  };
  return (
    <PropertyListingSection>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <BoxStyle>
          <SubscribePopUp open={open} setOpen={setOpen} />
        </BoxStyle>
      </Modal>
      <HeaderContainer>
        <HeaderText>{t('Top Properties')}</HeaderText>
        <CtaButton onClick={handleOpen}>
          {width > 500 && <EmailOutlinedIcon size={5} />}
          {t('SUBSCRIBE')}
        </CtaButton>
      </HeaderContainer>
      <HeaderContainer>
        <SubText>
          {t('We have handpicked some exquisite properties for you to choose from.')}
        </SubText>
      </HeaderContainer>
      <FilterScrollDiv>
        {width < 1530 && (
          <MdKeyboardArrowLeft
            size={30}
            style={{ marginLeft: "auto" }}
            onClick={() => (filterRef.current.scrollLeft -= 50)}
          />
        )}
        <FilterContainer ref={filterRef}>
          <PressFilterButton
            border={filterprop === ""}
            onClick={() => setFilterProp("")}
          >
            {t('All')}
          </PressFilterButton>
          <PressFilterButton
            border={filterprop === "Apartment"}
            onClick={() => setFilterProp("Apartment")}
          >
            {t('Apartment')}
          </PressFilterButton>
          <PressFilterButton
            border={filterprop === "Villa"}
            onClick={() => setFilterProp("Villa")}
          >
            {t('Villa')}
          </PressFilterButton>
          <PressFilterButton
            border={filterprop === "Townhouse"}
            onClick={() => setFilterProp("Townhouse")}
          >
            {t('Townhouse')}
          </PressFilterButton>
          <PressFilterButton
            border={filterprop === "Penthouse"}
            onClick={() => setFilterProp("Penthouse")}
          >
            {t('Penthouse')}
          </PressFilterButton>
          <PressFilterButton
            border={filterprop === "Commercial"}
            onClick={() => setFilterProp("Commercial")}
          >
            {t('Commercial')}
          </PressFilterButton>
        </FilterContainer>
        {width < 1530 && (
          <MdKeyboardArrowRight
            size={30}
            style={{ marginLeft: "auto" }}
            onClick={() => (filterRef.current.scrollLeft += 50)}
          />
        )}
      </FilterScrollDiv>
      <PageContainer>
        {width > 900 && (
          <PageIcon
            disabled={active === 0}
            onClick={() => scrollHandler(active - 1)}
          >
            <TfiArrowCircleLeft size={28} />
          </PageIcon>
        )}

        <PropertyScrollContainer ref={containerRef} onScroll={handleScroll}>
          {filteredProperties.map((property, id) => {
            return (
              <SinglePropertyCard ref={singleContainerRef}>
                <PropertyCard property={property} key={property.id} />
              </SinglePropertyCard>
            );
          })}
        </PropertyScrollContainer>
        {width > 900 && (
          <PageIcon
            disabled={active===scrollButton}
            onClick={() => scrollHandler(active + 1)}
          >
            <TfiArrowCircleRight size={28} />
          </PageIcon>
        )}
      </PageContainer>
      
      
        <ScrollContainer>
          {(() => {
            let td = [];
            for (let i = 0; i <= scrollButton; i++) {
              td.push(i);
            }
            return td.map((t, id) => {
              return (
                <ScrollButton
                  key={id}
                  active={id === active}
                  onClick={() => scrollHandler(id)}
                ></ScrollButton>
              );
            });
          })()}
        </ScrollContainer>
      
    </PropertyListingSection>
  );
};

export default Propertylisting;
