import { Skeleton } from '@mui/material';
import React from 'react'
import {PodcastContainer, PodcastImg, PodcastDate, PodcastHeaderText, PodcastButton, PodcastContentContainer} from './PressCenter.styled';
import { HiOutlineArrowLongRight } from 'react-icons/hi2';
import { Link } from 'react-router-dom';
const PodcastCard = ({data}) => {
  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
  const p = data.attributes.date.split('-');
  const date = p[2] + ' ' + monthNames[Number(p[1]).toString()-1]  + ' ' + p[0]
  return (
    <PodcastContainer>
    <PodcastImg img={data.attributes.featured_image.data.attributes.url}>{
      data.attributes.featured_image.data.attributes.url?'':
    <Skeleton
          animation="wave"
          variant="rectangular"
          width="100%"
          height="100%"
        />}
    </PodcastImg>
    <PodcastContentContainer>
      <PodcastDate>{(data.attributes.featured_image.data.attributes.url)?
        (date):
      <Skeleton
          animation="wave"
          variant="rectangular"
          width="100%"
          height="100%"
        />}
      </PodcastDate>
      <PodcastHeaderText height={data.attributes.title}>{data.attributes.title?data.attributes.title:
      <Skeleton
          animation="wave"
          variant="rectangular"
          width="100%"
          height="100%"
        />}
      </PodcastHeaderText>
      <Link to = {`/podcast/${data.id}`}><PodcastButton>Watch<HiOutlineArrowLongRight size={30}/></PodcastButton></Link>
    </PodcastContentContainer>
  </PodcastContainer>
  )
}

export default PodcastCard