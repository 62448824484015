import styled from "styled-components";

export const SocialBorderContainer = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  div {
    width: 50%;
    height: 0.45px;
    border-bottom: 0.456316px solid #000000;
    box-sizing: border-box;
  }
  p {
    box-sizing: border-box;
    min-width: fit-content;
    font-family: "Montserrat";
    font-style: normal;
    letter-spacing: 0.42em;
    color: #494949;
    @media (min-width: 1900px) {
      font-weight: 300;
      font-size: 16px;
      line-height: 20px;
    }
    @media ((min-width: 1300px) and (max-width:1900px)) {
      font-weight: 300;
      font-size: 13.2033px;
      line-height: 16px;
    }
    @media ((min-width: 900px) and (max-width:1300px)) {
      font-weight: 300;
      font-size: 10.4301px;
      line-height: 13px;
    }
    @media (max-width: 900px) {
      font-weight: 300;
      font-size: 10.4301px;
      line-height: 13px;
    }
  }
`;
export const SocialLinksContainer = styled.div`
    margin-top:25px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 25px;
  img {
    width: 40px;
    height: 40px;
  }
`;
export const RelatedCotainer = styled.div`
  width: 90%;
  margin: auto;
  margin-top:25px;
  display: flex;
  gap: 10px;
  @media (max-width:900px){
        justify-content:center;
    }
  h3 {
    font-family: "Didot";
    font-style: normal;
    letter-spacing: 0.03em;
    color: #0a0a0a;
    @media (min-width: 1900px) {
      font-weight: 400;
      font-size: 48px;
      line-height: 61px;
    }
    @media ((min-width: 1300px) and (max-width:1900px)) {
      font-weight: 400;
      font-size: 39.6099px;
      line-height: 50px;
    }
    @media ((min-width: 900px) and (max-width:1300px)) {
      font-weight: 400;
      font-size: 31.2902px;
      line-height: 40px;
    }
    @media (max-width: 900px) {
      font-weight: 400;
      font-size: 31.2902px;
      line-height: 40px;
    }
  }
  img {
    width:40px;
    height:40px;
    @media (max-width:900px){
        display:none;
    }
  }
`;
