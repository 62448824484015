import { put, retry } from 'redux-saga/effects';
import { requestPressCenter, requestPressCenterDetail } from '../../request/pressCenter/pressCenter.js';
import { setPressCenterContent, setPressCenterDetail, setPressCenterMeta } from '../../../slices/pressCenter/pressCenter';
import { showSnackBar } from '../../../slices/snackbarSlice/snackbarSlice';

export function* handlePressCenter(action) {
    try {
        const response = yield retry(0, 0, requestPressCenter, action.payload);
        const { data,meta } = response.data;
        yield put(setPressCenterContent({ data}));
        yield put(setPressCenterMeta({ meta}));
    } catch (error) {
        const snackbarObject = {
            type: 'error',
            message: 'Press Center Failed ',
            open: true,
        };
        yield put(showSnackBar(snackbarObject));
    }
}

export function* handlePressCenterDetail(action) {
    try {
        const response = yield retry(0, 0, requestPressCenterDetail, action.payload);
        const { data} = response;
        yield put(setPressCenterDetail({ data}));
    } catch (error) {
        const snackbarObject = {
            type: 'error',
            message: 'Press Center Failed ',
            open: true,
        };
        yield put(showSnackBar(snackbarObject));
    }
}