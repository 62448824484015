import {put, retry} from "redux-saga/effects";
import { setAgentsContent, hasError } from "../../../slices/agentSlice/getAgent";
import { requestGetAgents } from "../../request/agent/agents"

export function* handleAgents(action) {
    try {
        const response = yield retry(0,0,requestGetAgents, action.payload);
        const {data} = response.data;
        yield put(setAgentsContent(data));
    } catch (error) {
        yield put(hasError(error))
    }
}
