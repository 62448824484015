import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import React, { useRef, useState, useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import {
  FilterButton,
  PageIcon,
  ScrollButton,
  ScrollContainer,
} from "../PropertyListing.js/PropertyListing.styled";


import {
  DropdownContainer,
  DropdownDiv,
  ProjectHeadContainer,
  ProjectHeadContainerNoor,
  ProjectHeadText,
  ProjectPaginationContainer,
  ProjectScrollContainer,
  ProjectSection,
} from "./LatestProjects.styled";
import { useDispatch } from "react-redux";
import { getFutureProjects } from "../../redux/slices/projectSlice/getFutureProject";
import { ProjectCardDiv } from "../propertiesListing/propertylisting.styled";
import NewProjectCard from "../SearchProjects/NewProjectCard";
import { TfiArrowCircleLeft, TfiArrowCircleRight } from "react-icons/tfi";
import { FilterButtonLoad } from "../Project/Project.styled";

const Latestprojects = ({
  projects,
  nineteen,
  thirteen,
  nine,
  four,
  background,
}) => {
  const [width, setWidth] = useState(window.innerWidth);
  const location = useLocation();
  const [scrollButton, setScrollButton] = useState(0);
  const containerRef = useRef();
  const [factor, setFactor] = useState(0);
  const projectscategory = useParams();
  const isProjectPage = location.pathname === `/projects/${projectscategory.id}`;
  
  const [active, setActive] = useState(0);
  const [display, setDisplay] = useState("none");
  const [filter, setFilter] = useState("Offplan");

  const dispatch = useDispatch();

  useEffect(() => {
    const updateWidth = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  useEffect(() => {
    const { scrollWidth } = containerRef.current;
    setScrollButton(Math.ceil(scrollWidth / width));
    setActive(factor);
  }, [width, factor, projects.length]);

  const scrollHandler = (value) => {
    const { offsetWidth } = containerRef.current;
    setActive(value);
    const n = value * offsetWidth;
    containerRef.current.scrollTo(n, 0);
  };

  const handleScroll = () => {
    const { scrollWidth, offsetWidth, scrollLeft } = containerRef.current;
    setFactor(Math.ceil(scrollLeft / width));

    if (scrollLeft === 0) {
      setActive(0);
    }
    if (scrollLeft >= scrollWidth - offsetWidth) {
      setActive(Math.round(scrollButton));
    }
  };

  useEffect(() => {
    dispatch(getFutureProjects(filter));
  }, [filter, dispatch]);

  return (
    <ProjectSection
      nineteen={nineteen}
      thirteen={thirteen}
      nine={nine}
      four={four}
      background={background}
    >
      {isProjectPage ? (
        <ProjectHeadContainerNoor>
          <ProjectHeadText>Featured Projects</ProjectHeadText>
          <Link to={`/projects/search/${projectscategory.id}`}>
            <FilterButtonLoad>Load more</FilterButtonLoad>
          </Link>
        </ProjectHeadContainerNoor>
      ) : (
        <ProjectHeadContainer>
          <ProjectHeadText>Featured Projects</ProjectHeadText>
          <DropdownContainer onMouseLeave={() => setDisplay("none")}>
            <FilterButton filterprop="" onClick={() => setDisplay("flex")}>
              {filter}
              <KeyboardArrowDownOutlined />
            </FilterButton>
            <DropdownDiv display={display}>
              <button
                onClick={() => {
                  setDisplay("none");
                  setFilter("Offplan");
                }}
              >
                Offplan
              </button>
              <button
                onClick={() => {
                  setDisplay("none");
                  setFilter("Ready");
                }}
              >
                Ready
              </button>
              {/* <button onClick={() => { setDisplay('none'); setFilter('commercial'); }}>Commercial</button> */}
            </DropdownDiv>
          </DropdownContainer>
        </ProjectHeadContainer>
      )}
      <ProjectPaginationContainer>
        {width > 900 && (
          <PageIcon
            disabled={active === 0}
            onClick={() => scrollHandler(active - 1)}
          >
            <TfiArrowCircleLeft size={28} />
          </PageIcon>
        )}

        <ProjectScrollContainer ref={containerRef} onScroll={handleScroll}>
          {projects.map((project) => (
            <ProjectCardDiv key={project.id}>
              <NewProjectCard project={project} />
            </ProjectCardDiv>
          ))}
        </ProjectScrollContainer>

        {width > 900 && (
          <PageIcon
            disabled={active === scrollButton}
            onClick={() => scrollHandler(active + 1)}
          >
            <TfiArrowCircleRight size={28} />
          </PageIcon>
        )}
      </ProjectPaginationContainer>
      
        <ScrollContainer>
          {Array.from({ length: scrollButton }, (_, id) => (
            <ScrollButton
              key={id}
              active={id === active}
              onClick={() => scrollHandler(id)}
            ></ScrollButton>
          ))}
        </ScrollContainer>
      
    </ProjectSection>
  );
};

export default Latestprojects;
