import { createSlice } from '@reduxjs/toolkit';


const featurePropertiesSlice = createSlice({
    name: 'featureProperties',
    initialState: {
        featurePropertiesData: [],
        isLoading: false,
        error: false,
       },
    reducers: {
        startLoading: state => {
            state.isLoading = true;
         },
        hasError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        getFeatureProperties(state, action) {
        },
        setFeaturepropertiesContent(state, action) {
            const propertiesData = action.payload;            
            state.propertiesData = propertiesData;
        }
    },
});


export const { setFeaturepropertiesContent, getFeatureProperties, hasError } = featurePropertiesSlice.actions;

export default featurePropertiesSlice.reducer;