import { Skeleton } from '@mui/material'
import React from 'react'
import { HiOutlineArrowLongRight } from 'react-icons/hi2'
import time from "../../assets/images/time.svg";
import date from "../../assets/images/date.svg";
import { VerticalDiv, WebinarButtonContainer, WebinarCardContainer, WebinarCardImg, WebinarContentContainer, WebinarCtaButton, WebinarDateText, WebinarDateTimeContainer, WebinarFilterButton, WebinarHeaderText, WebinarIcon, WebinarSubText } from './PressCenter.styled'
import { Link } from 'react-router-dom';


const WebinarCard = ({data}) => {
 
  return (
    <WebinarCardContainer>
    <WebinarContentContainer>
    <WebinarHeaderText height={data.attributes.title?data.attributes.title:''}>{data.attributes.title ? data.attributes.title : <Skeleton
        animation="wave"
        variant="rectangular"
        width="100%"
        height="100%"
      />}
      </WebinarHeaderText>
    <WebinarSubText height={data.attributes.description}>{data.attributes.description ? data.attributes.description : <Skeleton
        animation="wave"
        variant="rectangular"
        width="100%"
        height="100%"
      />}
      </WebinarSubText>
      <WebinarDateTimeContainer>
        <WebinarIcon src={time} alt='time'/>
        <WebinarDateText>3 PM GMT+4</WebinarDateText>
        <VerticalDiv></VerticalDiv>
        <WebinarIcon src={date} alt='time'/>
        <WebinarDateText>3 PM GMT+4</WebinarDateText>
      </WebinarDateTimeContainer>
    <WebinarButtonContainer>
    <Link to = '/webinar/1'><WebinarCtaButton>REGISTER <HiOutlineArrowLongRight size={30}/></WebinarCtaButton></Link>
      <WebinarFilterButton>ADD TO CALENDER</WebinarFilterButton>
    </WebinarButtonContainer>
    </WebinarContentContainer>
    <WebinarCardImg img={data.attributes.featured_image.data.attributes.url}>{data.attributes.featured_image.data.attributes.url?'':<Skeleton
        animation="wave"
        variant="rectangular"
        width="100%"
        height="100%"
      />}
    </WebinarCardImg>
  </WebinarCardContainer>
  )
}

export default WebinarCard