import styled from "styled-components";

export const SliderPageContainer = styled.div`
 display: flex;
  align-items: center;
`
export const SliderContainer = styled.div`
     display: flex;
  overflow: auto;
  width: 90%;
  margin: auto;
 
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
  
  @media only screen and (min-width: 800px) {
     gap: 2.5%;
    }
`

export const Slider = styled.div`
display:flex;
flex-direction:column;
gap:20px;
    @media only screen and (min-width: 1920px) {
        min-width: 23.12%;
        }
    @media only screen and (min-width: 1300px) and (max-width:1920px){
        min-width: 23.12%;
        }
    @media only screen and (min-width: 800px) and (max-width:1300px){
        min-width:31.6%;
        }
    @media only screen and (max-width: 800px) {
         min-width:100%;
        }
    @media only screen and (max-width: 400px) {
        min-width:100%;
        }
    
`