import React, { useState, useEffect, useRef } from "react";
import data from "./secondary_buttons.json";
import { TfiAngleLeft, TfiAngleRight } from "react-icons/tfi";
import {
  SecondaryButton,
  SecondaryButtonDivContainer,
  SecondaryButtonsDiv,
  ArrowButton,
} from "./propertylisting.styled";
import { useDispatch, useSelector } from "react-redux";
import { setSearch } from "../../redux/slices/herosearch/herosearchSlice";
import { useTranslation } from "react-i18next";

export const ScrollButtons = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const containerRef = useRef();
  // const [active, setActive] = useState(0);
  const dispatch = useDispatch();
  const result = useSelector((state) => state);
  const [filter, setFilter] = useState('Newest')
  const { t } = useTranslation();
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    
  }, []);
  

  

  const HandleSearch = (prope) => {
    setFilter(prope)
    const searchObject = {
      type: result.herosearch.type?result.herosearch.type:'',
      community: result.herosearch.community?result.herosearch.community:'',
      unittype: result.herosearch.unittype?result.herosearch.unittype:'',
      bed: result.herosearch.beds?result.herosearch.beds:'',
      price: result.herosearch.price?result.herosearch.price:'',
      filter: prope?prope.toLowerCase():data.herosearch.filter,
      page: 1,
    };
    dispatch(setSearch(searchObject));
  };
  
  return (
    <SecondaryButtonDivContainer>
      {width<2100 && containerRef.current && <ArrowButton
        onClick={() => containerRef.current.scrollLeft -= 100}
      >
        <TfiAngleLeft size={25} />
      </ArrowButton>}
      <SecondaryButtonsDiv ref={containerRef}>
        {data.property.map((prope, index) => {
          return <SecondaryButton onClick={()=>HandleSearch(prope)} key={index} active={filter===prope}>{t(prope)}</SecondaryButton>;
        })}
      </SecondaryButtonsDiv>
      {width<2100 &&<ArrowButton
        onClick={() =>containerRef.current.scrollLeft += 100}
      >
        <TfiAngleRight size={25} />
      </ArrowButton>}
    </SecondaryButtonDivContainer>
  );
};
