import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import CustomizedSnackbar from '../components/CustomizedSnackbars/CustomizedSnackbar'
import Footer from '../components/Footer/Footer'
import FooterMobile from '../components/Footer/FooterMobile'
import Navbar from '../components/Navbar/Navbar'
import { PressContainer} from '../components/PressCenter/PressCenter.styled'
import PressReleaseCard from '../components/PressCenter/PressReleaseCard'
import PressRelease from '../components/PressCenterTemplate/PressRelease'
import SocialLinks from '../components/SocialLinks/SocialLinks'
import { getPressCenter, getPressCenterDetail } from '../redux/slices/pressCenter/pressCenter'

const PressReleaseTemplate = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const {pressCenterData} =  useSelector((state)=> state.presentReducer);
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    window.scrollTo(0, 0);
  }, []);
  const {id} = useParams()
  const dispatch = useDispatch();
  const fetchHandler = () =>{
    const pressObject = {
      property:'press_release',
      page:1,
    }
    dispatch(getPressCenter(pressObject))
  }
  useEffect(()=>{
    dispatch(getPressCenterDetail(id))
    fetchHandler();
    scrollTop();
  },[dispatch, id])

  const data =  useSelector((state)=> state.presentReducer.pressCenterDetail.attributes);
  const scrollTop = () =>{
    window.scrollTo(0,0);
  }
  return (
    <div>
        <Navbar background='#12284C'/>
        {data && <PressRelease data = {data}/>}
        <SocialLinks/>
        <PressContainer>
            {pressCenterData[0] && <PressReleaseCard scrollTop = {scrollTop} data = {pressCenterData[0]}/>}
            {pressCenterData[1] && width>900 && <PressReleaseCard scrollTop = {scrollTop} data = {pressCenterData[1]}/>}
        </PressContainer>
        {(width < 1204)?<FooterMobile/>:<Footer/>}
        <CustomizedSnackbar/>

    </div>
  )
}

export default PressReleaseTemplate