import { Route, Routes } from "react-router-dom";

import Landingpage from "./pages/Landingpage";
import PropertyListing from "./pages/PropertiesListing";
import BusinessServiceRealEstate from "./pages/BusinessServiceRealEstate";
import PressCenter from "./pages/PressCenter";
import PressReleaseTemplate from "./pages/PressReleaseTemplate";
import BlogTemplate from "./pages/BlogTemplate";
import PodcastTemplate from "./pages/PodcastTemplate";
import { PropertyDetail } from "./pages/PropertyDetail";
import ProjectPageLuxury from "./pages/ProjectPageLuxury";
import CompanyAboutUs from "./pages/CompanyAboutUs";
import CompanyWhyUs from "./pages/CompanyWhyUs";
import CompanyWhyInvest from "./pages/CompanyWhyInvest";
import CompanyCareer from "./pages/CompanyCareer";
import CompanyTeam from "./pages/CompanyTeam";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getCurrency } from "./redux/slices/unitConversion/unitConversion";
import { ProjectDetail } from "./pages/ProjectDetail";
import PreferenceScreen from "./pages/PreferenceScreen";
import { SearchProjectPage } from "./pages/SearchProjectPage";
import SellPage from "./pages/SellPage";
import SearchPage from "./pages/SearchPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Contactus from "./pages/Contactus";
import MainCollage from "./components/PropertyDetail/MainCollage";
import PropertyCarousel from "./components/PropertyDetail/PropertyCarousel";
import BusinessRentBuy from "./pages/BusinessRentBuy";
import BusinessConveyancer from "./pages/BusinessConveyancer";
import BusinessMortgage from "./pages/BusinessMortgage";
import BusinessServiceRefurbishment from "./pages/BusinessServiceRefurbishment";
import BusinessPartner from "./pages/BusinessPartner";
import BusinessServiceFurnishing from "./pages/BusinessServiceFurnishing";


function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCurrency());
  }, [dispatch]);
  return (
    <Routes>
    <Route exact path="/" element={<Landingpage/>} />
    <Route path="/properties/:id" element={<PropertyListing/>} />
    <Route path="/properties/detail/:id" element={<PropertyDetail/>} />
    <Route path="/properties/detail/images/:id" element={<MainCollage/>} />
    <Route path="/properties/detail/carousel/:id" element={<PropertyCarousel/> } />
    <Route path="/realestate" element={<BusinessServiceRealEstate/>} />
    <Route path="/business-mortgage" element={<BusinessMortgage />} />
    <Route path="/business-refurbishment" element={<BusinessServiceRefurbishment/>} />
    <Route path="/business-partner" element={<BusinessPartner/>} />
    <Route path="/rentbuy" element={<BusinessRentBuy/>} />
    <Route path="/conveyancer" element={<BusinessConveyancer/>} />
    <Route path="/press_release" element={<PressCenter/>} />
    <Route path="/press_release/:id" element={<PressReleaseTemplate/>} />
    <Route path="/business-furnishing" element={<BusinessServiceFurnishing />} />
    <Route path="/blog/:id" element={<BlogTemplate/>} />
    <Route path="/podcast/:id" element={<PodcastTemplate/> } />
      <Route path="/blog" element={<PressCenter />} />
      <Route path="/podcast" element={<PressCenter />} />
      <Route path="/market_update" element={<PressCenter />} />
      {/* <Route path="/webinar" element={<PressCenter/>} />  */}
      <Route path="/about" element={<CompanyAboutUs />} />
      <Route path="/whyus" element={<CompanyWhyUs />} />
      <Route path="/whyinvest" element={<CompanyWhyInvest />} />
      <Route path="/careers" element={<CompanyCareer />} />
      <Route path="/meettheteam" element={<CompanyTeam />} />
      <Route path="/contactus" element={<Contactus />} />
      <Route path="/projects/:id" element={<ProjectPageLuxury />} />
      <Route path="/projects/detail/:id" element={<ProjectDetail />} />
      <Route path="/projects/detail/images/:id" element={<MainCollage />} />
      <Route
        path="/projects/detail/carousel/:id"
        element={<PropertyCarousel />}
      />
      <Route path="/preferences" element={<PreferenceScreen />} />
      <Route path="/projects/search/:id" element={<SearchProjectPage />} />
      <Route path="/sell" element={<SellPage />} />
      <Route path="/search" element={<SearchPage />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
    </Routes>
  );
}

export default App;
