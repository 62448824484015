import React, { useEffect, useState } from 'react'
import { motion, AnimatePresence } from "framer-motion"
import { FieldDiv, LocationDiv, SearchDiv, SearchInput, SearchInputDiv, SearchText, TextDiv, Vertical } from './SearchComponent.styled';
import { AiOutlineClose} from "react-icons/ai";
import {useNavigate} from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { setSearch } from '../../redux/slices/herosearch/herosearchSlice';
import { getLocation } from '../../redux/slices/location/locationSlice';

const SearchComponent = () => {
    const dispatch = useDispatch()
    const [close,setClose] = useState(0)
    const [text,setText] = useState('Site Search')
    const [community, setCommunity] = useState('');
    const [areaActive, setAreaActive] = useState(false)
    const navigate = useNavigate();
    const animate = ()=>{
        setClose(!close);
        setTimeout(()=>{
            navigate(-1)      
         },300)
    }
    const HandleSearch = (e) => {
        const searchObject = {
          type:e,
          community:community,
          unittype:'',
          bed:'',
          price:'',
          page:1,
      }
        dispatch(setSearch(searchObject));  
        navigate(`/properties/${e}`)
      };
      const handleInput = event => {
        const newValue = event.target.value;
      if (newValue.trim() === '') {
        setCommunity('');}
        else {
        
        setCommunity(newValue);
      }
      };
    
      useEffect(()=>{
        let words = community.split(' ');
        const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
        dispatch(getLocation(capitalizedWords.join(' ')))
      },[community, dispatch])
      const data = useSelector(state=>state.location)
    return (
      <AnimatePresence>
      <motion.div
      key={close}
      initial={{ width:0}}
      animate={{ width:'100%' ,transition:{duration:0.5}}}
      exit={{x:window.innerWidth , trasition:{
        duration:1,
      }}}>
        <SearchDiv>
            <AiOutlineClose size={30} style={{marginLeft:'auto', display:'block'}} onClick={animate}/>
            <FieldDiv>
                <TextDiv>
                    {/* <SearchText onClick={()=>setText('Site Text')}>Site Search</SearchText> */}
                    <SearchText onClick={()=>setText('Property Search')}>Property Search</SearchText>
                </TextDiv>
                <SearchInputDiv onClick={()=>setAreaActive(true)}>
                    <SearchInput placeholder={community?community:'Area or Community'} onChange={handleInput} value={community}></SearchInput>
                   <div>
                        <button onClick={()=>HandleSearch('buy')}>BUY</button>
                        <Vertical/>
                        <button onClick={()=>HandleSearch('rent')}>RENT</button>
                    </div>
                </SearchInputDiv>
                {data && data.locationData && 
        <LocationDiv areaActive={areaActive}>
          {data.locationData.length===0?<h1>No Data Found</h1> :
          data.locationData.map((i,id)=>{
            return(
              <button key={id} onClick={()=>{setCommunity(i.attributes.area); setAreaActive(false)}}>{i.attributes.area}</button>
            )
            
          })
          }
          </LocationDiv>
          }
      
            </FieldDiv>
        </SearchDiv> 
        </motion.div>
      </AnimatePresence>
    )
}

export default SearchComponent