import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Footer from '../components/Footer/Footer';
import FooterMobile from '../components/Footer/FooterMobile';
import Navbar from '../components/Navbar/Navbar';
import { SearchProjects } from '../components/SearchProjects/SearchProjects';
import { setProjectSearch } from '../redux/slices/heroProjectSearch/heroProjectSearchSlices';
import { getDeveloper } from '../redux/slices/developers/developerSlice';

export const SearchProjectPage = () => {
    const [width, setWidth] = useState(window.innerWidth)
    const { id } = useParams();
    useEffect(()=>{
      window.addEventListener("resize", () => setWidth(window.innerWidth));
      
    },[])

    const dispatch = useDispatch();
  const HandleSearch = (e) => {
    const searchObject = {
      type: id || data.heroprojectsearch.type,
      community: data.heroprojectsearch.community ? data.heroprojectsearch.community : "",
      unittype:
        data.heroprojectsearch.unittype && data.heroprojectsearch.unittype !== "All"
          ? data.heroprojectsearch.unittype
          : "",
      price: data.heroprojectsearch.price ? data.heroprojectsearch.price : "",
      page: 1,
    };
    dispatch(setProjectSearch(searchObject));
  };
  const data = useSelector(state => state)
  useEffect(() => {
    HandleSearch();
    dispatch(getDeveloper())
  }, []);
  useEffect(() => {
    HandleSearch();
    dispatch(getDeveloper(''))
  }, [id]);
    const TabletBreakpoint = 1200;
  return (
    <div>
        <Navbar background='#12284c'/>
        <SearchProjects data = {data.project.projectData}/>
        {width < TabletBreakpoint?<FooterMobile/>:<Footer/>}
    </div>
  )
}
