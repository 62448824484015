import React, { useState } from "react";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import {
  AboutButton,
  AboutDescContainer,
  AboutDescText,
  AboutHeader,
  AboutReadMoreButton,
  AboutSection,
} from "./AboutUs.styled";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Aboutus = () => {
  const [active, setActive] = useState(false);
  const {t} = useTranslation()

  return (
    <AboutSection>
      <AboutHeader>{t('About Us')}</AboutHeader>
      <AboutDescContainer>
        <AboutDescText>
          {t('Founded by innovators and game-changers, we are the future of real estate.')}
        </AboutDescText>
        <br />
        <AboutDescText>
          {t('Our full-service real estate firm has a well-earned reputation for outstanding service. We not only facilitate the buying and selling of homes and investment properties, but we also see beyond what makes each property special and unique.')}
        </AboutDescText>
        <br />
        <AboutDescText>
          {t('We have an in-depth understanding of the marketplace, specializing in correct property valuation, unique marketing and sales strategy programs.')}
        </AboutDescText>
        {active && 
       <>
       <br />
       <AboutDescText>
          {t('Supported by a research team implemented to provide the utmost level of due diligence regarding your real estate transaction, our focus is based on a value driven, market savvy approach to acquiring both residential and investment properties.')}
        </AboutDescText>
        <br />
        <AboutDescText>
        {t('From sales, rentals and new development to mortgages and title insurance, our agents are relentless advocates for our clients.')}
      </AboutDescText>
      <br />
      <AboutDescText>
      {t('Our team is the top in the industry—they have access to the best and most reliable information and education, allowing them to expertly advise at every stage.')}
      </AboutDescText>
      <Link to = '/about'><AboutButton>{t('Read More About Us')}</AboutButton></Link>
      </> 
        }
        
        <AboutReadMoreButton onClick={() => setActive(!active)}>
        {active ? t('Read Less') : t('Read More')}
          {active ? (
            <KeyboardArrowUpOutlinedIcon />
          ) : (
            <KeyboardArrowDownOutlinedIcon />
          )}
        </AboutReadMoreButton>
      </AboutDescContainer>
    </AboutSection>
  );
};

export default Aboutus;
