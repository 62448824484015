import React from "react";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import styled from "styled-components";
import {
  CtaButton,
  HeaderText,
} from "../PropertyListing.js/PropertyListing.styled";

export const NewsSection = styled.section`
  background: #f7f9fa;
  @media only screen and (min-width: 1920px) {
    padding: 236px 0px 198px 0px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    padding: 172px 0px 146px 0px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    padding: 119px 0px 128px 0px;
  }
  @media only screen and (max-width: 900px) {
    padding: 105px 0px 50px 0px;
  }
  @media only screen and (max-width: 400px) {
    padding: 105px 0px 50px 0px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    padding: 119px 0px 128px 0px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    padding: 119px 0px 128px 0px;
  }
`;
export const NewsHeader = styled(HeaderText)`
  margin: 0px;
`;
export const NewsFilterContainer = styled.div`
  display: flex;
  gap: 30px;
  width: 90%;
  margin: auto;
  height: 41px;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const NewsFilterButton = styled.button`
  border: ${(props) =>
    props.filterprop ? "0.75px solid #000000" : "0.75px solid #decdad"};
  font-family: "Montserrat";
  border-radius: 90px;
  font-style: normal;
  overflow: hidden;
  font-weight: 400;
  line-height: 20px;
  color: #000000;
  background: transparent;
  min-width: fit-content;

  &:hover {
    border: 0.75px solid #000000;
  }
  &:focus {
    border: 0.75px solid #000000;
  }
  @media only screen and (min-width: 1366px) {
    font-size: 16px;
    height: 40px;
    padding: 10px 40px;
    gap: 24px;
  }
  @media only screen and (min-width: 1204px) and (max-width: 1366px) {
    font-size: 13px;
    height: 33px;
    padding: 7px 30px;
    gap: 20px;
  }

  @media only screen and (max-width: 1204px) {
    font-size: 15px;
    height: 33px;
    padding: 7px 30px;
    gap: 20px;
  }

  /* @media (max-width: 1366px) {
    height: 33px;
    padding: 7px 30px;
    gap: 20px;
  } */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-size: 15px;
    height: 33px;
    padding: 7px 30px;
    gap: 20px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-size: 15px;
    height: 33px;
    padding: 7px 30px;
    gap: 20px;
  }
`;
export const NewsPageContainer = styled.div`
  display: flex;
  overflow-x: auto;
  width: 90%;
  margin: auto;
  margin-top: 50px;
  gap: 5%;
`;
export const NewsCard = styled.div`
  height: auto;
  @media only screen and (min-width: 1920px) {
    min-width: 21.25%;
  }
  @media only screen and (min-width: 1360px) and (max-width: 1920px) {
    min-width: 21.25%;
  }
  @media only screen and (min-width: 900px) and (max-width: 1365px) {
    min-width: 47.5%;
  }
  @media only screen and (max-width: 900px) {
    min-width: 100%;
  }
  @media only screen and (max-width: 400px) {
    min-width: 100%;
  }
`;
export const DateText = styled.h5`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #decdad;
  @media only screen and (min-width: 1920px) {
    margin-top: 50px;
  }
  @media only screen and (min-width: 1366px) and (max-width: 1920px) {
    margin-top: 30px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1366px) {
    margin-top: 22px;
  }
  @media only screen and (max-width: 900px) {
    margin-top: 18px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    margin-top: 22px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    margin-top: 22px;
  }
`;
export const NewsHeaderText = styled.h1`
  font-family: "Montserrat";
  font-style: normal;
  color: #000000;
  margin-top: 10px;
  @media only screen and (min-width: 1920px) {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
  }
  @media only screen and (min-width: 1366px) and (max-width: 1920px) {
    font-weight: 300;
    font-size: 17.5515px;
    line-height: 21px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1366px) {
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
  }
`;

export const NewsDescText = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-top: 10px;
  color: #000000;
`;
export const ReadMoreButton = styled.button`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
  color: #000000;
  margin-top: 10px;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
`;
export const NewsImage = styled.img`
  width: 100%;
  height: auto;
`;
export const NewsCardItem = ({ data }) => {
  return (
    <>
      <NewsCard>
        <NewsImage src={data} alt="news" />
        <DateText>14 SEPTEMBER 2022</DateText>
        <NewsHeaderText>
          NEW RULES FOR OBTAINING VISAS AND RESIDENCE PERMITS.
        </NewsHeaderText>
        <NewsDescText>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec erat
          elit, tempus et sollicitudin vel, porta at lorem. Maecenas luctus
          vitae ex eu auctor. Curabitur non velit rhoncus, gravida erat vitae,
          aliquet neque.{" "}
        </NewsDescText>
        <ReadMoreButton>
          Read more <KeyboardArrowDownOutlined />
        </ReadMoreButton>
      </NewsCard>
    </>
  );
};

export const NewsCtaButton = styled(CtaButton)`
  padding: 10px 40px;
  @media only screen and (max-width: 700px) {
    padding: 10px;
  }
  a {
    color: #ffffff;
  }
`;
