const questions = [
  {
    id: 1,
    question: "Does Modern Living offer freehold properties?",
    answer:
      "Yes, Modern Living sells freehold properties to both local residents and international investors.",
  },
  {
    id: 2,
    question: "Can foreigners/expats buy houses in Dubai?",
    answer:
      "Yes, any person of any nationality, whether they are a resident of Dubai or based abroad, can purchase property in Dubai's freehold market. You are not required to hold any type of residency or similar permit in order to purchase property.",
  },
  {
    id: 3,
    question: "What are the steps to buying property in Dubai?",
    answer:
      "The process of buying a property includes four basic legal steps that need to be followed in Dubai.\n1. Establishing buyer and seller agreement Once you have found a property that you want to invest in, you must consult a real estate agent. They will help you communicate, negotiate and outline the terms of sale with the owner. 2. Signing an agreement for sale Once all the details are finalized, it’s time to sign the agreement of sale, also known as the Memorandum of Understanding (MoU). In Dubai, this document is entitled ‘Contract F’ and is available on the Dubai Land Department’s website, under the ‘Contracts’ page. Upon signing this contract it is standard practice in Dubai for the buyer to pay a 10% deposit of the property value to the seller. 3. Applying for a No Objection Certificate (NOC)To allow for the transfer of ownership you must apply and pay for a No Objection Certificate from the developer of the unit you are purchasing. This will allow for the transfer of ownership. Once all outstanding service charge payments are finalized the developer will be able to provide an NOC.",
  },
  {
    id: 4,
    question: "What are the fees needed to buy a property in Dubai?",
    answer:
      "The primary market purchasing fee in all DLD Registration Trustees offices is AED 4,000 for transactions over AED 500,000, and AED 2,000 for those below this amount. Fees are payable in cash and paid by the parties as agreed.For secondary market purchases, total fees include a 2% commission fee (for the sales agent or broker), the NOC fee [around AED 5,000 max] and a transfer appointment fee [AED 4,000].",
  },
  {
    id: 5,
    question: "How long does it take to buy a new property in Dubai?",
    answer:
      "An average property transaction in Dubai takes around 30 days to complete from the date on which the Agreement for Sale is signed.",
  },
  {
    id: 6,
    question: "Can I buy a house in Dubai and get a residency visa?",
    answer:
      "Investors, home owners and specialists, such as doctors and engineers, can receive UAE residency visas valid for up to 10 years as per the law announced in May 2018 by the UAE government. The existing property visa is a two-year renewable property investor visa issued by the Dubai Land Department for a freehold property valued at Dh1 million or more on the title deed.",
  },
];

export default questions;
