import styled from "styled-components";

export const AgencySection = styled.section`
    width:100%;
    height:auto;
    background: #12284C;
    padding:50px 0px;
`
export const AgencyContentContainer = styled.div`
    width:85%;
    margin:auto;
    h1{
        text-align:center;
        font-family: 'Didot';
font-style: normal;
color: #FFFFFF;
@media only screen and (min-width: 1900px) {
    font-weight: 400;
font-size: 64px;
line-height: 146%;
    }
@media only screen and (min-width: 1300px) and (max-width:1900px) {
    font-weight: 400;
font-size: 52.5333px;
line-height: 146%;
    }
@media only screen and (min-width: 900px) and (max-width:1300px) {
    font-weight: 400;
font-size: 32px;
line-height: 146%;
    }
@media only screen and (max-width: 900px) {
    font-weight: 400;
font-size: 30px;
line-height: 146%;
    }
    }
    p{
        font-family: 'Montserrat';
font-style: normal;
text-align: center;
color: #FFFFFF;
@media only screen and (min-width: 1900px) {
    font-weight: 300;
font-size: 20px;
line-height: 146%;
margin-top:60px;
    }
@media only screen and (min-width: 1300px) and (max-width:1900px){
    font-weight: 300;
font-size: 16.4167px;
line-height: 146%;
margin-top:50px;
    }
@media only screen and (min-width: 900px) and (max-width:1300px){
    font-weight: 300;
font-size: 14.3646px;
line-height: 146%;
margin-top:33px;
    }
@media only screen and (max-width: 900px) {
    font-weight: 300;
font-size: 13px;
line-height: 146%;
margin-top:20px;
    }
    }
`
export const AgencyContainer = styled.div`
    display:flex;
    justify-content:space-between;
    flex-wrap:wrap;
    margin-top:30px;
    margin:auto;
    width:min(100%, 1000px);
       
    
`
export const SingleAward = styled.div`
flex-shrink:0;
flex-grow:0;
margin-top:20px !important;
    @media only screen and (min-width: 1900px) {
        width:183px;
        height:auto;      
    }
    @media only screen and (min-width: 1300px) and (max-width:1900px){
        width:153.5px;
        height:auto;
        }
    @media only screen and (min-width: 900px) and (max-width:1300px){
        width:134.31px;
        height:auto;

        }
    @media only screen and (max-width: 900px) {
        width:127.73px;
        height:auto;

        }`
export const SingleAwardText = styled.p`        
        
            font-family: 'Montserrat';
font-style: normal;
text-align: center;
color: #FFFFFF;
margin-top:30px !important;
@media only screen and (min-width: 1900px) {
    font-weight: 500;
font-size: 24px;
line-height: 146%;
    }
@media only screen and (min-width: 1300px) and (max-width:1900px){
    font-weight: 500;
font-size: 19.7px;
line-height: 146%;
    }
@media only screen and (min-width: 900px) and (max-width:1300px){
    font-weight: 500;
font-size: 17.2375px;
line-height: 146%;
    }
@media only screen and (max-width: 900px) {
    font-weight: 500;
font-size: 13px;
line-height: 146%;
    }
        
`
export const AwardBox = styled.div`
    border: 1px solid #ECE1CE;
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    @media only screen and (min-width: 1900px) {
        
        height:183px;
        }
    @media only screen and (min-width: 1300px) and (max-width:1900px){
      
        height:153.5px;
        }
    @media only screen and (min-width: 900px) and (max-width:1300px){
      
        height:134.31px;
        }
    @media only screen and (max-width: 900px) {
        height:127.73px;
        }
        h1{
            text-align:center;
            margin-top:8px;
            font-family: 'Didot';
font-style: normal;
color: #FFFFFF;
@media only screen and (min-width: 1900px) {
    font-weight: 400;
font-size: 42px;
line-height: 146%;
    }
@media only screen and (min-width: 1300px) and (max-width:1900px){
    font-weight: 400;
font-size: 34.475px;
line-height: 146%;
    }
@media only screen and (min-width: 900px) and (max-width:1300px){
    font-weight: 400;
font-size: 30.1656px;
line-height: 146%;
    }
@media only screen and (max-width: 900px) {
    font-weight: 400;
font-size: 28.6886px;
line-height: 146%;
    }
        }
        img{
            display:block;
            margin:auto;
        }
    
`