import {put, retry} from "redux-saga/effects";
import {setpropertiesContent, hasError, setPropertiesDetails, setPropertiesMeta} from '../../../slices/propertiesSlices/getProperties';
import { requestGetProperties, requestGetFeatureProperties, requestGetPropertiesDetail} from "../../request/properties/properties";


export function* handleProperties(action) {
    try {
        const response = yield retry(0,0,requestGetProperties,action.payload);
        const {data, meta} = response.data;
        yield put(setpropertiesContent(data));
        yield put(setPropertiesMeta(meta));
    } catch (error) {
        yield put(hasError(error))
    }
}

export function* handleFeatureProperties(action){
    try {
        const response = yield retry(0,0, requestGetFeatureProperties);
        const {data} = response.data;
        yield put(setpropertiesContent(data));
    } catch (error) {
        yield put(hasError(error))
    }
}


export function* handlePropertiesDetails(action){
    try {
        const response = yield retry(0,0, requestGetPropertiesDetail, action.payload);
        const {data} = response;
        yield put(setPropertiesDetails(data));
    } catch (error) {
        yield put(hasError(error))
    }
}
