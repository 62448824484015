import styled from "styled-components";
import {
  ButtonText,
  HeaderText,
  SubText,
} from "../PropertyListing.js/PropertyListing.styled";

export const ServiceSection = styled.section`
  width: 100%;
  @media only screen and (min-width: 1920px) {
    height: auto;
    padding: 166px 0px;
    }
  @media only screen and (min-width: 1300px) and (max-width:1920px){
    height: auto;
    padding: 128px 0px;
    }
  @media only screen and (min-width: 900px) and (max-width:1300px){
    height: auto;
    padding: 100px 0px;
    }
  @media only screen and (max-width: 900px) {
    height: auto;
    padding: 50px 0px;
    }
  @media only screen and (max-width: 400px) {
    height: auto;
    padding: 50px 0px;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    height: auto;
    padding: 100px 0px;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    height: auto;
    padding: 100px 0px;
    }
`;
export const ServiceTextContainer = styled.div`
  margin: auto;
  width: 90%;
  height: fit-content;
`;
export const ServiceHeaderText = styled(HeaderText)`
  @media only screen and (min-width: 900px) {
    text-align: center;
  }
  @media only screen and (max-width: 900px) {
    text-align: left;
  }
  // Only for Ipad

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    text-align: center;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    text-align: center;
  }
`;

export const ServiceSubText = styled(SubText)`
margin:50px 0px;
  @media only screen and (min-width: 900px) {
    text-align: center;
  }
  @media only screen and (max-width: 900px) {
    text-align: left;
  }
  // Only for Ipad

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) {
    text-align: left;
  }

  @media only screen and (device-width: 768px) {
    text-align: left;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    text-align: left;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    text-align: left;
  }
`;

export const ServicePageContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 50px;
`;

export const ServiceContainer = styled.div`
  display: flex;
  overflow: auto;
  width: 90%;
  margin: auto;
  gap: 5%;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ServiceCardImg = styled.img`
  z-index: 1;
  width: 100%;
  height: 100%;
`;
export const ServiceText = styled.h1`
  transition: 0.3s;
  position: absolute;
  text-align: center;
  top: 50%;
  left: 0;
  right: 0;
  font-family: "Montserrat";
  font-style: normal;
  color: #ffffff;
  @media only screen and (min-width: 1920px) {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    }
  @media only screen and (min-width: 1300px) and (max-width:1920px){
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    }
  @media only screen and (min-width: 900px) and (max-width:1300px){
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    }
  @media only screen and (max-width: 900px) {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    }
  @media only screen and (max-width: 400px) {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    }
`;
export const ServiceButton = styled(ButtonText)`
  overflow: hidden;
  width: fit-content;
  color: #ffffff;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 20%;
  opacity: 0;
  background: transparent;
  border: 0.75px solid #ffffff;
  border-radius: 90px;
  transition: 0.3s;
  text-align: center;
  @media only screen and (min-width: 1367px) {
    height: 40px;
    padding: 10px 40px;
  }
  @media only screen and (max-width: 1366px) {
    height: 35px;
    padding: 6px 30px;
  }
  &:hover {
    border: transparent;
    background: white;
    color: #12284c;
  }
  // Only for Ipad
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    height: 30px;
    padding: 6px 30px;
    text-align: center;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    height: 30px;
    padding: 6px 30px;
    text-align: center;
  }
`;
export const ServiceCardDiv = styled.div`
  position: relative;
  overflow: hidden;
  @media only screen and (min-width: 1920px) {
    min-width: 21.25%;
    height: auto;
    }
  @media only screen and (min-width: 1300px) and (max-width:1920px){
    min-width: 21.25%;
    height: auto;
    }
  @media only screen and (min-width: 900px) and (max-width:1300px){
    min-width: 30%;
    height: auto;
    }
  @media only screen and (max-width: 900px) {
    min-width: 100%;
    height: auto;
    }
  @media only screen and (max-width: 400px) {
    min-width: 100%;
    height: auto;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    min-width: 30%;
    height: auto;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    min-width: 30%;
    height: auto;
    }
  &:hover {
    ${ServiceText} {
      transform: translateY(-50px);
    }
    ${ServiceButton} {
      transform: translateY(-40px);
      opacity: 1;
    }
  }
`;
export const Overlay = styled.div`
  position: absolute;
  top: 0;
  background: #12284c;
  opacity: 0.4;
  width: 100%;
  height: 100%;
`;
