import {put, call} from "redux-saga/effects";
import { setComments } from "../../../slices/testimonials/testimonialSlice";
import { requestTestimonial } from "../../request/testimonials/testimonial";


export function* handleTestimonial(action) {
   
    try {
        const response = yield call(requestTestimonial);
        const {data} = response;
        yield put(setComments(data));
    } catch (error) {
    }
}