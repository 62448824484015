import React, { useEffect, useRef, useState } from "react";
import logo from "../../assets/images/Logo.svg";
import Mobileimg from "../../assets/images/MobileLogo.svg";
import SearchIcon from "../../assets/images/SearchIcon.svg";
import MenuCircle from "../../assets/images/MenuCircle.svg";
import MenuLineIcon from "../../assets/images/MenuLine.svg";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import {
  NavbarHeader,
  NavbarLogo,
  MobileLogo,
  CenterDiv,
  NavbarButton,
  Vertical,
  MenuIcon,
  SearchButtonIcon,
  MenuDiv,
  MenuLine,
  MenuLine2,
  SidebarSection,
  SidebarMenuText,
  SidebarDropContainer,
  SidebarLink,
  PressCenterDropdown,
  BusinessDropdown,
  CompanyDropdown,
  PropertyDropdown,
  CurrencyDropdown,
  NavbarButtonDefault,
  SidebarContentContainer,
  SidebarDiv,
  UnitSpan,
  UnitDropdown,
  DropdownDiv,
  SideBarContactDiv,
  ProjectDropdown,
} from "./Navbar.styled";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getUserCurrency,
  getUserUnit,
} from "../../redux/slices/unitConversion/unitConversion";
import { SearchButton, SearchText } from "../HeroLandingPage/Hero.styled";
import { DownToggleIcon } from "../Footer/Footer.styled";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";


const Navbar = ({ background, backdrop }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [active, setActive] = useState(false);
  const [currency, setCurrency] = useState("AED");
  const [unit, setUnit] = useState("sqft");
  const [dropActive, setDropActive] = useState("");
  const [language, setLanguage] = useState(i18n.language)
  const {t} = useTranslation()

  const currencyRef = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);
  useEffect(() => {
    setActive(false);
  }, [width]);
  const handleDropdown = (value) => {
    setDropActive(dropActive !== value ? value : "");
  };
  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
  };
  return (
    <>
      <NavbarHeader background={background}>
        {width > 1203 && (
          <Link to="/">
            <NavbarLogo src={logo} alt="logo" />
          </Link>
        )}
        {width < 1204 && (
          <Link to="/">
            <MobileLogo src={Mobileimg} alt="logo" />
          </Link>
        )}
        <CenterDiv>
          {width > 1341 && (
            <PropertyDropdown>
              <NavbarButton>
                {t('Properties')}
                <KeyboardArrowDownOutlinedIcon />
              </NavbarButton>
              <Link to="/properties/buy">
                <NavbarButton>{t('Buy')}</NavbarButton>
              </Link>
              <Link to="/sell">
                <NavbarButton>{t('Sell')}</NavbarButton>
              </Link>
              <Link to="/properties/rent">
                <NavbarButton>{t('Rent')}</NavbarButton>
              </Link>
            </PropertyDropdown>
          )}
          {width > 1341 && (
            <ProjectDropdown>
              <NavbarButton>
                {t('PROJECTS')}
                <KeyboardArrowDownOutlinedIcon />
              </NavbarButton>
              <Link to="/projects/ready">
                <NavbarButton>{t('Ready')}</NavbarButton>
              </Link>
              <Link to="/projects/offplan">
                <NavbarButton>{t('OffPlan')}</NavbarButton>
              </Link>
               {/* <Link to="/projects/commercial">
                <NavbarButton>{t('Commercial')}</NavbarButton>
              </Link> */}
            </ProjectDropdown>
          )}
          {width > 1341 && (
            <BusinessDropdown>
              <NavbarButton>
                {t('Services')}
                <KeyboardArrowDownOutlinedIcon />
              </NavbarButton>
              <Link to="/realestate">
                <NavbarButton>{t('Real Estate Advisory')}</NavbarButton>
              </Link>
              <Link to="/rentbuy">
                <NavbarButton>{t('Buy / Rent Service')}</NavbarButton>
              </Link>
              <Link to="/conveyancer">
                <NavbarButton>{t('Conveyancer Service')}</NavbarButton>
              </Link>
              <Link to="/business-mortgage">
                <NavbarButton>{t('Mortgage Service')}</NavbarButton>
              </Link>
              <Link to="/business-furnishing">
                <NavbarButton>{t('Furnishing')}</NavbarButton>
              </Link>
              <Link to="/business-refurbishment">
                <NavbarButton>{t('Refurbishment')}</NavbarButton>
              </Link>
              <Link to="/business-partner">
                <NavbarButton>{t('Business partners')}</NavbarButton>
              </Link>
            </BusinessDropdown>
          )}
          {width > 1341 && (
            <CompanyDropdown>
              <NavbarButton>
                {t('About Us')}
                <KeyboardArrowDownOutlinedIcon />
              </NavbarButton>
              <Link to="/about">
                <NavbarButton>{t('About Modernliving')}</NavbarButton>
              </Link>
              <Link to="/meettheteam">
                <NavbarButton>{t('Our Team')}</NavbarButton>
              </Link>
              <Link to="/careers">
                <NavbarButton>{t('Invest in Dubai')}</NavbarButton>
              </Link>
              <Link to="/whyinvest">
                <NavbarButton style={{width:'120px'}}>{t('Join our team')}</NavbarButton>
              </Link>
              <Link to="/whyus">
                <NavbarButton style={{width:'119px'}}>{t('Client Voices')}</NavbarButton>
              </Link>
              <Link to="/contactus">
                <NavbarButton>{t('Contact Us')}</NavbarButton>
              </Link>
            </CompanyDropdown>
          )}
          {width > 1341 && (
            <PressCenterDropdown>
              <NavbarButton>
                {t('Insights')}
                <KeyboardArrowDownOutlinedIcon />
              </NavbarButton>
              <Link to="/press_release">
                <NavbarButton>{t('Press Release')}</NavbarButton>
              </Link>
              <Link to="/blog">
                <NavbarButton>{t('Blogs')}</NavbarButton>
              </Link>
              <Link to="/podcast">
                <NavbarButton>Podcast</NavbarButton>
              </Link>
              {/* <Link to="/webinar">
                <NavbarButton>Webinar</NavbarButton>
              </Link> */}
              <Link to="/market_update">
                <NavbarButton>Market Update</NavbarButton>
              </Link>
            </PressCenterDropdown>
          )}
          <Link to="/search">
            <SearchButtonIcon src={SearchIcon} alt="search" />
          </Link>
          {/* {width > 1341 && (
            <DropdownContainer>
              <NavbarButton>
                {language}
                <KeyboardArrowDownOutlinedIcon />
              </NavbarButton>
              <NavbarButton onClick={() => handleLanguageChange('EN')}>EN</NavbarButton>
          <NavbarButton onClick={() => handleLanguageChange('GR')}>GR</NavbarButton>
            </DropdownContainer>
          )} */}
          {width > 1341 && <Vertical></Vertical>}
          {width > 1341 && (
            <CurrencyDropdown
              ref={currencyRef}
              onMouseLeave={() => currencyRef.current.scrollTo(0, 0)}
            >
              <NavbarButtonDefault background={background}>
                {currency}
                <KeyboardArrowDownOutlinedIcon />
              </NavbarButtonDefault>
              <NavbarButton
                onClick={() => {
                  setCurrency("AED");
                  dispatch(getUserCurrency("AED"));
                  currencyRef.current.scrollTo(0, 0);
                }}
              >
                AED
              </NavbarButton>
              <NavbarButton
                onClick={() => {
                  setCurrency("USD");
                  dispatch(getUserCurrency("USD"));
                  currencyRef.current.scrollTo(0, 0);
                }}
              >
                USD
              </NavbarButton>
              <NavbarButton
                onClick={() => {
                  setCurrency("EUR");
                  dispatch(getUserCurrency("EUR"));
                  currencyRef.current.scrollTo(0, 0);
                }}
              >
                EUR
              </NavbarButton>
              <NavbarButton
                onClick={() => {
                  setCurrency("GBP");
                  dispatch(getUserCurrency("GBP"));
                  currencyRef.current.scrollTo(0, 0);
                }}
              >
                GBP
              </NavbarButton>
            </CurrencyDropdown>
          )}
          {width > 1341 && <Vertical></Vertical>}
          {width > 1341 && (
            <UnitDropdown>
              <NavbarButton style={{textTransform:'lowercase'}}>
                <UnitSpan>
                  {unit}{unit === 'm' ? <sup style={{marginTop:'-13px'}}>2</sup> :''}
                </UnitSpan>
                <KeyboardArrowDownOutlinedIcon />
              </NavbarButton>
              <NavbarButton
                onClick={() => {
                  setUnit("sqft");
                  dispatch(getUserUnit("SQFT"));
                }}
                style={{textTransform:'lowercase'}}
              >
                sqft
              </NavbarButton>
              <NavbarButton
                onClick={() => {
                  setUnit("m");
                  dispatch(getUserUnit("SQM"));
                }}
                style={{justifyContent:'flex-start', textTransform:'lowercase'}}
              >m<sub style={{marginTop:'-13px', marginLeft:'-5px'}}>2</sub>

              </NavbarButton>
            </UnitDropdown>
          )}
          {width < 1342 && (
            <MenuDiv onClick={() => setActive(!active)}>
              <MenuIcon src={MenuCircle} alt="" />
              <MenuLine src={MenuLineIcon} alt="" active={active} />
              <MenuLine2 src={MenuLineIcon} alt="" active={active} />
            </MenuDiv>
          )}
        </CenterDiv>
      </NavbarHeader>
      <SidebarSection active={active}>
        <SideBarContactDiv>
          <SidebarContentContainer>
            <SidebarDropContainer>
              <SidebarMenuText onClick={() => handleDropdown("property")}>
                PROPERTIES
                <DownToggleIcon animate={dropActive === "property"} />
              </SidebarMenuText>
              <DropdownDiv active={dropActive === "property"}>
                <Link to="/properties/buy">
                  <SidebarLink>Buy</SidebarLink>
                </Link>
                <Link to="/sell">
                  <SidebarLink>Sell</SidebarLink>
                </Link>
                <Link to="/properties/rent">
                  <SidebarLink>Rent</SidebarLink>
                </Link>
              </DropdownDiv>
            </SidebarDropContainer>
            <SidebarDropContainer>
            <SidebarMenuText onClick={()=>handleDropdown('project')}>
              PROJECTS
              <DownToggleIcon animate={dropActive==='project'}/>
            </SidebarMenuText>
            <DropdownDiv active={dropActive==='project'}>
            <Link to="/projects/ready">
                <SidebarLink>{t('Ready')}</SidebarLink>
              </Link>
              <Link to="/projects/offplan">
                <SidebarLink>{t('OffPlan')}</SidebarLink>
              </Link>
            </DropdownDiv>
          </SidebarDropContainer>
            <SidebarDropContainer>
              <SidebarMenuText onClick={() => handleDropdown("business")}>
                BUSINESS SERVICES
                <DownToggleIcon animate={dropActive === "business"} />
              </SidebarMenuText>
              <DropdownDiv active={dropActive === "business"}>
              <Link to="/realestate">
                <SidebarLink>{t('Real Estate Advisory')}</SidebarLink>
              </Link>
              <Link to="/rentbuy">
                <SidebarLink>{t('Buy / Rent Service')}</SidebarLink>
              </Link>
              <Link to="/conveyancer">
                <SidebarLink>{t('Conveyancer Service')}</SidebarLink>
              </Link>
              <Link to="/business-mortgage">
                <SidebarLink>{t('Mortgage Service')}</SidebarLink>
              </Link>
              <Link to="/business-furnishing">
                <SidebarLink>{t('Furnishing')}</SidebarLink>
              </Link>
              <Link to="/business-refurbishment">
                <SidebarLink>{t('Refurbishment')}</SidebarLink>
              </Link>
              <Link to="/business-partner">
                <SidebarLink>{t('Business partners')}</SidebarLink>
              </Link>
              </DropdownDiv>
            </SidebarDropContainer>
            <SidebarDropContainer>
              <SidebarMenuText onClick={() => handleDropdown("company")}>
                COMPANY
                <DownToggleIcon animate={dropActive === "company"} />
              </SidebarMenuText>
              <DropdownDiv active={dropActive === "company"}>
              <Link to="/about">
                <SidebarLink>{t('About Modernliving')}</SidebarLink>
              </Link>
              <Link to="/meettheteam">
                <SidebarLink>{t('Our Team')}</SidebarLink>
              </Link>
              <Link to="/careers">
                <SidebarLink>{t('Invest in Dubai')}</SidebarLink>
              </Link>
              <Link to="/whyinvest">
                <SidebarLink style={{width:'120px'}}>{t('Join our team')}</SidebarLink>
              </Link>
              <Link to="/whyus">
                <SidebarLink style={{width:'119px'}}>{t('Client Voices')}</SidebarLink>
              </Link>
              <Link to="/contactus">
                <SidebarLink>{t('Contact Us')}</SidebarLink>
              </Link>
              </DropdownDiv>
            </SidebarDropContainer>
            <SidebarDropContainer>
              <SidebarMenuText onClick={() => handleDropdown("press")}>
                PRESS CENTER
                <DownToggleIcon animate={dropActive === "press"} />
              </SidebarMenuText>
              <DropdownDiv active={dropActive === "press"}>
                <Link to="/press_release">
                  <SidebarLink>Press Release</SidebarLink>
                </Link>
                <Link to="/blog">
                  <SidebarLink>Blogs</SidebarLink>
                </Link>
                <Link to="/podcast">
                  <SidebarLink>Podcast</SidebarLink>
                </Link>
                <Link to="/market_update">
                  <SidebarLink>Market Update</SidebarLink>
                </Link>
              </DropdownDiv>
            </SidebarDropContainer>
          </SidebarContentContainer>

          <SidebarDiv>
            <SearchButton>
              <SearchText>CONTACT US</SearchText>
            </SearchButton>
            <CurrencyDropdown
              ref={currencyRef}
              onMouseLeave={() => currencyRef.current.scrollTo(0, 0)}
            >
              <NavbarButtonDefault background={background}>
                {currency}
                <KeyboardArrowDownOutlinedIcon />
              </NavbarButtonDefault>
              <NavbarButton
                onClick={() => {
                  setCurrency("AED");
                  dispatch(getUserCurrency("AED"));
                  currencyRef.current.scrollTo(0, 0);
                }}
              >
                AED
              </NavbarButton>
              <NavbarButton
                onClick={() => {
                  setCurrency("USD");
                  dispatch(getUserCurrency("USD"));
                  currencyRef.current.scrollTo(0, 0);
                }}
              >
                USD
              </NavbarButton>
              <NavbarButton
                onClick={() => {
                  setCurrency("EUR");
                  dispatch(getUserCurrency("EUR"));
                  currencyRef.current.scrollTo(0, 0);
                }}
              >
                EUR
              </NavbarButton>
              <NavbarButton
                onClick={() => {
                  setCurrency("GBP");
                  dispatch(getUserCurrency("GBP"));
                  currencyRef.current.scrollTo(0, 0);
                }}
              >
                GBP
              </NavbarButton>
            </CurrencyDropdown>
            <UnitDropdown>
              <NavbarButton style={{textTransform:'lowercase'}}>
                <UnitSpan>
                  {unit}{unit === 'm' ? <sup style={{marginTop:'-13px'}}>2</sup> :''}
                </UnitSpan>
                <KeyboardArrowDownOutlinedIcon />
              </NavbarButton>
              <NavbarButton
                onClick={() => {
                  setUnit("sqft");
                  dispatch(getUserUnit("SQFT"));
                }}
                style={{textTransform:'lowercase'}}
              >
                sqft
              </NavbarButton>
              <NavbarButton
                onClick={() => {
                  setUnit("m");
                  dispatch(getUserUnit("SQM"));
                }}
                style={{justifyContent:'flex-start', textTransform:'lowercase'}}
              >
                m<sub style={{marginTop:'-13px', marginLeft:'-5px'}}>2</sub>
              </NavbarButton>
            </UnitDropdown>
          </SidebarDiv>
        </SideBarContactDiv>
      </SidebarSection>
    </>
  );
};

export default Navbar;
