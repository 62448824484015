import styled from "styled-components";

export const BusinessMessageSection = styled.section`
  width: 100%;
  @media only screen and (min-width: 1920px) {
    height: auto;
    padding: 160px 0px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    height: auto;
    padding: 140px 0px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    height: auto;
    padding: 120px 0px;
  }
  @media only screen and (max-width: 900px) {
    height: auto;
    padding: 60px 0px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    height: auto;
    padding: 120px 0px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    height: auto;
    padding: 120px 0px;
  }
`;

export const MessageHeaderText = styled.h1`
  font-family: "Didot";
  font-style: normal;
  color: #131d2e;
  @media only screen and (min-width: 1920px) {
    font-weight: 400;
    font-size: 64px;
    line-height: 146%;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 400;
    font-size: 48px;
    line-height: 146%;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 400;
    font-size: 48px;
    line-height: 146%;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 400;
    font-size: 30px;
    line-height: 146%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 400;
    font-size: 48px;
    line-height: 146%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 400;
    font-size: 48px;
    line-height: 146%;
  }
`;
export const MessageDesc = styled.p`
  margin-top: 30px;
  font-family: "Montserrat";
  font-style: normal;
  color: #000000;
  text-align: justify;
  text-justify: inter-word;
  @media only screen and (min-width: 1920px) {
    font-weight: 400;
    font-size: 20px;
    line-height: 146%;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 400;
    font-size: 18px;
    line-height: 146%;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 146%;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 400;
    font-size: 13px;
    line-height: 146%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 400;
    font-size: 14px;
    line-height: 146%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 400;
    font-size: 14px;
    line-height: 146%;
  }
`;
export const MessageSpan = styled.span`
  margin-top: 30px;
  font-family: "Montserrat";
  font-style: normal;
  color: #000000;
  text-align: justify;
  text-justify: inter-word;
  @media only screen and (min-width: 1920px) {
    font-weight: 400;
    font-size: 20px;
    line-height: 146%;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 400;
    font-size: 18px;
    line-height: 146%;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 146%;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 400;
    font-size: 13px;
    line-height: 146%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 400;
    font-size: 14px;
    line-height: 146%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 400;
    font-size: 14px;
    line-height: 146%;
  }
`;
export const MessageButton = styled.button`
  margin-top: 30px;
  background: #12284c;
  border-radius: 90px;
  display: flex;
  border:none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat";
  font-style: normal;
  letter-spacing: 0.125em;
  color: #ffffff;
  @media only screen and (min-width: 1920px) {
    width: 204px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    height: 40px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 204px;
    height: 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 166.1px;
    height: 32.55px;
    font-weight: 500;
    font-size: 13.0196px;
    line-height: 16px;
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
    height: 33px;
    font-weight: 500;
    font-size: 13.0196px;
    line-height: 16px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 166.1px;
    height: 32.55px;
    font-weight: 500;
    font-size: 13.0196px;
    line-height: 16px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 166.1px;
    height: 32.55px;
    font-weight: 500;
    font-size: 13.0196px;
    line-height: 16px;
  }
`;

export const MessageCard = styled.div`
  display: flex;
  align-items: center;
  width: 85%;
  gap: 5%;
  margin: auto;
  @media only screen and (min-width: 1920px) {
    height: auto;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    height: auto;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    height: auto;
    flex-wrap: wrap-reverse;
  }
  @media only screen and (max-width: 900px) {
    height: auto;
    flex-wrap: wrap-reverse;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    height: auto;
    flex-wrap: wrap-reverse;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    height: auto;
    flex-wrap: wrap-reverse;
  }
`;
export const AboutReadMoreButton = styled.span`
  font-family: "Montserrat";
  font-style: normal;
  color: #12284c;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  @media only screen and (min-width: 1920px) {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-top: 19px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-top: 19px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-top: 30px;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-top: 32px;
  }
  @media only screen and (max-width: 400px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-top: 32px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-top: 30px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-top: 30px;
  }
`;
export const MessageDiv = styled.div`
  width: 85%;
  margin: auto;
  margin-top:150px;
`
export const MessageContainer = styled.div`
  @media only screen and (max-width: 1300px) {
    width: 100%;
    margin-top: 20px;
  }
  @media only screen and (min-width: 1300px) {
    width: 50%;
  }
`;
export const MessageImage = styled.img`
  height: auto;
  @media only screen and (max-width: 1300px) {
    width: 100%;
  }
  @media only screen and (min-width: 1300px) {
    width: 50%;
  }
`;
