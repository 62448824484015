import { Slider } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getMortage } from "../../redux/slices/MortageSlice/MortageSlice";
import {
  InterestDiv,
  InterestInput,
  PropertyPrice,
} from "./PropertyDetail.styled";
import './style.css'

export const Duration = () => {
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(getMortage({key:'duration',value:value*12}))
  })
  const [value, setValue] = React.useState(25);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = (event) => {
    setValue(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(25);
    } else if (value > 60) {
      setValue(60);
    }
  };
  return (
    <InterestDiv>
      <PropertyPrice> Duration: </PropertyPrice>
      <Slider
        value={typeof value === "number" ? value : 25}
        onChange={handleSliderChange}
        aria-labelledby="input-slider"
        defaultValue={25}
        min={5}
        max={60}
        step={1}
      />
      <InterestInput
        type="text"
        value={value + " Years"}
        margin="dense"
        onChange={handleInputChange}
        onBlur={handleBlur}
      />
    </InterestDiv>
  );
};
