import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Contact from "../components/ContactUs/Contact";
import CustomizedSnackbar from "../components/CustomizedSnackbars/CustomizedSnackbar";
import Footer from "../components/Footer/Footer";
import FooterMobile from "../components/Footer/FooterMobile";
import Navbar from "../components/Navbar/Navbar";
import SetupStepRealEstate from "../components/SetupSteps/SetupStepRealEstate";
import HeroImage from "../assets/images/refurhero.png";
import Image from "../assets/images/refurimage.png";
import HeroBusiness from "../components/HeroLandingPage/HeroBusiness";
import BusinessMessage from "../components/BusinessMessage/BusinessMessage";
import Step1 from "../assets/images/refur1.svg";
import Step2 from "../assets/images/refur2.svg";
import Step3 from "../assets/images/refur3.svg";
import Step4 from "../assets/images/refur4.svg";
import Step5 from "../assets/images/refur5.svg";
import Step6 from "../assets/images/refur6.svg";
const TabletBreakpoint = 1024;

const text = [
  { text: "Property Assesment", img: Step1 },
  { text: "Design and Planning", img: Step2 },
  { text: "Interior and Exterior Renovation", img: Step3 },
  { text: "Quality Craftsmanship and Finishing", img: Step4 },
  { text: "Custom Cabinetry and Joinery", img: Step5 },
  { text: "Project Management", img: Step6 },
];
const BusinessServiceRefurbishment = () => {
  const [offset, setOffset] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [background, setBackground] = useState("transparent");
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  useEffect(() => {
    if (offset > 100) setBackground("#12284C");
    else setBackground("transparent");
  }, [offset]);
  return (
    <div>
      <Navbar background={background} />
      <HeroBusiness
        heroImage={HeroImage}
        header="We renovate. We transform. You love!"
        desc="We strive for excellence in every detail, creating spaces that inspire, delight, and add value to your real estate investment."
      />
      <BusinessMessage
        header="Revitalize your space & enhance its value"
        desc="Welcome to Modern Living, your trusted companion for excellence real estate refurbishment services. We specialize in renewing homes and evolving them into contemporary, practical, and visually appealing surroundings.

        We identify the opportunity that each property offers. Our team of excellent contractors, designers, and project managers is committed to assisting you in realizing your full potential. We have the expertise to make your ideal residence a reality, whether you want to increase the value of your property for sale, transform a rental unit, or build your dream home."
        img={Image}
        desc2={`Every step of the renovation process is covered by our exhaustive refurbishing services. We work closely with you to understand your vision, needs, and budget from the initial concept and design to the last finishing touches. Every job is completed above your expectations because to the creativity, originality, and meticulousness of our team.

        We have the skills and resources to undertake any project, no matter how big or little, refurbishment or whole home makeover. We offer structural modifications, kitchen and bathroom remodeling, flooring and lighting improvements, painting, carpentry, and other services. In order to guarantee robustness and endurance, we work with reliable suppliers and high-quality materials.
        
        We think that good project management is essential for a renovation project to succeed. Our committed project managers keep an eye on every stage of the refurbishment, monitoring deadlines and making sure it stays on schedule and in budget. We place a high priority on open communication and keep you updated at every stage of the process.
        
        We take great satisfaction in providing superb craftsmanship and high-quality customer service. Our team is dedicated to making your experience as hassle-free as possible, reducing annoyances, and enhancing the appeal and value of your property. In order to add value to your real estate investment, we aim for quality in every aspect of the places we design.
        
        Discover the benefits of working with a reputable refurbishment business. Get in touch with us right away to discuss your requirements and let us make your idea a reality.`}
      />
      <SetupStepRealEstate data={text} header={"What we do in Refurbishment Service?"} />

      <Contact displaymsg="block" displayupload="none" />
      {width < TabletBreakpoint ? <FooterMobile /> : <Footer />}
      <CustomizedSnackbar />
    </div>
  );
};

export default BusinessServiceRefurbishment