import { createSlice } from '@reduxjs/toolkit';

const propertiesSlice = createSlice({
    name: 'properties',
    initialState: {
        propertiesData: [],
        propertiesMeta: {},
        propertiesDetail: {},
        isLoading: false,
        error: false,
        index:1,
        propertyId: 1
       },
    reducers: {
        startLoading: state => {
            state.isLoading = true;
         },
         hasError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        getProperties(state, action) {},
        setpropertiesContent(state, action) {
            const propertiesData = action.payload;            
            state.propertiesData = propertiesData;
            state.isLoading = false;
        },
        getIndex(state,action) {
            state.index = action.payload;
        },
        getPropertiesDetails(state, action) {
            state.propertyId = action.payload
        },
        setPropertiesDetails(state, action){
            state.propertiesDetail = action.payload;
        },
        setPropertiesMeta(state, action) {
            state.propertiesMeta = action.payload
        }

    },
});



export const { setpropertiesContent, hasError, getProperties, getIndex, getPropertiesDetails, setPropertiesDetails, setPropertiesMeta } = propertiesSlice.actions;

export default propertiesSlice.reducer;