import { createSlice } from '@reduxjs/toolkit';

const locationSlice = createSlice({
    name: 'Location',
    initialState: {
        locationData: [],
        isLoading: true,
        error: false,
       },
    reducers: {
         hasError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        getLocation(state, action) {},
        setLocation(state, action) {        
            state.locationData = action.payload;
            state.isLoading = false;
        },
    },
});



export const { setLocation, hasError, getLocation} = locationSlice.actions;

export default locationSlice.reducer;