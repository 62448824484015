import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { PressCenterTemplate } from '../assets/styles/MyStyle.styled'
import Footer from '../components/Footer/Footer'
import FooterMobile from '../components/Footer/FooterMobile'
import Navbar from '../components/Navbar/Navbar'
import BlogCard from '../components/PressCenter/BlogCard'
import { PressContainer } from '../components/PressCenter/PressCenter.styled'
import Blog from '../components/PressCenterTemplate/Blog'
import SocialLinks from '../components/SocialLinks/SocialLinks'
import { getPressCenter, getPressCenterDetail } from '../redux/slices/pressCenter/pressCenter'
import CustomizedSnackbar from '../components/CustomizedSnackbars/CustomizedSnackbar'


const BlogTemplate = () => {
    const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    window.scrollTo(0, 0)
  }, []);
  const {id} = useParams()
  const dispatch = useDispatch();
  const fetchHandler = () =>{
    const pressObject = {
      property:'blog',
      page:1,
    }
    dispatch(getPressCenter(pressObject))
  }
  useEffect(()=>{
    dispatch(getPressCenterDetail(id))
    fetchHandler();
    scrollTop()
  },[id, dispatch ])
  const data =  useSelector((state)=> state.presentReducer.pressCenterDetail.attributes);
  const {pressCenterData} =  useSelector((state)=> state.presentReducer);
  const scrollTop = () =>{
    window.scrollTo(0,0);
  }
  return (
    <PressCenterTemplate>
        <Navbar background='#12284C'/>
        {data && <Blog blogDetail={data}/>}
        <SocialLinks/>
        <PressContainer>
            {pressCenterData[0] && <BlogCard scrollTop={scrollTop} data = {pressCenterData[0]}/>}
            {pressCenterData[1] && width>900 && <BlogCard scrollTop={scrollTop} data = {pressCenterData[1]}/>}
            {pressCenterData[2] && width>1300 && <BlogCard scrollTop={scrollTop} data = {pressCenterData[2]}/>}
            {pressCenterData[3] && width>1300 && <BlogCard scrollTop={scrollTop} data = {pressCenterData[3]}/>}
        </PressContainer>
        {(width < 1204)?<FooterMobile/>:<Footer/>}
        <CustomizedSnackbar/>
    </PressCenterTemplate>
  )
}

export default BlogTemplate