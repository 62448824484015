import React, { useState } from "react";
import {
  DescriptionPrice,
  DescriptionPriceDiv,
  KeyFeatureButtonDiv,
  KeyFeatureButtons,
  MainDescriptionContainer,
  MainDescriptionHeading,
  MainDescriptionHeadingdiv,
  MainImageDiv,
  PricePara,
} from "./projects.styled";

export const AvailableUnits = (details) => {
  if (details.details.available_units.data) {
    var data = details.details.available_units.data;
  }
  const [features, setFeatures] = useState(0);

  if (data) {
    var data1 = data[features];
  }

  if (data1) {
    var number = Number(data1.attributes.size.toFixed(0));
    var formattedNumber = number.toLocaleString();
  }

  return (
    <>
      <KeyFeatureButtonDiv>
        {data &&
          data.map((available_units, id) => {
            return (
              <>
                {available_units.attributes.heading && (
                  <KeyFeatureButtons
                    key={data1.id}
                    onClick={() => setFeatures(id)}
                  >
                    {available_units.attributes.heading}
                  </KeyFeatureButtons>
                )}
              </>
            );
          })}
      </KeyFeatureButtonDiv>
      {data1 && data1.attributes && (
        <MainDescriptionContainer>
          <MainDescriptionHeadingdiv>
            {data1.attributes.heading && (
              <MainDescriptionHeading>
                {data1.attributes.heading}
              </MainDescriptionHeading>
            )}
            {data1.attributes.price && (
              <DescriptionPriceDiv>
                <DescriptionPrice>Starting Price:</DescriptionPrice>
                <PricePara>
                  AED {data1.attributes.price.toLocaleString()}
                </PricePara>
              </DescriptionPriceDiv>
            )}
            {data1.attributes.size && (
              <DescriptionPriceDiv>
                <DescriptionPrice>Starting Size:</DescriptionPrice>
                <PricePara>{formattedNumber} sqft</PricePara>
              </DescriptionPriceDiv>
            )}
            {(data1.attributes.rooms || data1.attributes.rooms === 0) && (
              <DescriptionPriceDiv>
                <DescriptionPrice>Bedrooms:</DescriptionPrice>
                <PricePara>
                  {data1.attributes.rooms === 0
                    ? "Studio"
                    : `${data1.attributes.rooms} Bedroom`}
                </PricePara>
              </DescriptionPriceDiv>
            )}
          </MainDescriptionHeadingdiv>
          {details.details.available_units && data1.attributes.images && (
            <MainImageDiv
              img={data1.attributes.images.data.attributes.url}
            ></MainImageDiv>
          )}
        </MainDescriptionContainer>
      )}
    </>
  );
};
