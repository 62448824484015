import React from 'react'
import { HeadingProjectDiv, HeadingProjectMain} from './projects.styled'

export const HeadingOfProject = (details) => {
  
  return (
    <HeadingProjectDiv>
        {details && details.details.name && <HeadingProjectMain>
          {details.details.name}
        </HeadingProjectMain>}
    </HeadingProjectDiv>
  )
}
