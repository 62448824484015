import React from "react";
import { AboutProject } from "./AboutProject";
import { AvailableUnit } from "./AvailableUnit";
import { BreadcrumbsProjectDetails } from "./BreadcrumbsProjectsDetails";
import { HeadingOfProject } from "./HeadingOfProject";
import { AvailableUnits } from "./AvailableUnits";
import { KeyFeature } from "./KeyFeature"
import MapAndContact from "./MapAndContact";
import { ProjectDescription } from "./ProjectDescription";
import { BreadcrumbPaddingProject, ContainerWidth } from "./projects.styled";
import Collage from "../PropertyDetail/Collage";

const ProjectPageDetail = ({ projectDetails }) => {
  return (
    <div style={{backgroundColor: "rgba(238, 242, 244, 0.5)"}}>
      <Collage details ={projectDetails} />
      <ContainerWidth>
        <BreadcrumbPaddingProject>
          <BreadcrumbsProjectDetails details={projectDetails.attributes} />
        </BreadcrumbPaddingProject>
        <HeadingOfProject details={projectDetails.attributes} />
        <ProjectDescription details={projectDetails.attributes} />
        <AvailableUnit details={projectDetails.attributes}  />
        <AvailableUnits details={projectDetails.attributes} />
        <AboutProject details={projectDetails.attributes} />
        <KeyFeature details={projectDetails.attributes} />
      </ContainerWidth>
      <MapAndContact details={projectDetails.attributes} />
    </div>
  );
};

export default ProjectPageDetail;
