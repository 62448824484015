import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getPropertiesDetails } from '../../redux/slices/propertiesSlices/getProperties';
import { useEffect } from 'react';
import { TfiArrowCircleLeft, TfiArrowCircleRight } from 'react-icons/tfi';
import { AiOutlineClose } from 'react-icons/ai';
import { getProjectsDetail } from '../../redux/slices/projectSlice/getProject';
import { SlideIconLeft, SlideIconRight } from '../PropertyListing.js/PropertyListing.styled';

const Background = styled.div`
  background-color: black;
  @media only screen and (min-width: 600px) {
    height:100vh;
  }
  @media only screen and (max-width: 600px) {
    height:90vh;
    }
`;

const BackgroundContainer = styled.div`
  width:100%;
  height:auto;
  position:relative;
  top:50%;
  transform:translateY(-50%);
`
const Div1 = styled.div`
  display:flex;
  width:80%;
  height:10vh;
  margin:auto;
  justify-content:space-between;
  @media only screen and (max-width: 500px) {
    width:95%;
    }

`
const SlideButton = styled.button`
  display: flex;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #ffffff;
  background:transparent;
  border:transparent;
  cursor: pointer;
`;

const SliderContainer = styled.div`
  display:flex;
  width:100%;
  height:auto;
  align-items:center;
  justify-content:space-evenly;
  
`
const navgateButton = styled.button`
  
  @media only screen and (max-width: 500px) {
    position:absolute;
    }
`
const SliderImage = styled.div`
width:80%;
height:70vh;
background: ${(props) => `url(${props.img})`};
background-position:center;
background-size:cover;
opacity: 1;
  transition: opacity 0.5s ease-in-out;

  @media only screen and (max-width: 500px) {
    width:95%;
    height:30vh;
    }
`



const PropertyCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0); // corrected the useState call
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  const [property] = useState(location.pathname.split('/')[1]);
  useEffect(() => {
      property==='properties' ? dispatch(getPropertiesDetails(id)) : dispatch(getProjectsDetail(id))
    },[id]);
    const propertiesData = useSelector((state) => state.properties.propertiesDetail.data);
    const projectsData = useSelector((state) => state.project.projectDetail.data);
    const images = property === 'properties' ? propertiesData : projectsData;
  const imageUrls = images ? images.attributes.image.data : [];
  return (
    <Background>
      {imageUrls && (
        <BackgroundContainer>
          <Div1>
            <SlideButton>
              {currentIndex + 1}/{imageUrls.length}
            </SlideButton>
            <SlideButton onClick={()=>navigate(-1)}>
              <AiOutlineClose size={25} />
              Close
            </SlideButton>
          </Div1>
          <SliderContainer>
          {window.innerWidth>500 && <TfiArrowCircleLeft 
              size={28}
              color="white"
              onClick={() =>
                setCurrentIndex(
                  currentIndex === 0 ? imageUrls.length-1 : currentIndex - 1
                )
              }
            />}
           {imageUrls && <SliderImage img={imageUrls[currentIndex]?.attributes?.url} />}
           {window.innerWidth>500 && <TfiArrowCircleRight
              size={28}
              color="white"
              onClick={() =>
                setCurrentIndex(
                  currentIndex === imageUrls.length - 1 ? 0 : currentIndex + 1
                )
              }
            />}
            {window.innerWidth<500 && <SlideIconRight style={{top:'60%'}}
            onClick={() =>
              setCurrentIndex(
                currentIndex === imageUrls.length - 1 ? 0 : currentIndex + 1
              )
            }
            ><TfiArrowCircleRight size={30}/></SlideIconRight>}
         {window.innerWidth<500 && <SlideIconLeft style={{top:'60%'}}
        onClick={() =>
          setCurrentIndex(
            currentIndex === imageUrls.length - 1 ? 0 : currentIndex + 1
          )
        }
        ><TfiArrowCircleLeft size={30} /></SlideIconLeft>}
          </SliderContainer>
        </BackgroundContainer>
      )}
    </Background>
  );
};

export default PropertyCarousel;