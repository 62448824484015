import styled from "styled-components";

export const PaddingTopSection = styled.div`
@media only screen and (min-width: 1920px) {
  padding-top: 200px;
  }
@media only screen and (min-width: 1300px) and (max-width:1920px){
  padding-top: 170px;
  }
@media only screen and (min-width: 900px) and (max-width:1300px){
  padding-top: 150px;
  }
@media only screen and (max-width: 900px) {
  padding-top: 100px;
  }
@media only screen and (max-width: 400px) {
  padding-top: 50px;
  }
@media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
  padding-top: 150px;
  }
@media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
  padding-top: 150px;
  }

`;

export const StepSection = styled.section`
  width: 100%;
  height: auto;
  @media only screen and (min-width: 1920px) {
    padding-bottom: 200px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    padding-bottom: 129px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    padding: 104px 0px;
  }
  @media only screen and (max-width: 900px) {
    padding: 83px 0px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    padding: 104px 0px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    padding: 104px 0px;
  }
`;
export const StepHeader = styled.h1`
  font-family: "Didot";
  font-style: normal;
  color: #131d2e;
  text-align: center;
  @media only screen and (min-width: 1920px) {
    font-weight: 400;
    font-size: 64px;
    line-height: 146%;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 400;
    font-size: 48px;
    line-height: 146%;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 400;
    font-size: 30px;
    line-height: 146%;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 400;
    font-size: 30px;
    line-height: 146%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 400;
    font-size: 30px;
    line-height: 146%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 400;
    font-size: 30px;
    line-height: 146%;
  }
`;
export const StepContainer = styled.div`
  display: flex;
  padding: 14px;
  flex-wrap: wrap;
  width: 85%;
  margin: auto;
  justify-content: center;
  @media only screen and (min-width: 900px) {
    margin-top: 50px;
    gap: 20px;
  }
  @media only screen and (max-width: 900px) {
    margin-top: 20px;
  }
`;
export const SingleStepContainer = styled.div`
  border: 1px solid transparent;
  border-radius: 31px;
  box-shadow: 0px 0px 32px 13px rgba(230, 230, 230, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  &:hover {
    border: 1px solid #000000;
  }
  @media only screen and (min-width: 1920px) {
    width: 456px;
    height: 319px;
    padding: 61px 39px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 358px;
    height: 250px;
    padding: 47px 30px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 240px;
    height: 170px;
    padding: 32px 31px;
  }
  @media only screen and (max-width: 900px) {
    padding: 15px 17px;
    width: 348px;
    height: 74px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 240px;
    height: 170px;
    padding: 32px 31px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 240px;
    height: 170px;
    padding: 32px 31px;
  }
`;
export const StepIcon = styled.img`
  display: block;
  margin: auto;
  @media only screen and (min-width: 1920px) {
    width: 79px;
    height: 71px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 59px;
    height: 56px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 41px;
    height: 39px;
  }
  @media only screen and (max-width: 900px) {
    width: 32px;
    height: 30px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 41px;
    height: 39px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 41px;
    height: 39px;
  }
`;
export const StepText = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  color: #262626;
  @media only screen and (min-width: 1920px) {
    font-weight: 500;
    font-size: 24px;
    line-height: 146%;
    text-align: center;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 500;
    font-size: 18px;
    line-height: 146%;
    text-align: center;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 146%;
    text-align: center;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 146%;
    text-align: center;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 400;
    font-size: 14px;
    line-height: 146%;
    text-align: center;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 400;
    font-size: 14px;
    line-height: 146%;
    text-align: center;
  }
`;
