import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Contact from "../components/ContactUs/Contact";
import CustomizedSnackbar from "../components/CustomizedSnackbars/CustomizedSnackbar";
import Footer from "../components/Footer/Footer";
import FooterMobile from "../components/Footer/FooterMobile";
import Navbar from "../components/Navbar/Navbar";
import SetupStepRealEstate from "../components/SetupSteps/SetupStepRealEstate";
import HeroImage from "../assets/images/morthero.png";
import Image from "../assets/images/mort-image.png";
import HeroBusiness from "../components/HeroLandingPage/HeroBusiness";
import BusinessMessage from "../components/BusinessMessage/BusinessMessage";
import Step1 from "../assets/images/mort1.svg";
import Step2 from "../assets/images/mort2.svg";
import Step3 from "../assets/images/mort3.svg";
import Step4 from "../assets/images/mort4.svg";
import Step5 from "../assets/images/mort5.svg";
import Step6 from "../assets/images/mort6.svg";
const TabletBreakpoint = 1024;

const text = [
  { text: "Mortgage Consultation", img: Step1 },
  { text: "Escrow Coordination", img: Step2 },
  { text: "Loan Application Assistance", img: Step3 },
  { text: "Mortgage Rate Negotiation", img: Step4 },
  { text: "Documentation  Review", img: Step5 },
  { text: "Ongoing Mortgage Support", img: Step6 },
];
const BusinessMortgage = () => {
  const [offset, setOffset] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [background, setBackground] = useState("transparent");
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  useEffect(() => {
    if (offset > 100) setBackground("#12284C");
    else setBackground("transparent");
  }, [offset]);
  return (
    <div>
      <Navbar background={background} />
      <HeroBusiness
        heroImage={HeroImage}
        header="We demystify the mortgage process!"
        desc="We guide you every step of the way, making your mortgage journey smooth, transparent, and tailored to your specific needs."
      />
      <BusinessMessage
        header="Unlock your real estate dreams"
        desc="Welcome to Modern Living, your dependable partner for all of your real estate mortgage needs. We are assisting people and families in obtaining the funding they require to realize their homeownership or investment objectives.

        $We recognize that getting a mortgage may be a complicated and intimidating process. That's why our skilled mortgage specialists are here to help you through each phase of this process, making your mortgage experience seamless, clear, and suitable for your particular situation."
        img={Image}
        desc2={`Whether you're a first-time buyer or a seasoned investor, we provide mortgage possibilities fitting your specific needs. Our committed team collaborates with several local and international banks to guarantee you have access to appropriate interest rates, flexible terms, and a range of loan alternatives that meet your economic goals.

        Our mortgage professionals are ready to learn about your specific demands and your financial status. We offer individualized guidance and suggestions to help you to select the best mortgage package for your budget, interests, and long-term goals. We demystify the mortgage process so that you understand the terms, costs, and obligations connected with your mortgage.
        
        Our team of experts provides thorough assistance throughout the application process, supporting you with the appropriate paperwork, pre-approval, and documentation collection. To achieve fast and efficient mortgage approval, we work closely with banks and act as your champion.
        
        We value open and transparent communication at Modern Living. We keep you updated on the status of your mortgage application, offering frequent updates and respond quickly to any questions or issues you may have. Our goal is to deliver great customer service that will make you feel confident and supported throughout the mortgage process.
        
        We know that a mortgage is a serious financial commitment, and we have dedicated ourselves to assisting you in making educated decisions. 
        
        
        Modern Living is your trusted partner in real estate mortgage services, whether you're looking to buy a new house, refinance an existing mortgage, or explore investment options. 
        
        Contact us today to talk with one of our mortgage professionals to begin the journey toward achieving your real estate objectives.`}
      />
      <SetupStepRealEstate data={text} header={"What we do in Mortgage Service?"}  />

      <Contact displaymsg="block" displayupload="none" />
      {width < TabletBreakpoint ? <FooterMobile /> : <Footer />}
      <CustomizedSnackbar />
    </div>
  );
};

export default BusinessMortgage;
