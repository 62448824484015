import React, { useCallback, useMemo, useRef, useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import audio from "../../assets/images/aud.mp3";
import { BackButtonContainer, BlogText, PathIcon, TemplateDateSpan, TemplateHeaderText, TemplatePathSpan, TemplateSection } from './PressCenterTemplate.styled'
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';

const Podcast = ({data}) => {
  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
]; 

  var p = data ? data.date.split('T')[0].split('-'):'';
  const date = p[2] + ' ' + monthNames[Number(p[1]).toString()-1]  + ' ' + p[0];
  const plugins = useMemo(() => {
    return [
      // {
      //   plugin: RegionsPlugin,
      //   options: {
      //     dragSelection: true
      //   }
      // }
      // {
      //   plugin: TimelinePlugin,
      //   options: {
      //     container: "#timeline"
      //   }
      // }
    ].filter(Boolean);
  }, []);

  const wavesurferRef = useRef();
  const handleWSMount = useCallback((waveSurfer) => {
    wavesurferRef.current = waveSurfer;
    if (wavesurferRef.current) {
      wavesurferRef.current.load(audio);

      wavesurferRef.current.on("ready", () => {});

      wavesurferRef.current.on("loading", (data) => {});

      if (window) {
        window.surferidze = wavesurferRef.current;
      }
    }
  }, []);

  const play = useCallback(() => {
    wavesurferRef.current.playPause();
  }, []);
  return (
    <TemplateSection>
      <BackButtonContainer>
        <Link to="/podcast">
          <button>
            <KeyboardBackspaceIcon />
          </button>
        </Link>
        <h4>Back</h4>
      </BackButtonContainer>
      <TemplatePathSpan>
      <Link to = '/'>Home</Link> &nbsp;&nbsp;<PathIcon/>&nbsp;&nbsp;<Link to = '/podcast'>Podcast</Link> &nbsp;&nbsp;<PathIcon/> &nbsp;&nbsp;<span>{data && data.title.substring(0,50)}...</span>
      </TemplatePathSpan>
      <TemplateHeaderText>
        {data && data.title}
      </TemplateHeaderText>
      <TemplateDateSpan>
        <span>Podcast</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{date}
      </TemplateDateSpan>
      <div id="video-container">
      <ReactPlayer
            url={data.youtube_link}
            width="100%"
            height="100%"
            controls
        />
        </div>
     
     
        <BlogText>{data ? data.blogs :''}</BlogText>
      </TemplateSection>
  )
}

export default Podcast;
