import { createSlice } from '@reduxjs/toolkit';

const pressCenterSlice = createSlice({
    name: 'pressCenter',
    initialState: {
        pressCenterData: [],
        pressCenterMeta: [],
        pressCenterDetail:[],
        pageCount:0,
        isLoading: true,
        error: false,
        index:1,
        filter:'',
       },
    reducers: {
        startLoading: state => {
            state.isLoading = true;
         },
         hasError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        getPressCenter(state, action) {},
        setPressCenterContent(state, action) {        
            state.pressCenterData = action.payload.data;
        },
        setPressCenterMeta(state, action) {   
            state.pageCount = action.payload.meta.pagination.pageCount;
            state.isLoading = false;
        },
        getPressCenterDetail(state, action) {},
        setPressCenterDetail(state, action) {   
            state.pressCenterDetail = action.payload.data;
            state.isLoading = false;
        },
        getIndex(state,action) {
            state.index = action.payload;
        }
    },
});



export const { setPressCenterContent, setPressCenterMeta, hasError, getPressCenter, getIndex, setPressCenterDetail, getPressCenterDetail } = pressCenterSlice.actions;

export default pressCenterSlice.reducer;