import React, { useEffect, useRef, useState } from "react";
import Pc1 from "../../assets/images/Rectangle.png";
import {
  MeetTheTeamSection,
  ScrollIcon,
  TeamButtonContainer,
  TeamCard,
  TeamCardImage,
  TeamCardJobTitle,
  TeamCardName,
  TeamDesc,
  TeamDetailContainer,
  TeamDetailTextContainer,
  TeamImage,
  TeamJobDiv,
  TeamJobTitle,
  TeamName,
  TeamPageContainer,
  TeamScrollContainer,
  TeamScrollMainContainer,
} from "./MeetTheTeam.styled";
import {
  ServiceHeaderText,
  ServiceTextContainer,
} from "../OurService/OurService.styled";
import {
  FilterButton,
  ScrollButton,
  ScrollContainer,
  WhatsappButtonLandingPage,
} from "../PropertyListing.js/PropertyListing.styled";
import { TfiArrowCircleLeft, TfiArrowCircleRight } from "react-icons/tfi";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import TeamSingleCard from "./TeamSingleCard";
import {
  PodcastButton,
  PressFilterButton,
} from "../PressCenter/PressCenter.styled";
import NewTeamSlider from "./NewTeamSlider";
import SliderComponent from "../Sliders/SliderComponent";

const MeetTheTeam = ({ agents, setLoad, detail, rows }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const containerRef = useRef();
  const [agent, setAgent] = useState();
  const { t } = useTranslation();
  const [button, setButton] = useState(detail);
  const dispatch = useDispatch();

  const agentHandler = (value) => {
    const selectedAgent = agents.find((agent) => agent.id === value);
    if (selectedAgent) {
      setAgent(selectedAgent);
    }
  };
  return (
    <MeetTheTeamSection>
      <ServiceHeaderText
        style={{
          position: "relative",
          display: detail === "false" ? "block" : "none",
          textAlign:'center'
        }}
      >
        {t("Meet Our Team")}
      </ServiceHeaderText>
      <TeamScrollMainContainer>
        <SliderComponent data={agents} DynamicComponent={TeamSingleCard} rows={rows} additionalProp={agentHandler}/>
      </TeamScrollMainContainer>
      {button === 'true' && detail === 'true' && (
        <PodcastButton
          onClick={() => {
            setLoad();
            setButton(false);
          }}
          style={{
            position: "relative",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "50px",
          }}
        >
          LOAD MORE
        </PodcastButton>
      )}

      {/* <ServiceTextContainer id="team">
        <ServiceHeaderText>{t('Meet The Team')}</ServiceHeaderText>
      </ServiceTextContainer> */}
      {agent && detail && (
        <TeamDetailContainer name="TeamCard">
          <TeamDetailTextContainer>
            <TeamName>{agent.attributes.name}</TeamName>
            <TeamJobTitle>{agent.attributes.job_title}</TeamJobTitle>
            <TeamDesc>{agent ? agent.attributes.description : ""}</TeamDesc>
            <TeamButtonContainer>
              {agent.attributes.linkedln && (
                <a href={agent.attributes.linkedln}>
                  <FilterButton filterprop="">LinkedIn</FilterButton>
                </a>
              )}
              {agent.attributes.whatsapp && (
                <WhatsappButtonLandingPage number={agent.attributes.whatsapp}>
                  WhatsApp
                </WhatsappButtonLandingPage>
              )}
            </TeamButtonContainer>
            <br />
          </TeamDetailTextContainer>
          <TeamImage
            src={
              agent.attributes.image.data
                ? agent.attributes.image.data.attributes.url
                : Pc1
            }
            alt=""
          />
        </TeamDetailContainer>
      )}
    </MeetTheTeamSection>
  );
};

export default MeetTheTeam;
