import { createSlice } from '@reduxjs/toolkit';
const heroProjectSearchSlice = createSlice({
    name: 'heroProjectSearch',
    initialState: {
        type: '',
        community: '',
        developer:'',
        date:'',
        unittype: '',
        price:{lower:0, higher:0},
        filter:'',
        page:1,
    },
    reducers: {
        setProjectSearch(state,action){
            state.type = action.payload.type;
            state.community = action.payload.community;
            state.developer = action.payload.developer;
            state.date = action.payload.date;
            state.unittype = action.payload.unittype;
            state.price = action.payload.price;
            state.page = action.payload.page;
            // console.log(action.payload)
        },
        setProjectSearchContent(state, action) {
            state[action.payload.key] = action.payload.value;
        },
    },
});
export const { setProjectSearch, setProjectSearchContent } = heroProjectSearchSlice.actions;

export default heroProjectSearchSlice.reducer;