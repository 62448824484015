import React, { useState } from "react";
import {
  FeatureImageDiv,
  FeatureList,
  FeatureListBullet,
  Features,
  KeyFeatureButtonDiv,
  KeyFeatureButtons,
  KeyFeatureHeading,
  KeyFeatureHeadingdiv,
  KeyImage,
  TheBuilding,
} from "../PropertyDetail/PropertyDetail.styled";

export const KeyFeature = (details) => {
  const [features, setFeatures] = useState(1);
  var key_feature_data = details.details.key_feature.data;
  if (key_feature_data) {
    var attributes = key_feature_data.attributes;
    if(attributes){
      var amenities = attributes.amenities ? attributes.amenities.split("." && "\n") : [];
      var neighbourhood = attributes.neighbourhood ? attributes.neighbourhood.split("." && "\n") : [];
      var accessibility = attributes.accessibility ? attributes.accessibility : "";
      var the_building = attributes.the_building ? attributes.the_building : "";
      if (attributes.amenity_image.data) {
        var amenities_image = attributes.amenity_image.data.attributes.url
      }
      if (attributes.accessibility_image.data) {
        var accessibility_image = attributes.accessibility_image?.data?.attributes?.url
      }
      if (attributes.neighbourhood_image.data) {
        var neighbourhood_image = key_feature_data?.attributes?.neighbourhood_image?.data?.attributes.url
      }
      if (attributes.building_image.data){
        var building_image = attributes?.building_image?.data?.attributes.url
      }
    }
  }

  if (
    details &&
    details.details.key_feature &&
    details.details.key_feature.data
  ) {
    return (
      <>
        <KeyFeatureHeadingdiv>
          <KeyFeatureHeading>Key features:</KeyFeatureHeading>
        </KeyFeatureHeadingdiv>
        <KeyFeatureButtonDiv>
          {details.details.key_feature && details.details.key_feature.data.attributes.amenities && <KeyFeatureButtons onClick={() => setFeatures(1)}>
            Amenities
          </KeyFeatureButtons>}
          {details.details.key_feature && details.details.key_feature.data.attributes.neighbourhood && <KeyFeatureButtons onClick={() => setFeatures(2)}>
            Neighbourhood
          </KeyFeatureButtons> }
          {details.details.key_feature && details.details.key_feature.data.attributes.accessibility && <KeyFeatureButtons onClick={() => setFeatures(3)}>
            Accessibility
          </KeyFeatureButtons>}
          {details.details.key_feature && details.details.key_feature.data.attributes.the_building && <KeyFeatureButtons onClick={() => setFeatures(4)}>
            The Building
          </KeyFeatureButtons>}
        </KeyFeatureButtonDiv>
        {/* amenities*/}
        {features === 1 && (
          <Features>
            <TheBuilding>
              {details.details.key_feature && <FeatureList key={details.details.key_feature.data.id}>
                {amenities.map((i) => {
                  return <FeatureListBullet>{i}</FeatureListBullet>;
                })}
              </FeatureList>}
            </TheBuilding>
            { details.details.key_feature && details.details.key_feature.data.attributes.amenity_image && <FeatureImageDiv>
              <KeyImage img={amenities_image}></KeyImage>
            </FeatureImageDiv> }
          </Features>
        )}
        {/* neighbourhood */}
        {features === 2 && (
          <Features>
            <TheBuilding>
              <FeatureList>
                {neighbourhood.map((i) => {
                  return <FeatureListBullet>{i}</FeatureListBullet>;
                })}
              </FeatureList>
            </TheBuilding>
            {details.details.key_feature.data && details.details.key_feature.data.attributes.neighbourhood_image && <FeatureImageDiv>
              <KeyImage img={neighbourhood_image}></KeyImage>
            </FeatureImageDiv>}
          </Features>
        )}
        {/* accesibility image */}
        {features === 3 && (
          <Features>
            <TheBuilding>
              {accessibility}
            </TheBuilding>
            {details.details.key_feature.data && details.details.key_feature.data.attributes.accessibility_image && <FeatureImageDiv>
            <KeyImage img={accessibility_image}></KeyImage>
            </FeatureImageDiv>}
          </Features>
        )}
        {/* building */}
        {features === 4 && (
          <Features>
            <TheBuilding>{the_building}</TheBuilding>
            {details.details.key_feature.data && details.details.key_feature.data.attributes.building_image && <FeatureImageDiv>
            <KeyImage img={building_image}></KeyImage>
            </FeatureImageDiv>}
          </Features>
        )}
      </>
    );
  }
};
