import React, { useEffect, useState } from "react";
import {
  DropDownPriceFrom,
  BoxItemPriceFrom,
  PaperCSS,
  PopperBedCSS,
  MenuItemCss,
  MenuListCSS,
} from "./propertylisting.styled";
import MenuList from "@mui/material/MenuList";
import { RiArrowDropDownLine } from "react-icons/ri";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useDispatch } from "react-redux";
import { setSearchContent } from "../../redux/slices/herosearch/herosearchSlice";
import {
  setProjectSearch,
} from "../../redux/slices/heroProjectSearch/heroProjectSearchSlices";


const PriceFrom = ["0-100K", "100K-500K", "500K-1M", "1M-2M", "2M-5M", "5M+"];

export const Price = ({
  data,
  border,
  color,
  arrow,
  menuback,
  menucolor,
  menuitemcolor,
  menuitembackgroundhover,
  activecolor
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [setOpen] = useState(false);
  const open = Boolean(anchorEl);
  const [price, setPrice] = useState("Price");
  const [selectedIndex, setSelectedIndex] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const priceRange = {
    Price: {
      lower: 0,
      higher: 0,
    },
    "0-100K": {
      lower: 0,
      higher: 100000,
    },
    "100K-500K": {
      lower: 100000,
      higher: 500000,
    },
    "500K-1M": {
      lower: 500000,
      higher: 1000000,
    },
    "1M-2M": {
      lower: 1000000,
      higher: 2000000,
    },
    "2M-5M": {
      lower: 2000000,
      higher: 5000000,
    },
    "5M+": {
      lower: 5000000,
      higher: 5000000,
    },
  };

  const dispatch = useDispatch();
  useEffect(() => {
    const range = priceRange[price];
    {
      data === "property" &&
        dispatch(setSearchContent({ key: "price", value: range }));
    }
    {
      data === "project" &&
        dispatch(setProjectSearch({ key: "price", value: range }));
    }
  }, [price, data]);
  return (
    <>
      <BoxItemPriceFrom
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        color={color}
        border={border}
      >
        <DropDownPriceFrom
          style={{
            color: PriceFrom[selectedIndex] ? activecolor : "#C0C0C0",
          }}
        >
          {PriceFrom[selectedIndex] || "Price"}
        </DropDownPriceFrom>
        <RiArrowDropDownLine
          size={40}
          style={{ marginRight: "15px" }}
          color={arrow}
        />
      </BoxItemPriceFrom>
      <PopperBedCSS
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        menuback={menuback}
        border={border}
        menuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <PaperCSS menuback={menuback} menucolor={menucolor}>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuListCSS autoFocusItem={open} onKeyDown={handleListKeyDown}>
              {PriceFrom.map((option, index) => (
                <MenuItemCss
                  key={option}
                  menuitemcolor={menuitemcolor}
                  menuitembackgroundhover ={menuitembackgroundhover}
                  selected={index === selectedIndex}
                  onClick={(event) => {
                    handleMenuItemClick(event, index);
                    setPrice(option);
                  }}
                >
                  {option}
                </MenuItemCss>
              ))}
            </MenuListCSS>
          </ClickAwayListener>
        </PaperCSS>
      </PopperBedCSS>
    </>
  );
};
