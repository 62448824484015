import React from "react";
import Contact from "../ContactUs/Contact";
import GoogleMapFile from "../PropertyDetail/GoogleMapFile";
import {
  BookViewContainer,
  Map,
  MapContainer,
  // VirtualImage,
  // VirtualText,
  // VirtualTextContainer,
  // VirtualTextSmall,
  // VirtualVisit,
} from "../PropertyDetail/PropertyDetail.styled";
// import VirtualVisitImage from "../../assets/images/Virtual.jpg";

const MapAndContact = (details) => {
  if (details.details.longtitude && details.details.latitude) {
    var latitude = details.details.latitude;
    var longitude = details.details.longtitude;
  }

  return (
    <>
      <MapContainer>
        {latitude && longitude && (
          <Map>
            <GoogleMapFile
              latitude={latitude}
              longitude={longitude}
              data="project"
            />
          </Map>
        )}
      </MapContainer>
      <BookViewContainer name="Contact">
        <Contact
          displaymsg="block"
          displayupload="none"
          title="Contact Us"
          backgroundColor="white"
          marginBottom="20px"
        />
      </BookViewContainer>
    </>
  );
};

export default MapAndContact;
