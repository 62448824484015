import  {combineReducers, 
    configureStore, 
    getDefaultMiddleware
} from '@reduxjs/toolkit';


import createSagaMiddleware from 'redux-saga';

import { watchSaga } from './sagas/rootSaga';
import propertiesReducer from './slices/propertiesSlices/getProperties';
import featurePropetiesReducer from './slices/projectSlice/getFutureProject';
import projectReducer from './slices/projectSlice/getProject'
import furtureProjectReducer from './slices/projectSlice/getFutureProject'
import agentsReducer from './slices/agentSlice/getAgent'
import unitConversionReducer from './slices/unitConversion/unitConversion'
import contactUsReducer from './slices/contactUs/contactUsSlices'
import heroSearchReducer from './slices/herosearch/herosearchSlice'
import heroProjectSearchReducer from './slices/heroProjectSearch/heroProjectSearchSlices'
import snackbarReducer from './slices/snackbarSlice/snackbarSlice'
import testimonialReducer from './slices/testimonials/testimonialSlice';
import pressCenterReducer from './slices/pressCenter/pressCenter';
import MortageReducer from './slices/MortageSlice/MortageSlice';
import locationReducer from './slices/location/locationSlice';
import developerReducer from './slices/developers/developerSlice';

const sagaMiddleware = createSagaMiddleware();

const reducer = combineReducers({
    snackbar: snackbarReducer,
    properties: propertiesReducer,
    featureProperties: featurePropetiesReducer,
    project: projectReducer,
    futureProject: furtureProjectReducer,
    agents: agentsReducer,
    unitConversion:unitConversionReducer,
    contactUs: contactUsReducer,
    herosearch:heroSearchReducer,
    heroprojectsearch:heroProjectSearchReducer,
    testimonial:testimonialReducer,
    presentReducer: pressCenterReducer,
    mortage:MortageReducer,
    location:locationReducer,
    developer:developerReducer,
});

const store = configureStore({
    reducer,
    middleware:[...getDefaultMiddleware({thunk: false}),
    sagaMiddleware]});

sagaMiddleware.run(watchSaga);

export default store;