import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { postSellPreference } from '../../redux/slices/SellPreferenceSlice.js/SellPreferenceSlice';
import { showSnackBar } from '../../redux/slices/snackbarSlice/snackbarSlice';
import { DownToggleIcon } from "../Footer/Footer.styled";
import { ButtonContainer, DropdownContainer, DropdownDiv, InputCheckbox, InputContainer, InputFlexButton, InputFlexDiv, InputText, InputTextBox, MessageDiv, MessageInput, PrefCtaButton, PreferenceButton, PreferenceHeaderText, PreferenceSubtext } from './PreferenceScreen.styled'
import { SellSection } from './PreferenceScreen.styled';
const defaultFormInput = {
  category: "Rent",
  area: "",
  unit_type: "",
  bedrooms: "",
  name: "",
  phone_no: "",
  email: "",
  message: "",
  checked: "",
};

const SellPreference = () => {
  const [formInput, setFormInput] = useState(defaultFormInput);
  const [bedActive,setBedActive] = useState(false)
  const [unitActive,setUnitActive] = useState(false)
  const dispatch = useDispatch();

  
  const handleInputChange = (name, value) => {
    setFormInput({
      ...formInput,
      [name]: value,
    });
  };
  const snackbarObject = {
    type: '',
    message: '',
    open: false,
  };
  
  const dispatchSnackBar = (type, message, open) => {
    snackbarObject.type = type;
    snackbarObject.message = message;
    snackbarObject.open = open;
    dispatch(showSnackBar(snackbarObject));
  };

  const handleContactUs = (e) => {
    e.stopPropagation();
  if (formInput.area === '') {
      dispatchSnackBar('error', 'Please enter area', true);
  }
  else if (formInput.budget === '') {
    dispatchSnackBar('error', 'Please enter Budget', true);
}
    else if (formInput.name === '') {
      dispatchSnackBar('error', 'Please enter Name', true);
  } else if (formInput.phone_no === '') {
      dispatchSnackBar('error', 'Please enter Phone', true);
  } else if (formInput.email === '') {
      dispatchSnackBar('error', 'Please enter Email', true);
}else if (formInput.message === '') {
    dispatchSnackBar('error', 'Please enter Message')
  }
  else if (!formInput.checked) {
    dispatchSnackBar('error', 'Please accept our user terms and conditions',true)
  } else {
    dispatchSnackBar('success', 'Your Details has been saved')
    dispatch(postSellPreference(formInput));
    setFormInput(defaultFormInput)
  }
};
  return (
    <SellSection>
        <PreferenceHeaderText align='center' >Enter your preferences</PreferenceHeaderText>
            <PreferenceSubtext align='center' >You’re looking property for:</PreferenceSubtext>
            <ButtonContainer center='center' border='0.5px solid #12284c'>
              <PreferenceButton active={formInput.category==='Rent'} onClick={() => handleInputChange("category", "Rent")}>Rent</PreferenceButton>
              <PreferenceButton active={formInput.category==='Sell'} onClick={() => handleInputChange("category", "Sell")}>Sell</PreferenceButton>
            </ButtonContainer>
            <InputContainer border='0.5px solid #12284c'>
            <InputText 
            name="area"
            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
            value={formInput.area}
            id="area"
            placeholder="Enter area of your property"></InputText></InputContainer>
            <InputFlexDiv>
            <DropdownDiv>
              <InputFlexButton onClick={()=>setUnitActive(!unitActive)} border='0.5px solid #12284c'>
              <InputTextBox>{formInput.unit_type===''? 'Unit Type' : formInput.unit_type}</InputTextBox>
              <DownToggleIcon animate={unitActive}/>
              </InputFlexButton>
              <DropdownContainer active={unitActive}>
            <InputTextBox onClick={()=>{setUnitActive(false); handleInputChange('unit_type','All')}}>All</InputTextBox>
            <InputTextBox onClick={()=>{setUnitActive(false); handleInputChange('unit_type','Apartment')}}>Apartment</InputTextBox>
            <InputTextBox onClick={()=>{setUnitActive(false); handleInputChange('unit_type','Villa')}}>Villa</InputTextBox>
            <InputTextBox onClick={()=>{setUnitActive(false); handleInputChange('unit_type','Townhouse')}}>Townhouse</InputTextBox>
            <InputTextBox onClick={()=>{setUnitActive(false); handleInputChange('unit_type','Penthouse')}}>Penthouse</InputTextBox>
            <InputTextBox onClick={()=>{setUnitActive(false); handleInputChange('unit_type','Commercial')}}>Commercial</InputTextBox>
          </DropdownContainer>
            </DropdownDiv>
            <DropdownDiv>
              <InputFlexButton onClick={()=>setBedActive(!bedActive)} border='0.5px solid #12284c'>
              <InputTextBox>{formInput.bedrooms===''? 'Bedrooms' : formInput.bedrooms}</InputTextBox>
              <DownToggleIcon animate={bedActive}/>
              </InputFlexButton>
              <DropdownContainer active={bedActive}>
            <InputTextBox onClick={()=>{setBedActive(false); handleInputChange('bedrooms','Studio')}}>Studio</InputTextBox>
            <InputTextBox onClick={()=>{setBedActive(false); handleInputChange('bedrooms','1-BR')}}>1-BR</InputTextBox>
            <InputTextBox onClick={()=>{setBedActive(false); handleInputChange('bedrooms','2-BR')}}>2-BR</InputTextBox>
            <InputTextBox onClick={()=>{setBedActive(false); handleInputChange('bedrooms','3-BR')}}>3-BR</InputTextBox>
            <InputTextBox onClick={()=>{setBedActive(false); handleInputChange('bedrooms','4-BR')}}>4-BR</InputTextBox>
          </DropdownContainer>
            </DropdownDiv>
            </InputFlexDiv>
                {/* <InputDropdownDiv><InputText placeholder='Unit Type'></InputText><KeyboardArrowDownOutlined/></InputDropdownDiv> */}
            {/* <SellCheckContainer>
              <InputCheckbox>Loream Epsum</InputCheckbox>
            </SellCheckContainer> */}
            {/* <SellCheckContainer>
              <SellCheckbox><input type='checkbox'/>Loream Epsum</SellCheckbox>
              <SellCheckbox><input type='checkbox'/>Loream Epsum</SellCheckbox>
              <SellCheckbox><input type='checkbox'/>Loream Epsum</SellCheckbox>
              <SellCheckbox><input type='checkbox'/>Loream Epsum</SellCheckbox>
              <SellCheckbox><input type='checkbox'/>Loream Epsum</SellCheckbox>
              <SellCheckbox><input type='checkbox'/>Loream Epsum</SellCheckbox>
            </SellCheckContainer> */}
            <InputFlexDiv>
            <InputFlexButton border='0.5px solid #12284c'>
              <InputText 
               name="name"
               onChange={(e) => handleInputChange(e.target.name, e.target.value)}
               value={formInput.name}
               id="name" 
              placeholder="Your Name"></InputText></InputFlexButton>
              <InputFlexButton border='0.5px solid #12284c'><InputText 
              name="phone_no"
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}
              value={formInput.phone_no}
              id="phone_no" 
              placeholder="Phone no"></InputText></InputFlexButton>
            </InputFlexDiv>
            <InputContainer border='0.5px solid #12284c'><InputText 
            name="email"
            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
            value={formInput.email}
            type='email'
            id="community"
            placeholder="Email"></InputText></InputContainer>
            <MessageDiv border='0.5px solid #12284c'><MessageInput 
            name="message"
            id="message" 
            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
            value={formInput.message}
            placeholder="Please mention your requirements here e.g near schools, near public transporation etc" 
            rows={12}></MessageInput></MessageDiv>
            <InputCheckbox>
            <input name= 'checked' checked={formInput.checked} type="checkbox" onChange={(e)=>handleInputChange(e.target.name, e.target.checked)}/>I have read and agree to the terms and conditions.
          </InputCheckbox>
            
            <PrefCtaButton onClick={handleContactUs}>Request your free property evaluation</PrefCtaButton>
    </SellSection>
  )
}

export default SellPreference