import React from "react";
import Typography from "@mui/material/Typography";
import { Breadcrumbs } from "@mui/material";
import Link from "@mui/material/Link";
import "./style.css";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export const BreadcrumbsProjectDetails = ({ details }) => {
 
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/">
    Home
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href={`/projects/${details.category}`}>
        {details.category}
    </Link>,
    <Typography key="3" color="text.primary">
      {details.name.substring(0,50)}...
    </Typography>,
  ];
  return (
    <Stack spacing={2}>
      {details && details.category && details.name && <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>}
    </Stack>
  );
};
