import React from "react";
import { useSelector } from "react-redux";
import {
  DetailAnotherDiv,
  DetailHeading,
  DetailMainDiv,
  MainDetailHeading,
} from "./PropertyDetail.styled";

export const PropertyDetailsInfo = ({ details }) => {
  const { convertValue, currency, convertUnit, unit } = useSelector(
    (state) => state.unitConversion
  );
  const area = Math.round(details.attributes.size * convertUnit);
  const convertedCurrency = Math.round(details.attributes.price * convertValue);
  return (
    <>
      <DetailMainDiv>
        <DetailAnotherDiv>
          <MainDetailHeading>Price:</MainDetailHeading>
          {details && details.attributes && details.attributes.price && (
            <DetailHeading>
              {currency} {convertedCurrency.toLocaleString()} {" "}
            </DetailHeading>
          )}
        </DetailAnotherDiv>
        <DetailAnotherDiv>
          <MainDetailHeading>Bedrooms:</MainDetailHeading>
          {details && details.attributes && details.attributes.bedrooms && (
            <DetailHeading>
              {details.attributes.bedrooms}
            </DetailHeading>
          )}
        </DetailAnotherDiv>
        <DetailAnotherDiv>
          <MainDetailHeading>Bathrooms:</MainDetailHeading>
          <DetailHeading>
            {details.attributes.bathrooms}
          </DetailHeading>
        </DetailAnotherDiv>
        <DetailAnotherDiv>
          <MainDetailHeading>Size:</MainDetailHeading>
          {details && details.attributes && details.attributes.size && (
            <DetailHeading>
              {area.toLocaleString()}
              {unit}
            </DetailHeading>
          )}
        </DetailAnotherDiv>
      </DetailMainDiv>
    </>
  );
};
