import React, { useEffect, useState } from 'react'
import AgencyYouCanTrust from '../components/AgencyYouCanTrust/AgencyYouCanTrust';
import HeroAboutUs from '../components/CompanyHero/HeroAboutUs';
import Contact from '../components/ContactUs/Contact';
import { ContactDiv } from '../components/ContactUs/ContactUs.styled';
import CustomizedSnackbar from '../components/CustomizedSnackbars/CustomizedSnackbar';
import Footer from '../components/Footer/Footer';
import FooterMobile from '../components/Footer/FooterMobile';
import Navbar from '../components/Navbar/Navbar';
import WhoWeAre from '../components/Vision/WhoWeAre';
import WhyInvestInDubai from '../components/WhyInvestInDubai/WhyInvestInDubai';
import aboutImage from "../assets/images/1649856483087.jpeg";
import image2 from "../assets/images/1652265893772.jpeg";
import image3 from "../assets/images/1652960386678.jpeg";
import image4 from "../assets/images/1654092906790.jpeg";

const text = [
'Highest Annual Return on Investment - Dubai offers comparatively higher RIO than other major metropolises. While cities like Singapore or New York offer an average return between 3 to 5%, in Dubai it ranges from 7 to 10%.',
'Attractive Prices - Prices of high quality apartments are significantly lower than other big cities, with average prices of apartments on the beach selling at USD $5,000/m² - for villas in popular family areas USD $3,800/m².',
'Security and Safety - Dubai ranks in the top 3 of most secure cities to live in. With low crime rates and a highly monitored city, it also offers a variety of gated communities',
'Favorable Tax System - Dubai comes close to being tax-free. Citizens are not obligated to pay taxes on property, income or capital gains.',
'Great Work-Life Balance - Aside from its incredible attractions, delicious dining, and amazing things to do, Dubai presents easy and affordable access to home cleanings, child care and more.',
"Sustainable Growth - Clear vision to develop Dubai to the world's best place to live in by 2040, with long-term well thought development plans for a stable economy."
]

const text2 = ['Travel and Tourism',
  'Real Estate Properties',
  'Transportation and Logistics',
  'Construction Sector',
  'Information Technology'
  ]
const CompanyWhyInvest = () => {
  const [width, setWidth] = useState(window.innerWidth)
  const [offset, setOffset] = useState(0);
  const [background,setBackground] = useState('transparent')
  useEffect(()=>{
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    window.scrollTo(0, 0)
  },[])
  useEffect(()=>{
    if (offset>100) setBackground('#12284C')
    else setBackground('transparent')
  },[offset])
  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll); 
}, []);
  return (
    <div>
      <Navbar background={background}/>
        <HeroAboutUs header='Why Invest in Dubai?' desc='A Haven for Investment'/>
        <WhyInvestInDubai data={text}/>
        <WhoWeAre
        wrap="reverse"
        button="border"
        header="Highly Regulated Industry"
        desc="All the processes in Dubai have been designed to ensure transparency and security for both buyers and sellers."
        cta="Contact Us"
      image={aboutImage}
        />
        <WhoWeAre
        button="border"
        header="Golden Visa Residency"
        desc="Buying a property that is valued at least AED 2 million in Dubai might give you an eligibility to the golden visa scheme - a long-term visa for residence, work and business in the UAE."
        cta="Contact Us"
      image={image2}
        />
        <WhoWeAre
        wrap="reverse"
        button="border"
        header="Attractive Rental Yields"
        desc="Dubai real estate is well known for its high rental yield. The city’s property market has been stable with demand consistently exceeding supply – which results in a high rental yield and moderate capital gains."
        cta="Contact Us"
        image={image3}
        />
        <WhoWeAre
        button="border"
        header="Favorable Tax Conditions"
        desc="The city attracts expatriates and companies from all over the world, providing added advantages for those who wish to move their businesses or families there."
        cta="Contact Us"
      image={image4}
        />
        <AgencyYouCanTrust header='Main Economic Drivers of Dubai' desc='' data={text2}/>
        <ContactDiv>
    <Contact title='Contact Us' shrink='true' displaymsg='block' displayupload = 'none'/>
    </ContactDiv>
        {width < 1204?<FooterMobile/>:<Footer/>}
        <CustomizedSnackbar/>
    </div>
  )
}

export default CompanyWhyInvest