import React from "react";
import {
  ContactUs,
  MainButtonThree,
  ProjectDescriptionContainer,
  ProjectPara,
} from "./projects.styled";
import { scroller } from "react-scroll";
export const ProjectDescription = (details) => {
  const handleClick = () => {
    scroller.scrollTo("Contact", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <ProjectDescriptionContainer>
      {details && details.details.description && (
        <ProjectPara>{details.details.description}</ProjectPara>
      )}
      <ContactUs>
        <MainButtonThree onClick={handleClick}>Contact Us</MainButtonThree>
      </ContactUs>
    </ProjectDescriptionContainer>
  );
};
