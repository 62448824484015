import styled from "styled-components";

export const ProjectSection = styled.section`
  height: auto;
  background:${props=>props.background};
  @media only screen and (min-width: 1900px) {
    padding-top:${props=>props.nineteen};
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
     padding-top:${props=>props.thirteen};
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    padding-top:${props=>props.nine};
  }
  @media only screen and (max-width: 400px) {
    padding-top:${props=>props.four};
  }
  @media only screen and (min-device-width: 400px) and (max-device-width: 1200px) and (orientation: portrait) {
    padding-top:${props=>props.nine};
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    padding-top:${props=>props.nine};
  }
`;
export const ProjectHeadText = styled.h1`
  font-family: "Didot";
  font-style: normal;
  color: #000000;
  @media only screen and (min-width: 1367px) {
    font-weight: 400;
    font-size: 64px;
    line-height: 81px;
  }
  @media only screen and (min-width: 1204px) and (max-width: 1366px) {
    font-weight: 400;
    font-size: 48px;
    line-height: 61px;
  }
  @media only screen and (max-width: 1204px) {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
  }
`;

export const ProjectTitle = styled.h1`
  font-family: "Montserrat";
  font-style: normal;
  color: #000000;
  @media only screen and (min-width: 1900px) {
    font-weight: 500;
    font-size: 40px;
    line-height: 51px;
  }
  @media only screen and (min-width: 1366px) and (max-width: 1900px) {
    font-weight: 500;
    font-size: 38px;
    line-height: 51px;
  }
  @media only screen and (min-width: 1204px) and (max-width: 1366px) {
    font-weight: 500;
    font-size: 25.2819px;
    line-height: 31px;
  }
  @media only screen and (max-width: 1204px) {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }
`;
export const ProjectSubtext = styled.h3`
  font-family: "Montserrat";
  font-style: normal;
  color: #000000;
  @media only screen and (min-width: 1900px) {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
  @media only screen and (min-width: 1366px) and (max-width: 1900px) {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
  }
  @media only screen and (min-width: 1204px) and (max-width: 1366px) {
    font-weight: 400;
    font-size: 11.1145px;
    line-height: 14px;
  }
  @media only screen and (max-width: 1204px) {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }
`;
export const ProjectDescription = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  color: #000000;
  text-align: justify;
  text-justify: inter-word;
  @media only screen and (min-width: 1900px) {
    font-weight: 400;
    font-size: 20px;
    line-height: 167.9%;
    margin-top: 43px;
  }
  @media only screen and (min-width: 1366px) and (max-width: 1900px) {
    font-weight: 300;
    font-size: 18.48px;
    line-height: 167.9%;
    margin-top: 43px;
  }
  @media only screen and (min-width: 1204px) and (max-width: 1366px) {
    font-weight: 300;
    font-size: 13.6931px;
    line-height: 167.9%;
    margin-top: 43px;
  }
  @media only screen and (max-width: 1204px) {
    font-weight: 300;
    font-size: 13px;
    line-height: 153.9%;
    margin-top: 27px;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 300;
    font-size: 13px;
    line-height: 153.9%;
    margin-top: 27px;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 300;
    font-size: 13px;
    line-height: 153.9%;
    margin-top: 27px;
  }
`;
export const ProjectIcon = styled.img`
  width: 15px;
  height: 15px;
`;

export const ProjectImage = styled.img`
  @media only screen and (min-width: 1900px) {
    min-width: 50%;
    height: 820px;
  }
  @media only screen and (min-width: 1366px) and (max-width: 1900px) {
    min-width: 50%;
    height: 723px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1366px) {
    min-width: 50%;
    height: 535px;
  }
  @media only screen and (max-width: 900px) {
    min-width: 100%;
    height: 328px;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    min-width: 100%;
    height: 328px;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    min-width: 50%;
    height: 535px;
  }
`;

export const DetailContainer = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: nowrap;
  @media only screen and (min-width: 900px) {
    width: 100%;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 100%;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 100%;
  }
`;
export const MobContainer = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  @media only screen and (max-width: 900px) {
    width: 290px;
    margin-top: 25px;
    justify-content: space-between;
    gap: 25px;
  }
  @media only screen and (min-width: 900px) {
    margin-top: 60px;
    flex-direction: column;
    gap: 35px;
  }
`;
export const ProjectCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  min-width: 100%;
  @media only screen and (min-width: 1900px) {
    flex-direction: row;
    height: 820px;
    gap: 5%;
    padding-left: 55px;
  }
  @media only screen and (min-width: 1366px) and (max-width: 1900px) {
    flex-direction: row;
    height: 723px;
    gap: 5%;
    padding-left: 35px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1366px) {
    height: 535px;
    flex-direction: row;
    gap: 5%;
    padding-left: 28px;
  }
  @media only screen and (max-width: 900px) {
    height: auto;
    flex-wrap: wrap-reverse;
  }
`;
export const DetailCardContainer = styled.div`
  @media only screen and (min-width: 900px) {
    min-width: 45%;
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
    padding: 20px;
  }
`;
export const ProjectScrollContainer = styled.div`
  display: flex;
  overflow-x: auto;
  height: auto;
  scroll-behavior: smooth;
  gap: 30px;
  @media only screen and (min-width: 900px) {
    width: 85%;
  }
  @media only screen and (max-width: 900px) {
    width: 90%;
    
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const ProjectPaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 30px;
`;
export const ProjectHeadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: auto;
  overflow-x: visible !important;
  @media only screen and (min-width: 900px) {
    width: 85%;
    align-items: center;
  }
  @media only screen and (max-width: 900px) {
    width: 90%;
  }
`;

export const ProjectHeadContainerNoor = styled.div`
  display: flex;
  justify-content: space-between;
  margin: auto;
  overflow-x: visible !important;
  @media only screen and (min-width: 900px) {
    width: 85%;
    align-items: center;
  }
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    gap: 20px;
    width: 90%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  height: fit-content;
  overflow-x: visible !important;
  @media only screen and (min-width: 1920px) {
    margin-top: 70px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    margin-top: 40px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    margin-top: 30px;
  }
  @media only screen and (max-width: 900px) {
    margin-top: 30px;
  }
  @media only screen and (max-width: 400px) {
    margin-top: 30px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    margin-top: 30px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    margin-top: 30px;
  }
`;
export const ProjectButton = styled.button`
  box-sizing: border-box;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.125em;
  color: #000000;
  border-radius: 90px;
  border: transparent;
  display: flex;
  align-items: center;
  overflow: hidden;
  min-width: fit-content;
  @media only screen and (min-width: 1367px) {
    font-size: 16px;
    padding: 10px 24px;
    height: 40px;
    gap: 24px;
  }
  @media only screen and (min-width: 1204px) and (max-width: 1366px) {
    font-size: 13px;
    padding: 10px 24px;
    height: 40px;
    gap: 24px;
  }
  @media only screen and (max-width: 1204px) {
    font-size: 12px;
    padding: 7px 18px;
    height: 30px;
    gap: 16px;
  }
`;
export const ExploreButton = styled(ProjectButton)`
  background: #12284c;
  color: #ffffff;
  cursor: pointer;
  &:hover {
    border: transparent;
    background: #004876;
  }
  a {
    color: #ffffff;
  }
`;
export const VirtualButton = styled(ProjectButton)`
  border: 0.75px solid #decdad;
  color: #000000;
  background: transparent;
  border: 0.75px solid #000000;
  &:hover {
    background: #decdad;
    border: 0.75px solid transparent;
  }
`;

export const VirtualImg = styled.img`
  @media only screen and (min-width: 900px) {
    width: 30px;
    height: 30px;
  }
  @media only screen and (max-width: 1000px) {
    width: 20px;
    height: 20px;
  }
`;
export const Arrowimg = styled.img`
  width: 23px;
  height: 14px;
`;
export const DropdownContainer = styled.div`
  position: relative;
  overflow-x: visible !important;
`;
export const DropdownDiv = styled.div`
  display: ${(props) => props.display};
  position: absolute;
  top: 40px;
  background: rgba(238, 242, 244, 0.5);
  border: 0.75px solid #000000;
  border-radius: 20px;
  backdrop-filter: blur(7.5px);
  height: auto;
  z-index: 99;
  flex-direction: column;
  gap: 10px;

  @media only screen and (min-width: 900px) {
    width: 184.53px;
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
  }
  button {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #000000;
    text-align: center;
    width: 100%;
    vertical-align: middle;
    border: none;
    border-radius: 20px;
    height: 35px;
    @media only screen and (min-width: 1367px) {
      font-size: 16px;
    }
    @media only screen and (min-width: 1204px) and (max-width: 1366px) {
      font-size: 13px;
    }
    @media only screen and (max-width: 1204px) {
      font-size: 15px;
    }
    &:hover {
      background: #decdad;
      color: #ffffff;
    }
  }
`;
