import React, { useState } from "react";
import { TfiMinus, TfiPlus } from "react-icons/tfi";
import {
  Wrapper,
  QuestionMarkImage,
  AllQuestions,
  AllAnswers,
  QuestionPart,
  QuestionContainer,
  PlusSign,
} from "./Project.styled";
import questions from "./faqs";
import Question from "../../assets/images/faq.svg";


export const Banner = () => {
  const [selected, setSelected] = useState(null);
  const [active, setActive] = useState(0)
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };
  return (
    <>
      {questions.map((item, i) => (
        <Wrapper key={item.id} active={item.id===active} onClick={()=>setActive(item.id)}>
          <QuestionPart>
            <QuestionMarkImage src={Question} alt="" />
            <QuestionContainer>
              <AllQuestions onClick={() => toggle(i)}>
                {item.question}
              </AllQuestions>
            </QuestionContainer>
            <PlusSign onClick={() => toggle(i)}>
              {selected === i ? <TfiMinus /> : <TfiPlus />}
            </PlusSign>
          </QuestionPart>
          {selected === i ? (
            <QuestionContainer>
              <AllAnswers> {item.answer} </AllAnswers>
            </QuestionContainer>
          ) : null}
        </Wrapper>
      ))}
    </>
  );
};
