import styled from "styled-components";
import { AboutDescText } from "../AboutUs/AboutUs.styled";

export const ClientSection = styled.section`
  position:relative;
  background: #12284c;
  height: auto;
  @media only screen and (min-width: 1920px) {
    padding: 150px 0px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    padding: 130px 0px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    padding: 100px 0px;
  }
  @media only screen and (max-width: 900px) {
    padding: 50px 0px;
  }
  @media only screen and (max-width: 400px) {
    padding: 50px 0px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    padding: 100px 0px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    padding: 100px 0px;
  }
`;

export const ClientName = styled.h4`
  font-family: "Montserrat";
  font-style: normal;
  color: #ffffff;
  @media only screen and (min-width: 1920px) {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-top: 46px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-top: 30px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    margin-top: 30px;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    margin-top: 12px;
  }
  @media only screen and (max-width: 400px) {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    margin-top: 12px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    margin-top: 30px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    margin-top: 30px;
  }
`;

export const ClientJob = styled.h5`
  font-family: "Montserrat";
  font-style: italic;
  color: #ffffff;
  @media only screen and (min-width: 1920px) {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
  }
  @media only screen and (max-width: 400px) {
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
  }
`;

export const ClientContainer = styled.div`
  width: 85%;
  margin: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
export const ClientDescContainer = styled.div`
  @media only screen and (min-width: 900px) {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  @media only screen and (max-width: 900px) {
    min-width: 100%;
  }
`;

export const ClientDesc = styled(AboutDescText)`
  @media only screen and (max-width: 900px) {
    overflow: hidden;
  }
`;
export const ClientSlider = styled.div`
  @media only screen and (min-width: 1920px) {
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  @media only screen and (max-width: 900px) {
    margin-top: 27px;
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  
`;
export const SliderButton = styled.button`
  width: ${(props) => (props.active ? "14px" : "12px")};
  height: ${(props) => (props.active ? "14px" : "12px")};
  border: transparent;
  border-radius: 50%;
  background: ${(props) => (props.active ? "white" : "gray")};
  @media only screen and (min-width: 1920px) {
    margin-top: 10px;
  }
`;

export const ClientScrollContainer = styled.div`
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (min-width: 1900px) {
    overflow-y: auto;
    width: 30%;
    margin-left: auto;
    height: 245px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    overflow-y: auto;
    width: 40%;
    margin-left: auto;
    height: 200px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    overflow-y: auto;
    width: 40%;
    margin-left: auto;
    height: 180px;
  }
  @media only screen and (max-width: 900px) {
    overflow-x: auto;
    min-width: 100%;
    height: 130px;
    display: flex;
    gap: 10px;
    margin-top: 34px;
  }
  @media only screen and (max-width: 400px) {
    overflow-x: auto;
    min-width: 100%;
    height: 130px;
    display: flex;
    gap: 10px;
    margin-top: 34px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    overflow-y: auto;
    width: 40%;
    margin-left: auto;
    height: 180px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    overflow-y: auto;
    width: 40%;
    margin-left: auto;
    height: 180px;
  }
`;
