import { createSlice } from '@reduxjs/toolkit';
const heroSearchSlice = createSlice({
    name: 'heroSearch',
    initialState: {
        type: '',
        community: '',
        unittype: '',
        beds:'',
        price:{lower:0, higher:0},
        filter:'',
        page:1,
    },
    reducers: {
        setSearch(state, action) {
            state.type = action.payload.type;
            state.community = action.payload.community;
            state.unittype = action.payload.unittype;
            state.beds = action.payload.bed;
            state.price = action.payload.price;
            state.page = action.payload.page;
            state.filter = action.payload.filter;
        },
        setSearchContent(state, action) {
            state[action.payload.key] = action.payload.value;
        },
    },
});
export const { setSearch, setSearchContent} = heroSearchSlice.actions;

export default heroSearchSlice.reducer;