import React, { useEffect, useState } from "react";
import HeroAboutUs from "../components/CompanyHero/HeroAboutUs";
import Contact from "../components/ContactUs/Contact";
import { ContactDiv } from "../components/ContactUs/ContactUs.styled";
import CustomizedSnackbar from "../components/CustomizedSnackbars/CustomizedSnackbar";
import Footer from "../components/Footer/Footer";
import FooterMobile from "../components/Footer/FooterMobile";
import Navbar from "../components/Navbar/Navbar";
import WhoWeAre from "../components/Vision/WhoWeAre";
import WhyInvestInDubai from "../components/WhyInvestInDubai/WhyInvestInDubai";
import aboutImage from "../assets/images/1649856483087.jpeg";
import image2 from "../assets/images/1652265893772.jpeg";
import image3 from "../assets/images/1652960386678.jpeg";

const TabletBreakpoint = 1024;
const text = ['Experienced advisors as passionate about home buying as you are',
'Enjoy the convenience of managing all your real estate needs in one place',
  'Solid Track Record',
  'Expansive Reach in the Market'];
const CompanyWhyUs = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [offset, setOffset] = useState(0);
  const [background, setBackground] = useState("transparent");
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (offset > 100) setBackground("#12284C");
    else setBackground("transparent");
  }, [offset]);
  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  return (
    <div>
      <Navbar background={background} />
      <HeroAboutUs header='Why Choose Modern Living?' desc='Whether you are looking for an investment or a home for yourself, a property by the seashore or the city center, family-friendly or with special facilities - we have the best solutions, to fit every pocket.'/>
      <WhoWeAre
        wrap="reverse"
        button="border"
        header="Local Agents On Your Side"
        desc="With over 10 years of experience our team holds comprehensive market knowledge to help you on every single step."
        cta="Contact Us"
      image={aboutImage}
      />
      <WhoWeAre
        button="border"
        header="Buy Your Dream Home At The Right Price"
        desc="Our know-how assures our customers the best prices in an honest and transparent way."
        cta="Contact Us"
        image={image2}

      />
      <WhoWeAre
        wrap="reverse"
        button="border"
        header="We Built Modern Living to Get You a Better Deal"
        desc="Our mission is to establish a relationship of trust with our clients, helping them to achieve their dream of owning a property in Dubai, whether for housing or for investment."
        cta="Contact Us"
        image={image3}

      />
      <WhyInvestInDubai data={text}/>
      <ContactDiv>
        <Contact title="Contact Us" shrink="true" displaymsg='block' displayupload = 'none'/>
      </ContactDiv>
      {width < TabletBreakpoint ? <FooterMobile /> : <Footer />}
      <CustomizedSnackbar />
    </div>
  );
};

export default CompanyWhyUs;
