import styled from "styled-components";
// import { keyframes } from "styled-components";
import {
  FilterButton,
  HeaderText,
  PageIcon,
} from "../PropertyListing.js/PropertyListing.styled";

export const MeetTheTeamSection = styled.section`
  width: 100%;
  position: relative;
  background: rgba(238, 242, 244, 0.42);
  scroll-behavior: smooth;
  overflow-x:visible !important;
  @media only screen and (min-width: 1920px) {
    padding: 115.86px 0px 181px 0px;
    }
  @media only screen and (min-width: 1300px) and (max-width:1920px){
    padding: 158px 0px 187px 0px;
    }
  @media only screen and (min-width: 900px) and (max-width:1300px){
    padding: 121px 0px 138px 0px;
    }
  @media only screen and (max-width: 900px) {
    padding: 54px 0px 68px 0px;
    }
  @media only screen and (max-width: 400px) {
    padding: 54px 0px 68px 0px;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    padding: 121px 0px 138px 0px;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    padding: 121px 0px 138px 0px;
    }
`;
export const TeamDetailContainer = styled.div`
  display: flex;
  margin: auto;
  gap: 3%;
  height: auto;
  overflow: hidden;
  @media only screen and (min-width: 1920px) {
    align-items: center;
    justify-content: space-between;
    align-items:center;
    margin-top: 50px;
    width: 66%;
    }
  @media only screen and (min-width: 1300px) and (max-width:1920px){
    align-items: center;
    justify-content: space-between;
    align-items:center;
    margin-top: 50px;
    width: 66%;
    }
  @media only screen and (min-width: 900px) and (max-width:1300px){
    align-items: center;
    justify-content: space-between;
    align-items:center;
    margin-top: 50px;
    width: 72%;
    }
  @media only screen and (max-width: 900px) {
    justify-content: space-between;
    align-items:center;
    margin-top: 37px;
    width: 90%;
    }
  @media only screen and (max-width: 500px) {
    flex-wrap: wrap-reverse;
    margin-top: 37px;
    width: 90%;
    }
  @media only screen and (min-device-width: 900px) and (max-device-width: 1200px) and (orientation: portrait) {
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
    width: 67%;
    }
  @media only screen and (min-device-width: 900px) and (max-device-width: 1200px) and (orientation: landscape) {
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
    width: 67%;
    }
`;
export const TeamDetailTextContainer = styled.div`
@media only screen and (min-width: 1920px) {
  width: 658px;
  }
@media only screen and (min-width: 1300px) and (max-width:1920px){
  width: 457px;
  }
@media only screen and (min-width: 900px) and (max-width:1300px){
  width: 318.44px;
  }
@media only screen and (max-width: 900px) {
  width: 100%;
  }
@media only screen and (max-width: 400px) {
  width: 100%;
  }
@media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
  width: 318.44px;
  }
@media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
  width: 318.44px;
  }
`;

export const TeamName = styled.h3`
  font-family: "Montserrat";
  font-style: normal;
  color: #000000;
  @media only screen and (min-width: 1920px) {
    font-weight: 700;
    font-size: 21px;
    line-height: 26px;
    }
  @media only screen and (min-width: 1300px) and (max-width:1920px){
    font-weight: 700;
    font-size: 21px;
    line-height: 26px;
    }
  @media only screen and (min-width: 900px) and (max-width:1300px){
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    }
  @media only screen and (max-width: 900px) {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-top: 23px;
    }
  @media only screen and (max-width: 400px) {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-top: 23px;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    }
`;

export const TeamJobTitle = styled.h4`
  font-family: "Montserrat";
  font-style: italic;
  color: #000000;
  @media only screen and (min-width: 1920px) {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    }
  @media only screen and (min-width: 1300px) and (max-width:1920px){
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    }
  @media only screen and (min-width: 900px) and (max-width:1300px){
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    }
  @media only screen and (max-width: 900px) {
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    }
  @media only screen and (max-width: 400px) {
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    }
`;

export const TeamDesc = styled.p`
  overflow: hidden;
  font-family: "Montserrat";
  font-style: normal;
  color: #000000;
  max-height: 170px;
  text-align: justify;
  text-justify: inter-word;
  height:100px;
  @media only screen and (min-width: 1920px) {
    font-weight: 400;
    font-size: 20px;
    line-height: 167.9%;
    margin-top: 43px;
    }
  @media only screen and (min-width: 1300px) and (max-width:1920px){
    font-weight: 400;
    font-size: 16px;
    line-height: 153.9%;
    margin-top: 30px;
    }
  @media only screen and (min-width: 900px) and (max-width:1300px){
    font-weight: 400;
    font-size: 16px;
    line-height: 153.9%;
    margin-top: 25px;
    }
  @media only screen and (max-width: 900px) {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    margin-top: 25px;
    }
  @media only screen and (max-width: 400px) {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    margin-top: 25px;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 400;
    font-size: 16px;
    line-height: 153.9%;
    margin-top: 25px;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 400;
    font-size: 16px;
    line-height: 153.9%;
    margin-top: 25px;
    }
`;

export const TeamButtonContainer = styled.div`
  display: flex;
  @media only screen and (min-width: 1200px) {
    gap: 30px;
    margin-top: 20px;
  }
  @media only screen and (max-width: 1200px) {
    gap: 17px;
    margin-top: 26px;
  }

  // Safari Laptop

  @media only screen and (min-device-width: 1200px) {
    gap: 30px;
    margin-top: 20px;
  }

  // Only for Ipad

  @media only screen and (min-device-width: 700px) and (max-device-width: 1200px) and (orientation: portrait) {
    gap: 17px;
    margin-top: 26px;
  }

  @media only screen and (min-device-width: 700px) and (max-device-width: 1200px) and (orientation: landscape) {
    gap: 10px;
    margin-top: 26px;
  }
`;

export const TrustImage = styled.img`
  @media (min-width: 1920px) {
    width: 138px;
    height: 56.32px;
    margin-top: 25px;
  }
  @media ((min-width: 1200px) and (max-width:1920px)) {
    width: 98.97px;
    height: 40.13px;
    margin-top: 25px;
  }
  @media ((min-width: 700px) and (max-width:1200px)) {
    width: 74.96px;
    height: 30.59px;
  }
  @media (max-width: 700px) {
    width: 103px;
    height: 42px;
    margin-top: 20px;
  }

  // Safari Laptop

  @media only screen and (min-device-width: 1200px) {
    width: 138px;
    height: 56.32px;
    margin-top: 25px;
  }

  // Only for Ipad

  @media only screen and (min-device-width: 700px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 74.96px;
    height: 30.59px;
  }

  @media only screen and (min-device-width: 700px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 74.96px;
    height: 30.59px;
  }
`;


export const TeamStarContainer = styled.div`
  display: flex;
  gap: 1px;
  margin-top: 13px;
`;
export const TeamImage = styled.img`
  aspect-ratio: 1/ 1;
  @media only screen and (min-width: 1920px) {
    width: 50%;
    }
  @media only screen and (min-width: 1300px) and (max-width:1920px){
    width: 50%;
    }
  @media only screen and (min-width: 900px) and (max-width:1300px){
    width: 50%;
    }
  @media only screen and (max-width: 900px) {
    width: 100%;
    }
  @media only screen and (max-width: 400px) {
    width: 100%;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 50%;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 50%;
    }
`;
export const TeamPageContainer = styled.div`
  display: flex;
  height: auto;
  align-items: center;
  /* @media only screen and (min-width: 1920px) {
    margin-top: 117px;
    }
  @media only screen and (min-width: 1300px) and (max-width:1920px){
    margin-top: 83px;
    }
  @media only screen and (min-width: 900px) and (max-width:1300px){
    margin-top: 39px;
    }
  @media only screen and (max-width: 900px) {
    margin-top: 30px;
    }
  @media only screen and (max-width: 400px) {
    margin-top: 30px;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    margin-top: 39px;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    margin-top: 39px;
    } */
`;

export const TeamScrollMainContainer = styled.div`
margin:auto;
@media only screen and (min-width: 1920px) {
  width: 90%;
  }
 @media only screen and (min-width: 1300px) and (max-width:1920px){
  width: 90%;
  }
 @media only screen and (min-width: 900px) and (max-width:1300px){
  width: 80%;
  }
 @media only screen and (max-width: 900px) {
  width: 90%;
  }
 @media only screen and (max-width: 400px) {
  width: 90%;
  }
 @media only screen and (min-device-width: 900px) and (max-device-width: 1200px) and (orientation: portrait) {
  width: 80%;
  }
 @media only screen and (min-device-width: 900px) and (max-device-width: 1200px) and (orientation: landscape) {
  width: 80%;
  }
`
export const TeamScrollContainer = styled.div`
  display: flex;
  margin: auto;
  height: auto;
  overflow:auto;
  scroll-behavior:smooth;
 @media only screen and (min-width: 1920px) {
  width: 100%;
    gap: 5%;
  }
 @media only screen and (min-width: 1300px) and (max-width:1920px){
  width: 100%;
    gap: 5%;
  }
 @media only screen and (min-width: 900px) and (max-width:1300px){
  width: 100%;
    gap: 5%;
  }
 @media only screen and (max-width: 900px) {
  width: 100%;
    gap: 5%;
  }
 @media only screen and (max-width: 400px) {
  width: 100%;
    gap: 5%;
  }
 @media only screen and (min-device-width: 900px) and (max-device-width: 1200px) and (orientation: portrait) {
  width: 100%;
    gap: 5%;
  }
 @media only screen and (min-device-width: 900px) and (max-device-width: 1200px) and (orientation: landscape) {
  width: 100%;
    gap: 5%;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const TeamCard = styled.div`
  position: relative;
  height: auto;
  @media only screen and (min-width: 1920px) {
    min-width: 19%;
    }
  @media only screen and (min-width: 1300px) and (max-width:1920px){
    min-width: 19%;
    }
  @media only screen and (min-width: 900px) and (max-width:1300px){
    min-width: 21.25%;
    }
  @media only screen and (max-width: 900px) {
    min-width: 47.5%;
    }
  @media only screen and (max-width: 400px) {
    min-width: 47.5%;
    }
  @media only screen and (min-device-width: 900px) and (max-device-width: 1200px) and (orientation: portrait) {
    min-width: 19%;
    }
  @media only screen and (min-device-width: 900px) and (max-device-width: 1200px) and (orientation: landscape) {
    min-width: 19%;
    }
`;
export const TeamCardImage = styled.img`
  width: 100%;
  height: 350px;
  object-fit:cover;

`;
export const TeamCardName = styled.h4`
  font-family: "Montserrat";
  font-style: normal;
  color: #000000;
  @media only screen and (min-width: 1920px) {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    }
  @media only screen and (min-width: 1300px) and (max-width:1920px){
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    }
  @media only screen and (min-width: 900px) and (max-width:1300px){
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    }
  @media only screen and (max-width: 900px) {
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    }
  @media only screen and (max-width: 400px) {
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    }
`;
export const TeamCardJobTitle = styled.h6`
  font-family: "Montserrat";
  font-style: italic;
  @media only screen and (min-width: 1920px) {
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    }
  @media only screen and (min-width: 1300px) and (max-width:1920px){
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    }
  @media only screen and (min-width: 900px) and (max-width:1300px){
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    }
  @media only screen and (max-width: 900px) {
    font-weight: 300;
    font-size: 1px;
    line-height: 15px;
    }
  @media only screen and (max-width: 400px) {
    font-weight: 300;
    font-size: 1px;
    line-height: 15px;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    }
`;
export const TeamJobDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: space-between;
`;

export const TeamIcon = styled.img`
  @media only screen and (min-width: 1920px) {
    width:35px;
    height:36px;
    }
  @media only screen and (min-width: 1300px) and (max-width:1920px){
    width:30px;
    height:31px;
    }
  @media only screen and (min-width: 900px) and (max-width:1300px){
    width:25px;
    height:26px;
    }
  @media only screen and (max-width: 900px) {
    width:20px;
    height:21px;
    }
  @media only screen and (max-width: 400px) {
    width:16px;
    height:17px;
    }
  
`;
export const AboutTeamHeader = styled(HeaderText)`
  @media only screen and (min-width: 900px) {
    text-align: left;
  }
  @media only screen and (max-width: 900px) {
    text-align: center;
  }
  // Only for Ipad

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) {
    text-align: center;
  }

  @media only screen and (device-width: 768px) {
    text-align: center;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    text-align: center;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    text-align: center;
  }
`;
export const AboutTeamContainer = styled.section`
  display: flex;
  align-items: center;
  width: 90%;
  margin: auto;
  justify-content: space-between;
`;
export const TeamAboutCard = styled.div`
  background: #ffffff;
  border-radius:10px;
  width:100%;
  height:auto;
  gap:10px;
  padding:20px;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  cursor: pointer;
  
  /* @media only screen and (min-width: 900px) {
    min-width: 21.25%;
    width: 21.25%;
  }
  @media  only screen and (max-width: 900px) {
    min-width: 47%;
  }
  @media  only screen and (max-width: 500px) {
    min-width: 100%;
  } */
`;

export const TeamDiv = styled.div`
  width:90%;
  margin:auto;
`

export const TeamComponentSection = styled.section`
  display: flex;
  gap: 5%;
  flex-wrap: wrap;
  width: 90%;
  margin: auto;
@media only screen and (min-width: 1920px) {
  row-gap: 65px;
    margin-bottom: 80px;
  }
@media only screen and (min-width: 1300px) and (max-width:1920px){
  row-gap: 47.5px;
    margin-bottom: 80px;
  }
@media only screen and (min-width: 900px) and (max-width:1300px){
  margin-bottom: 30px;
    row-gap: 35px;
  }
@media only screen and (max-width: 900px) {
  row-gap: 65px;
    margin-bottom: 30px;
  }
@media only screen and (max-width: 400px) {
  row-gap: 65px;
    margin-bottom: 30px;
  }
@media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
  margin-bottom: 30px;
    row-gap: 35px;
  }
@media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
  margin-bottom: 30px;
    row-gap: 35px;
  }
  
`;
export const LoadMoreButton = styled(FilterButton)`
  display: block;
  margin: auto !important;
@media only screen and (min-width: 1920px) {
  margin: 105px 0px;
  }
@media only screen and (min-width: 1300px) and (max-width:1920px){
  margin: 105px 0px;
  }
@media only screen and (min-width: 900px) and (max-width:1300px){
  margin: 65px 0px;
  }
@media only screen and (max-width: 900px) {
  margin: 100px 0px;
  }
@media only screen and (max-width: 400px) {
  margin: 100px 0px;
  }
@media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
  margin: 65px 0px;
  }
@media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
  margin: 65px 0px;
  }
 
`;
export const ScrollIcon = styled(PageIcon)`
  width: 10%;
`;
