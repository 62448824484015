import React, { useEffect, useState } from "react";
import HeroSellPage from "../components/CompanyHero/HeroSellPage";
import CustomizedSnackbar from "../components/CustomizedSnackbars/CustomizedSnackbar";
import Footer from "../components/Footer/Footer";
import FooterMobile from "../components/Footer/FooterMobile";
import Navbar from "../components/Navbar/Navbar";
import SellPreference from "../components/PreferenceScreen/SellPreference";
import Video from "../components/Video/Video";
import Videoplay from '../assets/images/LandingPageVideo.mp4'
const TabletBreakpoint = 1200;

const SellPage = () => {
  const [offset, setOffset] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [background, setBackground] = useState("transparent");
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  useEffect(() => {
    if (offset > 10) setBackground("#12284C");
    else setBackground("transparent");
  }, [offset]);
  return (
    <div>
      <Navbar background={background} />
      <HeroSellPage />
      <Video
        header="Why Partner with Modern Living?"
        desc="Our team has more than 10 years of experience in the Dubai luxury real estate market, with multilingual professionals who can provide unmatched support that ensures unsurpassed quality. Our centrally located office promotes credibility, and we're active on major social networks. We would welcome the opportunity to list your property. "
        headerDisplay='block'
        descDisplay='block'
        align='left'
        marginTop='true'
        videoContent={Videoplay}
      />
      <SellPreference/>
      {width < TabletBreakpoint ? <FooterMobile /> : <Footer />}
      <CustomizedSnackbar/>
    </div>
  );
};

export default SellPage;
