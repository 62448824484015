import styled, { keyframes, css } from 'styled-components';

const slideIn = keyframes`
  from {
    transform: translateY(30px);
  }
  to {
    transform: translateY(0);
  }
`;

const heightChange = keyframes`
  from {
    height:50px;
  }
  to {
height:280px;
  }
`;

export const ContactContainer = styled.div`
    width:100%;
    position:fixed;
    bottom:10px;
    right:10px;
    overflow:visible;
`

export const ButtonBackground = styled.div`
    width:50px;
    height:50px;
    background:#ECE1CE;
    border-radius:50%;
    padding:8px 10px;
    z-index:10;
    &::-webkit-scrollbar {
        display:none;
    }
`
export const ButtonBackgroundText = styled.div`
    width:fit-content;
    background:#ECE1CE;
    border-radius:25px;
    padding:12px 12px;
   font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 127%;
    &::-webkit-scrollbar {
        display:none;
    }
`
export const ButtonDetailContainer = styled.div`
    display:flex;
    flex-direction:row-reverse;
    gap:10px;
    align-items:center;
`
export const ContactBlockContainer = styled.div`
    display:${props=>props.visible ? 'flex' : 'none'};
    flex-direction:column;
    gap:5px;
    position:absolute;
    bottom:${props=>props.visible ? '60px' : '30px'};
    transition-duration: bottom 5s;
    z-index:10;
    animation: ${({ visible }) =>
    visible
      ? css`
          ${slideIn} 1s
        `
      : 'none'};
`

export const BlockDetailContainer = styled.div`
    display:block;
`
export const ContactAbsoluteConatiner = styled.div`
display:${props=>props.visible ? 'block' : 'none'};
  position:absolute;
  width:50px;
  border-radius:25px;
  bottom:0px;
  height:50px;
  background-color:#ECE1CE;
  z-index:1;
  animation: ${({ visible }) =>
    visible
      ? css`
          ${heightChange} 1s forwards
        `
      : 'none'};
`