import React, { useState, useEffect } from "react";
import { BsDownload } from "react-icons/bs";
import { DescriptionDetail } from "./DescriptionDetail";
import Modal from "@mui/material/Modal";
import {
  ButtonDiv,
  ButtonMobileDiv,
  DescriptionMainDiv,
  DescriptionPara,
  DetailDescriptionPage,
  DownloadBrocher,
  DownloadBrocherDiv,
  DownloadBrocherMobile,
} from "./PropertyDetail.styled";
import { BoxStyle } from "../PropertyListing.js/PropertyListing.styled";
import BrochuerPopUp from "../PopUps/BrochuerPopUp";

export const Description = ({ details }) => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <DetailDescriptionPage>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <BoxStyle>
            <BrochuerPopUp
              open={open}
              setOpen={setOpen}
              brochure={details.attributes.brochure}
              button="Download"
              msg="The message is received from Downloading project brochure"
              para="Like 10,000+ subscribers, stay up to date with the latest
              developments in real estate"
            />
          </BoxStyle>
        </Modal>
        <DescriptionMainDiv>
          <DescriptionPara>Description:</DescriptionPara>
          {width > 900 &&
            details &&
            details.attributes.brochure &&
            details.attributes.brochure.data && (
              <ButtonDiv onClick={handleOpen}>
                <BsDownload />
                <DownloadBrocher>Download Brochure</DownloadBrocher>
              </ButtonDiv>
            )}
        </DescriptionMainDiv>
        <DescriptionDetail details={details} />
        {width < 900 &&
          details &&
          details.attributes.brochure &&
          details.attributes.brochure.data && (
            <DownloadBrocherDiv>
              <ButtonMobileDiv onClick={handleOpen}>
                <BsDownload style={{ color: "#ffffff" }} />
                <DownloadBrocherMobile>Download Brochure</DownloadBrocherMobile>
              </ButtonMobileDiv>
            </DownloadBrocherDiv>
          )}
      </DetailDescriptionPage>
    </>
  );
};
