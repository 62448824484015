import axios from "axios";
import API_URL from "../constant";


export function requestGetLocations(data) {
  const url = `${API_URL}locations?filters[area][$startsWith]=${data}&pagination[pageSize]=5`;
  return axios.request({
    method: "get",
    url: url,
  });
}

export function requestGetRelLocations(data) {
  const url = `${API_URL}locations?filters[area][$containsi]=${data}&pagination[pageSize]=5`;
  return axios.request({
    method: "get",
    url: url,
  });
}