import React, { useState } from "react";
import {
  CollageImage,
  CollageMainDiv,
  CollageFigureOne,
  CollageFigureTwo,
  CollageFigureThree,
  CollageFigureFour,
  CollageFigureFive,
  CollageFigureOneButton,
  SymbolCollage,
  CollageFigureFourLess,
  CollageFigureTwoLess,
} from "./PropertyDetail.styled";
import Symbol from "../../assets/images/show.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const Collage = ({ details }) => {
  const {id} = useParams();
  const navigate = useNavigate()
  var image = details.attributes.image.data;
  const location = useLocation();
  const [property] = useState(location.pathname.split('/')[1]);
  
  const totalImages = image.length > 3 ? 5 : 3;
  
  const updatedImage = Array.from({ length: totalImages }, (item, index) => {
    if (index < image.length && image[index].attributes.url) {
      return image[index];
    } else {
      return { attributes: { url: "https://modrenliving.s3.eu-central-1.amazonaws.com/Property+Image+1.1-min.jpg" } };
    }
  });
  
  image = updatedImage;
  

  return (
    <>
      {image.length > 4 ? (
        <CollageMainDiv>
          <CollageFigureOne>
            <CollageImage src={image[0].attributes.url} alt="" onClick={()=>navigate(`/${property}/detail/carousel/${id}`)}/>
            <CollageFigureOneButton onClick={()=>{window.innerWidth > 500 ? navigate(`/${property}/detail/images/${id}`) : navigate(`/${property}/detail/carousel/${id}`)}}>
              <SymbolCollage src={Symbol} alt="" />
              Show more Photos
            </CollageFigureOneButton>
          </CollageFigureOne>
          <CollageFigureTwo>
            <CollageImage src={image[1].attributes.url} alt="" onClick={()=>navigate(`/${property}/detail/carousel/${id}`)}/>
          </CollageFigureTwo>
          <CollageFigureThree>
            <CollageImage src={image[2].attributes.url} alt="" onClick={()=>navigate(`/${property}/detail/carousel/${id}`)}/>
          </CollageFigureThree>
          <CollageFigureFour>
            <CollageImage src={image[3].attributes.url} alt="" onClick={()=>navigate(`/${property}/detail/carousel/${id}`)}/>
          </CollageFigureFour>
          <CollageFigureFive>
            <CollageImage src={image[4].attributes.url} alt="" onClick={()=>navigate(`/${property}/detail/carousel/${id}`)}/>
          </CollageFigureFive>
        </CollageMainDiv>
      ) : (
        <CollageMainDiv>
          <CollageFigureOne>
            <CollageImage src={image[0].attributes.url} alt="" onClick={()=>navigate(`/${property}/detail/carousel/${id}`)}/>
            <CollageFigureOneButton onClick={()=>navigate(`/properties/detail/images/${id}`)}>
              <SymbolCollage src={Symbol} alt="" />
              Show more Photos
            </CollageFigureOneButton>
          </CollageFigureOne>
          <CollageFigureTwoLess>
            <CollageImage src={image[1].attributes.url} alt="" onClick={()=>navigate(`/${property}/detail/carousel/${id}`)}/>
          </CollageFigureTwoLess>
          <CollageFigureFourLess>
            <CollageImage src={image[2].attributes.url} alt="" onClick={()=>navigate(`/${property}/detail/carousel/${id}`)}/>
          </CollageFigureFourLess>
        </CollageMainDiv>
      )}
    </>
  );
};

export default Collage;
