import React, { useState, useRef, useEffect } from "react";
import { TfiArrowCircleLeft, TfiArrowCircleRight } from "react-icons/tfi";
import { useTranslation } from "react-i18next";
import {
  Overlay,
  ServiceButton,
  ServiceCardImg,
  ServiceContainer,
  ServiceHeaderText,
  ServicePageContainer,
  ServiceSection,
  ServiceSubText,
  ServiceText,
  ServiceTextContainer,
} from "./OurService.styled";
import {
  PageIcon,
  ScrollButton,
  ScrollContainer,
} from "../PropertyListing.js/PropertyListing.styled";
import { Link } from "react-router-dom";

import Pc1 from "../../assets/images/BusinessNetworking_BG.png";
import Pc2 from "../../assets/images/BusinessHub_BG.png";
import Pc3 from "../../assets/images/BusinessSetup_BG.png";
import Pc4 from "../../assets/images/LegalServices_BG.png";
import Pc5 from "../../assets/images/RealEstateAdvisory_BG.png";
import Pc6 from "../../assets/images/Relocation_BG.png";
import Pc7 from "../../assets/images/MarketingSupport_BG.png";
import ServiceCard from "./ServiceCard";
import SliderComponent from "../Sliders/SliderComponent";

const Ourservices = () => {
  const { t } = useTranslation();
  const [width, setWidth] = useState(window.innerWidth);
  const containerRef = useRef();
  const [active, setActive] = useState(0);
  const [scrollButton, setScrollButton] = useState(0);

  const serviceCards = [
    { imgSrc: Pc1, text: t("BUY / RENT SERVICES"), link: "/rentbuy" },
    { imgSrc: Pc2, text: t("MORTGAGE SERVICES"), link: "/business-mortgage" },
    {
      imgSrc: Pc3,
      text: t("FURNISHING SERVICES"),
      link: "/business-furnishing",
    },
    { imgSrc: Pc4, text: t("CONVEYOR SERVICES"), link: "/conveyancer" },
    { imgSrc: Pc5, text: t("REAL ESTATE ADVISORY"), link: "/realestate" },
    { imgSrc: Pc6, text: t("REFURBISHMENT"), link: "/business-refurbishment" },
    { imgSrc: Pc7, text: t("BUSINESS PARTNERS"), link: "/business-partner" },
  ];

  

  return (
    <ServiceSection>
      <ServiceTextContainer>
        <ServiceHeaderText>{t("Dedicated to Your Success")}</ServiceHeaderText>
        <ServiceSubText>
          {t(
            "From real estate advisory to company formation, we make sure everything is taken care of. You can rest easy knowing that you are in good hands."
          )}
        </ServiceSubText>
      </ServiceTextContainer>
     
    <SliderComponent data={serviceCards} DynamicComponent={ServiceCard} rows={1}/>
    
      
    </ServiceSection>
  );
};

export default Ourservices;
