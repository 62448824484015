import React, { useState } from "react";
import {
  ContactDetailDiv,
  ContactDiv,
  ContactHeader,
  ContactSection,
  MapandContactDiv,
  MapandContactDivTwo,
  SocialDiv,
} from "./CompanyContact.styled";
import MailIcon from "../../assets/images/mail.svg";
import PhoneIcon from "../../assets/images/phone.svg";
import FacebookIcon from "../../assets/images/Group-816.svg";
import YoutubeIcon from "../../assets/images/Group-818.svg";
import LinkedinIcon from "../../assets/images/Group-817.svg";
import InstaIcon from "../../assets/images/Group-819.svg";
import { Map } from "../PropertyDetail/PropertyDetail.styled";
import GoogleMapFile from "../PropertyDetail/GoogleMapFile";
import { postContactUs } from "../../redux/slices/contactUs/contactUsSlices";
import { useDispatch } from "react-redux";
import { showSnackBar } from "../../redux/slices/snackbarSlice/snackbarSlice";
import {
  ContactButton,
  ContactCheckBox,
  ContactFormContainer,
  ContactFormContainerTwo,
  ContactInputBox,
  ContactInputFeild,
  ContactSpan,
  DropdownContactDiv,
  DropdownSpan,
} from "../ContactUs/ContactUs.styled";
import { useTranslation } from "react-i18next";
import { DownToggleIcon } from "../Footer/Footer.styled";
import {
  DropdownContainer,
  InputTextBox,
} from "../PreferenceScreen/PreferenceScreen.styled";
import CustomizedSnackbar from "../CustomizedSnackbars/CustomizedSnackbar";

const defaultFormInput = {
  category: "",
  full_name: "",
  email: "",
  phone: "",
  unit_type: "",
  budget: "",
  message: "",
  checked: false,
  document: "",
};

const CompanyContact = () => {
  const [unitActive, setUnitActive] = useState(false);
  const [categoryActive, setCategoryActive] = useState(false);
  const [budgetActive, setBudgetActive] = useState(false);
  const [formInput, setFormInput] = useState(defaultFormInput);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleInputChange = (name, value) => {
    setFormInput({
      ...formInput,
      [name]: value,
    });
  };

  // SnackBar Object
  const snackbarObject = {
    type: "",
    message: "",
    open: false,
  };

  // SnackBar
  const dispatchSnackBar = (type, message, open, displayMessage) => {
    snackbarObject.type = type;
    snackbarObject.message = message;
    snackbarObject.open = open;
    dispatch(showSnackBar(snackbarObject));
  };

  const handleContactUs = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("full_name", formInput.full_name);
    formData.append("email", formInput.email);
    formData.append("phone", formInput.phone);
    formData.append("message", formInput.message);
    formData.append("document", formInput.document);

    const contactUsObject = {
      full_name: formInput.full_name,
      email: formInput.email,
      phone: formInput.phone,
      message: formInput.message,
      checked: formInput.checked,
      document: formInput.document,
      unit_type: formInput.unit_type,
      category: formInput.category,
      budget: formInput.budget,
    };

    if (contactUsObject.first_name === "") {
      dispatchSnackBar("error", "Please enter Full Name", true);
    } else if (contactUsObject.email === "") {
      dispatchSnackBar("error", "Please enter Email", true);
    } else if (contactUsObject.phone === "") {
      dispatchSnackBar("error", "Please enter Phone", true);
    } else if (!contactUsObject.checked) {
      dispatchSnackBar("error", "Please accept our user terms and conditions");
    } else {
      dispatchSnackBar("success", "Your Details has been submitted");
      dispatch(postContactUs(contactUsObject));
      setFormInput(defaultFormInput);
    }
  };
  return (
    <ContactSection>
      <ContactDiv>
        <ContactHeader>We’re ready to help!</ContactHeader>
        <ContactDetailDiv>
          <img src={PhoneIcon} alt="" />
          <h3>+971 502 731297</h3>
        </ContactDetailDiv>
        <ContactDetailDiv>
          <img src={MailIcon} alt="" />
          <h3>support@modernliving.ae</h3>
        </ContactDetailDiv>
        <SocialDiv>
          <h4>Follow us</h4>
          <div>
            <a href="https://www.facebook.com/modernlivingrealestatedubai">
              <img src={FacebookIcon} alt="F" className="scicon" />
            </a>
            <a href="https://www.linkedin.com/company/modern-living-real-estate-dubai">
              <img src={LinkedinIcon} alt="F" className="scicon" />
            </a>
            <a href="https://www.youtube.com/@modernlivingrealestatedubai">
              <img src={YoutubeIcon} alt="F" className="scicon" />
            </a>
            <a href="https://www.instagram.com/modernliving.ae/?hl=en">
              <img src={InstaIcon} alt="F" className="scicon" />
            </a>
          </div>
        </SocialDiv>
      </ContactDiv>
      <MapandContactDivTwo>
        <Map>
          <GoogleMapFile
            latitude="25.1887"
            longitude="55.2671"
            data="project"
          />
        </Map>

        <ContactFormContainerTwo>
          <ContactInputBox>
            <ContactInputFeild
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}
              value={formInput.full_name}
              id="http://3.125.213.254/admin/"
              name="full_name"
              required
            />
            <ContactSpan>{t("YOUR NAME")}</ContactSpan>
          </ContactInputBox>
          <ContactInputBox>
            <ContactInputFeild
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}
              value={formInput.email}
              id="email"
              name="email"
              required
            />
            <ContactSpan>{t("YOUR EMAIL")}</ContactSpan>
            {/* <ErrorField>hghj</ErrorField> */}
          </ContactInputBox>
          <ContactInputBox>
            <ContactInputFeild
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}
              value={formInput.phone}
              id="phone"
              name="phone"
              required
            />
            <ContactSpan>{t("YOUR PHONE")}</ContactSpan>
          </ContactInputBox>
          <ContactInputBox>
            <DropdownContactDiv
              onClick={() => setCategoryActive(!categoryActive)}
              border="none"
            >
              <DropdownSpan
                style={{ color: categoryActive ? "black" : "#c0c0c0" }}
              >
                {formInput.category === ""
                  ? "I’m LooKING FOR BUY / RENT / SELL"
                  : formInput.category}
              </DropdownSpan>
              <DownToggleIcon animate={categoryActive} />
            </DropdownContactDiv>
            <DropdownContainer
              active={categoryActive}
              style={{ marginTop: "10px", borderRadius: "15px" }}
            >
              <InputTextBox
                onClick={() => {
                  setCategoryActive(false);
                  handleInputChange("category", "Buy");
                }}
              >
                Buy
              </InputTextBox>
              <InputTextBox
                onClick={() => {
                  setCategoryActive(false);
                  handleInputChange("category", "Rent");
                }}
              >
                Rent
              </InputTextBox>
              <InputTextBox
                onClick={() => {
                  setCategoryActive(false);
                  handleInputChange("category", "Sell");
                }}
              >
                Sell
              </InputTextBox>
            </DropdownContainer>
          </ContactInputBox>
          <ContactInputBox>
            <DropdownContactDiv
              onClick={() => setUnitActive(!unitActive)}
              border="none"
            >
              <DropdownSpan style={{ color: unitActive ? "black" : "#c0c0c0" }}>
                {formInput.unit_type === ""
                  ? "I’m interested in - Apartment, Villa, Townhouse, Penthouse"
                  : formInput.unit_type}
              </DropdownSpan>
              <DownToggleIcon animate={unitActive} />
            </DropdownContactDiv>
            <DropdownContainer
              active={unitActive}
              style={{ marginTop: "10px", borderRadius: "15px" }}
            >
              <InputTextBox
                onClick={() => {
                  setUnitActive(false);
                  handleInputChange("unit_type", "All");
                }}
              >
                All
              </InputTextBox>
              <InputTextBox
                onClick={() => {
                  setUnitActive(false);
                  handleInputChange("unit_type", "Apartment");
                }}
              >
                Apartment
              </InputTextBox>
              <InputTextBox
                onClick={() => {
                  setUnitActive(false);
                  handleInputChange("unit_type", "Villa");
                }}
              >
                Villa
              </InputTextBox>
              <InputTextBox
                onClick={() => {
                  setUnitActive(false);
                  handleInputChange("unit_type", "Townhouse");
                }}
              >
                Townhouse
              </InputTextBox>
              <InputTextBox
                onClick={() => {
                  setUnitActive(false);
                  handleInputChange("unit_type", "Penthouse");
                }}
              >
                Penthouse
              </InputTextBox>
              <InputTextBox
                onClick={() => {
                  setUnitActive(false);
                  handleInputChange("unit_type", "Commercial");
                }}
              >
                Commercial
              </InputTextBox>
            </DropdownContainer>
          </ContactInputBox>
          <ContactInputBox>
            <DropdownContactDiv
              onClick={() => setBudgetActive(!budgetActive)}
              border="none"
            >
              <DropdownSpan
                style={{ color: budgetActive ? "black" : "#c0c0c0" }}
              >
                {formInput.budget === "" ? "MY Budget" : formInput.budget}
              </DropdownSpan>
              <DownToggleIcon animate={budgetActive} />
            </DropdownContactDiv>
            <DropdownContainer
              active={budgetActive}
              style={{ marginTop: "10px", borderRadius: "15px" }}
            >
              <InputTextBox
                onClick={() => {
                  setBudgetActive(false);
                  handleInputChange("budget", "0-100k");
                }}
              >
                0-100k
              </InputTextBox>
              <InputTextBox
                onClick={() => {
                  setBudgetActive(false);
                  handleInputChange("budget", "100k-500k");
                }}
              >
                100k-500k
              </InputTextBox>
              <InputTextBox
                onClick={() => {
                  setBudgetActive(false);
                  handleInputChange("budget", "500k-1M");
                }}
              >
                500k-1M
              </InputTextBox>
              <InputTextBox
                onClick={() => {
                  setBudgetActive(false);
                  handleInputChange("budget", "1M-2M");
                }}
              >
                1M-2M
              </InputTextBox>
              <InputTextBox
                onClick={() => {
                  setBudgetActive(false);
                  handleInputChange("budget", "2M-5M");
                }}
              >
                2M-5M
              </InputTextBox>
              <InputTextBox
                onClick={() => {
                  setBudgetActive(false);
                  handleInputChange("budget", "5M+");
                }}
              >
                5M+
              </InputTextBox>
            </DropdownContainer>
          </ContactInputBox>
          <ContactCheckBox>
            <input
              name="checked"
              checked={formInput.checked}
              type="checkbox"
              onChange={(e) =>
                handleInputChange(e.target.name, e.target.checked)
              }
            />
            {t(
              "You agree to the terms and conditions of Modern Living and its affiliates."
            )}
          </ContactCheckBox>
          <ContactButton onClick={(e) => handleContactUs(e)}>
            {t("SUBMIT")}
          </ContactButton>
        </ContactFormContainerTwo>
      </MapandContactDivTwo>
      <CustomizedSnackbar />
    </ContactSection>
  );
};

export default CompanyContact;
