import {put, retry} from "redux-saga/effects";
import { hasError, setLocation } from "../../../slices/location/locationSlice";
import { requestGetLocations, requestGetRelLocations } from "../../request/location/location";


export function* handleLocation(action) {
    try {
        const response = yield retry(0,0,requestGetLocations,action.payload);
        const {data} = response;
        yield put(setLocation(data));
        if (data.length===0) {
            const response = yield retry(0,0,requestGetRelLocations,action.payload);
            const {data} = response;  
            yield put(setLocation(data)); 
        }
    } catch (error) {
        yield put(hasError(error))
    }
}