import React from "react";
import {
  AboutHead,
  AboutHeading,
  AboutParagraph,
  BackGround,
} from "./Project.styled";

export const ProjectAbout = ({project}) => {
  return (
    <BackGround>
      <AboutHead>
        <AboutHeading>The Ultimate in Luxury Real Estate</AboutHeading>
        <AboutParagraph>
          Discover Dubai's definitive selection of the best villas and
          apartments. Complete collection of {project} properties in one place.
        </AboutParagraph>
      </AboutHead>
    </BackGround>
  );
};
