import React from "react";
import { Banner } from "./Banner";
import { FAQContainer, FAQHeading } from "./Project.styled";

export const Faq = () => {
  return (
    <>
      <FAQContainer>
        <FAQHeading>Frequently asked questions (FAQ's)</FAQHeading>
        <Banner />
      </FAQContainer>
    </>
  );
};
