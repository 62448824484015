import styled from "styled-components";

export const BoxStyle = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: auto;
  position: relative;
  z-index: 100000 !important;
  @media only screen and (min-width: 1920px) {
    height: 630px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    height: 400px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    height: 380px;
    width: 600px;
  }
  @media only screen and (min-width: 550px) and (max-width: 900px) {
    height: 400px;
    width: 400px;
  }
  @media only screen and (min-width: 450px) and (max-width: 550px) {
    height: 380px;
    width: 300px;
  }
  @media only screen and (max-width: 450px) {
    height: 350px;
    width: 300px;
  }
`;

export const ImageStyle = styled.img`
  position: relative;
  z-index: 999;
  @media only screen and (min-width: 1920px) {
    width: 50%;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 50%;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 0%;
  }
  @media only screen and (max-width: 900px) {
    width: 0%;
  }
  @media only screen and (max-width: 400px) {
    width: 0%;
  }
`;

export const UpdatesBox = styled.div`
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  width: 50%;
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 100%;
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
  }
`;

export const UpdatesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
`;

export const HeadingMain = styled.p`
  font-family: "Didot";
  font-style: normal;
  font-weight: 400;
  line-height: 149.9%;
  letter-spacing: 0.03em;
  color: #000000;
  @media only screen and (min-width: 1900px) {
    font-size: 26px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    font-size: 24px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 24px;
  }
  @media only screen and (min-width: 650px) and (max-width: 900px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 650px) {
    font-size: 15px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-size: 20px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-size: 20px;
  }
`;

export const ParaMain = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  line-height: 155.4%;
  letter-spacing: 0.03em;
  color: #000000;
  @media only screen and (min-width: 1920px) {
    padding-top: 10px;
    font-size: 12px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    padding-top: 10px;
    font-size: 10px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    padding-top: 10px;
    font-size: 10px;
  }
  @media only screen and (min-width: 650px) and (max-width: 900px) {
    padding-top: 10px;
    font-size: 10px;
  }
  @media only screen and (max-width: 650px) {
    padding-top: 10px;
    font-size: 8px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    padding-top: 10px;
    font-size: 10px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    padding-top: 10px;
    font-size: 10px;
  }
`;

export const ParaDown = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  line-height: 12px;
  letter-spacing: 0.03em;
  color: grey;
  text-align: justify;
  text-justify: inter-word;
  padding-top: 3px;
  @media only screen and (min-width: 1920px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-size: 10px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 10px;
  }
  @media only screen and (min-width: 650px) and (max-width: 900px) {
    font-size: 10px;
  }
  @media only screen and (max-width: 650px) {
    font-size: 7px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-size: 10px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-size: 10px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-top: 5px;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const InputContainerBrochuer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 20px;
`;

export const InputEmail = styled.input`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  background: none;
  padding-bottom: 5px;
  border: none;
  border-bottom: 0.55px solid #000000;
  border-bottom: ${(props) =>
    props.filterprop ? "0.55px solid #FF0000" : "0.55px solid #000000"};
  color: #000000;
  width: 100%;
@media only screen and (min-width: 1920px) {
  font-size: 16px;
  }
@media only screen and (max-width: 1920px) {
  font-size: 12px;
  }

`;

export const DownloadButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.125em;
  color: #ffffff;
  background: #12284c;
  border-radius: 90px;
  border: 1px solid #12284c;
  color: #ffffff;
  @media only screen and (min-width: 1920px) {
    width: 150px;
    height: 40px;
    font-size: 16px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 133px;
    height: 40px;
    font-size: 16px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 133px;
    height: 40px;
    font-size: 16px;
  }

  @media only screen and (min-width: 600px) and (max-width: 900px) {
    width: 133px;
    height: 40px;
    font-size: 16px;
  }
  @media only screen and (max-width: 600px) {
    width: 90px;
    height: 40px;
    font-size: 14px;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 133px;
    height: 40px;
    font-size: 16px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 133px;
    height: 40px;
    font-size: 16px;
  }
`;

export const SendButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.125em;
  color: #ffffff;
  background: #12284c;
  border-radius: 90px;
  border: 1px solid #12284c;
  color: #ffffff;
  cursor: pointer;
  @media only screen and (min-width: 1920px) {
    width: 200px;
    height: 35px;
    font-size: 15px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 200px;
    height: 35px;
    font-size: 15px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 150px;
    height: 35px;
    font-size: 15px;
  }

  @media only screen and (min-width: 600px) and (max-width: 900px) {
    width: 180px;
    height: 35px;
    font-size: 15px;
  }
  @media only screen and (max-width: 600px) {
    width: 220px;
    height: 30px;
    font-size: 12px;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 133px;
    height: 35px;
    font-size: 15px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 133px;
    height: 35px;
    font-size: 15px;
  }
`;

export const EmailError = styled.span`
  font-family: "Montserrat";
  font-style: normal;
  color: red;
  @media only screen and (min-width: 1920px) {
    padding-top: 10px;
    font-size: 14px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    padding-top: 10px;
    font-size: 12px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    padding-top: 10px;
    font-size: 12px;
  }

  @media only screen and (min-width: 600px) and (max-width: 900px) {
    padding-top: 10px;
    font-size: 12px;
  }

  @media only screen and (min-width: 350px) and (max-width: 600px) {
    padding-top: 10px;
    font-size: 10px;
  }
  @media only screen and (max-width: 350px) {
    padding-top: 10px;
    font-size: 9px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    padding-top: 10px;
    font-size: 12px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    padding-top: 10px;
    font-size: 12px;
  }
`;

export const InLine = styled.div`
display: flex;
gap: 10px;
padding-top: 10px;
`;