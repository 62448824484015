import styled from "styled-components";
import { KeyboardArrowDownOutlined } from '@mui/icons-material'

export const InputText = styled.input`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    background:none;
    border:none;
    padding-bottom:8px;
    border-bottom:0.55px solid #FFFFFF;
    color: #FFFFFF;
`
export const DownToggleIcon = styled(KeyboardArrowDownOutlined)`
    transform:${props=>props.animate ? 'rotate(180deg)':''};
    transition-duration:5s;
`

export const myText = {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#ECE1CE',
    display: 'flex',
    alignItems: 'center',
    textTransform:'uppercase',
    gap: '24px',
    width:'242px',
}

export const FooterContentContainer = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    height:317px;
`

export const FooterLogo = styled.img`
    width:min(70%, 200px);
`

export const FooterSubmitButton = styled.button`
    box-sizing: border-box;
    display: flex;
    font-family: Montserrat;
    font-style: normal;
    color: #12284C;
    border: 1px solid #000000;
    border-radius: 90px;
    background-color:#ECE1CE;
    width:fit-content;
    border:transparent;
    @media (min-width: 1367px) {
        font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding:10px 10px;
      }
      @media (max-width: 1366px) {
        font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    padding:10px 15px;
      }
    `

export const FooterMobileContainer = styled.div`
position:relative;
    display:flex;
    align-items:top;
    justify-content:center;
    flex-wrap:wrap;
    overflow:visible;
    @media (min-width: 772px) {
        gap:70px;
        margin-top:35px;
      }
    @media (max-width: 772px) {
        gap:20px;
        margin-top:20px;
      }
    `

export const MobileFooterMenuText = styled.button`
    display:flex;
    width:328px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    color: #ECE1CE;
    background:none;
    border:none;
    padding:14px 0px;
    border-bottom:0.5px solid #ECE1CE;
    justify-content:space-between;
    `
export const SecFooterText = styled.h1`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    margin:8px 0px;
`

export const FooterTerms = styled.h6`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 13px;
    text-align: center;
    color: #FFFFFF;
    margin-top:40px;
    margin-bottom:0px;
    a{
       font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 13px;
    color: #FFFFFF; 
    text-decoration:underline;
    }
`
export const VersionText = styled.h1`
 font-size:15px;
 background:blue;
 padding:5px;
 width:70px;
`
export const AbsoluteDiv = styled.div`
    display:${props=>props.display ? 'flex' : 'none'} !important;
    position:absolute;
    width:100%;
    height:200px;
    display:flex;
    flex-direction:column;
    gap:15px;
    top: 81px;
    left: 0px;
    background: #12284C;
    backdrop-filter: blur(90px);
    z-index:99;
`

export const DropDownDiv = styled.div`
    position:relative;
    overflow:visible;
    width:328px;
`