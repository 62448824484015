import { put, retry } from 'redux-saga/effects';
import { setSellPreference } from '../../../slices/SellPreferenceSlice.js/SellPreferenceSlice';
import { showSnackBar } from '../../../slices/snackbarSlice/snackbarSlice';
import { sellPreferenceRequest } from '../../request/SellPreference/SellPreference';
export function* handleSellPreference(action) {
    try {
        const response = yield retry(0, 0, sellPreferenceRequest, action.payload);
        const { data } = response.config;
        const userData = JSON.parse(data);
        yield put(setSellPreference({ userData }));
    } catch (error) {
        const snackbarObject = {
            type: 'error',
            message: 'Preferences Us Failed ',
            open: true,
        };
        yield put(showSnackBar(snackbarObject));
    }
}