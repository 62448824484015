import styled from "styled-components";
import { MdKeyboardArrowRight } from "react-icons/md";
import { AiFillPlayCircle, AiFillPauseCircle } from "react-icons/ai";
import { WaveForm } from "wavesurfer-react";
import ReactMarkdown from "react-markdown";

export const PathIcon = styled(MdKeyboardArrowRight)`
  @media only screen and (min-width: 1920px) {
    font-size: 25px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-size: 17px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 12px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 10px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-size: 12px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-size: 12px;
  }
`;
export const PlayIcon = styled(AiFillPlayCircle)`
  @media only screen and (min-width: 1920px) {
    font-size: 150px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-size: 140px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 120px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 115px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-size: 120px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-size: 120px;
  }
`;
export const PauseIcon = styled(AiFillPauseCircle)`
  @media only screen and (min-width: 1920px) {
    font-size: 150px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-size: 140px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 120px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 115px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-size: 120px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-size: 120px;
  }
`;
export const Waves = styled(WaveForm)`
  overflow: hidden !important;
`;
export const BlogText = styled(ReactMarkdown)`
  margin-bottom: 35px;
  h1 {
    font-family: "Didot";
    font-style: normal;
    color: #0a0a0a;
    @media only screen and (min-width: 1920px) {
      font-weight: 700;
      font-size: 36px;
      line-height: 46px;
      margin-top: 45px;
    }
    @media only screen and (min-width: 1300px) and (max-width: 1920px) {
      font-weight: 700;
      font-size: 29.7074px;
      line-height: 38px;
      margin-top: 35px;
    }
    @media only screen and (min-width: 900px) and (max-width: 1300px) {
      font-weight: 700;
      font-size: 23.4677px;
      line-height: 30px;
      margin-top: 45px;
      margin-top: 28px;
    }
    @media only screen and (max-width: 900px) {
      font-weight: 600;
      font-size: 23.4677px;
      line-height: 29px;
      margin-top: 41px;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
      font-weight: 700;
      font-size: 23.4677px;
      line-height: 30px;
      margin-top: 45px;
      margin-top: 28px;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
      font-weight: 700;
      font-size: 23.4677px;
      line-height: 30px;
      margin-top: 45px;
      margin-top: 28px;
    }
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    color: #494949;
    @media only screen and (min-width: 1920px) {
      font-weight: 400;
      font-size: 20px;
      line-height: 31px;
      margin-top: 50px;
    }
    @media only screen and (min-width: 1300px) and (max-width: 1920px) {
      font-weight: 400;
      font-size: 16.5041px;
      line-height: 26px;
      margin-top: 50px;
      margin-top: 41.26px;
    }
    @media only screen and (min-width: 900px) and (max-width: 1300px) {
      font-weight: 400;
      font-size: 13.0376px;
      line-height: 20px;
      margin-top: 32.59px;
    }
    @media only screen and (max-width: 900px) {
      font-weight: 400;
      font-size: 13.0376px;
      line-height: 20px;
      margin-top: 31px;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
      font-weight: 400;
      font-size: 13.0376px;
      line-height: 20px;
      margin-top: 32.59px;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
      font-weight: 400;
      font-size: 13.0376px;
      line-height: 20px;
      margin-top: 32.59px;
    }
    span {
      font-weight: 700;
      text-decoration: underline;
    }
  }
  img {
    width: 100%;
    height: auto;
  }
`;
export const TemplateSection = styled.section`
  width: 90%;
  height: auto;
  margin: auto;
`;
export const BackButtonContainer = styled.div`
  margin-top: 200px;
  display: flex;
  align-items: center;
  gap: 10px;
  & :hover {
    background: #12284c;
    color: #ffffff;
  }
  a {
    border-radius: 50%;
    & :hover {
      background: #12284c;
      color: #ffffff;
    }
  }
  button {
    border-radius: 50%;
    border: 0.65188px solid #000000;
    padding: 2px 4px;
    background: transparent;
    color: #000000;
  }
  h4 {
    font-family: "Montserrat";
    font-style: normal;
    text-decoration-line: underline;
    color: #131d2e;
    @media only screen and (min-width: 1920px) {
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
    }
    @media only screen and (min-width: 1300px) and (max-width: 1920px) {
      font-weight: 600;
      font-size: 13.2033px;
      line-height: 21px;
    }
    @media only screen and (min-width: 900px) and (max-width: 1300px) {
      font-weight: 600;
      font-size: 10.4301px;
      line-height: 17px;
    }
    @media only screen and (max-width: 900px) {
      font-weight: 700;
      font-size: 10.4301px;
      line-height: 17px;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
      font-weight: 600;
      font-size: 10.4301px;
      line-height: 17px;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
      font-weight: 600;
      font-size: 10.4301px;
      line-height: 17px;
    }
  }
`;
export const AudioDiv = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  height: 80px !important;
  width: 190% !important;
  background: #ffffff;
  padding: 10px;
  overflow: hidden;
  canvas {
    width: 100% !important;
    overflow: hidden;
  }
  wave {
    overflow: hidden !important;
  }
`;
export const TemplatePathSpan = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (min-width: 1920px) {
    margin-top: 79px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    margin-top: 67px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    margin-top: 51.5px;
  }
  @media only screen and (max-width: 900px) {
    margin-top: 36px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    margin-top: 51.5px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    margin-top: 51.5px;
  }

  a {
    font-family: "Montserrat";
    font-style: normal;
    letter-spacing: 0.03em;
    color: #000000;
    @media only screen and (min-width: 1920px) {
      font-weight: 400;
      font-size: 18px;
      line-height: 127%;
    }
    @media only screen and (min-width: 1300px) and (max-width: 1920px) {
      font-weight: 400;
      font-size: 10.7277px;
      line-height: 127%;
    }
    @media only screen and (min-width: 900px) and (max-width: 1300px) {
      font-weight: 400;
      font-size: 8.47444px;
      line-height: 127%;
    }
    @media only screen and (max-width: 900px) {
      font-weight: 400;
      font-size: 8.47444px;
      line-height: 127%;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
      font-weight: 400;
      font-size: 8.47444px;
      line-height: 127%;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
      font-weight: 400;
      font-size: 8.47444px;
      line-height: 127%;
    }

    &:hover {
      text-decoration: underline;
    }
  }
  span {
    color: #898080;
    font-family: "Montserrat";
    font-style: normal;
    letter-spacing: 0.03em;
    @media only screen and (min-width: 1920px) {
      font-weight: 400;
      font-size: 18px;
      line-height: 127%;
    }
    @media only screen and (min-width: 1300px) and (max-width: 1920px) {
      font-weight: 400;
      font-size: 10.7277px;
      line-height: 127%;
    }
    @media only screen and (min-width: 900px) and (max-width: 1300px) {
      font-weight: 400;
      font-size: 8.47444px;
      line-height: 127%;
    }
    @media only screen and (max-width: 900px) {
      font-weight: 400;
      font-size: 8.47444px;
      line-height: 127%;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
      font-weight: 400;
      font-size: 8.47444px;
      line-height: 127%;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
      font-weight: 400;
      font-size: 8.47444px;
      line-height: 127%;
    }
  }
`;
export const TemplateHeaderText = styled.h1`
  font-family: "Montserrat";
  font-style: normal;
  color: #212121;
  @media only screen and (min-width: 1920px) {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    margin-top: 26px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 700;
    font-size: 26.4066px;
    line-height: 32px;
    margin-top: 21px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 700;
    font-size: 20.8602px;
    line-height: 25px;
    margin-top: 17px;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 700;
    font-size: 20.8602px;
    line-height: 26px;
    margin-top: 10px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 700;
    font-size: 20.8602px;
    line-height: 25px;
    margin-top: 17px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 700;
    font-size: 20.8602px;
    line-height: 25px;
    margin-top: 17px;
  }
`;
export const TemplateDateSpan = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  color: #818181;
  @media only screen and (min-width: 1920px) {
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    margin-top: 21px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 600;
    font-size: 13.2033px;
    line-height: 21px;
    margin-top: 17.3px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 600;
    font-size: 10.4301px;
    line-height: 17px;
    margin-top: 13.69px;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 600;
    font-size: 10.4301px;
    line-height: 17px;
    margin-top: 33px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 600;
    font-size: 10.4301px;
    line-height: 17px;
    margin-top: 13.69px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 600;
    font-size: 10.4301px;
    line-height: 17px;
    margin-top: 13.69px;
  }
  span {
    text-decoration: underline;
  }
`;
export const TemplateImageContainer = styled.div`
  width: 100%;
  background: ${(props) => `url(${props.img})`};
  background-repeat: no-repeat;
  background-size: cover;
  @media only screen and (min-width: 1920px) {
    height: 598px;
    margin-top: 37px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    height: 493.47px;
    margin-top: 30.53px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    height: 389.82px;
    margin-top: 24.12px;
  }
  @media only screen and (max-width: 900px) {
    height: 390px;
    margin-top: 57.79px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    height: 389.82px;
    margin-top: 24.12px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    height: 389.82px;
    margin-top: 24.12px;
  }
`;
export const TemplatePara1 = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  color: #494949;
  @media only screen and (min-width: 1920px) {
    font-weight: 400;
    font-size: 20px;
    line-height: 31px;
    margin-top: 50px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 400;
    font-size: 16.5041px;
    line-height: 26px;
    margin-top: 50px;
    margin-top: 41.26px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 400;
    font-size: 13.0376px;
    line-height: 20px;
    margin-top: 32.59px;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 400;
    font-size: 13.0376px;
    line-height: 20px;
    margin-top: 31px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 400;
    font-size: 13.0376px;
    line-height: 20px;
    margin-top: 32.59px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 400;
    font-size: 13.0376px;
    line-height: 20px;
    margin-top: 32.59px;
  }
  span {
    font-weight: 700;
    text-decoration: underline;
  }
`;
export const TemplatePara2 = styled(TemplatePara1)`
  margin: 80px 0px;
`;
export const TemplateSubText = styled.h2`
  font-family: "Didot";
  font-style: normal;
  color: #0a0a0a;
  @media only screen and (min-width: 1920px) {
    font-weight: 700;
    font-size: 36px;
    line-height: 46px;
    margin-top: 45px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 700;
    font-size: 29.7074px;
    line-height: 38px;
    margin-top: 35px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 700;
    font-size: 23.4677px;
    line-height: 30px;
    margin-top: 45px;
    margin-top: 28px;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 600;
    font-size: 23.4677px;
    line-height: 29px;
    margin-top: 41px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 700;
    font-size: 23.4677px;
    line-height: 30px;
    margin-top: 45px;
    margin-top: 28px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 700;
    font-size: 23.4677px;
    line-height: 30px;
    margin-top: 45px;
    margin-top: 28px;
  }
`;
export const TemplateImageContainer2 = styled(TemplateImageContainer)`
  margin-top: 80px;
`;
export const PodcastTemplateContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 5%;
  overflow: hidden !important;
  @media only screen and (min-width: 1920px) {
    height: 400px;
    margin-top: 83px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    height: 350px;
    margin-top: 71px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    height: 290px;
    margin-top: 29px;
  }
  @media only screen and (max-width: 900px) {
    height: 376;
    margin-top: 23.97px;
    flex-wrap: wrap;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    height: 290px;
    margin-top: 29px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    height: 290px;
    margin-top: 29px;
  }

  img {
    @media only screen and (min-width: 900px) {
      width: auto;
      height: 100%;
    }
    @media only screen and (max-width: 900px) {
      min-width: 100%;
      height: 100%;
    }
  }
  div {
    @media only screen and (min-width: 900px) {
      width: 60%;
      height: auto;
    }
    @media only screen and (max-width: 900px) {
      width: 100%;
      height: auto;
    }
    div {
      margin-top: 15px;
      width: 100%;
      height: 50px;
    }
  }
  h3 {
    font-family: "Montserrat";
    font-style: normal;
    color: #000000;
    @media only screen and (min-width: 1920px) {
      font-weight: 700;
      font-size: 21px;
      line-height: 26px;
    }
    @media only screen and (min-width: 1300px) and (max-width: 1920px) {
      font-weight: 700;
      font-size: 17.9781px;
      line-height: 22px;
    }
    @media only screen and (min-width: 900px) and (max-width: 1300px) {
      font-weight: 700;
      font-size: 17.9781px;
      line-height: 22px;
    }
    @media only screen and (max-width: 900px) {
      font-weight: 700;
      font-size: 17.9781px;
      line-height: 22px;
      margin-top: 20px;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
      font-weight: 700;
      font-size: 17.9781px;
      line-height: 22px;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
      font-weight: 700;
      font-size: 17.9781px;
      line-height: 22px;
    }
  }
  h4 {
    font-family: "Montserrat";
    font-style: italic;
    color: #000000;
    @media only screen and (min-width: 1920px) {
      font-weight: 300;
      font-size: 16px;
      line-height: 20px;
    }
    @media only screen and (min-width: 1300px) and (max-width: 1920px) {
      font-weight: 300;
      font-size: 13.6976px;
      line-height: 17px;
    }
    @media only screen and (min-width: 900px) and (max-width: 1300px) {
      font-weight: 300;
      font-size: 13.6976px;
      line-height: 17px;
    }
    @media only screen and (max-width: 900px) {
      font-weight: 300;
      font-size: 13.6976px;
      line-height: 17px;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
      font-weight: 300;
      font-size: 13.6976px;
      line-height: 17px;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
      font-weight: 300;
      font-size: 13.6976px;
      line-height: 17px;
    }
  }
`;
