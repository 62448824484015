import {put, retry} from "redux-saga/effects";
import { setSearchContent } from "../../../slices/herosearch/herosearchSlice";
import { requestSearch } from "../../request/herosearch/herosearch";


export function* handleSearch(action) {
    try {
        const response = yield retry(0,0,requestSearch,action.payload);
        const {data} = response;
        yield put(setSearchContent(data));
    } catch (error) {
        // yield put(hasError(error))
    }
}