import React from 'react'
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { BackButtonContainer, BlogText, PathIcon, TemplateDateSpan, TemplateHeaderText, TemplateImageContainer, TemplatePara1, TemplatePathSpan, TemplateSection } from './PressCenterTemplate.styled'
import { Link } from 'react-router-dom';

const Blog = ({blogDetail}) => {

  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
]; 

  var p = blogDetail ? blogDetail.date.split('T')[0].split('-'):'';
  const date = p[2] + ' ' + monthNames[Number(p[1]).toString()-1]  + ' ' + p[0];
  return (
    <TemplateSection>
      <BackButtonContainer>
      <Link to = '/blog'>
        <button>
          <KeyboardBackspaceIcon />
        </button>
        </Link>
        <h4>Back</h4>
      </BackButtonContainer>
      <TemplatePathSpan>
      <Link to = '/'>Home</Link> &nbsp;&nbsp;<PathIcon/>&nbsp;&nbsp;<Link to = '/blog'>Blogs</Link> &nbsp;&nbsp;<PathIcon/> &nbsp;&nbsp;<span>{blogDetail.title}</span>
      </TemplatePathSpan>
      <TemplateHeaderText>
        {blogDetail.title}
      </TemplateHeaderText>
      {blogDetail && blogDetail.date && <TemplateDateSpan>
        <span>Blogs</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{date}
      </TemplateDateSpan>}
      <TemplateImageContainer img={blogDetail.featured_image.data.attributes.url ? blogDetail.featured_image.data.attributes.url :''}/>
      <TemplatePara1>{blogDetail.short_description}</TemplatePara1>
      <BlogText>{blogDetail ? blogDetail.blogs :''}</BlogText>
    </TemplateSection>
  )
}

export default Blog