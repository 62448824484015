import styled from "styled-components";
export const HeroSection = styled.section`
  overflow: hidden;
  width: 100%;
  background: ${(props) => `url(${props.img})`};
  background-size: cover;
  background-position: center;

  @media only screen and (min-width: 1920px) {
    height: 1084px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    height: 100vh;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    height: 644px;
  }
  @media only screen and (max-width: 900px) {
    height: 936px;
  }
  @media only screen and (max-width: 400px) {
    height: 936px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    height: 644px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    height: 644px;
  }
`;

export const HeroBusinessSection1 = styled.section`
  background: ${(props) => `url(${props.img})`};
  background-size: cover;
  background-position: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  @media only screen and (min-width: 1920px) {
    height: 1080px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    height: 100vh;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    height: 652px;
  }
  @media only screen and (max-width: 900px) {
    height: 793px;
  }
  @media only screen and (max-width: 400px) {
    height: 793px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    height: 652px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    height: 652px;
  }
`;
export const HeroImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const HeroHeaderText = styled.h1`
  font-family: "Didot";
  font-style: normal;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  color: #ffffff;
  @media only screen and (min-width: 1920px) {
    font-weight: 400;
    font-size: 99px;
    line-height: 125px;
    top: 338px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 400;
    font-size: 64px;
    line-height: 81px;
    top: 32vh;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 400;
    font-size: 48px;
    line-height: 61px;
    top: 129px;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 400;
    font-size: 42px;
    line-height: 53px;
    top: 231px;
  }
  @media only screen and (max-width: 400px) {
    padding: 0px 20px;
    top: 151px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 400;
    font-size: 48px;
    line-height: 61px;
    top: 129px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 400;
    font-size: 48px;
    line-height: 61px;
    top: 129px;
  }
`;

export const BusinessHeaderText = styled.h1`
  font-family: "Didot";
  font-style: normal;
  text-align: center;
  color: #ece1ce;
  z-index: 99;
  @media only screen and (min-width: 1920px) {
    font-weight: 400;
    font-size: 64px;
    line-height: 146%;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 400;
    font-size: 64px;
    line-height: 146%;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 400;
    font-size: 42px;
    line-height: 146%;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 400;
    font-size: 30px;
    line-height: 146%;
  }
  @media only screen and (max-width: 400px) {
    font-weight: 400;
    font-size: 30px;
    line-height: 146%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 400;
    font-size: 42px;
    line-height: 146%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 400;
    font-size: 42px;
    line-height: 146%;
  }
`;

export const NftHeaderText = styled(BusinessHeaderText)`
  text-align: left;
`;
export const HeroBusinessSubText = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  color: #ffffff;
  text-align: center;
  @media only screen and (min-width: 1920px) {
    font-weight: 300;
    font-size: 20px;
    line-height: 146%;
    top: 574px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 300;
    font-size: 20px;
    line-height: 146%;
    top: 445px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 300;
    font-size: 18px;
    line-height: 146%;
    top: 332px;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 300;
    font-size: 15px;
    line-height: 146%;
    top: 373px;
  }
  @media only screen and (max-width: 400px) {
    font-weight: 300;
    font-size: 15px;
    line-height: 146%;
    top: 373px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 300;
    font-size: 18px;
    line-height: 146%;
    top: 332px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 300;
    font-size: 18px;
    line-height: 146%;
    top: 332px;
  }
`;
export const HeroNftSubText = styled(HeroBusinessSubText)`
  text-align: left;
  @media only screen and (min-width: 1920px) {
    margin-top: 43px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    margin-top: 49px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    margin-top: 32px;
  }
  @media only screen and (max-width: 900px) {
    margin-top: 38px;
  }
  @media only screen and (max-width: 400px) {
    margin-top: 34px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    margin-top: 32px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    margin-top: 32px;
  }
`;

export const HeroButtonContainer = styled.div`
  display: flex;
  height: 108px;
  flex-wrap: wrap;
  position: absolute;
  left: 0;
  right: 0;
  justify-content: center;
  margin: auto;
  overflow: visible;

  @media only screen and (min-width: 1920px) {
    top: 558px;
    gap: 26px;
    width: 100%;
  }

  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    top: 52vh;
    gap: 20px;
    width: 100%;
  }

  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    top: 228px;
    gap: 18px;
    width: 70%;
  }
  @media only screen and (max-width: 900px) {
    top: 375px;
    gap: 12px;
    width: 100%;
  }
  @media only screen and (max-width: 400px) {
    top: 375px;
    gap: 12px;
    width: 100%;
    padding: 0px 20px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    top: 228px;
    gap: 18px;
    width: 70%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    top: 228px;
    gap: 18px;
    width: 70%;
  }
`;

export const SingleButtonContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background: rgba(18, 40, 76, 0.22);
  border: 0.75px solid #decdad;
  backdrop-filter: blur(10px);
  border-radius: 34px;
  transition: 0.3s;
  color: #ffffff;
  overflow: hidden;

  @media only screen and (min-width: 1920px) {
    height: 57.45px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    height: 45px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    height: 43px;
  }
  @media only screen and (max-width: 900px) {
    height: 43px;
  }
  @media only screen and (max-width: 400px) {
    height: 43px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    height: 43px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    height: 43px;
  }
`;
export const RentSaleButton = styled.button`
  position: absolute;
  background: #ece1ce;
  backdrop-filter: blur(17px);
  border-radius: 90px;
  border: none;
  transition: 0.3s;
  @media only screen and (min-width: 1920px) {
    width: 71px;
    height: 43px;
    left: ${(props) => (props.left ? "85px" : "6px")};
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 58.18px;
    height: 32.21px;
    left: ${(props) => (props.left ? "72px" : "6px")};
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 57.94px;
    height: 32.19px;
    left: ${(props) => (props.left ? "60px" : "4px")};
  }
  @media only screen and (max-width: 900px) {
    width: 53.86px;
    height: 29.65px;
    left: ${(props) => (props.left ? "67px" : "6px")};
  }
  @media only screen and (max-width: 400px) {
    width: 53.86px;
    height: 29.65px;
    left: ${(props) => (props.left ? "67px" : "6px")};
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 57.94px;
    height: 32.19px;
    left: ${(props) => (props.left ? "66px" : "4px")};
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 57.94px;
    height: 32.19px;
    left: ${(props) => (props.left ? "66px" : "4px")};
  }
`;

export const HeroButtonText = styled.h3`
  font-family: "Montserrat";
  font-style: normal;
  letter-spacing: 0.04em;
  color: #ffffff;
  min-width: fit-content;
  @media only screen and (min-width: 1920px) {
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
  }
  @media only screen and (max-width: 400px) {
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
  }
`;
export const HeroDropdownText = styled(HeroButtonText)`
  width: 100%;
  padding: 10px 20px;
  border-radius: 20px;
  &:hover {
    background: #decdad;
    color: black;
  }
`;
export const RentSaleText = styled(HeroButtonText)`
  color: ${(props) => (props.activeSlider ? "#404040" : "#FFFFFF")};
  z-index: 99;
  @media only screen and (min-width: 1920px) {
    font-size: 14px;
    padding: 7px 24px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-size: 14px;
    padding: 11px 18.2px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 13px;
    padding: 11px 15px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 13px;
    padding: 10px 17px;
  }
  @media only screen and (max-width: 400px) {
    font-size: 13px;
    padding: 10px 17px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-size: 13px;
    padding: 11px 16px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-size: 13px;
    padding: 11px 16px;
  }
`;
export const HeroInputText = styled.input`
  background: transparent;
  border: none;
  font-family: "Montserrat";
  font-style: normal;
  letter-spacing: 0.04em;
  color: #ffffff;
  width: 100%;
  &::placeholder {
    color: #ffffff;
  }
  @media only screen and (min-width: 1920px) {
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    width: 100%;
  }
  @media only screen and (max-width: 400px) {
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    width: 100%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
  }
`;
export const CommunityButton = styled(SingleButtonContainer)`
  @media only screen and (min-width: 1920px) {
    width: 443px;
    gap: 24px;
    padding: 10px 20px
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 361.55px;
    gap: 22px;
    padding: 10px 18px
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 359px;
    gap: 20px;
    padding: 10px 17px
  }
  @media only screen and (max-width: 900px) {
    width: 247px;
    gap: 10px;
    padding: 10px 17px
  }
  @media only screen and (max-width: 400px) {
    width: 247px;
    gap: 10px;
    padding: 10px 17px
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 359px;
    gap: 20px;
    padding: 10px 17px
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 359px;
    gap: 20px;
    padding: 10px 17px
  }
`;
export const UnitButton = styled(SingleButtonContainer)`
  justify-content: space-between;
  padding: 10px 20px;
  @media only screen and (min-width: 1920px) {
    width: 262px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 200px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 200px;
  }
  @media only screen and (max-width: 900px) {
    width: 136px;
  }
  @media only screen and (max-width: 400px) {
    width: 136px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 217.83px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 217.83px;
  }
`;
export const DropDownWrapper = styled.div`
  position: relative;
  overflow: visible;
`;
export const DropdownContainerUnit = styled(UnitButton)`
  display: ${(props) => (props.unitActive ? "block" : "none")};
  margin-top: 5px;
  position: absolute;
  left: 0;
  z-index: 99;
  padding: 0px;
  border-radius: 23px;
  @media only screen and (min-width: 1920px) {
    height: 284px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    height: 266px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    height: 248px;
  }
  @media only screen and (max-width: 900px) {
    height: 240px;
  }
  @media only screen and (max-width: 400px) {
    height: 240px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    height: 248px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    height: 248px;
  }
`;
export const DropdownContainerArea = styled(UnitButton)`
  display: ${(props) => (props.areaActive ? "block" : "none")};
  margin-top: 5px;
  position: absolute;
  left: 0;
  z-index: 99;
  padding: 0px;
  border-radius: 23px;
  height: auto;
  width: 100% !important;
`;
export const BedButton = styled(SingleButtonContainer)`
  padding: 10px 20px;
  justify-content: space-between;
  @media only screen and (min-width: 1920px) {
    width: 217px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 180px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 180px;
  }
  @media only screen and (max-width: 900px) {
    width: 170px;
  }
  @media only screen and (max-width: 400px) {
    width: 170px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 200px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 200px;
  }
`;
export const DropdownContainerBeds = styled(BedButton)`
  display: ${(props) => (props.bedActive ? "block" : "none")};
  margin-top: 5px;
  position: absolute;
  left: 0;
  z-index: 99;
  padding: 0px;
  border-radius: 23px;
  @media only screen and (min-width: 1920px) {
    height: 189px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    height: 178px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    height: 165px;
  }
  @media only screen and (max-width: 900px) {
    height: 160px;
  }
  @media only screen and (max-width: 400px) {
    height: 160px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    height: 187px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    height: 187px;
  }
`;
export const PriceButton = styled(SingleButtonContainer)`
  padding: 10px 20px;
  justify-content: space-between;
  @media only screen and (min-width: 1920px) {
    width: 178px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 145px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 145px;
  }
  @media only screen and (max-width: 900px) {
    width: 126px;
  }
  @media only screen and (max-width: 400px) {
    width: 126px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 145px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 145px;
  }
`;
export const DropdownContainerPrice = styled(PriceButton)`
  display: ${(props) => (props.priceActive ? "block" : "none")};
  margin-top: 5px;
  position: absolute;
  left: 0;
  z-index: 99;
  padding: 0px;
  border-radius: 23px;
  @media only screen and (min-width: 1920px) {
    height: 284px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    height: 268px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    height: 250px;
  }
  @media only screen and (max-width: 900px) {
    height: 240px;
  }
  @media only screen and (max-width: 400px) {
    height: 240px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    height: 250px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    height: 250px;
  }
`;
export const SearchButton = styled(SingleButtonContainer)`
  justify-content: center;
  background: #ece1ce;
  margin-right: 10px;
  cursor: pointer;
  &:hover {
    background: #decdad;
  }
  @media only screen and (min-width: 1900px) {
    width: 178px;
    height: 45px
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    width: 145px;
    height: 45px
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 145px;
    height: 43px
  }
  @media only screen and (max-width: 900px) {
    width: 139px;
    height: 43px
  }
`;

export const SearchText = styled(HeroButtonText)`
  color: #0d0d0d;
`;

export const InputText = styled.input`
  font-family: "Montserrat";
  font-style: normal;
  letter-spacing: 0.04em;
  color: #ffffff;
  background: transparent;
  border: none;
  @media only screen and (min-width: 1920px) {
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
  }
  @media only screen and (max-width: 400px) {
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
  }
`;

export const HeroSubTextContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0px;
  text-align: center;
  @media only screen and (min-width: 1920px) {
    top: 681px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    top: 68vh;
  }
  @media only screen and (min-width: 1060px) and (max-width: 1300px) {
    top: 390px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1060px) {
    top: 464px;
  }
  @media only screen and (max-width: 900px) {
    top: 600px;
  }
  @media only screen and (max-width: 400px) {
    top: 700px;
    padding: 0px 40px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    top: 464px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    top: 464px;
  }
`;

export const HeroSubText = styled.span`
  font-family: "Montserrat";
  font-style: normal;
  text-align: center;
  letter-spacing: 0.04em;
  color: #ffffff;
  @media only screen and (min-width: 1920px) {
    font-weight: 300;
    font-size: 18px;
    line-height: 150%;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 300;
    font-size: 18px;
    line-height: 150%;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 300;
    font-size: 15px;
    line-height: 150%;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
  }
  @media only screen and (max-width: 400px) {
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 300;
    font-size: 15px;
    line-height: 150%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 300;
    font-size: 15px;
    line-height: 150%;
  }
`;

export const HeroPreferenceText = styled.a`
  font-weight: 600;
  color: #ece1ce;
  text-decoration: underline;
  font-family: "Montserrat";
  font-style: normal;
  text-align: center;
  letter-spacing: 0.04em;
  @media only screen and (min-width: 1920px) {
    font-size: 18px;
    line-height: 150%;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-size: 18px;
    line-height: 150%;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 15px;
    line-height: 150%;
  }
  @media only screen and (max-width: 900px) {
    font-size: 14px;
    line-height: 150%;
  }
  @media only screen and (max-width: 400px) {
    font-size: 14px;
    line-height: 150%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-size: 15px;
    line-height: 150%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-size: 15px;
    line-height: 150%;
  }
`;

export const BusinessContactButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  font-family: "Montserrat";
  font-style: normal;
  color: #000000;
  background: #ece1ce;
  border-radius: 136.72px;
  border: none;
  letter-spacing: 0.125em;
  @media only screen and (min-width: 1920px) {
    width: 220px;
    height: 60px;
    margin-top: 53px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 220px;
    height: 60px;
    margin-top: 53px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 172px;
    height: 40px;
    margin-top: 36px;
    font-weight: 500;
    font-size: 11.8204px;
    line-height: 14px;
  }
  @media only screen and (max-width: 900px) {
    width: 80%;
    height: 40px;
    margin-top: 41px;
    font-weight: 500;
    font-size: 11.8204px;
    line-height: 14px;
  }
  @media only screen and (max-width: 400px) {
    width: 80%;
    height: 40px;
    margin-top: 41px;
    font-weight: 500;
    font-size: 11.8204px;
    line-height: 14px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 172px;
    height: 40px;
    margin-top: 36px;
    font-weight: 500;
    font-size: 11.8204px;
    line-height: 14px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 172px;
    height: 40px;
    margin-top: 36px;
    font-weight: 500;
    font-size: 11.8204px;
    line-height: 14px;
  }
`;

export const HeroContentContainer = styled.div`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`;
export const HeroNftContentContainer = styled(HeroContentContainer)`
  width: 85%;
  margin: auto;
`;
export const HeroNftButton = styled.div`
  background: rgba(236, 225, 206, 0.31);
  backdrop-filter: blur(30px);
  border-radius: 90px;
  display: flex;
  align-items: center;
  margin-top: 50px;
  justify-content: flex-start;
  @media only screen and (min-width: 1920px) {
    height: 48px;
    gap: 29.11px;
    width: 220px;
    padding-left: 5px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    height: 48px;
    gap: 29.11px;
    width: 220px;
    padding-left: 5px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    height: 43px;
    gap: 23.24px;
    width: 210px;
    padding: 4px;
  }
  @media only screen and (max-width: 900px) {
    height: 43px;
    gap: 23.24px;
    width: 210px;
    padding: 4px;
  }
  @media only screen and (max-width: 400px) {
    height: 43px;
    gap: 23.24px;
    width: 210px;
    padding: 4px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    height: 43px;
    gap: 23.24px;
    width: 210px;
    padding: 4px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    height: 43px;
    gap: 23.24px;
    width: 210px;
    padding: 4px;
  }

  img {
    background: #12284c;
    border-radius: 50%;
    @media only screen and (min-width: 1920px) {
      padding: 4px 6px;
      width: 40px;
      height: 40px;
    }
    @media only screen and (max-width: 1920px) {
      padding: 4px 6px;
      width: 34px;
      height: 34px;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
      padding: 4px 6px;
      width: 34px;
      height: 34px;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
      padding: 4px 6px;
      width: 34px;
      height: 34px;
    }
  }
  h4 {
    font-family: "Montserrat";
    font-style: normal;
    letter-spacing: 0.03em;
    color: #ffffff;
    @media only screen and (min-width: 1920px) {
      font-weight: 400;
      font-size: 20px;
      line-height: 127%;
    }
    @media only screen and (max-width: 1920px) {
      font-weight: 400;
      font-size: 16px;
      line-height: 127%;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
      font-weight: 400;
      font-size: 16px;
      line-height: 127%;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
      font-weight: 400;
      font-size: 16px;
      line-height: 127%;
    }
  }
`;
