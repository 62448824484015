import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import { AboutReadMoreButton, BusinessMessageSection, MessageButton, MessageCard, MessageContainer, MessageDesc, MessageDiv, MessageHeaderText, MessageImage } from './BusinessMessage.styled'

const BusinessMessage = ({header, desc, img, desc2}) => {

const [showFullDescription, setShowFullDescription] = useState(false);
  const { t } = useTranslation();
  const paragraphs = desc2.split('\n');
  // const a = desc.split("$")
  return (
    <BusinessMessageSection>
        <MessageCard>
      <MessageContainer>
        <MessageHeaderText>{header}</MessageHeaderText>
        <MessageDesc>{t(desc.split('$')[0])}</MessageDesc>
        <MessageDesc>{t(desc.split('$')[1])}</MessageDesc>
        <Link to = '/contactUs'><MessageButton>{t('CONTACT US')}</MessageButton></Link>
      </MessageContainer>
      <MessageImage src={img} alt='message'/>
      </MessageCard>
      <MessageDiv>
      {paragraphs.slice(0, 3).map((paragraph, index) => (
  <MessageDesc key={index}>{paragraph}</MessageDesc>
))}

  
      
{!showFullDescription && <AboutReadMoreButton onClick={() => setShowFullDescription(true)}>Read More</AboutReadMoreButton>}

    {showFullDescription && (
      <>
        {paragraphs.slice(3).map((paragraph, index) => (
          <MessageDesc key={index}>{paragraph}</MessageDesc>
        ))}

        <AboutReadMoreButton onClick={() => setShowFullDescription(false)}>Read less</AboutReadMoreButton>
      </>
    )}
          <Link to = '/contactUs'><MessageButton>{t('CONTACT US')}</MessageButton></Link>
  </MessageDiv>
    </BusinessMessageSection>
  )
}

export default BusinessMessage