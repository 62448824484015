import { Slider } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getMortage } from "../../redux/slices/MortageSlice/MortageSlice";
import {
  InterestDivContainer,
  InterestInput,
  PropertyPrice,
} from "./PropertyDetail.styled";
import './style.css'

const InterestSlider = () => {
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(getMortage({key:'interest',value:value/100}))
  })
  const [value, setValue] = React.useState(2.5);

  const handleSliderChange = (event, newValue) => {
    setValue(event.target.value);
  };

  const handleInputChange = (event) => {
    setValue(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > 20) {
      setValue(20);
    }
  };
  return (
    <InterestDivContainer>
      <PropertyPrice> Interest: </PropertyPrice>
      <Slider
        value={value}
        onChange={handleSliderChange}
        aria-labelledby="input-slider"
        defaultValue={2.5}
        min={1}
        max={20}
        step={0.1}
      />
      <InterestInput
        type="text"
        value={value + "%"}
        margin="dense"
        onChange={handleInputChange}
        onBlur={handleBlur}
      />
    </InterestDivContainer>
  );
}

export default InterestSlider
