import { createSlice } from '@reduxjs/toolkit';
const SellpreferenceSlice = createSlice({
    name: 'user',
    initialState: {
        type: "Rent",
        community: "",
        unittype: "",
        bedrooms: "",
        name: "",
        phone: "",
        email: "",
        message: "",
        checked: "",
    },
    reducers: {
        postSellPreference(state, action) {},
        setSellPreference(state, action) {
            const preferenceData = action.payload;
            state.preferenceObj = preferenceData;
        },
    },
});
export const { postSellPreference, setSellPreference  } = SellpreferenceSlice.actions;

export default SellpreferenceSlice.reducer;