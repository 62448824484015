import styled from "styled-components";

export const PageDiv = styled.div`
  width: min(90%,1720px);
  margin: auto;
`;

export const LocationDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MainPageDiv = styled.div`
  background: rgba(238, 242, 244, 0.5);
`;

export const SearchButtonDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;
  @media (min-width: 1900px) {
    gap: 26px;
    padding-bottom: 65px;
  }
  @media ((min-width: 1300px) and (max-width:1900px)) {
    gap: 26px;
    padding-bottom: 53px;
  }
  @media ((min-width: 900px) and (max-width:1300px)) {
    gap: 23px;
    padding-bottom: 45px;
  }
  @media (max-width: 900px) {
    gap: 20px;
    padding-bottom: 68px;
  }
`;


export const SearchOneDiv = styled.div`
  @media (min-width: 1900px) {
    padding-top: 65px;
  }
  @media ((min-width: 1300px) and (max-width:1900px)) {
    padding-top: 53px;
  }
  @media ((min-width: 900px) and (max-width:1300px)) {
    padding-top: 45px;
  }
  @media (max-width: 900px) {
    padding-top: 68px;
  }
`;

export const MainHeadingDiv = styled.div`
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: center;
  @media (min-width: 1900px) {
    padding-top: 189px;
    padding-bottom: 60px;
  }
  @media ((min-width: 1300px) and (max-width:1900px)) {
    padding-top: 189px;
    padding-bottom: 60px;
  }
  @media ((min-width: 900px) and (max-width:1300px)) {
    padding-top: 160px;
    padding-bottom: 30px;
  }
  @media (max-width: 900px) {
    padding-top: 140px;
    padding-bottom: 20px;
  }
`;

export const Heading = styled.p`
  font-family: "Didot";
  font-style: normal;
  font-weight: 400;
  text-align: center;
  color: #131d2e;
  overflow: hidden;
  padding-bottom: 20px;
  @media (min-width: 1900px) {
    font-size: 64px;
    line-height: 81px;
  }
  @media ((min-width: 1300px) and (max-width:1900px)) {
    font-size: 54px;
  }
  @media ((min-width: 900px) and (max-width:1300px)) {
    font-size: 42px;
    line-height: 53px;
  }
  @media ((min-width: 400px) and (max-width:900px)) {
    font-size: 28px;
    line-height: 30px;
  }
  @media (max-width: 400px) {
    font-size: 27px;
    line-height: 25px;
  }
`;

export const SearchButton = styled.button`
  text-align: center;
  background-color: ${props => props.bg};
  border: 1px solid #12284c;
  color:  ${(props) => props.color};
  border-radius: 90px;
  transition: 0.8s;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 16px;
  overflow: hidden;
  text-transform: uppercase;
  letter-spacing: 0.125em;
  transition: 0.8s;
  &:hover {
    background-color: #004876;
  }
  @media (min-width: 1900px) {
    width: 157px;
    height: 40px;
  }
  @media ((min-width: 1300px) and (max-width:1900px)) {
    width: 157px;
    height: 40px;
  }
  @media ((min-width: 900px) and (max-width:1300px)) {
    width: 250px;
    height: 40px;
  }
  @media (max-width: 900px) {
    width: 258px;
    height: 40px;
  }
`;

export const PreferencesMainDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
`;

export const PreferencesHeading = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.04em;
  color: #000000;
  font-size: 18px;

  @media (max-width: 900px) {
    font-size: 12px;
  }
`;

export const AnchorTag = styled.a`
  color: #12284c;
  font-weight: 500;
  text-decoration: underline;
`;

export const RecentlyAddedDiv = styled.div`
  display: flex;
  
  justify-content: space-between;
  margin: auto;
  @media (min-width: 1900px) {
    padding-top: 146px;
    width: min(100%, 1720px);
    padding-bottom: 88px;
  }
  @media ((min-width: 1300px) and (max-width:1900px)) {
    padding-top: 99px;
    padding-bottom: 88px;
  }
  @media ((min-width: 900px) and (max-width:1300px)) {
    padding-top: 80px;
    padding-bottom: 59px;
  }
  @media (max-width: 900px) {
    padding-top: 40px;
    padding-bottom: 55px;
  }
`;

export const SearchResultsHeading = styled.p`
  font-family: "Didot";
  font-style: normal;
  font-weight: 400;
  line-height: 146%;
  color: #000000;
  @media (min-width: 1900px) {
    font-size: 30px;
  }
  @media ((min-width: 1300px) and (max-width:1900px)) {
    font-size: 30px;
  }
  @media ((min-width: 500px) and (max-width:1300px)) {
    font-size: 24px;
  }
`;

export const RecentlyAddedProjects = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

export const FoundProjects = styled.button`
  border: none;
  background-color: transparent;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 127%;
  letter-spacing: 0.03em;
  text-decoration-line: underline;
  color: #000000;
  @media (max-width: 900px) {
    font-size: 13px;
  }
`;

export const Cards = styled.div`
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
@media (min-width: 1900px) {
    padding-bottom: 155px;
    }
@media ((min-width: 1300px) and (max-width:1900px)){
    padding-bottom: 125px;
    }
@media ((min-width: 900px) and (max-width:1300px)){
    padding-bottom: 89px;
    }
@media (max-width: 900px) {
    padding-bottom: 39px;
    }
`;

export const PreferencesPadding = styled.div`
  @media (min-width: 1900px) {
    padding-top: 97px;
    padding-bottom: 117px;

    }
  @media ((min-width: 1300px) and (max-width:1900px)){
    padding-top: 69px;
    padding-bottom: 95px;
    }
  @media ((min-width: 900px) and (max-width:1300px)){
    padding-top: 41px;
    padding-bottom: 92px;
    }
  @media (max-width: 900px) {
    padding-top: 27px;
    padding-bottom: 59px;
    }


`;
