import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Contact from "../components/ContactUs/Contact";
import CustomizedSnackbar from "../components/CustomizedSnackbars/CustomizedSnackbar";
import Footer from "../components/Footer/Footer";
import FooterMobile from "../components/Footer/FooterMobile";
import Navbar from "../components/Navbar/Navbar";
import SetupStepRealEstate from "../components/SetupSteps/SetupStepRealEstate";
import HeroImage from "../assets/images/refurhero.png";
import Image from "../assets/images/furimage.png";
import HeroBusiness from "../components/HeroLandingPage/HeroBusiness";
import BusinessMessage from "../components/BusinessMessage/BusinessMessage";
import Step1 from "../assets/images/fur1.svg";
import Step2 from "../assets/images/fur2.svg";
import Step3 from "../assets/images/fur3.svg";
import Step4 from "../assets/images/fur4.svg";
import Step5 from "../assets/images/fur5.svg";
import Step6 from "../assets/images/fur6.svg";
const TabletBreakpoint = 1024;

const text = [
  { text: "Furniture Selection and Placement", img: Step1 },
  { text: "Project Management", img: Step2 },
  { text: "Interior Design Consultation", img: Step3 },
  { text: "Accessory and Decor Styling", img: Step4 },
  { text: "Inventory Management and Logistics", img: Step5 },
  { text: "Vacant Home Stagng", img: Step6 },
];
const BusinessServiceFurnishing = () => {
  const [offset, setOffset] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [background, setBackground] = useState("transparent");
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  useEffect(() => {
    if (offset > 100) setBackground("#12284C");
    else setBackground("transparent");
  }, [offset]);
  return (
    <div>
      <Navbar background={background} />
      <HeroBusiness
        heroImage={HeroImage}
        header="We design. We furnish. You live!"
        desc="We elevate your home with stylish designs, quality furniture, and impeccable attention to detail."
      />
      <BusinessMessage
        header="Furnish your dreams, create your perfect space"
        desc="Welcome to Modern Living, we are your one-stop shop for expert real estate furnishing services. We specialize in evolving residences into beautiful, welcoming places that excite potential purchasers or improve the comfort and attractiveness of rental properties. We realize the significance of establishing an appealing environment that appeals to prospective purchasers or tenants. Our experienced designers and furnishing specialists are committed to assisting you in showcasing the actual potential of your home."
        img={Image}
        desc2={`We provide customised furnishing solutions to match your goals, whether you're a homeowner hoping to sell your home faster and for a higher price, or a property manager looking to improve the appeal of your rental units. Our high-quality furniture, décor, and accessories are hand-picked to complement the design, utility, and overall mood of any area.

        Our knowledgeable staff collaborates with you to fully grasp your vision, objectives, and target market. We create personalized furnishing plans that reflect the particular aspects of the property while also catering to your specific budget and timetable. We construct furniture packages and design concepts that optimize space, accentuate architectural details, and create an appealing ambiance with thoroughly attention to detail.
        
        We aim for excellence in both design and service at Modern Living. From selection and delivery to installation and style, we handle every element of the outfitting process. Our experienced team handles all logistics, assuring a smooth and trouble-free experience for you. We take pleasure in exceeding your expectations with amazing results.
        
        We recognize the need of staying ahead of design trends and providing timeless beauty. To create places that are both modern and timeless, our team stays up to date on the newest design trends and industry developments. We create spaces that elicit pleasant feelings and excite potential purchasers or tenants by incorporating the proper colors, textures, and furniture combinations.
        
        Feel the difference of professionally furnished spaces for yourself. Contact us immediately to discuss your furnishing requirements and allow us to change your home into a compelling and desired location.`}
      />
      <SetupStepRealEstate data={text} header={"What we do in Furnishing Service?"}  />

      <Contact displaymsg="block" displayupload="none" />
      {width < TabletBreakpoint ? <FooterMobile /> : <Footer />}
      <CustomizedSnackbar />
    </div>
  );
};

export default BusinessServiceFurnishing;
