import { put, retry } from 'redux-saga/effects';
import { contactUs } from '../../request/contactUs/contactUs';
import { setContactUs } from '../../../slices/contactUs/contactUsSlices';
import { showSnackBar } from '../../../slices/snackbarSlice/snackbarSlice';

export function* handleContactUs(action) {
    try {
        const response = yield retry(0, 0, contactUs, action.payload);
        const { data } = response.config;
        const userData = JSON.parse(data);
        yield put(setContactUs({ userData }));
    } catch (error) {
        // const snackbarObject = {
        //     type: 'error',
        //     message: 'Contact Us Failed ',
        //     open: true,
        // };
        // yield put(showSnackBar(snackbarObject));
    }
}