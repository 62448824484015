import styled from "styled-components";

export const ContactSection = styled.section``;

export const ContactDiv = styled.div`
  @media (min-width: 1900px) {
    padding-left: 167px;
  }
  @media ((min-width: 1300px) and (max-width:1900px)) {
    padding-left: 108px;
  }
  @media ((min-width: 900px) and (max-width:1300px)) {
    padding-left: 126px;
  }
  @media (max-width: 900px) {
    padding-left: 40px;
  }
`;
export const ContactHeader = styled.h1`
  margin-bottom: 20px;
  margin-top: 250px;
  font-family: "Didot";
  font-style: normal;
  letter-spacing: 0.05em;
  color: #000000;
  @media (min-width: 1900px) {
    font-weight: 400;
    font-size: 96px;
    line-height: 122%;
  }
  @media ((min-width: 1300px) and (max-width:1900px)) {
    font-weight: 400;
    font-size: 74.0836px;
    line-height: 122%;
  }
  @media ((min-width: 900px) and (max-width:1300px)) {
    font-weight: 400;
    font-size: 48px;
    line-height: 122%;
  }
  @media (max-width: 900px) {
    font-weight: 400;
    font-size: 48px;
    line-height: 122%;
  }
`;
export const ContactDetailDiv = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 20px;
  h3 {
    font-family: "Montserrat";
    font-style: normal;
    color: #000000;
    @media (min-width: 1900px) {
      font-weight: 300;
      font-size: 26px;
      line-height: 146%;
    }
    @media ((min-width: 1300px) and (max-width:1900px)) {
      font-weight: 300;
      font-size: 20.0643px;
      line-height: 146%;
    }
    @media ((min-width: 900px) and (max-width:1300px)) {
      font-weight: 300;
      font-size: 20.0643px;
      line-height: 146%;
    }
    @media (max-width: 900px) {
      font-weight: 300;
      font-size: 20.0643px;
      line-height: 146%;
    }
  }
  img {
    width: 40px;
    height: 30px;
  }
`;

export const SocialDiv = styled.div`
  margin-bottom: 100px;
  h4 {
    font-family: Montserrat;
    font-style: normal;
    color: #000000;
    @media (min-width: 1900px) {
      font-weight: 300;
      font-size: 26px;
      line-height: 146%;
      margin-top: 85px;
    }
    @media ((min-width: 1300px) and (max-width:1900px)) {
      font-weight: 300;
      font-size: 20.0643px;
      line-height: 146%;
      margin-top: 65px;
    }
    @media ((min-width: 900px) and (max-width:1300px)) {
      font-weight: 300;
      font-size: 20.0643px;
      line-height: 146%;
      margin-top: 65px;
    }
    @media (max-width: 900px) {
      font-weight: 300;
      font-size: 20.0643px;
      line-height: 146%;
      margin-top: 55px;
    }
  }
  div {
    display: flex;
    gap: 20px;
    margin-top: 10px;
  }
`;

export const MapandContactDivTwo = styled.div`
  display: flex;
  gap: 2%;
  width: 100%;
  @media only screen and (max-width: 900px) {
    flex-wrap: wrap;
  }

  div {
    overflow-x: visible !important;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const MapandContactDiv = styled.div`
  display: flex;
  gap: 2%;
  width: 100%;
  @media (min-width: 1900px) {
    height: 900px;
  }
  @media ((min-width: 1300px) and (max-width:1900px)) {
    height: 800px;
  }
  @media ((min-width: 900px) and (max-width:1300px)) {
    height: 650px;
  }
  @media (max-width: 900px) {
    height: 1200px;
    flex-wrap: wrap;
  }
  div {
    overflow-x: visible !important;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
