import React from "react";
import Collage from "./Collage";
import { Description } from "./Description";
import { MortageCalculator } from "./MortageCalculator";
import { PropertyDetailsInfo } from "./PropertyDetailsInfo";
import { PropertyHeadingAndButtons } from "./PropertyHeadingAndButtons";
import { KeyFeature } from "../ProjectDetail/KeyFeature";

const PropertyPageDetail = ({ propertiesDetails}) => {
  return (
    <div style={{backgroundColor:"rgba(238, 242, 244, 0.5)"}}>
        {propertiesDetails && (
          <Collage
            details={propertiesDetails}
          />
        )}       
        {propertiesDetails && (
          <PropertyHeadingAndButtons details={propertiesDetails} />
        )}
        {propertiesDetails && (
          <PropertyDetailsInfo details={propertiesDetails} />
        )}
        { propertiesDetails && <Description details={propertiesDetails} />}
        {propertiesDetails && <div style={{width: "80%", margin:"auto"}}><KeyFeature details={propertiesDetails.attributes} /></div>}
        {propertiesDetails && <MortageCalculator details={propertiesDetails} />}
    </div>
  );
};

export default PropertyPageDetail;
