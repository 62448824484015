import React, { useState } from "react";
import Visit1 from "../../assets/images/virtualvisit.svg";
import Visit2 from "../../assets/images/virtualvisit2.svg";
import Arrow from "../../assets/images/Arrow.svg";
import Arrow2 from "../../assets/images/Arrow2.svg";
import { useSelector } from "react-redux";
import { BiChevronRight, BiChevronLeft } from "react-icons/bi";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import {
  PropertyCardDetailWrapper,
  PropertyCardDetailContainer,
  PropertyCardDetailText,
  PropertyCardDetailText2,
  PropertyCardButton,
  PropertyIconVisit,
  PropertyIconExplore,
  PropertyImage,
  CardTitle,
  CardSubText,
  PriceText,
  ButtonContainer,
  SlideIconRight,
  PropertyCardContent,
  SlideIconLeft,
  ProjectMainContainer,
  CardDescriptionText,
  PropertyCardHoverText,
} from "../PropertyListing.js/PropertyListing.styled";
import { useTranslation } from "react-i18next";
import {
  ProjectDescription,
  ProjectIcon,
} from "../LatestProjects/LatestProjects.styled";

const convertToDateQuarter = (dateString) => {
  const dateParts = dateString.split("-");
  const month = parseInt(dateParts[1], 10);
  const year = parseInt(dateParts[0], 10);
  const quarter = Math.ceil(month / 3);
  return `Q${quarter} ${year}`;
};

const NewProjectCard = ({ project }) => {
  const [visit, setVisit] = useState(Visit1);
  const [position, setPosition] = useState("");
  const [explore, setExplore] = useState(Arrow2);
  const [count, setCount] = useState(0);
  const [unit, setUnit] = useState(false);
  const { t } = useTranslation();
  if (project.attributes.completion_date) {
    var date = project.attributes.completion_date;
  }

  const images = project.attributes.image.data;
  if (date) {
    var quater_date = convertToDateQuarter(date);
  }

  if (project.attributes.available_units) {
    var unitData = project.attributes.available_units.data
      .map((p) => p.attributes.rooms);
  
    if (unitData.length === 0) {
      var finalResult = "";
    } else {
      var uniqueSet = new Set(unitData);
      var formattedData = [];
  
      if (uniqueSet.has(0)) {
        uniqueSet.delete(0);
        formattedData.push("Studio");
      }
  
      var sortedUniqueValues = [...uniqueSet].sort((a, b) => a - b);
  
      var ranges = [];
      var startRange = sortedUniqueValues[0];
      var endRange = sortedUniqueValues[0];
  
      for (let i = 1; i < sortedUniqueValues.length; i++) {
        if (sortedUniqueValues[i] === endRange + 1) {
          endRange = sortedUniqueValues[i];
        } else {
          if (startRange === endRange) {
            ranges.push(startRange);
          } else {
            ranges.push(`${startRange}-${endRange}`);
          }
          startRange = endRange = sortedUniqueValues[i];
        }
      }
  
      if (startRange === endRange) {
        ranges.push(startRange);
      } else {
        ranges.push(`${startRange}-${endRange}`);
      }
  
      var finalResult = formattedData.concat(ranges).join(",");
    }
  } else {
    var finalResult = "";
  }
  
  
  
  return (
    <>
      <PropertyCardContent>
          <CardTitle>{project.attributes.name}</CardTitle>
        {project.attributes &&
          project.attributes.location.data.attributes.area && (
            <CardSubText>
              {project.attributes.location.data.attributes.area}, Dubai
            </CardSubText>
          )}
        <PropertyCardDetailWrapper>
          {project && project.attributes && project.attributes.unit_type && (
            <PropertyCardDetailContainer>
              <PropertyCardDetailText>{t("Unit Type")}:</PropertyCardDetailText>

              <PropertyCardDetailText2>
                {project.attributes.unit_type}
              </PropertyCardDetailText2>
            </PropertyCardDetailContainer>
          )}
          {project.attributes.available_units.data.length !== 0 && (
            <PropertyCardDetailContainer>
              <PropertyCardDetailText>{t("Units")}:</PropertyCardDetailText>
              <PropertyCardDetailText2>{finalResult}</PropertyCardDetailText2>
            </PropertyCardDetailContainer>
          )}
          <PropertyCardDetailContainer>
            <PropertyCardDetailText>{t("handover")}:</PropertyCardDetailText>
            {project &&
              project.attributes &&
              project.attributes.completion_date && (
                <PropertyCardDetailText2>{quater_date}</PropertyCardDetailText2>
              )}
          </PropertyCardDetailContainer>
        </PropertyCardDetailWrapper>
        {project.attributes.image && project.attributes.image.data && (
          <div style={{ position: "relative" }}>
            <Link to={`/projects/detail/${project.id}`}>
              <AnimatePresence mode="wait">
                <motion.div
                  key={images[count].attributes.url}
                  initial={{ x: position }}
                  animate={{ x: 0 }}
                  exit={{ opacity: 0 }}
                >
                  <PropertyImage
                    img={images[count].attributes.url}
                  ></PropertyImage>
                </motion.div>
              </AnimatePresence>
            </Link>
            <SlideIconRight
              disabled={count === project.attributes.image.data.length - 1}
              onClick={() => {
                setCount(count + 1);
                setPosition("100%");
              }}
            >
              {" "}
              <BiChevronRight size={25} />
            </SlideIconRight>
            <SlideIconLeft
              disabled={count === 0}
              onClick={() => {
                setCount(count - 1);
                setPosition("-100%");
              }}
            >
              {" "}
              <BiChevronLeft size={25} />
            </SlideIconLeft>
          </div>
        )}
        
      </PropertyCardContent>
      <ButtonContainer>
        <PropertyCardButton
          onMouseEnter={() => setVisit(Visit2)}
          onMouseLeave={() => setVisit(Visit1)}
          style={{
            borderRight: "1px solid",
            pointerEvents: "none",
            opacity: "0.5",
          }}
        >
          <PropertyIconVisit src={visit} alt="visit" />
          VIRTUAL VISIT
        </PropertyCardButton>
        <PropertyCardButton
          onMouseEnter={() => setExplore(Arrow)}
          onMouseLeave={() => setExplore(Arrow2)}
        >
          <Link to={`/projects/detail/${project.id}`}>
            EXPLORE
            <PropertyIconExplore src={explore} alt="arrow" />
          </Link>
        </PropertyCardButton>
      </ButtonContainer>
    </>
  );
};

export default NewProjectCard;
