import React, { useEffect, useState } from 'react'
import Contact from '../components/ContactUs/Contact';
import CustomizedSnackbar from '../components/CustomizedSnackbars/CustomizedSnackbar';
import Footer from '../components/Footer/Footer';
import FooterMobile from '../components/Footer/FooterMobile';
import HeroBusiness from '../components/HeroLandingPage/HeroBusiness';
import Navbar from '../components/Navbar/Navbar';
import SetupStepRealEstate from '../components/SetupSteps/SetupStepRealEstate';
import HeroImage from '../assets/images/ML_businessservises_legalservicesscreen.jpg'
import Image from "../assets/images/ML_business _servises_legalservices.jpg";
import BusinessMessage from '../components/BusinessMessage/BusinessMessage';
import Step1 from '../assets/images/Step1.svg'
import Step2 from '../assets/images/Untitleddesign.svg'
import Step3 from '../assets/images/282.svg'
import Step4 from '../assets/images/Vector(8).svg'
import Step5 from '../assets/images/Group506.svg'
import Step6 from '../assets/images/Group507.svg'
const TabletBreakpoint = 1024;


const text = [
  {text:'Title Search and Examination',img:Step1}, 
  {text:'Contract Preparation and Review',img:Step2},
  {text:'Property Settlement and Transfer',img:Step3},
  {text:'Financial Coordination',img:Step4},
  {text:'Property registration and documentation',img:Step5},
  {text:'Post-Settlement Services',img:Step6}
  ]
const BusinessConveyancer = () => {
    const [offset, setOffset] = useState(0);
  const [width, setWidth] = useState(window.innerWidth)
  const [background,setBackground] = useState('transparent')
  useEffect(()=>{
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    window.scrollTo(0, 0)
  },[])
  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
    
}, []);
useEffect(()=>{
  if (offset>100) setBackground('#12284C')
  else setBackground('transparent')
},[offset])
  return (
    <div>
      <Navbar background={background}
      />
      <HeroBusiness heroImage={HeroImage}
      header='We make property transactions seamless and simple!'
      desc='Our goal is to ensure that your rights and interests are protected, giving you peace of mind throughout the entire transfer process.'
      />
      <BusinessMessage
      img={Image}
      header='Simplify your real estate transaction.'
      desc='We at Modern Living are your trusted partner for professional real estate conveyancer services.$Our Conveyancer Service ensures a smooth and secure transfer of ownership for both buyers and sellers. We are specialized in handling the legal aspects of property transactions aim to provide a hassle-free transition.'
      desc2={"We often recognize that handling the complexities of property law can be overwhelming and exhausting. Therefore our team of experts is providing you with comprehensive and reliable information and support throughout the whole process.\n\nOur experienced conveyance experts have a deep knowledge and high expertise in property laws, regulations and the corresponding processes. Whether you’re selling or buying residential or commercial property, we are there for you, to guide you through any individual step.\n\nWhen you decide to work with us, you can be confident that your property transaction is in trustful hands. We are focused on all the details and ensure a high accuracy, to identify and mitigate potential issues which may affect the property’s value or title. We are aiming to ensure that your rights and interests are protected throughout the entire journey, so that you can easily navigate through the entire process.\n\nOur team is driven by creating the hugest transparency of the whole process, while ensuring effective communication, keeping you informed about the progress and status of your transaction. Therefore we immediately addressing any of your questions or concerns you may have. Our mission is to keep you updated at every stage.\n\nWith our huge network of experts in the real estate industry, like real estate agents, mortgage bankers, and other legal professionals, we provide an efficient and streamlined process, to ensure a seamless transfer of ownership.\n\nRegardless of your experience, a first-time buyer, an experienced investor or a property developer, we are your partner. We are dedicated to take care of  your real estate strategies and ambitions.\n\nContact us today to discuss your requirements and let us get the hand around your legal complexities while you can focus on your property transaction."}
      />
        <SetupStepRealEstate data={text} header="What we do in Conveyancer Service?"/>
        <Contact displaymsg='block' displayupload = 'none'/>
        {width < TabletBreakpoint?<FooterMobile/>:<Footer/>}
        <CustomizedSnackbar/>
    </div>
  )
}

export default BusinessConveyancer