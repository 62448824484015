import React, { useEffect, useState } from 'react'
import CustomizedSnackbar from '../components/CustomizedSnackbars/CustomizedSnackbar';
import Footer from '../components/Footer/Footer';
import FooterMobile from '../components/Footer/FooterMobile';
import Navbar from '../components/Navbar/Navbar'
import Preferences from '../components/PreferenceScreen/Preferences.js'
const TabletBreakpoint = 1200;

const PreferenceScreen = () => {
    const [width, setWidth] = useState(window.innerWidth)
  useEffect(()=>{
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    window.scrollTo(0, 0);
  },[])
  return (
    <div>
        <Navbar background='#12284C'/>
        <Preferences/>
        {width < TabletBreakpoint?<FooterMobile/>:<Footer/>}
        <CustomizedSnackbar/>
    </div>
  )
}

export default PreferenceScreen