import React, { useEffect, useState } from 'react'
import { PressCenterTemplate } from '../assets/styles/MyStyle.styled'
import CompanyContact from '../components/CompanyContact/CompanyContact'
import Footer from '../components/Footer/Footer'
import FooterMobile from '../components/Footer/FooterMobile'
import Navbar from '../components/Navbar/Navbar'

const Contactus = () => {
    const [width, setWidth] = useState()
    useEffect(()=>{
        window.addEventListener("resize", () => setWidth(window.innerWidth));
        window.scrollTo(0, 0)
      },[])
  return (
    <PressCenterTemplate>
        <Navbar background='#12284C'/>
        <CompanyContact/>
        {(width < 1204)?<FooterMobile/>:<Footer/>}
    </PressCenterTemplate>
  )
}

export default Contactus