import {Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import logo from '../../assets/images/Logo.svg'
import { FooterContentContainer, FooterSubmitButton, InputText, myText} from './Footer.styled';
import FacebookIcon from '../../assets/images/FacebookIcon.svg'
import YoutubeIcon from '../../assets/images/YoutubeIcon.svg'
import LinkedinIcon from '../../assets/images/LinkedinIcon.svg'
import InstaIcon from '../../assets/images/InstaIcon.svg'
import './Footer.css'
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showSnackBar } from '../../redux/slices/snackbarSlice/snackbarSlice';
import { NavbarLogo } from '../Navbar/Navbar.styled';
import { useTranslation } from 'react-i18next';
const Footer = () => {
  const [email, setEmail] = useState('')
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const snackbarObject = {
    type: '',
    message: '',
    open: false,
  };
  const dispatchSnackBar = (type, message, open) => {
    snackbarObject.type = type;
    snackbarObject.message = message;
    snackbarObject.open = open;
    dispatch(showSnackBar(snackbarObject));
  };
  const handleContactUs = (e) => {
    e.stopPropagation();
    if (email === '') {
      dispatchSnackBar('error', 'Please enter Email', true);
    }
    else {
      dispatchSnackBar('success', 'Your information is submitted')
      setEmail('');
    }
};
  return (
    <section style={{ height: 'auto', backgroundColor: '#12284C', padding: '3%', position:'relative', zIndex:'1'}}>
      <Grid container alignItems='center' justifyContent='space-between'>
        <Grid item xs={2.8}>
          <FooterContentContainer>
          <Link to="/">
            <NavbarLogo src={logo} alt="logo" />
          </Link>
            <Typography sx={{
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: '300',
              fontSize: '14px',
              lineHeight: '17px',
              color: '#FFFFFF',
              
            }}>
              {t('Terms & Conditions')} / <a href = '/privacy' style={{fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: '300',
              fontSize: '14px',
              lineHeight: '17px',
              color: '#FFFFFF',
              textDecoration:'underline'}}>{t('Privacy Policy')}</a> / {t('Sitemap© 2024. Modern Living. All rights reserved.')}
            </Typography>
          </FooterContentContainer>
        </Grid>
        <Grid item xs={2.5}>
          <FooterContentContainer >
            <Typography sx={{
              ...myText,
              marginTop:'10px',
            }}>{t('Menu')}</Typography>



           <Link to = '/properties/buy'> <Typography sx={{
              ...myText,
            }} >
              {t('Properties')}
              
            </Typography></Link>
            <Link to = '/projects/offplan'>
            <Typography sx={{
              ...myText,
            }} className='footer_menu_text'>
              PROJECTS
              
            </Typography>
            </Link>
            <Link to = '/realestate'>
            <Typography sx={{
              ...myText,
            }} className='footer_menu_text'>
              {t('Business Services')}
              
            </Typography>
            </Link>
            <Link to = '/about'>
            <Typography sx={{
              ...myText,
            }} className='footer_menu_text'>
              {t('Company')}
              
            </Typography>
            </Link>
            <Link to = '/press_release'>
            <Typography sx={{
              ...myText,
            }}>
              {t('Press Center')}
              
            </Typography>
            </Link>
          </FooterContentContainer>
        </Grid>
        <Grid item xs={2.1}>
          <FooterContentContainer>
            <Typography sx={{ ...myText,marginTop:'10px', }}>
              {t('CONTACTS')}
            </Typography>
            <div>
            <Typography sx={{
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '14px',
              lineHeight: '17px',
              color: '#FFFFFF'
            }}>
              {t('Address')}
            </Typography>
            <Typography sx={{
              marginTop:'10px',
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: '300',
              fontSize: '14px',
              lineHeight: '17px',
              color: '#FFFFFF'
            }}>
              Office 101 C, The Opus, Business Bay, Dubai - UAE
            </Typography>
            </div>
            <div>
            <Typography sx={{
              marginTop:'10px',
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '14px',
              lineHeight: '17px',
              color: '#FFFFFF'
            }}>
              {t('Telephone')}
            </Typography>
            <a href='tel:+971502731297'><Typography sx={{
              marginTop:'10px',
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: '300',
              fontSize: '14px',
              lineHeight: '17px',
              color: '#FFFFFF'
            }}>
              +971 502 731297
            </Typography>
            </a>
            </div>
            <div>
            <Typography sx={{
              marginTop:'10px',
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '14px',
              lineHeight: '17px',
              color: '#FFFFFF'
            }}>
              {t('Whatsapp')}
            </Typography>
            <a href='http://wa.me/971502731297'><Typography sx={{
              marginTop:'10px',
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: '300',
              fontSize: '14px',
              lineHeight: '17px',
              color: '#FFFFFF'
            }}>
              +971 502 731297
            </Typography>
            </a>
            </div>
            <div>
            <Typography sx={{
              marginTop:'22px',
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '14px',
              lineHeight: '17px',
              color: '#FFFFFF'
            }}>
              {t('Email')}
            </Typography>
            <a href='mailto:info@modern-living.ae'><Typography sx={{
              marginTop:'10px',
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: '300',
              fontSize: '14px',
              lineHeight: '17px',
              color: '#FFFFFF'
            }}>
              info@modern-living.ae
            </Typography></a>
            </div>
          </FooterContentContainer>
        </Grid>
        <Grid item xs={3}>
          <FooterContentContainer>
            <Typography sx={{ ...myText,marginTop:'10px', }}>
              {t('GET IN TOUCH')}
            </Typography>
            <div>
            <Typography sx={{
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '14px',
              lineHeight: '17px',
              color: '#FFFFFF'
            }}>
              {t('Subscribe to our newsletter')}
            </Typography>
            <div className='footer_input'>
            <InputText value={email} placeholder={t('YOUR EMAIL')} onChange={(e)=>setEmail(e.target.value)}/>
            <FooterSubmitButton onClick={(e) => handleContactUs(e)}>{t('SUBMIT')}</FooterSubmitButton>
            </div>
            </div>
            <div style = {{marginTop:'60px',}}>
            <Typography sx={{
            
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '14px',
              lineHeight: '17px',
              color: '#FFFFFF'
            }}>
              {t('Join us on social media')}
            </Typography>
            <div className='socialmediaicons'>
              <a href='https://www.facebook.com/modernlivingrealestatedubai'><img src={FacebookIcon} alt='F' className='scicon'/></a>
              <a href='https://www.linkedin.com/company/modern-living-real-estate-dubai'><img src={LinkedinIcon} alt='F' className='scicon'/></a>
              <a href='https://www.youtube.com/@modernlivingrealestatedubai'><img src={YoutubeIcon} alt='F' className='scicon'/></a>
              <a href='https://www.instagram.com/modernliving.ae/?hl=en'><img src={InstaIcon} alt='F' className='scicon'/></a>
            </div>
            </div>
          </FooterContentContainer>
        </Grid>

      </Grid>
    </section>
  )
}

export default Footer