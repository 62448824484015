import styled from "styled-components";

export const WhyInvestSection = styled.section`
position:relative;
width:100%;
background-image: ${(props) => `url(${props.img})`};
padding:100px 0px;
`
export const WhyInvestContentContainer = styled.div`
width:90%;
margin:auto;
position:relative;
  h1 {
    font-family: "Didot";
    font-style: normal;
    letter-spacing: 0.03em;
    color: #ece1ce;
    position:relative;
    z-index:999;
    @media only screen and (min-width: 1900px) {
      font-weight: 400;
      font-size: 64px;
      line-height: 127%;
    }
    @media only screen and (min-width: 1300px) and (max-width:1900px) {
      font-weight: 400;
      font-size: 49.9038px;
      line-height: 127%;
    }
    @media only screen and (min-width: 900px) and (max-width:1300px) {
      font-weight: 400;
      font-size: 32px;
      line-height: 127%;
    }
    @media only screen and (max-width: 900px) {
      font-weight: 400;
      font-size: 26px;
      line-height: 127%;
    }
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    color: #ffffff;
    position:relative;
    z-index:999;
    @media only screen and (min-width: 1900px) {
      font-weight: 300;
      font-size: 20px;
      line-height: 146%;
      margin-top:77px;
    }
    @media only screen and (min-width: 1300px) and (max-width:1900px) {
      font-weight: 300;
      font-size: 20px;
      line-height: 146%;
      margin-top:52.46px;
    }
    @media only screen and (min-width: 900px) and (max-width:1300px) {
      font-weight: 300;
      font-size: 15.3377px;
      line-height: 146%;
      margin-top:37px;
    }
    @media only screen and (max-width: 900px) {
      font-weight: 300;
      font-size: 13px;
      line-height: 146%;
      margin-top:31px;
    }
  }
`;

export const StepContainer = styled.div`
    display:flex;
    gap:15px;
    width:100%;
    flex-wrap:wrap;
`
export const SingleStepContainer = styled.div`
    display:flex;
    align-items:center;
    gap:15px;
    margin-top:57px;
    @media only screen and (min-width: 900px){
        width:45%;
    }
    @media only screen and (max-width: 900px){
        min-width:100%;
    }
    h1{
        min-width:fit-content;
        font-family: 'Didot';
font-style: normal;
letter-spacing: 0.03em;
color: #ECE1CE;
z-index:999;
@media only screen and (min-width: 1900px) {
    font-weight: 400;
font-size: 35px;
line-height: 127%;
    }
@media only screen and (min-width: 1300px) and (max-width:1900px) {
    font-weight: 400;
font-size: 30px;
line-height: 127%;
    }
@media only screen and (min-width: 900px) and (max-width:1300px) {
    font-weight: 400;
font-size: 25px;
line-height: 127%;
    }
@media only screen and (max-width: 900px) {
    font-weight: 400;
font-size: 20px;
line-height: 127%;
    }
    }
    h4{
        font-family: 'Montserrat';
font-style: normal;
color: #FFFFFF;
z-index:999;
@media only screen and (min-width: 1900px) {
    font-weight: 300;
font-size: 20px;
line-height: 146%;
    }
@media only screen and (min-width: 1300px) and (max-width:1900px) {
    font-weight: 300;
font-size: 15.5949px;
line-height: 146%;
    }
@media only screen and (min-width: 900px) and (max-width:1300px){
    font-weight: 300;
font-size: 11.9595px;
line-height: 146%;
    }
@media only screen and (max-width: 900px) {
    font-weight: 300;
font-size: 11.9595px;
line-height: 146%;
    }
    }
`