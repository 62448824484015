import styled from "styled-components";
import ProjectImage from "../../assets/images/projecthero.png";

// Project Hero
export const ProjectImageDiv = styled.div`
  background-image: url(${ProjectImage});
  width: 100%;
  height: auto;
`;

export const MainHeadingProject = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: Didot;
  font-style: normal;
  color: #ffffff;
  overflow-x: visible;

  @media only screen and (min-width: 1900px) {
    padding-top: 300px;
    font-weight: 400;
    font-size: 99px;
    line-height: 125.14px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    padding-top: 270px;
    font-weight: 400;
    font-size: 64px;
    line-height: 81px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    padding-top: 220px;
    font-weight: 400;
    font-size: 42px;
    line-height: 53px;
  }
  @media only screen and (max-width: 900px) {
    padding-top: 200px;
    font-weight: 400;
    font-size: 30px;
    line-height: 33px;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    padding-top: 220px;
    font-weight: 400;
    font-size: 42px;
    line-height: 53px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    padding-top: 220px;
    font-weight: 400;
    font-size: 42px;
    line-height: 53px;
  }
`;

export const Para = styled.p`
  overflow: visible;
`;

export const HeroPrimaryButtons = styled.div`
  display: flex;
  overflow: visible;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 15px;
  margin: auto;
  align-items: center;
  width: 80%;
  padding-top: 50px;
  @media only screen and (min-width: 1900px) {
    row-gap: 20px;
    padding-bottom: 50px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    row-gap: 20px;
    padding-bottom: 50px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    row-gap: 15px;
    padding-bottom: 50px;
  }
  @media only screen and (max-width: 900px) {
    row-gap: 15px;
    padding-bottom: 277px;
  }
`;

export const Preferences = styled.div`
  display: flex;
  justify-content: center;
  @media only screen and (min-width: 1900px) {
    padding-top: 66.3px;
    padding-bottom: 279px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    padding-top: 37px;
    padding-bottom: 213px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    padding-top: 43.37px;
    padding-bottom: 141px;
  }
`;

export const Link = styled.a`
  color: #ece1ce;
`;

export const ParaTwo = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.04em;
  color: #ffffff;
`;

// Project About

export const BackGround = styled.div`
  background-color: rgba(238, 242, 244, 0.7);
`;

export const AboutHead = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 85%;
  color: #000000;
  @media only screen and (min-width: 1900px) {
    padding-top: 233px;
    padding-bottom: 233px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    padding-top: 142px;
    padding-bottom: 142px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  @media only screen and (max-width: 900px) {
    padding-top: 52px;
    padding-bottom: 52px;
  }
`;

export const AboutHeading = styled.div`
  font-family: Didot;
  font-style: normal;
  font-weight: 400;

  @media only screen and (min-width: 1900px) {
    font-size: 64px;
    line-height: 146%;
    padding-bottom: 47px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    font-size: 64px;
    line-height: 146%;
    padding-bottom: 47px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 42px;
    line-height: 146%;
    padding-bottom: 61px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 30px;
    line-height: 146%;
    padding-bottom: 30px;
  }
`;

export const AboutParagraph = styled.div`
  line-height: 146%;
  font-family: Montserrat;
  font-weight: 400;
  text-align: justify;
  text-justify: inter-word;
  @media only screen and (min-width: 1900px) {
    font-size: 20px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    font-size: 20px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 13px;
  }
`;

export const ProjectPage = styled.div`
  margin: auto;
  background-color: rgba(238, 242, 244, 0.5) !important;
  justify-content: center;
  @media only screen and (min-width: 1900px) {
    padding-bottom: 150px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    padding-bottom: 100px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    padding-bottom: 50px;
  }
  @media only screen and (max-width: 900px) {
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const SingleButtonContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background: rgba(18, 40, 76, 0.22);
  border: 0.75px solid #decdad;
  backdrop-filter: blur(10px);
  border-radius: 34px;
  transition: 0.3s;
  color: #ffffff;
  overflow: hidden;
  @media only screen and (min-width: 1900px) {
    height: 57.45px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    height: 45px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    height: 43px;
  }
  @media only screen and (max-width: 900px) {
    height: 43px;
  }
`;

export const CommunityButton = styled(SingleButtonContainer)`
  @media only screen and (min-width: 1900px) {
    width: 443px;
    gap: 24px;
    padding: 10px 20px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    width: 365px;
    gap: 22px;
    padding: 10px 18px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 365px;
    gap: 20px;
    padding: 10px 17px;
  }
  @media only screen and (max-width: 900px) {
    width: 270px;
    gap: 12px;
    padding: 10px 15px;
  }
`;

export const DropDownWrapper = styled.div`
  position: relative;
  overflow: visible;
`;

export const UnitButton = styled(SingleButtonContainer)`
  justify-content: space-between;
  padding: 10px 20px;
  cursor: pointer;
  @media only screen and (min-width: 1900px) {
    width: 262px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    width: 217.83px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 217.83px;
  }
  @media only screen and (max-width: 900px) {
    width: 145px;
  }
`;

export const DropdownContainerUnit = styled(UnitButton)`
  display: ${(props) => (props.unitActive ? "block" : "none")};
  margin-top: 5px;
  position: absolute;
  left: 0;
  z-index: 99;
  padding: 0px;
  border-radius: 23px;
  height: auto;
  /* @media (min-width: 1900px) {
    height: 191px;
  }
  @media ((min-width: 1300px) and (max-width:1900px)) {
    height: 178px;
  }
  @media ((min-width: 900px) and (max-width:1300px)) {
    height: 167px;
  }
  @media (max-width: 900px) {
    height: 160px;
  } */
`;

export const BedButton = styled(SingleButtonContainer)`
  padding: 10px 20px;
  justify-content: space-between;
  @media only screen and (min-width: 1900px) {
    width: 178px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    width: 146px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 146px;
  }
  @media only screen and (max-width: 900px) {
    width: 148px;
  }
`;
export const DropdownContainerBeds = styled(BedButton)`
  display: ${(props) => (props.bedActive ? "block" : "none")};
  margin-top: 5px;
  position: absolute;
  left: 0;
  z-index: 99;
  padding: 0px;
  border-radius: 23px;
  @media only screen and (min-width: 1900px) {
    height: 191px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    height: 178px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    height: 167px;
  }
  @media only screen and (max-width: 900px) {
    height: 160px;
  }
`;

export const PriceButton = styled(SingleButtonContainer)`
  padding: 10px 20px;
  justify-content: space-between;
  cursor: pointer;
  @media only screen and (min-width: 1900px) {
    width: 178px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    width: 145px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 145px;
  }
  @media only screen and (max-width: 900px) {
    width: 139px;
  }
`;
export const DropdownContainerPrice = styled(PriceButton)`
  display: ${(props) => (props.priceActive ? "block" : "none")};
  margin-top: 5px;
  position: absolute;
  left: 0;
  z-index: 99;
  padding: 0px;
  border-radius: 23px;
  height: auto;
`;

export const FAQContainer = styled.div`
  background-color: rgba(238, 242, 244, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
  @media only screen and (max-width: 900px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const FAQHeading = styled.h1`
  font-family: "Didot";
  display: flex;
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  line-height: 146%;
  color: #000000;
  @media only screen and (min-width: 1900px) {
    font-size: 64px;
    padding-bottom: 111px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    font-size: 64px;
    padding-bottom: 111px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 42px;
    padding-bottom: 63px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 30px;
    padding-bottom: 52px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  border: ${(props) => (props.active ? "1px solid black" : "none")};
  flex-direction: column;
  align-items: center;
  padding: 20px 20px 20px 20px;
  background: #ffffff;
  box-shadow: 0px 0px 32px 13px rgba(230, 230, 230, 0.2);
  border-radius: 31px;
  margin-bottom: 50px;
  @media only screen and (min-width: 1900px) {
    width: 896px;
    margin-bottom: 50px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    width: 896px;
    margin-bottom: 50px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 896px;
    margin-bottom: 30px;
  }
  @media only screen and (max-width: 900px) {
    width: 349px;
    margin-bottom: 20px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 600px;
    margin-bottom: 30px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 896px;
    margin-bottom: 30px;
  }
`;

export const QuestionPart = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media only screen and (min-width: 1900px) {
    align-items: center;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    align-items: center;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    align-items: center;
  }
`;

export const QuestionMarkImage = styled.img`
  width: 20.83px;
  @media only screen and (max-width: 900px) {
    height: 20.83px;
  }
`;

export const AllQuestions = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  line-height: 146%;
  color: #000000;
  font-size: 20px;
  cursor: pointer;
  @media only screen and (max-width: 900px) {
    font-size: 14px;
  }
`;

export const AllAnswers = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 146%;
  padding-top: 23px;
  color: #000000;
  text-align: justify;
  text-justify: inter-word;
  @media only screen and (max-width: 900px) {
    font-size: 13px;
  }
`;

export const QuestionContainer = styled.div`
  display: flex;
  width: 90%;
  margin: auto;
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 80%;
  }
  @media only screen and (max-width: 900px) {
    width: 80%;
  }
`;

export const PlusSign = styled.span`
  cursor: pointer;
`;

export const ProjectSection = styled.section`
  height: auto;
  background-color: rgba(238, 242, 244, 0.7);
`;

export const ButtonText = styled.button`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #000000;
  @media only screen and (min-width: 1367px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 1204px) and (max-width: 1366px) {
    font-size: 13px;
  }
  @media only screen and (max-width: 1204px) {
    font-size: 15px;
  }
`;


export const FilterButtonLoad = styled(ButtonText)`
  border: 0.75px solid rgba(18, 40, 76, 1);
  border-radius: 90px;
  display: flex;
  align-items: center;
  color: #ffffff;
  overflow: hidden;
  transition: 0.3s;
  background: rgba(18, 40, 76, 1);
  min-width: fit-content;
  @media only screen and (min-width: 1367px) {
    height: 40px;
    padding: 10px 40px;
    gap: 24px;
  }
  @media only screen and (max-width: 1366px) {
    height: 33px;
    padding: 7px 30px;
    gap: 20px;
  }
  &:hover {
    border: 0.75px solid #000000;
  }
  &:focus {
    border: 0.75px solid #000000;
  }
`;
