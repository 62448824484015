import React from "react";
import { Link } from "react-router-dom";
import HeroIcon from "../../assets/images/companyheroicon.svg";
import aboutImage from "../../assets/images/aboutus.jpeg";
import {
  CompanyHeroContentContainer,
  CompanyHeroSection,
  HeroButton,
  HeroHeaderText,
  HeroSubText,
  Overlay,
} from "./Hero.styled";
import { useTranslation } from "react-i18next";

const HeroAboutUs = ({header, desc}) => {
  const { t } = useTranslation();
  return (
    <CompanyHeroSection img={aboutImage}>
      <Overlay/>
      <CompanyHeroContentContainer>
        <HeroHeaderText>{t(header)}</HeroHeaderText>
        <HeroSubText>{t(desc)}</HeroSubText>
        <Link to = '/contactus'>
        <HeroButton>
          <img src={HeroIcon} alt=" " /> <h3>{t('CONTACT US')}</h3>
        </HeroButton>
        </Link>
      </CompanyHeroContentContainer>
    </CompanyHeroSection>
  );
};

export default HeroAboutUs;
