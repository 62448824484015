import axios from "axios";
import API_URL from "../constant";


export function requestGetDevelopers(data) {
  const url = `${API_URL}developers?${data && `filters[developer_name][$startsWith]=${data}`}&pagination[pageSize]=5&populate=*`;
  return axios.request({
    method: "get",
    url: url,
  });
}

export function requestGetRelDevelopers(data) {
  const url = `${API_URL}developers?filters[developer_name][$containsi]=${data}&pagination[pageSize]=5&populate=*`;
  return axios.request({
    method: "get",
    url: url,
  });
}