import React, { useState } from "react";
import SubscribeImage from "../../assets/images/subscribe.png";
import validator from "validator";
import {
  BoxStyle,
  EmailError,
  HeadingMain,
  ImageStyle,
  InputEmail,
  ParaMain,
  SendButton,
  UpdatesBox,
  UpdatesContainer,
  ParaDown, 
  InputContainerBrochuer,
  InLine
} from "./subscribe.styled";
import { AiOutlineClose } from "react-icons/ai";
import {BsFillEyeFill} from "react-icons/bs";
import { useDispatch } from "react-redux";
import { postContactUs } from "../../redux/slices/contactUs/contactUsSlices";

const SubscribePopUp = ({setOpen}) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const dispatch = useDispatch();

  const contactUsObject = {
    full_name: name,
    email: email,
    phone: phoneNumber,
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleClick = async () => {
    if (!validator.isEmail(email)) {
      setEmailError("Please enter your email address");
      setNameError("");
      setPhoneNumberError("");
    } else if (!phoneNumber) {
      setPhoneNumberError("Please enter your phone");
      setEmailError("");
      setNameError("");
    } else if (!name) {
      setNameError("Please enter your name");
      setEmailError("");
      setPhoneNumberError("");
    } else {
      setEmailError("");
      setNameError("");
      setPhoneNumberError("");
      dispatch(postContactUs(contactUsObject));
      handleClose();
    }
  };


  return (
    <>
      <BoxStyle>
        <ImageStyle src={SubscribeImage} alt=""></ImageStyle>
        <AiOutlineClose size={30} style={{position:'absolute', right:'10px', top:'10px'}} onClick={handleClose}/>
        <UpdatesBox>
          <UpdatesContainer>
            <HeadingMain>Get updates on new & hot listings!</HeadingMain>
            <ParaMain>
            Like 10,000+ subscribers, stay up to date with the latest
              developments in real estate
            </ParaMain>
            {emailError && <EmailError>{emailError} </EmailError>}
            {nameError && <EmailError>{nameError}</EmailError>}
            {phoneNumberError && <EmailError>{phoneNumberError}</EmailError>}
            <InputContainerBrochuer>
              <InputEmail
                placeholder="Name"
                type="text"
                id="name"
                name="name"
                onChange={handleNameChange}
              />
              <InputEmail
                filterprop={emailError ? "error" : ""}
                placeholder="Email"
                type="text"
                id="email"
                name="email"
                onChange={handleChange}
                value={email}
              />
              <InputEmail
                placeholder="Phone number"
                type="text"
                id="phoneNumber"
                name="phoneNumber"
                onChange={handlePhoneNumberChange}
                value={phoneNumber}
                required
              />
              <SendButton onClick={handleClick}>Subscribe</SendButton>
            </InputContainerBrochuer>
            <InLine>
            <BsFillEyeFill size={20} color="grey" /> <ParaDown>
            Modern Living and Business Partners use your data for marketing
            purposes. You can unsubscribe at any time free of charge
          </ParaDown>
          </InLine>
          </UpdatesContainer>
        </UpdatesBox>
      </BoxStyle>
    </>
  );
};

export default SubscribePopUp;
