import styled from "styled-components";
import { ButtonText } from "../PropertyListing.js/PropertyListing.styled";

export const PreferenceSection = styled.div`
position:relative;
  /* height: auto; */
 @media only screen and (min-width: 1920px) {
  display: flex;
    margin-top: 102px;
   height:1400px;
  }
 @media only screen and (min-width: 1300px) and (max-width:1920px){
  display: flex;
    margin-top: 90px;
    height:1400px;
  }
 @media only screen and (min-width: 900px) and (max-width:1300px){
  margin-top: 90px;
    margin-bottom: 89px;
  }
 @media only screen and (max-width: 900px) {
  margin-top: 90px;
    margin-bottom: 75px;
  }
 @media only screen and (max-width: 400px) {
  margin-top: 90px;
    margin-bottom: 75px;
  }
 @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
  margin-top: 90px;
    margin-bottom: 89px;
  }
 @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
  margin-top: 90px;
    margin-bottom: 89px;
  }
`;
export const PreferenceScreenText = styled.h1`
  font-family: "Didot";
  font-style: normal;
  color: #12284c;
  @media only screen and (min-width: 1920px) {
    font-weight: 400;
    font-size: 48px;
    line-height: 61px;
    text-align:center;
    }
  @media only screen and (min-width: 1300px) and (max-width:1920px){
    font-weight: 400;
    font-size: 45px;
    line-height: 61px;
    text-align:center;
    }
  @media only screen and (min-width: 900px) and (max-width:1300px){
    font-weight: 400;
    font-size: 42px;
    line-height: 61px;
    }
  @media only screen and (max-width: 900px) {
    font-weight: 400;
    font-size: 35px;
    line-height: 61px;
    text-align: center;
    }
  @media only screen and (max-width: 400px) {
    font-weight: 400;
    font-size: 35px;
    line-height: 61px;
    text-align: center;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 400;
    font-size: 42px;
    line-height: 61px;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 400;
    font-size: 42px;
    line-height: 61px;
    }
`;
export const PreferenceHeaderText = styled.h1`
  text-align: ${(props) => props.align};
  font-family: "Didot";
  font-style: normal;
  color: #000000;
  @media only screen and (min-width: 1920px) {
    font-weight: 400;
    font-size: 48px;
    line-height: 61px;
    }
  @media only screen and (min-width: 1300px) and (max-width:1920px){
    font-weight: 400;
    font-size: 48px;
    line-height: 61px;
    }
  @media only screen and (min-width: 900px) and (max-width:1300px){
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    }
  @media only screen and (max-width: 900px) {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    }
  @media only screen and (max-width: 400px) {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    }
`;

export const PreferenceSubtext = styled.p`
  text-align: ${(props) => props.align};
  font-family: "Montserrat";
  font-style: normal;
  letter-spacing: 0.03em;
  color: #000000;
  @media only screen and (min-width: 1920px) {
    font-weight: 300;
    font-size: 26px;
    line-height: 127%;
    margin-top: 71px;
    }
  @media only screen and (min-width: 1300px) and (max-width:1920px){
    font-weight: 300;
    font-size: 26px;
    line-height: 127%;
    margin-top: 71px;
    }
  @media only screen and (min-width: 900px) and (max-width:1300px){
    font-weight: 300;
    font-size: 22px;
    line-height: 127%;
    margin-top: 71px;
    }
  @media only screen and (max-width: 900px) {
    font-weight: 300;
    font-size: 16px;
    line-height: 127%;
    margin-top: 41px;
    text-align: center;
    }
  @media only screen and (max-width: 400px) {
    font-weight: 300;
    font-size: 16px;
    line-height: 127%;
    margin-top: 41px;
    text-align: center;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 300;
    font-size: 22px;
    line-height: 127%;
    margin-top: 71px;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 300;
    font-size: 22px;
    line-height: 127%;
    margin-top: 71px;
    }
`;

export const ScreenDiv = styled.div`
  position: relative;
  background: rgba(236, 225, 206, 0.26);
  @media only screen and (min-width: 1920px) {
    width: 50%;
    height: 100%;
    }
  @media only screen and (min-width: 1300px) and (max-width:1920px){
    width: 50%;
    height:100%;
    }
  @media only screen and (min-width: 900px) and (max-width:1300px){
    padding-top:50px;
    padding-bottom:50px;
    width: 100%;
    height: auto;
    }
  @media only screen and (max-width: 900px) {
    padding-top:50px;
    padding-bottom:50px;
    width: 100%;
    height: auto;
    }
  @media only screen and (max-width: 400px) {
    padding-top:50px;
    padding-bottom:50px;
    width: 100%;
    height: auto;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    padding-top:50px;
    padding-bottom:50px;
    width: 100%;
    height: auto;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    padding-top:50px;
    padding-bottom:50px;
    width: 100%;
    height: auto;
    }
  `

  export const VideoDiv = styled.div`
   
  @media only screen and (min-width: 1900px) {
      width:${props=>props.position === true ? '100%' : '50%'};
    margin:auto;
    position:${props=>props.position === true ? 'relative' : 'fixed'};
    top:${props=>props.position === true ? `${props.distance}px` : '50%'};
    left:0;
    transform: translateY(-50%);
    }
  @media only screen and (min-width: 1300px) and (max-width:1900px){
    width:${props=>props.position === true ? '100%' : '50%'};
    margin:auto;
    position:${props=>props.position === true ? 'relative' : 'fixed'};
    top: ${props => props.position === true ? `${props.distance}px` : '50%'};
    left:0;
    transform:  translateY(-50%);
    }
  @media only screen and (min-width: 900px) and (max-width:1300px){
    width: 90%;
      height:auto;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  @media only screen and (max-width: 900px) {
    width: 90%;
      margin: auto;
    }
  @media only screen and (max-width: 400px) {
    width: 90%;
      margin: auto;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 90%;
      height:auto;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 90%;
      height:auto;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  
`;

export const PreferenceContentContainer = styled.div`
 @media only screen and (min-width: 1920px) {
  width: 50%;

  }
 @media only screen and (min-width: 1300px) and (max-width:1920px){
  width: 50%;
  
  }
 @media only screen and (min-width: 900px) and (max-width:1300px){
  width: 100%;
  }
 @media only screen and (max-width: 900px) {
  width: 100%;
  }
 @media only screen and (max-width: 400px) {
  width: 100%;
  }
 @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
  width: 100%;
  }
 @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
  width: 100%;
  }
`;
export const PreferenceContentDiv = styled.div`
  margin: auto;
  @media only screen and (min-width: 1920px) {
    width: 85%;
    margin-top: 122px;
    }
  @media only screen and (min-width: 1300px) and (max-width:1920px){
    width: 90%;
    margin-top: 139px;
    }
  @media only screen and (min-width: 900px) and (max-width:1300px){
    width: 90%;
    margin-top: 81px;
    }
  @media only screen and (max-width: 900px) {
    width: 90%;
    margin-top: 41px;
    }
  @media only screen and (max-width: 400px) {
    width: 90%;
    margin-top: 41px;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 90%;
    margin-top: 81px;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 90%;
    margin-top: 81px;
    }
`;
export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${(props) => (props.center === "center" ? "center" : "")};
  @media only screen and (min-width: 1920px) {
    gap: 28px;
    margin-top: 40px;
    }
  @media only screen and (min-width: 1300px) and (max-width:1920px){
    gap: 28px;
    margin-top: 40px;
    }
  @media only screen and (min-width: 900px) and (max-width:1300px){
    gap: 28px;
    margin-top: 40px;
    }
  @media only screen and (max-width: 900px) {
    gap: 2.5%;
    margin-top: 28px;
    }
  @media only screen and (max-width: 400px) {
    gap: 2.5%;
    margin-top: 28px;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    gap: 28px;
    margin-top: 40px;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    gap: 28px;
    margin-top: 40px;
    }
`;
export const PreferenceButton = styled(ButtonText)`
  border: ${props=>props.active?'transparent':'0.75px solid #000000'};
  border-radius: 30px;
  background: ${props=>props.active?'#004876':'transparent'};
  color:${props=>props.active ? '#ffffff' : '#000000'};
  &:hover {
    background: #004876;
    color: #ffffff;
    border:transparent;
  }
  @media only screen and (min-width: 900px) {
    width: 127px;
    height: 40px;
  }

  @media only screen and (max-width: 900px) {
    min-width: 31.66%;
    height: 31px;
  }
`;
export const InputText = styled.input`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 127%;
  letter-spacing: 0.03em;
  background: none;
  width: 100%;
  border: none;
  color: #000000;
  &::placeholder {
    color: #000000;
  }
`;

export const InputTextBox = styled.button`
text-align:left;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 127%;
  letter-spacing: 0.03em;
  background: none;
  width: 100%;
  border: none;
  color: #000000;
`

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: ${props=>props.border};
  border-radius: 40px;
  @media only screen and (min-width: 1920px) {
    height: 56px;
    padding: 0px 29px;
    margin-top: 20px !important;
    }
  @media only screen and (min-width: 1300px) and (max-width:1920px){
    height: 56px;
    padding: 0px 29px;
    margin-top: 20px !important;
    }
  @media only screen and (min-width: 900px) and (max-width:1300px){
    height: 58.72px;
    padding: 0px 29px;
    margin-top: 20px !important;
    }
  @media only screen and (max-width: 900px) {
    height: 31px;
    padding: 0px 21px;
    margin-top: 16px !important;
    }
  @media only screen and (max-width: 400px) {
    height: 31px;
    padding: 0px 21px;
    margin-top: 16px !important;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    height: 58.72px;
    padding: 0px 29px;
    margin-top: 20px !important;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    height: 58.72px;
    padding: 0px 29px;
    margin-top: 20px !important;
    }
`;

export const DropdownDiv = styled.div`
width:100%;
  position:relative;
  overflow:visible;
`
export const DropdownContainer = styled.div`
  position:absolute;
  border: 0.5px solid #12284c;
  border-radius: 30px;
  width: 100%;
  background:#FFFFFF;
  display:${props=>props.active ? 'flex': 'none'};
  flex-direction:column;
  gap:20px;
  z-index:10;
  @media only screen and (min-width: 900px) {
    padding:20px 29px;
    }
  @media only screen and (max-width: 900px) {
    padding:20px 21px;
    }
`
export const InputFlexDiv = styled.div`
  display: flex;
  gap: 2%;
  margin-top: 0px !important;
  overflow:visible;
  @media only screen and (min-width: 900px) {
   
    flex-wrap: nowrap;
  }
  @media only screen and (max-width: 900px) {
    flex-wrap: wrap;
    
  }
`;
export const InputFlexButton = styled(InputContainer)`
@media only screen and (min-width: 900px) {
  
  width: 100%;
  }
@media (max-width: 900px) {
  
  width: 100%;
  }
`;
export const MessageDiv = styled(InputContainer)`
  align-items: flex-start;
  border-radius: 30px;
  overflow: hidden;
  @media only screen and (min-width: 1920px) {
    height: 374px;
    padding: 27px 29px;
    }
  @media only screen and (min-width: 1300px) and (max-width:1920px){
    height: 374px;
    padding: 27px 29px;
    }
  @media only screen and (min-width: 900px) and (max-width:1300px){
    height: 392px;
    padding: 27px 29px;
    }
  @media only screen and (max-width: 900px) {
    height: 210px;
    padding: 20px 27px;
    }
  @media only screen and (max-width: 400px) {
    height: 210px;
    padding: 20px 27px;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    height: 392px;
    padding: 27px 29px;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    height: 392px;
    padding: 27px 29px;
    }
`;
export const MessageInput = styled.textarea`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 151.5%;
  letter-spacing: 0.03em;
  color: #000000;
  width: 100%;
  border: none;
  &::placeholder {
    color: #000000;
  }
`;
export const InputCheckbox = styled.label`
display:inline-block;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 151.5%;
  letter-spacing: 0.03em;
  color: #000000;
  &::placeholder {
    color: #000000;
  }
  @media only screen and (min-width: 900px) {
    margin-top: 40px;
  }
  @media only screen and (max-width: 900px) {
    margin-top: 29px;
  }
`
export const SellCheckbox = styled(InputCheckbox)`
@media only screen and (min-width: 900px) {
    margin-top: 20px !important;
  }
  @media only screen and (max-width: 900px) {
    margin-top: 16px !important;
  }
`
export const PrefCtaButton = styled.button`
 width: 100%;
  font-family: "Montserrat";
  font-style: normal;
  letter-spacing: 0.125em;
  color: #ffffff;
  display: flex;
  align-items: center;
  background: #12284c;
  border-radius: 90px;
  justify-content: center;
  @media only screen and (min-width: 1920px) {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* width: 330px; */
    margin-top: 50px;
    height: 40px;
    }
  @media only screen and (min-width: 1300px) and (max-width:1920px){
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* width: 330px; */
    margin-top: 50px;
    height: 40px;
    }
  @media only screen and (min-width: 900px) and (max-width:1300px){
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* width: 330px; */
    margin-top: 45px;
    height: 40px;
    }
  @media only screen and (max-width: 900px) {
     /* width: 100%; */
     font-weight: 500;
    font-size: 12.2219px;
    line-height: 15px;
    margin-top: 30px;
    height: 30px;
    }
  @media only screen and (max-width: 400px) {
     /* width: 100%; */
     font-weight: 500;
    font-size: 12.2219px;
    line-height: 15px;
    margin-top: 30px;
    height: 30px;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* width: 330px; */
    margin-top: 45px;
    height: 40px;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* width: 330px; */
    margin-top: 45px;
    height: 40px;
    }
`;

export const MacImage = styled.video`
object-fit:cover;
  @media only screen and (min-width: 1920px) {
    width: 95% !important;
    height: auto !important;
    margin:auto;
    margin-top: 82px;
    display:block;
    }
  @media only screen and (min-width: 1300px) and (max-width:1920px){
    width: min(600px,80%) !important;
    height: auto !important;
    margin:auto;
    margin-top: 20px;
    display:block;
    }
  @media only screen and (min-width: 900px) and (max-width:1300px){
    width: 536px !important;
    height: auto !important;
    }
  @media only screen and (max-width: 900px) {
    width: 90% !important;
    height: auto !important;
    margin: auto;
    display: block;
    margin-top: 52px;
    }
  @media only screen and (max-width: 400px) {
    width: 90% !important;
    height: auto !important;
    margin: auto;
    display: block;
    margin-top: 52px;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 536px !important;
    height: auto !important;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 536px !important;
    height: auto !important;
    }
`;
export const SellSection = styled.section`
  width: 80%;
  margin: auto;
  @media only screen and (min-width: 1920px) {
    padding-top: 250px;
    padding-bottom: 205px;
    }
  @media only screen and (min-width: 1300px) and (max-width:1920px){
    padding-top: 163px;
    padding-bottom: 104px;
    }
  @media only screen and (min-width: 900px) and (max-width:1300px){
    padding-top: 146px;
    padding-bottom: 168px;
    }
  @media only screen and (max-width: 900px) {
    padding-top: 112px;
    padding-bottom: 116px;
    }
  @media only screen and (max-width: 400px) {
    padding-top: 112px;
    padding-bottom: 116px;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    padding-top: 146px;
    padding-bottom: 168px;
    }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    padding-top: 146px;
    padding-bottom: 168px;
    }
`;
export const InputDropdownDiv = styled(InputContainer)`
  @media only screen and (max-width: 900px) {
    min-width: 32%;
  }
  @media only screen and (max-width: 900px) {
    min-width: 100%;
  }
`;
export const SellCheckContainer = styled.div`
  display: flex;
  row-gap: 0px;
  column-gap:30px;
  width: 100%;
  flex-wrap: wrap;
  align-content:flex-start
`;


