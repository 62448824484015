import * as React from "react";
import { useEffect} from "react";
import PaginationItem from "@mui/material/PaginationItem";
import Pagination from "@mui/material/Pagination";
import { PaginationCard } from "./propertylisting.styled";
import { getIndex } from "../../redux/slices/propertiesSlices/getProperties";
import { useDispatch, useSelector } from "react-redux";
import './styles.css'
import { setSearch } from "../../redux/slices/herosearch/herosearchSlice";
import { setProjectSearch } from "../../redux/slices/heroProjectSearch/heroProjectSearchSlices";

export const PaginationOutlined = ({scrollTop, value}) => {
  const [page, setPage] = React.useState(1);
  const propertyData = useSelector(state=>state.herosearch)
  const projectData =  useSelector(state=> state.heroprojectsearch)
  const {propertiesMeta} = useSelector(state=>state.properties)
  const {projectsMeta} = useSelector(state=>state.project)
  const dispatch = useDispatch();
  const handleChange = (event, value) => {
    setPage(value);
  };
  useEffect(()=>{
    dispatch(getIndex(1));
  },[dispatch])

  useEffect(()=>{
    if (value === "property"){
    const searchObject = {
      type:propertyData.type,
      community:propertyData.community,
      unittype:propertyData.unittype,
      bed:propertyData.beds,
      price:propertyData.price,
      page:page,
    }
    dispatch(setSearch(searchObject));
    scrollTop();
  } 
  else {
    if (projectData) {
      const searchObject = {
        type: projectData.type,
        community: projectData.community,
        developer:projectData.developer, 
        date: projectData.date,
        unittype: projectData.unittype,
        bed: projectData.bed,
        price:projectData.price,
        page: page,
      };
      dispatch(setProjectSearch(searchObject));
      scrollTop();
    } }
  
  },[page])

  return (
      <PaginationCard>
      <Pagination
        count={value === 'property' ? propertiesMeta.pagination?propertiesMeta.pagination.pageCount:1 : projectsMeta.pagination?projectsMeta.pagination.pageCount:1}
        page={page}
        onChange={handleChange}
        hidePrevButton
        hideNextButton
        renderItem={(item) => (
          <PaginationItem
              {...item}
          />
      )}
      />
      </PaginationCard>
  );
};
