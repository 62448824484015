import React from "react";
import { GoogleMap, LoadScript, MarkerF } from "@react-google-maps/api";
const styles = require("./map.json");
const containerStyle = {
  width: "100%",
  height: "100%",
};

const GoogleMapFile = ({longitude, latitude, data}) => {


  const center = {
    lat: parseFloat(latitude),
    lng: parseFloat(longitude)
  }
  return (
    <LoadScript
      googleMapsApiKey="AIzaSyDd1T6v7UdnhL44_SGqgw-fBmsTJmWmJuM"
      style={{ width: "50%"}}
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={15}
        options={{ styles: styles }}
      >
        <MarkerF
          position={{
            lat: parseFloat(latitude),
            lng: parseFloat(longitude)
          }}
        />
      </GoogleMap>
    </LoadScript>
  );
};

export default GoogleMapFile;
