import React from 'react'
import { Heading, MainHeadingDiv } from './SearchProject.styled'
import { useParams } from 'react-router-dom'

export const MainHeading = () => {
  const id = useParams()
  return (
    <MainHeadingDiv>
        <Heading> Search Properties in the {id.id} projects!</Heading>
    </MainHeadingDiv>
  )
}
