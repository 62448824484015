import React, { useEffect } from "react";
import { BoxItemRecentlyAdded, DropDownMenuRecentlyAdded } from "./propertylisting.styled";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { RiArrowDropDownLine } from "react-icons/ri";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";

const Recentstyle = makeStyles({
  unit_menu: {
    overflow: "hidden",
    position: "absolute",
    marginTop: "4px !important",
    height: "auto",
    minWidth: "fit-content",
    boxSizing: "border-box",
    borderRadius: "20px",
    border: "0.75px solid #12284C",
    transition: "none !important"
  },

  unit_menuItem: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    overflowY: "hidden",
  },

  paperWork: {
    backgroundColor: "#FFFFFF !important"
  }
});

const RecentMenu = ["Recently Added", "Highest Price", "Lowest Price"];
export const RecentButton = ({sortButton}) => {
  const styling = Recentstyle();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [setOpen] = React.useState(false);
  const open = Boolean(anchorEl);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  useEffect(()=>{
    if (selectedIndex===0) {
      sortButton('recent');
  }else if (selectedIndex===1) {
    sortButton('highToLow');
  }else if (selectedIndex===2) {
    sortButton('lowToHigh');
  }
  },[selectedIndex])

  return (
    <>
      <BoxItemRecentlyAdded
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <DropDownMenuRecentlyAdded>
          {RecentMenu[selectedIndex] || RecentMenu[0]}
        </DropDownMenuRecentlyAdded>
        <RiArrowDropDownLine size={40}/>
      </BoxItemRecentlyAdded>
      <Popper className={styling.unit_menu}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}>
            <Paper className={styling.paperWork}>
              <ClickAwayListener 
              onClickAway={handleClose}
              >
                <MenuList
                  autoFocusItem={open}
                  onKeyDown={handleListKeyDown}
                >
                  {RecentMenu.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
      </Popper>
    </>
  );
};
