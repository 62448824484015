import styled from "styled-components";
import HeroSlider, { Slide } from "hero-slider";

export const SlideImages = styled(Slide)`
  width: 100% !important;
  height: 100vh !important;
  object-fit: cover !important;
  background-attachment: "fixed";
  background-repeat: "no-repeat";
  background-size: "cover";
  background-position: "center";
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 100%;
    height: 100%;
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
    height: 100%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 100%;
    height: 100%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 100%;
    height: 100%;
  }
`;

export const HeroSliderForImages = styled(HeroSlider)`
  width: 100% !important;
  height: 100vh !important;
  object-fit: cover;
`;

export const PropertyImageDiv = styled.div`
  width: auto;
  height: auto;
  overflow: hidden;
`;

export const PropertyDetailImages = styled.img`
  width: 100%;
`;

export const PlayVideo = styled.button`
  background: #12284c;
  border-radius: 90px;
  color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.125em;
  text-transform: uppercase;
  padding: 10px 20px;
  border: 1px solid #12284c;
`;

export const BreadCrumbsPadding = styled.div`

`;

export const DetailsBackground = styled.div``;

export const PropertiesDetailDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 50px;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const PropertiesDetailsHeading = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  line-height: 159%;
  color: #12284c;
  @media only screen and (min-width: 1920px) {
    font-size: 46px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-size: 32px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 27px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 24px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-size: 27px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-size: 27px;
  }
`;

export const Linebreak = styled.br`
  @media only screen and (min-width: 1920px) {
    display: none;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    display: none;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    display: none;
  }
  @media only screen and (max-width: 900px) {
    display: none;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    display: none;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    display: none;
  }
`;

export const ButtonsMainDiv = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 15px;
  @media only screen and (max-width: 900px) {
    margin-top: 30px;
  }
`;

export const ButtonAnotherDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const HeadingButton = styled.button`
  background: transparent;
  border: 1px solid #12284c;
  border-radius: 74px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 127%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #12284c;
  padding: 10px 30px 10px 30px;
  align-items: center;
  justify-content: center;
`;

export const BookButton = styled.button`
  background: #12284c;
  border-radius: 90px;
  border: 1px solid #12284c;
  line-height: 127%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-size: 14px;
  color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const DetailMainDiv = styled.div`
  display: flex;
  width: 80%;
  margin: auto;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  @media only screen and (min-width: 1900px) {
    flex-direction: row;
    flex-wrap: nowrap;
    padding-bottom: 21px;
    gap: 2%;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    flex-direction: row;
    flex-wrap: nowrap;
    padding-bottom: 21px;
    gap: 2%;
  }
  @media only screen and (min-width: 1000px) and (max-width: 1300px) {
    flex-direction: row;
    row-gap: 20px;
    flex-wrap: wrap;
    padding-bottom: 21px;
  }
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    gap: 15px;
    padding-bottom: 21px;
  }
`;

export const DetailAnotherDiv = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white !important;
  text-align: left;
  @media only screen and (min-width: 1900px) {
    padding: 30px 40px;
    min-width: 23.5%;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    padding: 20px 40px;
    min-width: 23.5%;
  }
  @media only screen and (min-width: 1000px) and (max-width: 1300px) {
    padding: 30px 30px;
    min-width: 45%;
  }
  @media only screen and (max-width: 1000px) {
    padding: 20px 30px;
    width: 100%;
  }
`;

export const MainDetailHeading = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  line-height: 127%;
  letter-spacing: 0.03em;
  color: #000000;
  @media only screen and (min-width: 1920px) {
    font-size: 24px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-size: 24px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 20px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-size: 20px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-size: 20px;
  }
`;

export const DetailDescriptionPage = styled.div`
  background-color: white;
  width: 80%;
  margin: auto;
  @media only screen and (min-width: 1920px) {
    padding-top: 50px;
    padding-bottom: 100px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    padding-top: 50px;
    padding-bottom: 100px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    padding-top: 45px;
    padding-bottom: 80px;
  }
  @media only screen and (max-width: 900px) {
    padding-top: 39px;
    padding-bottom: 50px;
  }
  @media only screen and (min-device-width: 900px) and (max-device-width: 1200px) and (orientation: portrait) {
    padding-top: 45px;
    padding-bottom: 80px;
  }
  @media only screen and (min-device-width: 900px) and (max-device-width: 1200px) and (orientation: landscape) {
    padding-top: 45px;
    padding-bottom: 80px;
  }
`;

export const DetailHeading = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  line-height: 127%;
  letter-spacing: 0.06em;
  color: #131d2e;
  @media only screen and (min-width: 1900px) {
    font-size: 23px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    font-size: 20px;
  }
  @media only screen and (min-width: 400px) and (max-width: 1300px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 400px) {
    font-size: 16px;
  }
`;

export const DescriptionMainDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  @media only screen and (min-width: 1900px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media only screen and (max-width: 900px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    padding-left: 40px;
    padding-right: 40px;
  }
`;

export const ButtonDiv = styled.div`
  display: flex;
  height: 40.35px;
  border-radius: 74px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #000000;
  cursor: pointer;
`;

export const ButtonMobileDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 40px;
  height: 40.35px;
  cursor: pointer;
  background: #12284c;
  border-radius: 90px;
  @media only screen and (max-width: 400px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

export const DownloadBrocherDiv = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
`;

export const DownloadBrocher = styled.button`
  background-color: transparent;
  padding-left: 15px;
  border: none;
  font-family: "Montserrat";
  font-style: normal;
  text-align: center;
  letter-spacing: 0.125em;
  text-transform: uppercase;
  color: #000000;
  transition: 0.3;
  cursor: pointer;
  @media only screen and (min-width: 1920px) {
    font-weight: 500;
    font-size: 16px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 500;
    font-size: 16px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 500;
    font-size: 12px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 500;
    font-size: 12px;
  }
`;

export const DownloadBrocherMobile = styled.button`
  background-color: transparent;
  padding-left: 15px;
  border: none;
  font-family: "Montserrat";
  font-style: normal;
  text-align: center;
  letter-spacing: 0.125em;
  text-transform: uppercase;
  color: #ffffff;
  transition: 0.3;
  font-weight: 500;
  font-size: 13px;
  text-align: center;
  cursor: pointer;
  @media only screen and (max-width: 400px) {
    font-weight: 500;
    font-size: 10px;
  }
`;

// Description Paragraph
export const DescriptionPara = styled.p`
  font-family: "Didot";
  font-style: normal;
  font-weight: 500;
  line-height: 159%;
  color: #000000;
  @media only screen and (min-width: 1920px) {
    font-size: 48px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-size: 48px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 35px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 30px;
  }
  @media only screen and (max-width: 500px) {
    font-size: 25px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-size: 35px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-size: 35px;
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  @media only screen and (min-width: 1900px) {
    padding: 0px 40px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    padding: 0px 40px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    padding: 0px 30px;
  }
  @media only screen and (max-width: 900px) {
    padding: 0px 30px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    padding: 0px 30px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    padding: 0px 40px;
  }
`;

export const DescriptionDetailPara = styled.p`
  font-family: "Montserrat";
  text-align: justify;
  text-justify: inter-word;
  font-style: normal;
  font-weight: 400;
  line-height: 159%;
  color: #000000;
  @media only screen and (min-width: 1920px) {
    font-size: 20px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-size: 20px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 13px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 13px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-size: 13px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-size: 13px;
  }
`;

export const LineBreak = styled.br``;

export const ParaLines = styled.div`
  margin-bottom: 5px;
`;

// Download Brochuer Mobile Button
export const MobileButtonConatiner = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
  overflow: hidden;
  padding-bottom: 104px;
`;

export const MobileDownloadDiv = styled.div`
  background: #12284c;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  margin: auto;
  border-radius: 72.7434px;
  border: 2px solid #12284c;
  @media only screen and (min-width: 400px) {
    width: 370px;
  }
  @media only screen and (max-width: 399px) {
    width: 300px;
  }
`;

export const MobileDownload = styled.button`
  font-family: "Montserrat";
  background: transparent;
  padding-left: 20px;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.125em;
  color: #ffffff;
  cursor: pointer;
  @media only screen and (max-width: 900px) {
    font-size: 13px;
  }
`;

// Key Features

export const KeyFeatureMainCoantiner = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 80%;
  margin: auto;
`;

export const KeyFeatureHeadingdiv = styled.div`
  @media only screen and (min-width: 1920px) {
    padding-top: 100px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    padding-top: 100px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    padding-top: 80px;
  }
  @media only screen and (max-width: 900px) {
    padding-top: 50px;
  }
  @media only screen and (min-device-width: 900px) and (max-device-width: 1200px) and (orientation: portrait) {
    padding-top: 80px;
  }
  @media only screen and (min-device-width: 900px) and (max-device-width: 1200px) and (orientation: landscape) {
    padding-top: 80px;
  }
`;

export const KeyFeatureHeading = styled.p`
  font-family: "Didot";
  font-style: normal;
  font-weight: 400;
  line-height: 159%;
  color: #000000;
  @media only screen and (min-width: 1920px) {
    font-size: 48px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-size: 48px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 30px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 30px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-size: 30px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-size: 30px;
  }
`;

export const KeyFeatureButtonDiv = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  padding-bottom: 20px;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (min-width: 1920px) {
    padding-bottom: 39px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    padding-bottom: 35px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    padding-bottom: 27px;
  }
  @media only screen and (max-width: 900px) {
    padding-bottom: 25px;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    padding-bottom: 27px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    padding-bottom: 27px;
  }
`;

export const KeyFeatureButtons = styled.button`
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 40px;
  margin-right: 15px;
  font-family: "Montserrat";
  font-style: normal;
  color: #000000;
  line-height: 127%;
  font-weight: 400;
  min-width: fit-content;
  border: 1px solid #decdad;
  padding: 10px 40px;
  &:hover {
    border: 1px solid #000000;
  }
  &:focus {
    border: 1px solid #000000;
  }
`;

//Amenities

export const TheBuilding = styled.div`
  display: flex;
  text-justify: inter-word;
  line-height: 146%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  text-align: justify;
  text-justify: inter-word;
  @media only screen and (min-width: 1920px) {
    width: 50%;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 50%;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 50%;
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
  }
  @media only screen and (min-device-width: 900px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 50%;
  }
  @media only screen and (min-device-width: 900px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 50%;
  }
`;

export const FeatureImageDiv = styled.div`
border-radius: 21px;
  @media only screen and (min-width: 1920px) {
    width: 48%;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 48%;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 48%;
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
  }
  @media only screen and (min-device-width: 900px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 48%;
  }
  @media only screen and (min-device-width: 900px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 48%;
  }
`;

export const Features = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    gap: 36px;
  }
`;

export const FeatureList = styled.ol`
  list-style-type: decimal;
  list-style-position: inside;
  width: 100%;
`;

export const FeatureListBullet = styled.li`
  font-family: Montserrat !important;
  font-style: normal;
  font-weight: 300;
  line-height: 146%;
  color: #000000;
  border-bottom: 0.3px solid #131d2e;
  @media only screen and (min-width: 1920px) {
    font-size: 24px;
    padding-bottom: 17px;
    padding-top: 30px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-size: 18px;
    padding-bottom: 12px;
    padding-top: 20px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 13px;
    padding-bottom: 9px;
    padding-top: 16px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 13px;
    padding-bottom: 8px;
    padding-top: 15px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-size: 13px;
    padding-bottom: 9px;
    padding-top: 16px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-size: 13px;
    padding-bottom: 9px;
    padding-top: 16px;
  }
`;

export const KeyImage = styled.div`
  background: ${(props) => `url(${props.img})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media only screen and (min-width: 1900px) {
    min-height: 500px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    min-height: 500px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    min-height: 400px;
  }
  @media only screen and (min-device-width: 900px) and (max-device-width: 1200px) and (orientation: portrait) {
    min-height: 400px;
  }
  @media only screen and (min-device-width: 900px) and (max-device-width: 1200px) and (orientation: landscape) {
    min-height: 400px;
  }
  @media only screen and (max-width: 900px) {
    min-height: 300px;
    width: 100%;
  }
`;

//Mortage Calculator

export const MortageContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow: visible;
  width: 80%;
  margin: auto;
  @media only screen and (min-width: 1920px) {
    height: 620px;
    margin-bottom: 100px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    height: 570px;
    margin-bottom: 100px;
  }
  @media only screen and (min-width: 1000px) and (max-width: 1300px) {
    height: 510px;
    margin-bottom: 80px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    height: 510px;
    margin-bottom: 80px;
  }
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    margin-bottom: 50px;
  }
`;

export const MortageDiv = styled.div`
  display: flex;
  overflow: visible;
  flex-direction: column;
  gap: 40px;
  min-width: 50%;
  padding: 0px 40px;
  background-color: white;
  padding-top: 10px;

  @media only screen and (max-width: 1500px) and (min-width: 1300px) {
    gap: 25px;
    padding-bottom: 10px;
  }

  @media only screen and (max-width: 1300px) and (min-width: 500px) {
    gap: 22px;
    padding-bottom: 10px;
  }
  @media only screen and (max-width: 500px) {
    padding-bottom: 20px;
    gap: 20px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    padding-bottom: 20px;
    gap: 30px;
  }
`;

export const CalculatorDiv = styled.div`
  display: flex;
  overflow: visible;
  flex-direction: column;
  gap: 30px;
`;

export const MortageHeading = styled.p`
  font-family: "Didot";
  font-style: normal;
  font-weight: 400;
  line-height: 159%;
  color: #000000;
  @media only screen and (min-width: 1920px) {
    font-size: 40px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-size: 40px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 32px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 26px;
  }
  @media only screen and (max-width: 500px) {
    font-weight: 500;
    font-size: 21px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-size: 32px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-size: 32px;
  }
`;

export const PropertyPriceDiv = styled.div`
  border-bottom: 0.5px solid #131d2e;
  justify-content: center;
  overflow: visible;
  @media only screen and (min-width: 1900px) {
    padding: 10px 0px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    padding: 10px 0px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    padding: 10px 0px;
  }
  @media only screen and (max-width: 900px) {
    padding: 10px 0px;
  }
  @media only screen and (max-width: 500px) {
    padding: 10px 0px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    padding: 10px 0px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    padding: 10px 0px;
  }
`;

export const PropertyPriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
`;

export const PropertyPrice = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  line-height: 146%;
  color: #000000;
  @media only screen and (min-width: 1920px) {
    font-size: 20px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-size: 18px;
  }
  @media only screen and (min-width: 1000px) and (max-width: 1300px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 1000px) {
    font-size: 13px;
  }
  @media only screen and (max-width: 500px) {
    font-size: 09px;
  }
  @media only screen and (min-device-width: 1000px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-size: 13px;
  }
  @media only screen and (min-device-width: 1000px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-size: 13px;
  }
`;

export const PropertyPriceMain = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  line-height: 146%;
  color: #000000;
  @media only screen and (min-width: 1920px) {
    font-weight: 500;
    font-size: 26px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 500;
    font-size: 24px;
  }
  @media only screen and (min-width: 1000px) and (max-width: 1300px) {
    font-weight: 500;
    font-size: 16px;
  }
  @media only screen and (max-width: 1000px) {
    font-weight: 500;
    font-size: 13px;
  }
  @media only screen and (max-width: 500px) {
    font-weight: 500;
    font-size: 14px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 500;
    font-size: 20px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 500;
    font-size: 16px;
  }
`;

export const InterestDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: visible;
  margin-top: -35px;
`;

export const InterestDivContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: visible;
  margin-top: -10px;
`;

export const InterestInput = styled.input`
  text-align: center;
  background-color: transparent;
  border: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  line-height: 146%;
  color: #000000;
  @media only screen and (min-width: 1920px) {
    font-size: 20px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-size: 18px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 14px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-size: 14px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-size: 14px;
  }
`;

// Monthly Installments

export const CalltoActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4%;
  justify-content: space-between;
  align-items: center; /* Center the items vertically */
  height: 100%; /* Set the height to ensure the button is centered vertically */
`;

export const CalltoActionButton = styled.div`
  display: flex;
  justify-content: center;
`;

export const CTAbutton = styled.button`
  background-color: #12284c;
  color: white;
  border-radius: 90px;
  border: 1px solid #12284c;
  @media only screen and (min-width: 1920px) {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 15px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 15px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 15px;
  }
  @media only screen and (max-width: 900px) {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 15px;
  }
  @media only screen and (max-width: 400px) {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 11px;
  }
`;

export const MonthlyInstallmentConatiner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 2%;
`;

//Mortage Expert

export const MonthlyInstallments = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  line-height: 146%;
  color: #000000;
  @media only screen and (min-width: 1900px) {
    font-size: 30px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    font-size: 24px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 15px;
  }
`;

// Mortage Expert

export const MortageExpert = styled.img`
  object-fit: cover;
  @media only screen and (min-width: 1920px) {
    min-width: 50%;
    height: 620px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    min-width: 50%;
    height: 570px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    min-width: 50%;
    height: 510px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    min-width: 50%;
  }
`;

export const MortageExpertHeading = styled.div`
  font-family: "Didot";
  font-style: normal;
  font-weight: 400;
  line-height: 159%;
  color: #000000;
  @media only screen and (min-width: 1920px) {
    padding-top: 43px;
    font-size: 48px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    padding-top: 43px;
    font-size: 42px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    padding-top: 35px;
    font-size: 30px;
  }
  @media only screen and (max-width: 900px) {
    padding-top: 35px;
    font-size: 30px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    padding-top: 35px;
    font-size: 30px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    padding-top: 35px;
    font-size: 30px;
  }
`;

export const MortageExpertCardDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 27px;
  @media only screen and (min-width: 1920px) {
    padding-top: 59px;
    padding-bottom: 68px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    padding-top: 59px;
    padding-bottom: 37px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    padding-top: 38px;
    padding-bottom: 27px;
  }
  @media only screen and (max-width: 900px) {
    padding-top: 38px;
    padding-bottom: 28px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    padding-top: 38px;
    padding-bottom: 27px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    padding-top: 38px;
    padding-bottom: 27px;
  }
`;

export const MortageExpertImage = styled.img`
  @media only screen and (min-width: 1920px) {
    width: 136px;
    height: 141px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 136px;
    height: 141px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 91px;
    height: 94px;
  }
  @media only screen and (max-width: 900px) {
    width: 89px;
    height: 94px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 91px;
    height: 94px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 91px;
    height: 94px;
  }
`;

export const ExpertNameDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const MortageExpertName = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  color: #000000;
  @media only screen and (min-width: 1920px) {
    font-size: 21px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-size: 21px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 18px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-size: 18px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-size: 18px;
  }
`;
export const MortageExpertJobTitle = styled.p`
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  @media only screen and (min-width: 1920px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 12px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 12px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-size: 12px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-size: 12px;
  }
`;

export const ParaDiv = styled.div`
  @media only screen and (min-width: 1920px) {
    padding-right: 42px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    padding-right: 42px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    padding-right: 31px;
  }
  @media only screen and (max-width: 900px) {
    padding-right: 24px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    padding-right: 31px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    padding-right: 31px;
  }
`;

export const MortageExpertDesc = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  line-height: 167.9%;
  color: #000000;
  text-align: justify;
  text-justify: inter-word;
  @media only screen and (min-width: 1920px) {
    font-size: 20px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-size: 18px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 13px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 13px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-size: 13px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-size: 13px;
  }
`;

export const ContactDiv = styled.div`
  display: flex;
  flex-direction: row;
  border: none;
  gap: 22px;
  @media only screen and (min-width: 1920px) {
    padding-top: 80px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    padding-top: 50px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    padding-top: 27px;
  }
  @media only screen and (max-width: 900px) {
    padding-top: 27px;
    gap: 10px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    padding-top: 27px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    padding-top: 27px;
  }
`;

export const ContactButton = styled.button`
  display: flex;
  padding: 10px 27px;
  justify-content: center;
  border: 0.93px solid #12284c;
  border-radius: 74px;
  background-color: transparent;
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  @media only screen and (max-width: 900px) {
    padding: 10px 20px;
  }
`;

export const MapContainer = styled.div`
  display: flex;
  div {
    overflow-x: visible !important;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  @media only screen and (min-width: 1920px) {
    padding-top: 100px;
    padding-bottom: 100px;
    width: 80%;
    margin: auto;
    height: 750px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    padding-top: 100px;
    padding-bottom: 100px;
    width: 80%;
    margin: auto;
    height: 700px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    padding-top: 80px;
    padding-bottom: 80px;
    width: 80%;
    margin: auto;
    height: 650px;
  }
  @media only screen and (max-width: 900px) {
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
    margin: auto;
  }
  @media only screen and (min-device-width: 900px) and (max-device-width: 1200px) and (orientation: portrait) {
    padding-top: 80px;
    padding-bottom: 80px;
    width: 80%;
    margin: auto;
  }
  @media only screen and (min-device-width: 900px) and (max-device-width: 1200px) and (orientation: landscape) {
    padding-top: 80px;
    padding-bottom: 80px;
    width: 80%;
    margin: auto;
  }
`;

export const Map = styled.div`
  @media only screen and (min-width: 1920px) {
    width: 100%;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1920px) {
    width: 100%;
  }
  @media only screen and (min-width: 900px) and (max-width: 1200px) {
    width: 100%;
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
    height: 500px;
  }
`;

export const VirtualVisit = styled.div`
  display: flex;
  position: relative;
  height: auto;
  object-fit: contain;
  background: ${(props) => `url(${props.img})`};
  @media only screen and (min-width: 1920px) {
    width: 50%;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 50%;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 50%;
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
    height: 50%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 50%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 50%;
  }
`;

export const VirtualImage = styled.img`
  width: 100%;
  @media only screen and (min-width: 1920px) {
    height: 764px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    height: 520px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    height: 487px;
  }
  @media only screen and (max-width: 900px) {
    height: 395px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    height: 487px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    height: 487px;
  }
`;

export const VirtualText = styled.p`
  font-family: "Didot";
  font-style: normal;
  font-weight: 400;
  line-height: 159%;
  color: #ece1ce;
  @media only screen and (min-width: 1920px) {
    font-size: 64px;
    top: 489px;
    left: 82px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-size: 48px;
    top: 343px;
    left: 56px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 40px;
    top: 332px;
    left: 86px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 30px;
    top: 182px;
    left: 76px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-size: 40px;
    top: 332px;
    left: 86px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-size: 40px;
    top: 332px;
    left: 86px;
  }
`;

export const VirtualTextSmall = styled.p`
  font-family: "Didot";
  font-style: normal;
  font-weight: 400;
  line-height: 159%;
  color: #ece1ce;
  @media only screen and (min-width: 1920px) {
    font-size: 24px;
    top: 589px;
    left: 82px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-size: 18px;
    top: 414px;
    left: 56px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 18px;
    top: 403px;
    left: 86px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 14px;
    top: 238px;
    left: 76px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-size: 18px;
    top: 403px;
    left: 86px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-size: 18px;
    top: 403px;
    left: 86px;
  }
`;

export const VirtualTextContainer = styled.div`
  display: flex;
  width: 100%;
  position: absolute;
  display: flex;
  width: 100%;
  gap: 25%;
  justify-content: center;
  margin: auto;
  bottom: 75px;

  @media only screen and (max-width: 900px) {
    gap: 10%;
  }
`;

export const VirtualTextContainerForButton = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const BookViewDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const BookImage = styled.img`
  width: 50%;
  @media only screen and (min-width: 1920px) {
    height: 726px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    height: 508.2px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    height: 477px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    height: 508.2px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    height: 508.2px;
  }
`;

export const BookViewContainer = styled.div`
  display: flex;
  width: 80%;
  margin: auto;
  @media only screen and (min-width: 1920px) {
    padding-bottom: 100px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    padding-bottom: 100px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    padding-bottom: 80px;
  }
  @media only screen and (max-width: 900px) {
    padding-bottom: 50px;
    height: 900px;
    overflow: hidden;
  }
  @media only screen and (min-device-width: 900px) and (max-device-width: 1200px) and (orientation: portrait) {
    padding-bottom: 80px;
  }
  @media only screen and (min-device-width: 900px) and (max-device-width: 1200px) and (orientation: landscape) {
    padding-bottom: 80px;
  }
`;

export const MapDiv = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: visible !important;
`;

export const BookView = styled.p`
  font-family: "Didot";
  font-style: normal;
  font-weight: 400;
  line-height: 83px;
  color: #000000;
  @media only screen and (min-width: 1920px) {
    font-size: 65.5px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-size: 45.9px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 32px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 32px;
  }
  @media only screen and (max-width: 500px) {
    font-size: 25px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-size: 32px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-size: 32px;
  }
`;

export const BookViewHead = styled.div`
  display: flex;
  width: 80%;
  margin: auto;
  justify-content: flex-start;
  @media only screen and (min-width: 1900px) {
    padding-top: 100px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    padding-top: 100px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    padding-top: 80px;
  }
  @media only screen and (max-width: 900px) {
    padding-top: 30px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    padding-top: 80px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    padding-top: 80px;
  }
`;

export const VirtualVisitIcon = styled.img`
  width: 127px;
  height: 127px;
`;

export const CollageImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
`;

export const CollageMainDiv = styled.div`
  display: grid;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 10px;
  z-index: 10;
  @media only screen and (min-width: 1900px) {
    padding-top: 150px;
    width: 80%;
    margin: auto;
    grid-template-rows: repeat(6, 4.75vw);
  }
  @media only screen and (min-width: 1200px) and (max-width: 1900px) {
    padding-top: 130px;
    width: 80%;
    margin: auto;
    grid-template-rows: repeat(6, 7vh);
  }
  @media only screen and (min-width: 900px) and (max-width: 1200px) {
    padding-top: 130px;
    width: 80%;
    margin: auto;
    grid-template-rows: repeat(6, 6.75vh);
  }
  @media only screen and (max-width: 900px) {
    grid-template-rows: repeat(6, 17vw);
  }
  @media only screen and (max-width: 500px) {
    grid-template-rows: repeat(6, 18vw);
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    grid-template-rows: repeat(6, 13vw);
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    padding-top: 130px;
    width: 80%;
    margin: auto;
    grid-template-rows: repeat(6, 8vw);
  }
`;

export const CollageFigureOneButton = styled.div`
  display: flex;
  justify-content: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12.9322px;
  line-height: 16px;
  letter-spacing: 0.125em;
  position: absolute;
  transform: translate(-50%, -50%);
  padding: 10px 30px;
  background: #ffffff;
  border-radius: 72.7434px;
  border: transparent;
  color: #12284c;
  text-decoration: none;
  align-items: center;
  bottom: 10px;
  left: 150px;
  gap: 10px;
  cursor: pointer;
  @media only screen and (max-width: 900px) {
    font-size: 11px;
    padding: 10px 35px;
  }
`;

export const SymbolCollage = styled.img`
  width: 14px;
  height: 14px;
`;

export const CollageFigureOne = styled.figure`
  overflow-y: hidden;
  position: relative;
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 7;
  @media only screen and (max-width: 1200px) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 7;
  }
`;

export const CollageFigureTwo = styled.figure`
  overflow-y: hidden;
  grid-column-start: 5;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 4;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    display: none;
  }
`;

export const CollageFigureTwoLess = styled.figure`
  overflow-y: hidden;
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 4;
  @media only screen and (max-width: 900px) {
    display: none;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    display: none;
  }
`;

export const CollageFigureThree = styled.figure`
  overflow-y: hidden;
  grid-column-start: 7;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 4;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    display: none;
  }
`;

export const CollageFigureFour = styled.figure`
  overflow-y: hidden;
  grid-column-start: 5;
  grid-column-end: 7;
  grid-row-start: 4;
  grid-row-end: 7;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    display: none;
  }
`;

export const CollageFigureFourLess = styled.figure`
  overflow-y: hidden;
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 4;
  grid-row-end: 7;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    display: none;
  }
`;

export const CollageFigureFive = styled.figure`
  overflow-y: hidden;
  grid-column-start: 7;
  grid-column-end: 9;
  grid-row-start: 4;
  grid-row-end: 7;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    display: none;
  }
`;

export const BreadCrumbAndDetailInfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  @media only screen and (max-width: 1600px) and (min-width: 1920px) {
    justify-content: space-evenly;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1600px) {
    height: max(35vh, 225px);
    overflow-y: hidden !important;
    justify-content: space-evenly;
  }
  @media only screen and (max-width: 900px) {
    overflow-y: hidden !important;
  }
`;

export const BreadcrumbsContainer = styled.div`
  display: flex;
  width: 80%;
  margin: auto;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 25px;
`;

export const BackgroundDiv = styled.div`
  background-color: white;
  @media only screen and (min-width: 900px) {
    width: 50%;
  }

  @media only screen and (max-width: 900px) {
    width: 80%;
    margin: auto;
    margin-top: 0px;
  }
`;

// Main Collage
export const MainCollageContainer = styled.div`
  position: absolute;
  top: 0;
  background: #eef2f4;
  width: 100%;
  height: 100%;
  z-index: 20000;
`;

export const MainCollageNav = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: white;
  @media only screen and (min-width: 1900px) {
    height: 70px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    height: 50px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    height: 50px;
  }
  @media only screen and (max-width: 900px) {
    height: 50px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    height: 50px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    height: 50px;
  }
`;

export const BackIconImage = styled.img`
  height: 25px;
  width: 25px;
`;

export const BackButtonDiv = styled.div`
  display: flex;
  width: 80%;
  align-items: center;
  margin: auto;
  cursor: pointer;
`;

export const BackText = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  text-decoration-line: underline;
  color: #131d2e;
  margin-left: 10px;
`;

export const CollageMainContainer = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
`;

export const MainCollageStarted = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 10px;
  z-index: 10;
  width: 835px;
  grid-template-rows: repeat(10, 80px);
  margin: auto;
  margin-top: 10px;
  @media only screen and (max-width: 900px) {
    width: 600px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 600px;
  }
`;

export const MainCollageFigureOne = styled.figure`
  overflow-y: hidden;
  position: relative;
  grid-column-start: 1;
  grid-column-end: 10;
  grid-row-start: 1;
  grid-row-end: 7;
`;

export const MainCollageFigureTwo = styled.figure`
  overflow-y: hidden;
  position: relative;
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 7;
  grid-row-end: 11;
`;

export const MainCollageFigureThree = styled.figure`
  overflow-y: hidden;
  position: relative;
  grid-column-start: 5;
  grid-column-end: 10;
  grid-row-start: 7;
  grid-row-end: 11;
`;
