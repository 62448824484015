import React from 'react'
import { RelatedCotainer, SocialBorderContainer, SocialLinksContainer } from './SocialLink.styled'
import FacebookIcon from '../../assets/images/Group-816.svg'
import YoutubeIcon from '../../assets/images/Group-817.svg'
import LinkedinIcon from '../../assets/images/Group-818.svg'
import InstaIcon from '../../assets/images/Group-819.svg'

const SocialLinks = () => {
  return (
    <div>
        <SocialBorderContainer>
            <div></div>
            <p>FOUND USEFUL? SHARE ON</p>
            <div></div>
        </SocialBorderContainer>
        <SocialLinksContainer>
        <a href='https://www.facebook.com/modernlivingrealestatedubai'><img src={FacebookIcon} alt='F' className='scicon'/></a>
              <a href='https://www.linkedin.com/company/modern-living-real-estate-dubai'><img src={YoutubeIcon} alt='F' className='scicon'/></a>
              <a href='https://www.youtube.com/@modernlivingrealestatedubai'><img src={LinkedinIcon} alt='F' className='scicon'/></a>
              <a href='https://www.instagram.com/modernliving.ae/?hl=en'><img src={InstaIcon} alt='F' className='scicon'/></a>
        </SocialLinksContainer>  
        <RelatedCotainer>
            <h3>Related</h3>
            
        </RelatedCotainer>  
    </div>
  )
}

export default SocialLinks