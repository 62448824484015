import React from "react";
import {
  DescriptionContainer,
  DescriptionDetailPara,
  LineBreak,
  // MobileButtonConatiner,
  // MobileDownload,
  // MobileDownloadDiv,
  ParaLines,
} from "./PropertyDetail.styled";
// import { BsDownload } from "react-icons/bs";

export const DescriptionDetail = ({ details }) => {
  // const [width, setWidth] = useState(window.innerWidth);
  // useEffect(() => {
  //   window.addEventListener("resize", () => setWidth(window.innerWidth));
  // }, []);

  return (
    <>
      <DescriptionContainer>
        {details && details.attributes && details.attributes.description && (
          <DescriptionDetailPara>
            {details.attributes.description}
            <LineBreak />
            <ParaLines>
              Finance is available on this property through Modern Living
            </ParaLines>
            <LineBreak />
            <ParaLines>This property is managed by Modern Living. </ParaLines>
            <LineBreak />
            <ParaLines>Office location: Opus Residences, Dubai UAE. </ParaLines>
            {details.attributes.phone_number &&<LineBreak />}
            {details.attributes.phone_number && (
              <ParaLines>Tel.No: {details.attributes.phone_number}  </ParaLines>
            
            )}
              {details.attributes.rera_no && <LineBreak />}
            {details.attributes.rera_no && (
              <ParaLines>RERA No: {details.attributes.rera_no} </ParaLines>
            )}
            {details.attributes.reference_no && <LineBreak />}
            {details.attributes.reference_no && (
              <ParaLines>Reference No: {details.attributes.reference_no}</ParaLines>
            )}
          </DescriptionDetailPara>
        )}
      </DescriptionContainer>
      {/* {width < 900 && (
        // <MobileButtonConatiner>
        //   <MobileDownloadDiv>
        //     <BsDownload color="white" />
        //     <MobileDownload>Download Broucher</MobileDownload>
        //   </MobileDownloadDiv>
        // </MobileButtonConatiner>
      )} */}
    </>
  );
};
