import React, { useEffect, useRef, useState } from 'react'
import { PageIcon, ScrollButton, ScrollContainer } from '../PropertyListing.js/PropertyListing.styled';
import { TfiArrowCircleLeft, TfiArrowCircleRight } from 'react-icons/tfi';
import { Slider, SliderContainer, SliderPageContainer } from './Slider.styled';

const SliderComponent = ({data, DynamicComponent, rows, additionalProp}) => {
    const [width, setWidth] = useState(window.innerWidth);
    const containerRef = useRef();
    const [active, setActive] = useState(0);
    const [scrollButton, setScrollButton] = useState(0);
    const [factor, setFactor] = useState(Math.ceil(containerRef.current ? containerRef.current.scrollLeft/width:0))
    useEffect(() => {
  
        const { scrollWidth, offsetWidth } = containerRef.current;
        if (window.innerWidth > 1300) setScrollButton((data.length / 4)/rows)
        else if (window.innerWidth > 800 && window.innerWidth <1300 ) setScrollButton((data.length / 3)/rows)
        else setScrollButton((data.length / 1)/rows)
      }, [window.innerWidth , data.length]);

      useEffect(()=>{
        setActive(factor);
      },[factor])
    
      const scrollHandler = (value) => {
        const { offsetWidth } = containerRef.current;
        setActive(value);
        var n = 0;
        if (window.innerWidth > 800) {
        n = value * (offsetWidth + 0.02 * width);}
        else {
            n = value * offsetWidth;    
        }
        if (containerRef.current) {
          containerRef.current.scrollTo(n, 0);
        }
      };
     
    
      const handleScroll = (e) => {
        const { scrollWidth, offsetWidth, scrollLeft } = containerRef.current;
        setFactor(Math.ceil(scrollLeft/width))
        if (scrollLeft === 0) setActive(0);
        if (scrollLeft >= scrollWidth - offsetWidth) {
          let v = Math.floor(scrollButton)
          if (v === scrollButton) setActive(v-1)
          else setActive(v)
        }
      };
      const dataRows = [];
      for (let i = 0; i < data.length; i += rows) {
        dataRows.push(data.slice(i, i + rows));
      }
    
  return (
    <>
    <SliderPageContainer>
      {width > 900 && <PageIcon
            disabled={active === 0}
            onClick={() => scrollHandler(active - 1)}
          >
            <TfiArrowCircleLeft size={28} />
          </PageIcon>}
        <SliderContainer ref={containerRef} onScroll={handleScroll}>
        {dataRows.map((row, rowIndex) => (
          <Slider key={rowIndex}>
            {row.map((card, cardIndex) => (
              <DynamicComponent key={cardIndex} data={card} agentHandler={additionalProp}/>
            ))}
          </Slider>
        ))}
        </SliderContainer>
        {width > 900 && <PageIcon
            disabled={active===Math.floor(scrollButton)}
            onClick={() => scrollHandler(active + 1)}
          >
            <TfiArrowCircleRight size={28} />
          </PageIcon> }
      </SliderPageContainer>
      <ScrollContainer>
          {(() => {
            var td = [];
            for (let i = 0; i < scrollButton; i++) {
              td.push(i);
            }
            return td.map((t, id) => {
              return (
                <ScrollButton
                  key={id}
                  active={id === active}
                  onClick={() => scrollHandler(id)}
                ></ScrollButton>
              );
            });
          })()}
        </ScrollContainer>
      </>
  )
}

export default SliderComponent