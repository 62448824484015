import { put, retry } from 'redux-saga/effects';
import { showSnackBar } from '../../../slices/snackbarSlice/snackbarSlice';
import { setPreference } from '../../../slices/preferenceSlice.js/preferenceSlice';
import { preferenceRequest } from '../../request/preference/preference';

export function* handlePreference(action) {
    try {
        const response = yield retry(0, 0, preferenceRequest, action.payload);
        const { data } = response.config;
        const userData = JSON.parse(data);
        yield put(setPreference({ userData }));
    } catch (error) {
        const snackbarObject = {
            type: 'error',
            message: 'Preference Failed ',
            open: true,
        };
        yield put(showSnackBar(snackbarObject));
    }
}