import React from 'react'
import { CompanyHeroContentContainer, CompanyHeroSection, HeroButton, HeroHeaderText, HeroSubText, Overlay } from './Hero.styled'
import aboutImage from "../../assets/images/portrait-business-partners-greeting-one-another-by-handshaking-way-office1.png";

const HeroSellPage = () => {
  return (
    <CompanyHeroSection img={aboutImage}>
      <Overlay/>
        <CompanyHeroContentContainer>
        <HeroHeaderText>We help you market your property with ease.</HeroHeaderText>
        <HeroSubText>
        We take the time to understand your needs, and work with you to find the right renter or buyer for your property.
        </HeroSubText>
        <HeroButton><h3>REQUEST EVALUATION</h3></HeroButton>
        
      </CompanyHeroContentContainer>
    </CompanyHeroSection>
  )
}

export default HeroSellPage