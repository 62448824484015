import { useState, useEffect, useRef } from 'react';

const useDropdown = () => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useState(false);

  const handleClick = (e) => {
    if (dropdownRef.current.contains(e.target)) {
      // Click inside dropdown
      return;
    }
    // Click outside dropdown
    setIsActive(false);
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClick);
    // Unbind the event listener on cleanup
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return [dropdownRef, isActive, setIsActive];
};

export default useDropdown;