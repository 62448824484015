import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { PressCenterTemplate } from '../assets/styles/MyStyle.styled';
import HeroAboutUs from '../components/CompanyHero/HeroAboutUs';
import Contact from '../components/ContactUs/Contact';
import { ContactDiv } from '../components/ContactUs/ContactUs.styled';
import CustomizedSnackbar from '../components/CustomizedSnackbars/CustomizedSnackbar';
import Footer from '../components/Footer/Footer';
import FooterMobile from '../components/Footer/FooterMobile';
import TeamComponent from '../components/MeetTheTeam.js/TeamComponent';
import Navbar from '../components/Navbar/Navbar';
import Trustedclients from '../components/TrustedClients/Trustedclients';
import { WhoAreWeSection, WhoWeAreContainer, WhoWeAreTextContainer } from '../components/Vision/Vision.styled';
// import WhoWeAre from '../components/Vision/WhoWeAre';
import { getAgents } from '../redux/slices/agentSlice/getAgent';
import { getComments } from '../redux/slices/testimonials/testimonialSlice';
import MeetTheTeam from '../components/MeetTheTeam.js/MeetTheTeam';
import MySlider from '../components/MeetTheTeam.js/NewTeamSlider';
import NewTeamSlider from '../components/MeetTheTeam.js/NewTeamSlider';

const CompanyTeam = () => {
  const [width, setWidth] = useState(window.innerWidth)
  const [offset, setOffset] = useState(0);
  const [background,setBackground] = useState('transparent')
  const {comments} = useSelector((state) => state.testimonial);
  const dispatch = useDispatch();
  const { agents } = useSelector((state) => state.agents);

  
  useEffect(()=>{
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    dispatch(getAgents(8))
    dispatch(getComments());
  },[dispatch])
  useEffect(()=>{
    if (offset>100) setBackground('#12284C')
    else setBackground('transparent')
  },[offset])
  useEffect(() => {
    window.scrollTo(0, 0)
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll); 
}, []);

const setLoad = () => {
  dispatch(getAgents(''))
}
  return (
    <PressCenterTemplate>
      <Navbar background={background}/>
      <HeroAboutUs header='You’re in good hands with Modern Living.' desc='We are committed to providing the best possible service for you.'/>
      <WhoAreWeSection>
      <WhoWeAreContainer >
        <WhoWeAreTextContainer image='none' button="background">
          <h1>Meet Our Team</h1>
          <p>We have a team of multilingual specialists and professionals who can meet all your real estate needs.</p>
         <button style={{marginTop:'20px'}}>
            <h4>CONTACT US</h4>
          </button>
        </WhoWeAreTextContainer>
      </WhoWeAreContainer>
    </WhoAreWeSection>
    {agents.length > 0 && <MeetTheTeam agents={agents} setLoad={setLoad} detail='true' rows={2}/>}
      <Trustedclients comments={comments} offset={offset}/>
      <ContactDiv>
    <Contact title='Contact Us' shrink='true' displaymsg='block' displayupload = 'none'/>
    </ContactDiv>
        {width < 1204?<FooterMobile/>:<Footer/>}
        <CustomizedSnackbar/>
    </PressCenterTemplate>
  )
}

export default CompanyTeam