import axios from 'axios';
import API_URL from '../constant'

export function sellPreferenceRequest(data) {
    const dataStract = {
        data: data
    }
    var config = {
        method: 'post',
        url: `${API_URL}sale-out-or-rent-out-queries`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: dataStract,
    };
    return axios(config);
}