import React, { useState, useEffect } from "react";
import { PaginationOutlined } from "../propertiesListing/PaginationOutlined";
import { MainHeading } from "./MainHeading";
import { Preferences } from "./Preferences";
import { RecentlyAdded } from "./RecentlyAdded";
import { SearchButtons } from "./SearchButtons";
import {
  Cards,
  MainPageDiv,
  PageDiv,
  PreferencesPadding,
  SearchOneDiv,
} from "./SearchProject.styled";
import { useDispatch } from "react-redux";
import { getLocation } from "../../redux/slices/location/locationSlice";
import {
  DisplayText,
  ProjectSearchCardContainer,
  ProjectSearchCarddiv,
  PropertyCarDiv,
  PropertyCardCointer,
} from "../propertiesListing/propertylisting.styled";
import NewProjectCard from "./NewProjectCard";
import { useTranslation } from "react-i18next";

export const SearchProjects = ({ data }) => {
  const [sort, setSort] = useState("recent");
  const [width, setWidth] = useState(window.innerWidth);
  const [community, setCommunity] = useState("");
  const [area, setArea] = useState("");
  const [areaActive, setAreaActive] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);
  const dispatch = useDispatch();
  const handleInput = (event) => {
    setAreaActive(true);
    const newValue = event.target.value;
    if (newValue.trim() === "") {
      setCommunity("");
    } else {
      setCommunity(newValue);
    }
  };

  useEffect(() => {
    let words = community.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
    const joinedWords = capitalizedWords.join(" ");
    setArea(joinedWords);
    dispatch(getLocation(joinedWords));
  }, [community, dispatch]);

  let sortedData = data;

  switch (sort) {
    case "lowToHigh":
      sortedData = sortedData
        .slice()
        .sort((a, b) => a.attributes.price - b.attributes.price);
      break;
    case "highToLow":
      sortedData = sortedData
        .slice()
        .sort((a, b) => b.attributes.price - a.attributes.price);
      break;
    case "recent":
      sortedData = sortedData
        .slice()
        .sort((a, b) => a.attributes.createdAt - b.attributes.createdAt);
      break;
  }
  const sortButton = (value) => {
    setSort(value);
  };
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <MainPageDiv>
      <PageDiv>
        <MainHeading />
          <SearchButtons
            border="1px solid #12284c"
            color="#000000 !important"
            menuback="transparent !important"
            menucolor="#000000 !important"
            menuitemcolor="white !important"
            menuitembackgroundhover="#12284C !important"
            arrow="#12284C"
            activecolor="black"
          />
        {width > 600 && <Preferences />}
        <RecentlyAdded data={data} sortButton={sortButton} />
        <ProjectSearchCardContainer>
          {sortedData.length === 0 && (
            <DisplayText>{t("No properties matching your search")}</DisplayText>
          )}
          {sortedData.map((project, id) => {
            return (
              <ProjectSearchCarddiv key={project.id}>
                <NewProjectCard project={project} key={id} />
              </ProjectSearchCarddiv>
            );
          })}
        </ProjectSearchCardContainer>
        <PaginationOutlined scrollTop={scrollTop} value="project"/>
        <PreferencesPadding>
          <Preferences />
        </PreferencesPadding>
      </PageDiv>
    </MainPageDiv>
  );
};
