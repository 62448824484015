import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { RiArrowDropDownLine } from "react-icons/ri";
import { makeStyles } from "@mui/styles";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import { BoxItemBeds, DropDownBeds } from "./propertylisting.styled";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setSearchContent } from "../../redux/slices/herosearch/herosearchSlice";
import {
  DropDownMenu,
  BoxItem,
  PaperCSS,
  PopperBedCSS,
  MenuItemCss,
  MenuListCSS,
} from "./propertylisting.styled";

const Bedrooms = ["Studio", "1-BR", "2-BR", "3-BR and more"];

export const Beds = (
  {data,
  border,
  color,
  arrow,
  menuback,
  menucolor,
  menuitemcolor,
  menuitembackgroundhover}
) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [setOpen] = React.useState(false);
  const open = Boolean(anchorEl);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [beds, setBeds] = useState("Beds");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setSearchContent({ key: "beds", value: beds.split("-")[0] }));
  }, [beds]);


  return (
    <>
      <BoxItemBeds
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        color={color}
        border={border}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <DropDownBeds
          style={{
            color: Bedrooms[selectedIndex] ? "black" : "#C0C0C0",
          }}
        >
          {Bedrooms[selectedIndex] || "Beds"}
        </DropDownBeds>
        <RiArrowDropDownLine size={40} style={{ marginRight: "15px" }} />
      </BoxItemBeds>
      <PopperBedCSS
        menuback={menuback}
        border={border}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <PaperCSS menuback={menuback} menucolor={menucolor}>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuListCSS autoFocusItem={open} onKeyDown={handleListKeyDown}>
              {Bedrooms.map((option, index) => (
                <MenuItemCss
                  key={option}
                  selected={index === selectedIndex}
                  menuitemcolor={menuitemcolor}
                  menuitembackgroundhover ={menuitembackgroundhover}
                  onClick={(event) => {
                    handleMenuItemClick(event, index);
                    setBeds(option);
                  }}
                >
                  {option}
                </MenuItemCss>
              ))}
            </MenuListCSS>
          </ClickAwayListener>
        </PaperCSS>
      </PopperBedCSS>
    </>
  );
};
