import styled from "styled-components";

export const PrivacyDiv = styled.div`
    background: #F1F5FB;
    height:auto;
    padding:100px 100px;
`
export const Privacyheader = styled.h1`
     min-width:fit-content;
  font-family: "Didot";
  font-style: normal;
  color: #000000;
  @media only screen and (min-width: 1900px) {
    font-weight: 400;
    font-size: 48px;
    line-height: 125px;
  }
  @media only screen and (min-width: 1300px) and (max-width:1900px) {
    font-weight: 400;
    font-size: 48px;
    line-height: 61px;
  }
  @media only screen and ((min-width: 900px) and (max-width:1300px)) {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;

  }
`
export const PrivacyDesc = styled.p`
font-family: "Montserrat";
  font-style: normal;
  color: #000000;
  text-align: justify;
  text-justify: inter-word;
  height:fit-content !important;
  @media only screen and (min-width: 1900px) {
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
  }
  @media only screen and (min-width: 1300px) and (max-width:1900px) {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
  }
  @media only screen and (min-width: 900px) and (max-width:1300px) {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
  }
  @media only screen and (max-width: 900px) {
    margin-top:34px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }`