import { Slider } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMortage } from "../../redux/slices/MortageSlice/MortageSlice";
import {
  InterestDiv,
  InterestInput,
  PropertyPrice,
} from "./PropertyDetail.styled";
import './style.css'

export default function DownPayment() {
  const {convertValue,currency} = useSelector((state) => state.unitConversion);
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(getMortage({key:'downPayment',value:value}))
  })
  const [value, setValue] = React.useState(80000);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = (event) => {
    setValue(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(20000);
    } else if (value > 1000000) {
      setValue(1000000);
    }
  };
  const convertedValue = Math.round(value * convertValue);
  return (
    <InterestDiv>
      <PropertyPrice> Down Payment: </PropertyPrice>
      <Slider
        value={typeof value === "number" ? value : 80000}
        onChange={handleSliderChange}
        aria-labelledby="input-slider"
        defaultValue={80000}
        min={20000}
        max={100000}
        step={1000}
      />
      <InterestInput
        type="text"
        value={convertedValue.toLocaleString() + currency}
        margin="dense"
        onChange={handleInputChange}
        onBlur={handleBlur}
      />
    </InterestDiv>
  );
}
