import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setSearchContent } from "../../redux/slices/herosearch/herosearchSlice";
import { CheckBoxWrapper } from "./propertylisting.styled";
import { SaleButton, RentButton} from "./propertylisting.styled";


export const SaleRentSlider = ({id}) => {
  const {type} = useSelector(state=>state.herosearch)
  const [choice, setChoice] = React.useState(id);
  const { t } = useTranslation();

  const handleChange = (newChoice) => {
    setChoice(newChoice);
  };
  const dispatch = useDispatch();
  useEffect(()=>{
    setChoice(id)
    dispatch(setSearchContent({key:'type',value:choice}))
  },[id])
  useEffect(()=>{
    setChoice(choice)
    dispatch(setSearchContent({key:'type',value:choice}))
  },[choice])
    return (
        <>
          <CheckBoxWrapper value={choice} exclusive onChange={handleChange} >
            <SaleButton
              value="buy"
              style={{
                backgroundColor: choice === "buy" ? "#12284C" : "transparent",
                color: choice === "buy" ? "#FFFFFF" : "#C0C0C0",
                border: choice === "buy" ? "#12284C" : "rgba(238, 242, 244, 0.5)"
              }}
              onClick={() => {setChoice("buy")}}
            >
              {t('Buy')}
            </SaleButton>
            <RentButton
              value="rent"
              style={{
                background: choice === "rent" ? "#12284C" : "none",
                color: choice === "rent" ? "#FFFFFF" : "#C0C0C0",
                border: choice === "rent" ? "#12284C" : "rgba(238, 242, 244, 0.5)"
              }}
              onClick={() => {setChoice("rent")}}
            >
              {t('Rent')}
            </RentButton>
          </CheckBoxWrapper>
      </>
    );
  };