import {call, put, retry} from "redux-saga/effects";
import { setProjectsContent, hasError, setProjectsMeta, setProjectDetail } from "../../../slices/projectSlice/getProject";
import {requestGetProjects, requestGetFeatureProjects, requestGetProjectDetail} from '../../request/project/project'


export function* handleProjects(action) {
    try {
    
        const response = yield retry(0,0,requestGetProjects,action.payload);
        const {data, meta} = response.data;
        yield put(setProjectsContent(data));
        yield put(setProjectsMeta(meta))
    } catch (error) {
        yield put(hasError(error))
    }
}


export function* handleFeatureProjects(action) {
    try {
        const response = yield retry(0,0,requestGetFeatureProjects, action.payload);
        const {data} = response.data;
        yield put(setProjectsContent(data));
    } catch (error) {
        yield put(hasError(error))
    }
}

export function* handleProjectDetails(action){
    try {
        // const response = yield call(0,0, requestGetProjectDetail, action.payload);
        const response = yield call(requestGetProjectDetail, action.payload)
        const {data} = response;
        yield put(setProjectDetail(data));
    } catch (error) {
        yield put(hasError(error.message))
    }
}