import styled from "styled-components";
import React from 'react';

export const PrimaryButton = styled.button`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 60px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    text-transform: none;
    border: 1px solid #000000;
    border-radius: 90px;
    @media (max-width: 1204px) {
        padding: 7px 17px;
      }
      @media (min-width: 1204px) {
        padding: 10px 24px;
      }
    `

export const DropdownContainer = styled.div`
    position:absolute;
    width:fit-content;
    height:40px;
    background:red;
    border: 0.75px solid #DECDAD;
    &:hover {
      border: 0.75px solid #000000;
    }
    @media (min-width: 1300px){
      border-radius: 90px;
    }
    @media ((min-width:900px) and (max-width:1300px)){
      border-radius: 69.8361px;
    }
    @media (max-width:900px){
      border-radius: 20px;
    }
    
`

export const DropdownWrapper = styled.div`
  position:relative;
  margin-left:none;
  width:300px;
  height:300px;
  background:blue;
`

export const DropdownText = styled.h1`
    font-family: 'Montserrat';
    font-style: normal;
    display: flex;
    align-items: center;
    color: #000000;
    border-radius: 69.8361px;
    @media (min-width: 1300px){
      padding:10px 30px;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }
    @media ((min-width:900px) and (max-width:1300px)){
      padding:7px 23px;
      font-weight: 400;
      font-size: 12.4153px;
      line-height: 15px;
      gap:43px;
    }
    @media (max-width:900px){
      padding:7px 13px;
      font-weight: 400;
      font-size: 12.4153px;
      line-height: 15px;
      gap:20px;
    }
`
export const MainText = styled(DropdownText)`
    border-bottom:0.75px solid #000000;
`

export const SubText = styled(DropdownText)`
    &:hover{
      background: #F4F4F4;
    }
`
export const MobDropdownmenu = () =>{
    return(
      <DropdownWrapper>
        <DropdownContainer>
          <MainText>All</MainText>
          <SubText>Appartment</SubText>
        </DropdownContainer>
        </DropdownWrapper>
    )
}

export const PressCenterTemplate = styled.div`
  background: rgba(238, 242, 244, 0.5);
`