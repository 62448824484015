import styled from "styled-components";
import ReactWhatsapp from "react-whatsapp";

export const WhatsappButton = styled(ReactWhatsapp)`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000000;
  height: 40px;
  overflow: hidden;
  text-align: center;
  border-radius: 90px;
  min-width: fit-content;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 127%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #12284c;
  background-color: transparent;
  cursor: pointer;
  @media only screen and (min-width: 1920px) {
      padding: 20px 40px;
    }
  @media only screen and (min-width: 1300px) and (max-width:1920px){
      padding: 20px 40px;
    }
  @media only screen and (min-width: 400px) and (max-width:1300px){
      padding: 20px 40px;
    }
  @media only screen and (max-width: 400px) {
     width: 48%;
    }
`;

export const ProjectImageSliderDiv = styled.div`
  position: relative;
  min-width: 100%;
  min-height: 100%;
  height: auto;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  z-index: 99;
  @media only screen and (min-width: 1900px) {
    top: 88.61%;
    left: 80px;
    right: 80px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    top: 85.94%;
    left: 49px;
    right: 49px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    top: 85.94%;
    left: 20px;
    right: 20px;
  }
  @media only screen and (max-width: 900px) {
    top: 79.84%;
    left: 10px;
    right: 10px !important;
  }
  @media only screen and (max-width: 400px) {
    top: 79.84%;
    left: 10px;
    right: 10px !important;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    top: 83.94%;
    left: 20px;
    right: 20px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    top: 79.94%;
    left: 20px;
    right: 20px;
  }
`;

export const ButtonAnotherDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;

  @media only screen and (max-width: 900px) {
    gap: 10px;
  }
`;

export const LocationDiv = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 24px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  border-radius: 72.7434px;
`;

export const LocationSymbolImage = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 20px;
`;

export const LocationButton = styled.button`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12.9322px;
  line-height: 16px;
  letter-spacing: 0.125em;
  color: #12284c;
  border: none;
  background-color: transparent;
  padding: 10px 10px 10px 10px;
`;

export const VideoAndGallery = styled.button`
  background: #12284c;
  border-radius: 50px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 127%;
  text-align: center;
  letter-spacing: 0.03em;
  color: #ffffff;
  border: 2px solid #12284c;
  padding: 10px 50px;

  @media only screen and (max-width: 900px) {
    padding: 10px 30px;
  }
`;

export const MobilebuttonLocation = styled.a`
  display: flex;
  width: fit-content;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  background-color: white;
  padding: 10px;
`;

export const LocationMobileSymbolImage = styled.img`
  width: 22px;
  height: 22px;
`;

export const ContainerWidth = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
`;

export const BreadcrumbPaddingProject = styled.div`
  @media only screen and (min-width: 1900px) {
    padding-top: 50px;
    padding-bottom: 30px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    padding-top: 40px;
    padding-bottom: 30px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    padding-top: 47px;
    padding-bottom: 37px;
  }
  @media only screen and (max-width: 900px) {
    padding-top: 30px;
    padding-bottom: 20px;
  }
`;

export const LineBreak = styled.br``;

export const HeadingPaddingProperty = styled.div`
  display: flex;
  width: 80%;
  margin: auto;
  flex-direction: row;
  padding-top: 10px;
  @media only screen and (min-width: 1920px) {
    padding-bottom: 45px;
    justify-content: space-between;
    align-items: center;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    padding-bottom: 45px;
    justify-content: space-between;
    align-items: center;
  }
  @media only screen and (min-width: 1000px) and (max-width: 1300px) {
    padding-bottom: 30px;
    justify-content: space-between;
    align-items: center;
  }
  @media only screen and (max-width: 1000px) {
    padding-bottom: 20px;
    flex-direction: column;
    gap: 20px;
  }
`;

export const HeadingDiv = styled.div`
  display: flex;
  max-width: 50%;
  padding: 0px;
  @media only screen and (max-width: 1000px) {
    min-width: 100%;
    
    }
`;

export const HeadingProjectDiv = styled.div`
  display: flex;
`;

export const MainHeading = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  line-height: 47.7px;
  color: #12284c;
  text-transform: uppercase;
  @media only screen and (min-width: 1920px) {
    font-size: 30px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-size: 30px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 25px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 23px;
    line-height: 32px;
  }
`;

export const CallWhatsappButtonConatiner = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  
  @media only screen and (min-width: 400px) and (max-width:1000px){
  width: 310px;
  justify-content: space-between;
  }
  @media only screen and (min-width: 400px) and (max-width:350px){
  width: 250px;
  justify-content: space-between;
  }
  @media only screen and (max-width: 320px) {
    width: 230px;
    justify-content: space-between;
  }
`;

export const MainButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000000;
  height: 40px;
  overflow: hidden;
  text-align: center;
  border-radius: 90px;
  min-width: fit-content;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 127%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #12284c;
  background-color: transparent;
  padding: 20px 40px;
  @media only screen and (max-width: 400px) {
     width: 48%;
     padding: 0px
    }

`;

export const MainButtonTwo = styled.button`
  border: 1px solid #12284c;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  text-align: center;
  border-radius: 90px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 127%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  background-color: #12284c;
  color: #ffffff;
  cursor: pointer;
  @media only screen and (min-width: 400px) and (max-width:1000px){
  width: 310px;
  }
  @media only screen and (min-width: 400px) and (max-width:350px){
  width: 250px;
  }
  @media only screen and (max-width: 320px) {
    width: 230px;
  }
`;

export const QRImage = styled.img`
  height: 90px;
  width: 90px;
@media only screen and (max-width: 500px) {
  height: auto;
  min-width: 47.5%;
  }
`;
export const VirtualImageSmall = styled.img`
  height: 52px;
  width: 52px;
`;

export const QRVirtual = styled.div`
display: flex;
flex-direction: row;
flex-wrap: nowrap;
column-gap: 15px !important;
@media only screen and (max-width: 500px) {
  justify-content: space-between;
  
  }

`;

export const MainPropertyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 4%;
  width: 40%;
  @media only screen and (max-width: 1000px) {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 20px ;
    }

`;

export const VirtualImageWriting = styled.p`
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.125em;
  text-align: left;
  color: rgba(18, 40, 76, 1);

`;

export const VirtualVisitMainContainer = styled.button`
  display: flex;
  border: none;
  flex-direction: column;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  width: 100px;
  row-gap: 10px;
  height: 90px;
@media only screen and (max-width: 500px) {
  padding-top: 10px;
  padding-bottom: 10px;
  height: auto;
  min-width: 47.5%;
  }
`;

export const CallWhatsappBookViewButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media only screen and (max-width: 1000px) {
    width: 100%;
    }
`;

export const HeadingProjectMain = styled.p`
  font-family: "Didot";
  font-style: normal;
  font-weight: 400;
  line-height: 146%;
  color: #000000;
  @media only screen and (min-width: 1900px) {
    font-size: 48px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    font-size: 40px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 36px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 30px;
  }
`;

export const ProjectDescriptionContainer = styled.div`
  text-align: justify;
  text-justify: inter-word;
  @media only screen and (min-width: 1920px) {
    padding-top: 50px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    padding-top: 50px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    padding-top: 40px;
  }
  @media only screen and (max-width: 900px) {
    padding-top: 30px;
  }
  @media only screen and (min-device-width: 900px) and (max-device-width: 1200px) and (orientation: portrait) {
    padding-top: 40px;
  }
  @media only screen and (min-device-width: 900px) and (max-device-width: 1200px) and (orientation: landscape) {
    padding-top: 40px;
  }
`;

export const ProjectPara = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  line-height: 146%;
  color: #000000;
  @media only screen and (min-width: 1900px) {
    font-size: 26px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    font-size: 20px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 13px;
  }
`;

export const ContactUs = styled.div`
  cursor: pointer;
  @media only screen and (min-width: 1900px) {
    padding-top: 50px;
    width: 250px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    padding-top: 40px;
    width: 250px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    padding-top: 40px;
    width: 250px;
  }
  @media only screen and (min-width: 500px) and (max-width: 900px) {
    padding-top: 40px;
    width: 250px;
  }
  @media only screen and (max-width: 500px) {
    justify-content: center;
    padding-top: 35px;
    width: 100%;
  }
`;

export const MainButtonThree = styled.div`
  border: 1px solid #000000;
  text-align: center;
  border-radius: 90px;
  padding: 10px 0px;
  min-width: fit-content;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 127%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #12284c;
`;

export const AvailableUnitContainer = styled.div`
  @media only screen and (min-width: 1900px) {
    padding-top: 100px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    padding-top: 100px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    padding-top: 80px;
  }
  @media only screen and (max-width: 900px) {
    padding-top: 50px;
  }
`;

export const AvailableUnitPara = styled.p`
  font-family: "Didot";
  font-style: normal;
  font-weight: 400;
  line-height: 146%;
  color: #000000;
  @media only screen and (min-width: 1900px) {
    font-size: 42px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    font-size: 42px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 36px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 30px;
  }
`;

export const KeyFeatureButtonDiv = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (min-width: 1900px) {
    padding-top: 45px;
    padding-bottom: 39px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    padding-top: 28px;
    padding-bottom: 35px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    padding-top: 18px;
    padding-bottom: 27px;
  }
  @media only screen and (max-width: 900px) {
    padding-top: 18px;
    padding-bottom: 25px;
  }
`;

export const KeyFeatureButtons = styled.button`
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 40px;
  margin-right: 15px;
  font-family: "Montserrat";
  font-style: normal;
  color: #000000;
  line-height: 127%;
  font-weight: 400;
  min-width: fit-content;
  border: 1px solid #decdad;
  &:hover {
    border: 1px solid #000000;
  }
  &:focus {
    border: 1px solid #000000;
  }
  @media only screen and (min-width: 1920px) {
    padding: 10px 60px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    padding: 10px 60px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    padding: 10px 50px;
  }
  @media only screen and (max-width: 900px) {
    padding: 10px 40px;
  }
  @media only screen and (max-width: 400px) {
    padding: 10px 40px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    padding: 10px 50px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    padding: 10px 50px;
  }
`;

export const MainDescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 100px;
  align-items: stretch;
  @media only screen and (max-width: 900px) {
    flex-direction: column-reverse;
    gap: 30px;
  }
`;

export const MainDescriptionHeadingdiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  
  @media only screen and (min-width: 1900px) {
    width: 50%;
    height: 400px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    width: 50%;
    height: 400px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 50%;
    height: 300px;
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
    height: 300px;
  }
`;

export const MainDescriptionHeading = styled.p`
  font-family: "Didot";
  font-style: normal;
  font-weight: 500;
  line-height: 146%;
  color: #000000;
  @media only screen and (min-width: 1900px) {
    font-size: 36px;
    padding-bottom: 30px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    font-size: 36px;
    padding-bottom: 30px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 28px;
    padding-bottom: 25px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 24px;
    padding-bottom: 20px;
  }
`;

export const MainDescriptionPara = styled.p`
  text-align: justify;
  text-justify: inter-word;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  line-height: 146%;
  color: #000000;
  @media only screen and (min-width: 1900px) {
    font-size: 20px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    font-size: 18px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 13px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 14px;
  }
`;

export const MainImageDiv = styled.div`
  background-image: ${(props) => `url(${props.img})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 21px;
  @media only screen and (min-width: 1900px) {
    width: 50%;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    width: 50%;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 50%;
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
    height: 300px;
  }
`;

export const MainImage = styled.img`
  object-fit: cover;
  @media only screen and (min-width: 1900px) {
    width: 664px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    width: 636px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 419px;
  }
  @media only screen and (max-width: 900px) {
    width: 366px;
    height: 370px;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 500px;
    height: 500px;
  }
`;

export const DescriptionPriceDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 0.2px solid #000000;
  padding-top: 52px;
`;

export const DescriptionPrice = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  line-height: 127%;
  letter-spacing: 0.03em;
  color: #000000;
  @media only screen and (min-width: 1900px) {
    font-size: 20px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    font-size: 20px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 14px;
  }
`;

export const PricePara = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  line-height: 127%;
  letter-spacing: 0.06em;
  color: #131d2e;
  @media only screen and (min-width: 1900px) {
    font-size: 20px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    font-size: 20px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 14px;
  }
`;

export const VisitDiv = styled.div`
  margin-top: 56px;
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  border: 1px solid #decdad;
  border-radius: 90px;
  background-color: transparent;
  @media only screen and (min-width: 1900px) {
    width: 250px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    width: 250px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 250px;
  }
  @media only screen and (min-width: 500px) and (max-width: 900px) {
    width: 250px;
  }
  @media only screen and (max-width: 500px) {
    justify-content: center;
    width: 100%;
  }
`;

export const VirtualVisit = styled.button`
  font-family: "Montserrat";
  font-style: normal;
  border: none;
  background-color: transparent;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  letter-spacing: 0.125em;
  color: #000000;
  padding-right: 20px;
`;

export const VirtualSymbol = styled.img`
  width: 25px;
  height: 22px;
`;

export const AboutProjectDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  @media only screen and (min-width: 1900px) {
    padding-top: 100px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    padding-top: 100px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    padding-top: 80px;
  }
  @media only screen and (max-width: 900px) {
    padding-top: 50px;
  }
`;

export const AboutProjectHeading = styled.p`
  font-family: "Didot";
  font-style: normal;
  font-weight: 400;
  line-height: 146%;
  color: #000000;
  @media only screen and (min-width: 1900px) {
    font-size: 64px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    font-size: 42px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 36px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 30px;
  }
`;

export const AboutProjectDescription = styled.p`
  text-align: justify;
  text-justify: inter-word;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  line-height: 146%;
  color: #000000;
  @media only screen and (min-width: 1900px) {
    font-size: 20px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    font-size: 20px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 13px;
  }
`;

export const MobileDiv = styled.div``;

export const MobileDownloadDiv = styled.div`
  background: #12284c;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  border-radius: 72.7434px;
  border: 2px solid #12284c;
  cursor: pointer;
  @media only screen and (min-width: 1920px) {
    width: 400px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 370px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 370px;
  }
  @media only screen and (min-width: 500px) and (max-width: 900px) {
    width: 350px;
  }
  @media only screen and (max-width: 500px) {
    min-width: fit-content;
  }
  @media only screen and (min-device-width: 900px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 370px;
  }
  @media only screen and (min-device-width: 900px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 370px;
  }
`;
