import {put, retry} from "redux-saga/effects";
import { hasError, setLocation } from "../../../slices/location/locationSlice";
import { requestGetDevelopers, requestGetRelDevelopers } from "../../request/developer/developer";
import { setDeveloper } from "../../../slices/developers/developerSlice";


export function* handleDeveloper(action) {
    try {
        const response = yield retry(0,0,requestGetDevelopers,action.payload);
        const {data} = response;
        yield put(setDeveloper(data));
        if (data.length===0) {
            const response = yield retry(0,0,requestGetRelDevelopers,action.payload);
            const {data} = response;  
            yield put(setDeveloper(data)); 
        }
    } catch (error) {
        yield put(hasError(error))
    }
}