import styled from "styled-components";
import Box from "@mui/material/Box";
import ReactWhatsapp from "react-whatsapp";

export const BoxStyle = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: 0;
  border-radius: 20px;
`;

export const PropertyListingSection = styled.section`
  height: auto;
  width: 100%;
  background: #f7f9fa;
  @media only screen and (min-width: 1920px) {
    padding: 6% 0px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    padding: 6% 0px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    padding: 10% 0px;
  }
  @media only screen and (max-width: 900px) {
    padding: 10% 0px;
  }
  @media only screen and (max-width: 400px) {
    padding: 10% 0px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    padding: 10% 0px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    padding: 10% 0px;
  }
`;

export const PropertyIconVisit = styled.img`
  @media only screen and (min-width: 1920px) {
    width: 20px;
    height: 20px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 20px;
    height: 20px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 18px;
    height: 18px;
  }
  @media only screen and (max-width: 900px) {
    width: 16px;
    height: 16px;
  }
  @media only screen and (max-width: 400px) {
    width: 16px;
    height: 16px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 18px;
    height: 18px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 18px;
    height: 18px;
  }
`;
export const PropertyCardContent = styled.div`
  width: 90%;
  margin: auto;

  /* background:#ffffff; */
  @media only screen and (min-width: 1920px) {
    margin-top: 35px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    margin-top: 25px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    margin-top: 25px;
  }
  @media only screen and (max-width: 900px) {
    margin-top: 25px;
  }
  @media only screen and (max-width: 400px) {
    margin-top: 25px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    margin-top: 25px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    margin-top: 25px;
  }
`;

export const ProjectCardContent = styled.div`
  width:90%;
  margin:auto;
`

export const ProjectMainContainer = styled.div`
  background: #ffffff;
  width: 95%;
  margin: auto;
`;
export const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  @media only screen and (min-width: 1920px) {
    margin-top: 32px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    margin-top: 22px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    margin-top: 25px;
  }
  @media only screen and (max-width: 900px) {
    margin-top: 22px;
  }
  @media only screen and (max-width: 400px) {
    margin-top: 22px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    margin-top: 25px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    margin-top: 25px;
  }
`;
export const PropertyIconExplore = styled.img`
  @media only screen and (min-width: 1920px) {
    width: 41px;
    height: 22px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 41px;
    height: 22px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 33px;
    height: 16px;
  }
  @media only screen and (max-width: 900px) {
    width: 33px;
    height: 16px;
  }
  @media only screen and (max-width: 400px) {
    width: 33px;
    height: 16px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 33px;
    height: 18px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 33px;
    height: 18px;
  }
`;
export const PropertyCardButton = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  line-height: 149%;
  letter-spacing: 0.255em;
  color: #131d2e;
  width: 50%;
  display: flex !important;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: 1920px) {
    gap: 10px;
    font-size: 13px;
    padding: 12px 20px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-size: 10px;
    gap: 10px;
    padding: 12px 20px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 9px;
    gap: 8px;
    padding: 12px 20px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 9px;
    gap: 8px;
    padding: 12px 20px;
  }
  @media only screen and (max-width: 400px) {
    font-size: 9px;
    gap: 8px;
    padding: 12px 20px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-size: 9px;
    gap: 8px;
    padding: 12px 20px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-size: 9px;
    gap: 8px;
    padding: 12px 20px;
  }
  &:hover {
    background-color: #12284c;
    color: #ffffff;
    a {
      color: #ffffff;
    }
  }
  a {
    color: #000000;
    display: flex;
    gap: 8px;
    align-items: center;
  }
`;

export const PageContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 33px 0px;
  justify-content: space-evenly;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin: auto;
  padding-bottom: 20px;
  width: 90%;
`;

export const PageIcon = styled.button`
  width: max(5%, 40px);
  height: 31px;
  border: none;
  background: transparent;
  overflow: hidden;
  cursor: pointer;
  color: ${(props) => (props.disabled ? "lightgray" : "black")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
`;

export const PropertyCardDetailWrapper = styled.div`
  display: flex;
  /* gap: 10%; */
  gap: 20px;
  margin-top: 5%;
  justify-content: space-between;
`;
export const CardTitle = styled.h3`
  display: ${(props) => (props.hover ? "none" : "block")};
  font-family: "Montserrat";
  font-style: normal;
  letter-spacing: 0.03em;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media only screen and (min-width: 1920px) {
    font-weight: 600;
    font-size: 20px;
    line-height: 149%;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 600;
    font-size: 18px;
    line-height: 149%;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 600;
    font-size: 16px;
    line-height: 149%;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 600;
    font-size: 16px;
    line-height: 149%;
  }
  @media only screen and (max-width: 400px) {
    font-weight: 600;
    font-size: 16px;
    line-height: 149%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 600;
    font-size: 16px;
    line-height: 149%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 600;
    font-size: 16px;
    line-height: 149%;
  }
`;
export const CardSubText = styled.h5`
  display: ${(props) => (props.hover ? "none" : "block")};
  font-family: "Montserrat";
  font-style: normal;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: #131d2e;
  @media only screen and (min-width: 1920px) {
    font-weight: 400;
    font-size: 16px;
    line-height: 149%;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 149%;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 400;
    font-size: 12px;
    line-height: 149%;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 400;
    font-size: 12px;
    line-height: 149%;
  }
  @media only screen and (max-width: 400px) {
    font-weight: 400;
    font-size: 12px;
    line-height: 149%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 400;
    font-size: 12px;
    line-height: 149%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 400;
    font-size: 12px;
    line-height: 149%;
  }
`;
export const PropertyImage = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
  margin-top: 15px;
  background: ${(props) => `url(${props.img})`};
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  @media only screen and (min-width: 1920px) {
    aspect-ratio: 2 / 1.5;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    aspect-ratio: 2 / 1.5;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    aspect-ratio: 2 / 1.5;
  }
  @media only screen and (max-width: 900px) {
    aspect-ratio: 2 / 1.5;
  }
  @media only screen and (max-width: 400px) {
    aspect-ratio: 2 / 1.5;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    aspect-ratio: 2 / 1.5;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    aspect-ratio: 2 / 1.5;
  }
`;

export const SlideIconRight = styled.button`
  position: absolute;
  top: 50%;
  right: 10px;
  border: 0px solid black;
  border-radius: 50%;
`;

export const SlideIconLeft = styled.button`
  position: absolute;
  top: 50%;
  left: 10px;
  border: 0px solid black;
  border-radius: 50%;
`;

export const CardDescriptionText = styled.p`
  padding-top: 18px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  @media only screen and (max-width: 900px) {
    font-size: 14px;
    }
  @media only screen and (max-width: 400px) {
    font-size: 12px;
    }
`;

export const PriceText = styled.h4`
  margin-top: 15px;
  bottom: 50px;
  font-family: "Montserrat";
  font-style: normal;
  color: #131d2e;
  @media only screen and (min-width: 1920px) {
    font-weight: 600;
    font-size: 24px;
    line-height: 149%;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 600;
    font-size: 22px;
    line-height: 149%;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 600;
    font-size: 20px;
    line-height: 149%;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 600;
    font-size: 20px;
    line-height: 149%;
  }
  @media only screen and (max-width: 400px) {
    font-weight: 600;
    font-size: 20px;
    line-height: 149%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 600;
    font-size: 20px;
    line-height: 149%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 600;
    font-size: 20px;
    line-height: 149%;
  }
`;
export const PropertyCardDetailContainer = styled.div`
  max-width: 35%;
position:relative;
  gap: 10px;
  align-items: center;
  overflow:visible;
`
export const PropertyCardDetailText = styled.h3`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  line-height: 149%;
  color: #131d2e;
  @media only screen and (min-width: 1920px) {
    font-size: 18px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 10px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 8px;
  }
  @media only screen and (max-width: 400px) {
    font-size: 8px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-size: 10px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-size: 10px;
  }
`;
export const PropertyCardHoverText = styled(PropertyCardDetailText)`
  position:absolute;
  bottom:0px;
  padding:2px;
  font-weight: 400;
  background:rgb(237 241 243);
  border-radius:5px;
  `
export const PropertyCardDetailText2 = styled(PropertyCardDetailText)`
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover ${PropertyCardHoverText} {
  display: block; 
    
}
`;



export const ButtonText = styled.button`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #000000;
  @media only screen and (min-width: 1920px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 13px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 15px;
  }
  @media only screen and (max-width: 400px) {
    font-size: 15px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-size: 13px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-size: 13px;
  }
`;

export const WhatsappButtonLandingPage = styled(ReactWhatsapp)`
  border: ${(props) =>
    props.filterprop === "" ? "0.75px solid #000000" : "0.75px solid #DECDAD"};
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #000000;
  border-radius: 90px;
  display: flex;
  align-items: center;
  color: #000000;
  overflow: hidden;
  transition: 0.3s;
  background: transparent;
  min-width: fit-content;
  @media only screen and (min-width: 1920px) {
    height: 40px;
    padding: 10px 38px;
    gap: 24px;
    font-size: 16px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    height: 40px;
    padding: 10px 38px;
    gap: 24px;
    font-size: 16px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    height: 33px;
    padding: 7px 38px;
    gap: 20px;
    font-size: 13px;
  }
  @media only screen and (max-width: 900px) {
    height: 33px;
    padding: 10px 10px;
    gap: 5px;
    font-size: 15px;
  }
  @media only screen and (max-width: 400px) {
    height: 33px;
    padding: 10px 10px;
    gap: 5px;
    font-size: 15px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    height: 33px;
    padding: 7px 40px;
    gap: 20px;
    font-size: 13px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    height: 33px;
    padding: 7px 40px;
    gap: 20px;
    font-size: 13px;
  }
  &:focus {
    border: 0.75px solid #000000;
  }
`;

export const FilterButton = styled(ButtonText)`
  border: ${(props) =>
    props.filterprop === "" ? "0.75px solid #000000" : "0.75px solid #DECDAD"};
  border-radius: 90px;
  display: flex;
  align-items: center;
  color: #000000;
  overflow: hidden;

  transition: 0.3s;
  background: transparent;
  min-width: fit-content;
  @media only screen and (min-width: 1920px) {
    height: 40px;
    padding: 10px 38px;
    gap: 24px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    height: 40px;
    padding: 10px 38px;
    gap: 24px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    height: 33px;
    padding: 7px 38px;
    gap: 20px;
  }
  @media only screen and (max-width: 900px) {
    height: 33px;
    padding: 10px 10px;
    gap: 5px;
  }
  @media only screen and (max-width: 400px) {
    height: 33px;
    padding: 10px 10px;
    gap: 5px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    height: 33px;
    padding: 7px 40px;
    gap: 20px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    height: 33px;
    padding: 7px 40px;
    gap: 20px;
  }
  &:focus {
    border: 0.75px solid #000000;
  }
`;
export const FilterScrollDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media only screen and (min-width: 1300px) {
    margin-top: 25px;
  }
  @media only screen and (max-width: 1300px) {
    margin-top: 39px;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) {
    margin-top: 39px;
  }

  @media only screen and (device-width: 768px) {
    margin-top: 39px;
  }

  /* @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    margin-top: 39px;
  } */

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    margin-top: 39px;
  }
`;
export const FilterContainer = styled.div`
  position: relative;
  display: flex;
  gap: 30px;
  width: 90%;
  margin: auto;
  overflow: auto;
  height: 40px;

  &::-webkit-scrollbar {
    display: none;
  }
`;
export const HeaderText = styled.h1`
  overflow: visible;
  font-family: "Didot";
  font-style: normal;
  color: #000000;
  @media only screen and (min-width: 1920px) {
    font-weight: 400;
    font-size: 64px;
    line-height: 81px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 400;
    font-size: 48px;
    line-height: 61px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 400;
    font-size: 36px;
    line-height: 46px;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
  }
  @media only screen and (max-width: 400px) {
    font-weight: 400;
    font-size: 26px;
    line-height: 40px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 400;
    font-size: 36px;
    line-height: 46px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 400;
    font-size: 36px;
    line-height: 46px;
  }
`;

export const SubText = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  color: #000000;
`;
export const CtaButton = styled(FilterButton)`
  background: #12284c;
  color: #ffffff;
  margin-left: auto;
  padding: 10px 24px;
  letter-spacing: 0.125em;
  border: none;
  &:hover {
    border: none;
    background: #004876;
  }
  &:focus {
    border: none;
    background: #004876;
  }
  @media only screen and (max-width: 500px) {
    padding: 10px 16px;
    font-size: 10px;
  }
  // Only for Ipad

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) {
    padding: 10px 24px;
  }

  @media only screen and (device-width: 768px) {
    padding: 10px 24px;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    padding: 10px 24px;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    padding: 10px 24px;
  }
`;

export const ScrollButton = styled.button`
  width: 37px;
  height: 5px;
  border: none;
  background: ${(props) => (props.active ? "#12284c" : "#d6d9db")};
  &:hover {
    background: #12284c;
  }
`;

export const ScrollContainer = styled.div`
  display: flex;
  justify-content: center;
  @media only screen and (min-width: 1920px) {
    margin-top: 80px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    margin-top: 70px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    margin-top: 50px;
  }
  @media only screen and (max-width: 900px) {
    margin-top: 50px !important;
    width: 80%;
    margin: auto;
  }
  @media only screen and (max-width: 400px) {
    margin-top: 50px !important;
    width: 80%;
    margin: auto;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    margin-top: 50px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    margin-top: 50px;
  }
`;

export const PropertyScrollContainer = styled.div`
  padding: 20px;
  width: 90%;
  margin: auto;
  overflow-x: auto;
  display: flex;
  scroll-behavior: smooth;
  @media only screen and (min-width: 1920px) {
    gap: 2.5%;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    gap: 2.5%;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    gap: 5%;
  }
  @media only screen and (max-width: 900px) {
    gap: 5%;
  }
  @media only screen and (max-width: 400px) {
    gap: 5%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    gap: 5%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    gap: 5%;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  // Ipad

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) {
    gap: 5%;
  }

  @media only screen and (device-width: 768px) {
    gap: 5%;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    gap: 5%;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    gap: 5%;
  }
`;
export const SinglePropertyCard = styled.div`
  overflow: hidden;
  box-shadow: 0px 0px 15px 5px rgb(161 161 161 / 19%);
  border-radius: 10px;
  border: transparent;
  position: relative;
  height: auto;
  @media only screen and (min-width: 1920px) {
    min-width: 31.6%;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    min-width: 31.6%;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    min-width: 47.5%;
  }
  @media only screen and (max-width: 900px) {
    min-width: 100%;
  }
  @media only screen and (max-width: 400px) {
    min-width: 100%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    min-width: 47.5%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    min-width: 47.5%;
  }
`;

export const SingleProjectCard = styled.div`
  height: auto;
  @media only screen and (min-width: 1920px) {
    min-width: 31.6%;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    min-width: 31.6%;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    min-width: 47.5%;
  }
  @media only screen and (max-width: 900px) {
    min-width: 100%;
  }
  @media only screen and (max-width: 400px) {
    min-width: 100%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    min-width: 47.5%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    min-width: 47.5%;
  }
`;
export const ModalDiv = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  margin-top: 20px;
`;
export const ModalInput = styled.input`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  background: none;
  border: none;
  padding-bottom: 8px;
  border-bottom: 0.55px solid #ffffff;
  color: #ffffff;
  width: 220px;
`;

export const ModalButton = styled.button`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  color: #000000;
  letter-spacing: 0.125em;
  padding: 10px 24px;
  border-radius: 20px;
`;
