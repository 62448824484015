import { createSlice } from '@reduxjs/toolkit';
const MortageSlice = createSlice({
    name: 'Mortage',
    initialState: {
        price:0,
        downPayment:0,
        interest:1,
        duration:0,
        amount:0,
    },
    reducers: {
        getMortage(state, action) {
            state[action.payload.key] = action.payload.value;
            const principalAmount = state.price-state.downPayment;
            const result = (principalAmount+(principalAmount * state.interest)) / state.duration;
            state.amount = Math.round(result);
          
        },
       
    },
});
export const { getMortage } = MortageSlice.actions;

export default MortageSlice.reducer;