import { createSlice } from '@reduxjs/toolkit'
// Slice

const agentSlice = createSlice({
    name: 'agents',
    initialState: {
        agents: [],
        isLoading: false,
        error: false,
    },
    reducers: {
        startLoading: state => {
            state.isLoading = true;
         },
        hasError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        getAgents(state, action) {            
        },
        setAgentsContent(state, action) {
            state.agents = action.payload;
            state.isLoading = false;
        }
    },
});


export const { setAgentsContent, getAgents, hasError } = agentSlice.actions;

export default agentSlice.reducer
