import { createSlice } from '@reduxjs/toolkit';

const testimonialSlice = createSlice({
    name: 'testimonial',
    initialState: {
        comments:[],
    },
    reducers: {
        getComments(state, action) {},
        setComments(state, action) {
            state.comments = action.payload;
        },
    },
});
export const { getComments, setComments  } = testimonialSlice.actions;

export default testimonialSlice.reducer;