import React, { useState, useEffect } from "react";
import "./style.css";
import {
  BookViewContainer,
  CTAbutton,
  CalculatorDiv,
  CalltoActionButton,
  CalltoActionContainer,
  Map,
  MapContainer,
  MonthlyInstallmentConatiner,
  MonthlyInstallments,
  MortageContainer,
  MortageDiv,
  MortageExpert,
  MortageHeading,
  PropertyPrice,
  PropertyPriceContainer,
  PropertyPriceDiv,
  PropertyPriceMain,
} from "./PropertyDetail.styled";
import DownPayment from "./DownPayment";
import { Duration } from "./Duration";
import InterestSlider from "./InterestSlider";
import MortgagePicture from "../../assets/images/Mortgage.png";
import Contact from "../ContactUs/Contact";
import { useDispatch, useSelector } from "react-redux";
import { getMortage } from "../../redux/slices/MortageSlice/MortageSlice";
import GoogleMapFile from "./GoogleMapFile";
import Modal from "@mui/material/Modal";
import BrochuerPopUp from "../PopUps/BrochuerPopUp";
import { BoxStyle } from "../PropertyListing.js/PropertyListing.styled";
import { showSnackBar } from "../../redux/slices/snackbarSlice/snackbarSlice";

export const MortageCalculator = ({ details }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  if (details.attributes.longitude && details.attributes.latitude) {
    var longitude = details.attributes.longitude;
    var latitude = details.attributes.latitude;
  }
  const dispatch = useDispatch();
  const { amount } = useSelector((state) => state.mortage);
  const { convertValue, currency } = useSelector(
    (state) => state.unitConversion
  );
  const convertedCurrency = Math.round(details.attributes.price * convertValue);
  useEffect(() => {
    dispatch(getMortage({ key: "price", value: details.attributes.price }));
  });
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  return (
    <>
      <MapContainer>
        {details &&
          details.attributes &&
          details.attributes.latitude &&
          details.attributes.longitude && (
            <Map name="Location">
              <GoogleMapFile
                longitude={longitude}
                latitude={latitude}
                data="properties"
              />
            </Map>
          )}
      </MapContainer>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <BoxStyle>
          <BrochuerPopUp
            setOpen={setOpen}
            parameter="Get in Touch"
            button="Submit"
            msg="The message is received from mortgage calculator"
            para="You want to finance in a real estate and looking for some information regarding mortgage? Please get in touch with us."
          />
        </BoxStyle>
      </Modal>
      {details.attributes.listing_category === "buy" && (
        <>
        <MortageContainer>
          <MortageDiv>
          <MortageHeading>Mortage Calculator</MortageHeading>
            <CalculatorDiv>
              <PropertyPriceDiv>
                <PropertyPriceContainer>
                  <PropertyPrice> Property price: </PropertyPrice>
                  <PropertyPriceMain>
                    {convertedCurrency.toLocaleString()} {currency}
                  </PropertyPriceMain>
                </PropertyPriceContainer>
              </PropertyPriceDiv>
              <InterestSlider />
              <DownPayment />
              <Duration />
              <CalltoActionContainer>
                <CalltoActionButton>
                  <CTAbutton onClick={handleOpen}>CONTACT US</CTAbutton>
                </CalltoActionButton>
                <MonthlyInstallmentConatiner>
                  <PropertyPrice> Monthly Installments: </PropertyPrice>
                  <MonthlyInstallments>
                    {" "}
                    {Math.round(amount * convertValue).toLocaleString()}{" "}
                    {currency}
                  </MonthlyInstallments>
                </MonthlyInstallmentConatiner>
              </CalltoActionContainer>
            </CalculatorDiv>
          </MortageDiv>
          {width > 1000 && <MortageExpert src={MortgagePicture} alt="" />}
        </MortageContainer>
        </>
      )}
      <BookViewContainer name="BookView">
        <Contact
          displaymsg="block"
          displayupload="none"
          title="Book Viewing"
          backgroundColor="white"
          marginBottom="20px"
        />
      </BookViewContainer>
    </>
  );
};
