
import React, { useEffect, useRef, useState } from "react";
import {
  ButtonContainer,
  DropdownContainer,
  DropdownDiv,
  InputCheckbox,
  InputContainer,
  InputFlexButton,
  InputFlexDiv,
  InputText,
  InputTextBox,
  MacImage,
  MessageDiv,
  MessageInput,
  PrefCtaButton,
  PreferenceButton,
  PreferenceContentContainer,
  PreferenceContentDiv,
  PreferenceHeaderText,
  PreferenceScreenText,
  PreferenceSection,
  PreferenceSubtext,
  ScreenDiv,
  VideoDiv,
} from "./PreferenceScreen.styled";
import Mac from "../../assets/images/PreferenceVideo.mov";
import { showSnackBar } from "../../redux/slices/snackbarSlice/snackbarSlice";
import { useDispatch } from "react-redux";
import { postPreference } from "../../redux/slices/preferenceSlice.js/preferenceSlice";
import { DownToggleIcon } from "../Footer/Footer.styled";



const defaultFormInput = {
  listing_category: "Rent",
  area: "",
  unit_type: "",
  budget: "",
  name: "",
  phone: "",
  email: "",
  requirements: "",
  checked: "",
};
const PreferenceScreen = () => {
  const [formInput, setFormInput] = useState(defaultFormInput);
  const [active,setActive] = useState(false)
  const [isBottomVisible, setIsBottomVisible] = useState(false);
  // const [distance, setDistance] = useState()
  const containerRef = useRef()
  const dispatch = useDispatch();

  useEffect(() => {
    function handleScroll() {
      if (containerRef.current) {
        const bottom = containerRef.current.getBoundingClientRect().bottom;
        const windowHeight = window.innerHeight;
        setIsBottomVisible(bottom <= windowHeight);
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [containerRef]);


  
  const handleInputChange = (name, value) => {
    setFormInput({
      ...formInput,
      [name]: value,
    });
  };
  const snackbarObject = {
    type: '',
    message: '',
    open: false,
  };
  
  const dispatchSnackBar = (type, message, open) => {
    snackbarObject.type = type;
    snackbarObject.message = message;
    snackbarObject.open = open;
    dispatch(showSnackBar(snackbarObject));
  };

  const handleContactUs = (e) => {
    e.stopPropagation();
  if (formInput.area === '') {
      dispatchSnackBar('error', 'Please enter community', true);
  }
  else if (formInput.budget === '') {
    dispatchSnackBar('error', 'Please enter Budget', true);
}
    else if (formInput.name === '') {
      dispatchSnackBar('error', 'Please enter Name', true);
  } else if (formInput.phone === '') {
      dispatchSnackBar('error', 'Please enter Phone', true);
  } else if (formInput.email === '') {
      dispatchSnackBar('error', 'Please enter Email', true);
  } else if (!formInput.email.includes('@') || !formInput.email.includes('.com')) {
    dispatchSnackBar('error', 'Please enter valid Email', true);
}  else if (formInput.requirements === '') {
    dispatchSnackBar('error', 'Please enter Message')
  }
  else if (!formInput.checked) {
    dispatchSnackBar('error', 'Please accept our user terms and conditions',true)
  } else {
    dispatchSnackBar('success', 'Your Details has been saved')
    dispatch(postPreference(formInput));
    setFormInput(defaultFormInput)
  }
};

  return (
    <PreferenceSection ref={containerRef}>
      <ScreenDiv>
        <VideoDiv position = {isBottomVisible} distance={1400-(window.innerHeight / 2)} >
          <PreferenceScreenText>Learn how it works?</PreferenceScreenText>
          <MacImage src={Mac} alt="" autoPlay loop muted playsInline style={{width:'100%', height:'auto'}}/>
        </VideoDiv>

      </ScreenDiv>
      <PreferenceContentContainer>
        <PreferenceContentDiv>
          <PreferenceHeaderText>Need Some Help?</PreferenceHeaderText>
          <PreferenceSubtext>Let’s Find the Right Property for You.</PreferenceSubtext>
          <ButtonContainer>
            <PreferenceButton active={formInput.listing_category==='Rent'} onClick={() => handleInputChange("listing_category", "Rent")}>
              Rent
            </PreferenceButton>
            <PreferenceButton active={formInput.listing_category==='Buy'} onClick={() => handleInputChange("listing_category", "Buy")}>
              Buy
            </PreferenceButton>
            <PreferenceButton
              active={formInput.listing_category==='Invest'} onClick={() => handleInputChange("listing_category", "Invest")}
            >
              Invest
            </PreferenceButton>
          </ButtonContainer>
          <InputContainer>
            <InputText
              name="area"
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}
              value={formInput.area}
              id="community"
              placeholder="Enter area or community"
            ></InputText>
          </InputContainer>
          <DropdownDiv>
          <InputContainer onClick={()=>setActive(!active)}>
           <InputTextBox>{formInput.unit_type===''? 'Unit Type' : formInput.unit_type}</InputTextBox>
          <DownToggleIcon animate={active}/>
          </InputContainer>
          <DropdownContainer active={active}>
            <InputTextBox onClick={()=>{setActive(false); handleInputChange('unit_type','All')}}>All</InputTextBox>
            <InputTextBox onClick={()=>{setActive(false); handleInputChange('unit_type','Apartment')}}>Apartment</InputTextBox>
            <InputTextBox onClick={()=>{setActive(false); handleInputChange('unit_type','Villa')}}>Villa</InputTextBox>
            <InputTextBox onClick={()=>{setActive(false); handleInputChange('unit_type','Townhouse')}}>Townhouse</InputTextBox>
            <InputTextBox onClick={()=>{setActive(false); handleInputChange('unit_type','Penthouse')}}>Penthouse</InputTextBox>
            <InputTextBox onClick={()=>{setActive(false); handleInputChange('unit_type','Commercial')}}>Commercial</InputTextBox>
          </DropdownContainer>
          </DropdownDiv>
          <InputContainer>
            <InputText 
            name="budget"
            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
            value={formInput.budget}
            id="budget"
            placeholder="Enter your budget e.g AEDxxx">
            </InputText>
          </InputContainer>
          <InputFlexDiv>
            <InputFlexButton>
              <InputText
              name="name"
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}
              value={formInput.name}
              id="name" 
              placeholder="Your Name"></InputText>
            </InputFlexButton>
            <InputFlexButton>
              <InputText 
              name="phone"
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}
              value={formInput.phone}
              id="phone" 
              placeholder="Phone no"></InputText>
            </InputFlexButton>
          </InputFlexDiv>
          <InputContainer>
            <InputText name="email"
              id="email" 
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}
              value={formInput.email}
            placeholder="Email"></InputText>
          </InputContainer>
          <MessageDiv>
            <MessageInput
            name="requirements"
            id="requirements" 
            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
            value={formInput.requirements}
              placeholder="Please let us know your requirements in order for us to serve you better (ex. near schools, property types such as  apartment/villa,  etc.)"
              rows={12}
            ></MessageInput>
          </MessageDiv>
          <InputCheckbox>
            <input name= 'checked' checked={formInput.checked} type="checkbox" onChange={(e)=>handleInputChange(e.target.name, e.target.checked)}/>I have read and agree to the Privacy Policy.
          </InputCheckbox>
          <PrefCtaButton onClick={handleContactUs}>BEGIN ENQUIRY</PrefCtaButton>
        </PreferenceContentDiv>
      </PreferenceContentContainer>
    
    </PreferenceSection>
  );
};

export default PreferenceScreen;
