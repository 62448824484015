import React from 'react'
import { ServiceCardDiv, ServiceButton, ServiceCardImg, ServiceText} from './OurService.styled'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const ServiceCard = ({data}) => {
    const { t } = useTranslation();
  return (
    <ServiceCardDiv>
              <ServiceCardImg src={data.imgSrc} alt="" />
              
              <ServiceText>{data.text}</ServiceText>
              <Link to={data.link}>
                <ServiceButton>{t("Learn More")}</ServiceButton>
              </Link>
            </ServiceCardDiv>
  )
}

export default ServiceCard