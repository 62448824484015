import React from 'react'
import ln from "../../assets/images/lnfill.svg";
import Pc1 from "../../assets/images/Rectangle.png";
import { BsChat } from "react-icons/bs";
import { scroller } from 'react-scroll'


import { TeamAboutCard, TeamCardImage, TeamCardJobTitle, TeamCardName, TeamDiv, TeamIcon, TeamJobDiv } from './MeetTheTeam.styled'

const TeamSingleCard = ({data, agentHandler}) => {
  const link = `https://wa.me/${data.attributes.whatsapp}`;
  const handleClick = () => {
    scroller.scrollTo('TeamCard', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -200
    })
  }
    return (
    <TeamAboutCard onClick={() => {agentHandler(data.id); handleClick()}}>
      <TeamCardImage  src={data.attributes.image.data?data.attributes.image.data.attributes.url: Pc1} alt=''/>
      <TeamJobDiv>
               <div>
            {data && data.attributes && data.attributes.name && <TeamCardName>{data.attributes.name}</TeamCardName>}
            {data && data.attributes && data.attributes.job_title && <TeamCardJobTitle>{data.attributes.job_title}</TeamCardJobTitle>}
            {data && data.attributes && data.attributes.language && <TeamCardJobTitle>{data.attributes.language}</TeamCardJobTitle>}
           </div>
            <a href={link}><BsChat size='25px'/></a>
             </TeamJobDiv>

    </TeamAboutCard>
  )
}

export default TeamSingleCard