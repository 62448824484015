import { createSlice } from '@reduxjs/toolkit';
const preferenceSlice = createSlice({
    name: 'user',
    initialState: {
        type: "Rent",
        community: "",
        unittype: "",
        budget: "",
        name: "",
        phone: "",
        email: "",
        message: "",
        checked: "",
    },
    reducers: {
        postPreference(state, action) {},
        setPreference(state, action) {
            const preferenceData = action.payload;
            state.preferenceObj = preferenceData;
        },
    },
});
export const { postPreference, setPreference  } = preferenceSlice.actions;

export default preferenceSlice.reducer;