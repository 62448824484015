import React from "react";
import {
  WhoAreWeSection,
  WhoWeAreContainer,
  WhoWeAreTextContainer,
} from "./Vision.styled";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const WhoWeAre = ({ wrap, button, image, header, desc, cta }) => {
  const { t } = useTranslation();
  return (
    <WhoAreWeSection>
      <WhoWeAreContainer wrap={wrap}>
        <WhoWeAreTextContainer button={button}>
          <h1>{t(header)}</h1>
          <p>{t(desc.split('$')[0])}</p>
          <p>{t(desc.split('$')[1])}</p>
         <Link to= '/contactus'> <button>
            <PlayArrowIcon />
            <h4>{t(cta)}</h4>
          </button></Link>
        </WhoWeAreTextContainer>
        <img src={image} alt=""/>
      </WhoWeAreContainer>
    </WhoAreWeSection>
  );
};

export default WhoWeAre;
