import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { hideSnackBar } from "../../redux/slices/snackbarSlice/snackbarSlice";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomizedSnackbar = () => {
  const dispatch = useDispatch();

  const { open, message, type } = useSelector((state) => state.snackbar);
  
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(hideSnackBar());
  };

  return (
    <div>
      
      <Snackbar 
      anchorOrigin={{ vertical:'top' , horizontal:'center'}}
      open={open} 
      autoHideDuration={2000} 
      onClose={handleClose}
      key='top center'
      sx={{zIndex:'99999'}}
      >
        <Alert onClose={handleClose} severity={type} sx={{ width: "500px", height:'auto',fontSize:'22px', alignItems:'center' }}>
{message}
</Alert>
      </Snackbar>
      </div>
  );
};

export default CustomizedSnackbar;
