import styled from "styled-components";

export const SearchDiv = styled.div`
    width:100%;
    height:100vh;
    color:white;
    background:#12284C;
`
export const FieldDiv = styled.div`
position:relative;
  top: 50%;
  transform: translateY(-50%);
  height:600px;
  @media (min-width: 900px) {
        width:75%;
        margin:auto
    }
    @media (max-width: 900px) {
        width:90%;
        margin:auto
        }
`
export const SearchText = styled.h1`
position: relative;
width:fit-content;
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 35px;
line-height: 55px;
color: #FFFFFF;
@media (max-width: 900px) {
    font-size:20px;
    }
`

export const TextDiv = styled.div`
    display:flex;
    gap:50px;
    margin-bottom:50px;
    
`
export const SearchInputDiv = styled.div`
padding:0px 20px;
        display:flex;
        align-items:center;
    width:100%;
    border: 0.75px solid #DECDAD !important;
    background:transparent;
    justify-content:space-between;
    border-radius:20px;
    div {
        display:flex;
        gap:20px;
        align-items:center;
        @media (max-width: 900px) {
    gap:10px;
    width:145px;
    }
    }
    button {
        border:transparent;
        background:transparent;
        font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 30px;
line-height: 35px;
color: #FFFFFF;
overflow:hidden;
min-width:fit-content;
@media (max-width: 900px) {
    font-size:16px;
    }
    }
    @media (min-width: 900px) {
    height:100px;
    }
@media (max-width: 900px) {
    heigth:50px;
    }
`
export const LocationDiv = styled(SearchInputDiv)`
display:${props=>props.areaActive ? 'block' :'none'};
padding-top:10px;
    margin-top:10px;
    height:auto;
    button {
        padding:15px;
        width:100%;
        text-align:left;
        &:hover {
            background: #DECDAD;
            border-radius:20px;
    color:black;
        }
    }
    h1 {
        font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 30px;
line-height: 35px;
color: #FFFFFF;
    }
`
export const SearchInput = styled.input`
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 30px;
line-height: 55px;
color: #FFFFFF;
background:transparent;
border:transparent;
&::placeholder {
    color: gray; 
}
@media (max-width: 900px) {
    font-size:20px;
    }
`

export const Vertical = styled.div`
  border-right:0.3px solid #FFFFFF;
  height:50px;
  @media (max-width: 900px) {
    height:20px;
    }
`