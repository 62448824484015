import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import Privacy from '../components/PrivacyPolicy/Privacy'

const PrivacyPolicy = () => {
  return (
    <div>
        <Navbar background='#12284C'/>
        <Privacy/>
    </div>
  )
}

export default PrivacyPolicy