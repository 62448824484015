import { createSlice } from '@reduxjs/toolkit';
const usersSlice = createSlice({
    name: 'user',
    initialState: {
        name: '',
        email: '',
        phone: '',
        message:'',
        document:'',
    },
    reducers: {
        postContactUs(state, action) {},
        setContactUs(state, action) {
            const contactUsData = action.payload;
            state.userObj = contactUsData;
        },
    },
});
export const { postContactUs, setContactUs  } = usersSlice.actions;

export default usersSlice.reducer;