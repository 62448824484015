import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar/Navbar";
import ProjectHero from "../components/Project/ProjectHero";
import FooterMobile from "../components/Footer/FooterMobile";
import Footer from "../components/Footer/Footer";
import { useParams } from "react-router-dom";
import { ProjectInter } from "../components/Project/ProjectInter";
import ProjectCommercial from "../components/Project/ProjectCommercial";
import { useDispatch } from "react-redux";
import { getFutureProjects } from "../redux/slices/projectSlice/getFutureProject";

const TabletBreakpoint = 1024;
const ProjectPageLuxury = () => {
  const { id } = useParams();
  const [offset, setOffset] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const dispatch  = useDispatch()
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    window.scrollTo(0, 0);
    dispatch(getFutureProjects(id));
  }, []);
  useEffect(()=>{
    dispatch(getFutureProjects(id));
  },[id])
  
  const [background, setBackground] = useState("transparent");
  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  useEffect(() => {
    if (offset > 100) setBackground("#12284C");
    else setBackground("transparent");
  }, [offset]);

  if (id === "ready"){
   
    return (
      <div>
        <Navbar background={background} />
        <ProjectHero />  
        {width < TabletBreakpoint ? <FooterMobile /> : <Footer />}
      </div>
    );

  }

  if (id === "offplan"){
   
    return (
      <div>
        <Navbar background={background} />
          <ProjectHero/>
        {width < TabletBreakpoint ? <FooterMobile /> : <Footer />}
      </div>
    );  
  }

  // if (id === "commercial"){
     
  // return (
  //   <div>
  //     <Navbar background={background} />
  //     <ProjectHero />
  //     {width < TabletBreakpoint ? <FooterMobile /> : <Footer />}
  //   </div>
  // );
  // }
};

export default ProjectPageLuxury;
