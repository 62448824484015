import React from 'react'
import { VideoCard, VideoDesc, VideoHeaderText } from './Video.styled'
import ReactPlayer from 'react-player'
import YouTube from 'react-youtube';



const Video = ({header, desc, headerDisplay, descDisplay,align, marginTop, videoContent}) => {
  return (
    <VideoCard>
      <div>
      <VideoHeaderText headerDisplay={headerDisplay} align={align} marginTop={marginTop}>{header} </VideoHeaderText>
      <VideoDesc descDisplay={descDisplay}>{desc}</VideoDesc>
      </div>
      <video src = {videoContent} autoPlay loop muted playsInline style={{width:'100%', height:'auto'}}/>
    </VideoCard>
  )
}

export default Video