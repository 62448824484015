import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getPressCenter } from "../../redux/slices/pressCenter/pressCenter";
import BlogCard from "../PressCenter/BlogCard";

import PressReleaseCard from "../PressCenter/PressReleaseCard";

import {
  FilterScrollDiv,
  HeaderContainer,
} from "../PropertyListing.js/PropertyListing.styled";
import { motion } from "framer-motion";
import {
  NewsCtaButton,
  NewsFilterButton,
  NewsFilterContainer,
  NewsHeader,
  NewsPageContainer,
  NewsSection,
} from "./News.styled";
import { useTranslation } from "react-i18next";
// import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

const News = ({ data, width }) => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState("blog");
  const {t} = useTranslation()
  useEffect(() => {
    const pressObject = {
      property: filter,
      page: 1,
    };
    dispatch(getPressCenter(pressObject));
  }, [filter, dispatch]);
  return (
    <NewsSection>
      <HeaderContainer>
        <NewsHeader>News and Insights</NewsHeader>
        {width < 700 && (
          <NewsCtaButton>
            {" "}
            <Link to={`/${filter}`}>LOAD MORE</Link>
          </NewsCtaButton>
        )}
      </HeaderContainer>
      <FilterScrollDiv>
        <NewsFilterContainer>
          <NewsFilterButton
            filterprop={filter === "blog"}
            onClick={() => setFilter("blog")}
          >
            {t('Blog')}
          </NewsFilterButton>
          <NewsFilterButton onClick={() => setFilter("press_release")}>
            {t('Press Release')}
          </NewsFilterButton>
          {/* <NewsFilterButton onClick={()=>setFilter('podcast')}>Podcasts</NewsFilterButton>
                <NewsFilterButton onClick={()=>setFilter('webinar')}>Webinars</NewsFilterButton> */}
          {width > 700 && (
            <NewsCtaButton>
              {" "}
              <Link to={`/${filter}`}>{t('LOAD MORE')}</Link>
            </NewsCtaButton>
          )}
        </NewsFilterContainer>
      </FilterScrollDiv>
      <motion.div
        key={filter}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1 }}
      >
        {filter === "press_release" && (
          <NewsPageContainer>
            <PressReleaseCard data={data[1]} />
            {width > 900 && <PressReleaseCard data={data[2]} />}
          </NewsPageContainer>
        )}
        {filter === "blog" && (
          <NewsPageContainer>
            <BlogCard data={data[0]} />
            {width > 900 && <BlogCard data={data[1]} />}
            {/* {data[1] && width > 700 && <BlogCard data={data[1]} />}
            {data[2] && width > 1300 && <BlogCard data={data[2]} />}
            {data[3] && width > 1300 && <BlogCard data={data[3]} />} */}
          </NewsPageContainer>
        )}
        {/* {filter === 'podcast' && <NewsPageContainer>
            <PodcastCard data={data[1]}/>
            {width>900 && <PodcastCard data={data[2]}/>}
            </NewsPageContainer>}
            {filter === 'webinar' && <NewsPageContainer>
            <WebinarCard data={data[1]}/>
            </NewsPageContainer>} */}
      </motion.div>
    </NewsSection>
  );
};

export default News;
