import {
  DropDownMenu,
  BoxItem,
  PaperCSS,
  PopperCSS,
  MenuItemCss,
  MenuListCSS,
} from "./propertylisting.styled";
import React, { useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useDispatch } from "react-redux";
import { setSearchContent } from "../../redux/slices/herosearch/herosearchSlice";
import { useEffect } from "react";
import { setProjectSearchContent } from "../../redux/slices/heroProjectSearch/heroProjectSearchSlices";
import { useTranslation } from "react-i18next";

const unitType = [
  "Any",
  "Apartment",
  "Villa",
  "Townhouse",
  "Penthouse",
  "Commercial",
];

export const UnitTypeButton = ({
  data,
  border,
  color,
  arrow,
  menuback,
  menucolor,
  menuitemcolor,
  menuitembackgroundhover,
  activecolor
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [setOpen] = React.useState(false);
  const open = Boolean(anchorEl);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [unittype, setUnittype] = useState("Unit Type");
  const { t } = useTranslation();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }
  const dispatch = useDispatch();
  useEffect(() => {
    if (data === "property") {
      if (unittype === "Any")
        dispatch(setSearchContent({ key: "unittype", value: "" }));
      else dispatch(setSearchContent({ key: "unittype", value: unittype }));
    }
    if (data === "project")
      dispatch(setProjectSearchContent({ key: "unittype", value: unittype }));
  }, [unittype, data]);

  return (
    <>
      <BoxItem
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        border={border}
        color={color}
      >
        <DropDownMenu
          style={{
            color: unitType[selectedIndex] ? activecolor : "#C0C0C0",
          }}
        >
          {unitType[selectedIndex] || "Unit Type"}
        </DropDownMenu>
        <RiArrowDropDownLine
          size={40}
          style={{ marginRight: "15px" }}
          color={arrow}
        />
      </BoxItem>
      <PopperCSS
        menuback={menuback}
        border={border}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <PaperCSS menuback={menuback} menucolor={menucolor}>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuListCSS autoFocusItem={open} onKeyDown={handleListKeyDown}>
              {unitType.map((option, index) => (
                <MenuItemCss
                  key={option}
                  menuitemcolor={menuitemcolor}
                  menuitembackgroundhover={menuitembackgroundhover}
                  selected={index === selectedIndex}
                  onClick={(event) => {
                    handleMenuItemClick(event, index);
                    setUnittype(option);
                  }}
                >
                  {t(option)}
                </MenuItemCss>
              ))}
            </MenuListCSS>
          </ClickAwayListener>
        </PaperCSS>
      </PopperCSS>
    </>
  );
};
