import CookieConsent from "react-cookie-consent";

import React from 'react'
import { useNavigate } from "react-router-dom";

const CookieBanner = () => {
    const navigate = useNavigate()
  return (
    <CookieConsent
    enableDeclineButton
    flipButtons
  location="bottom"
  buttonText="ACCEPT COOKIES"
  declineButtonText='Privacy Policy'
  cookieName="myAwesomeCookieName2"
  style={{ background: 'white' , height:'auto', display:'flex', alignItems:'center',padding:'0px 50px'}}
  buttonStyle={{ color: "#FFFFFF", fontSize: "20px" , background:'#12284C', width:'250px', height:'50px',fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: '400', lineHeight: '163%', letterSpacing: '0.03em', borderRadius:'25px'}}
  declineButtonStyle={{ color: "#000000", fontSize: "20px" , background:'transparent', width:'250px', height:'77px',fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: '400', lineHeight: '163%', letterSpacing: '0.03em',textDecoration:'underline'}}
  contentStyle={{fontFamily: 'Montserrat', fontStyle: 'normal',fontWeight: '400', fontSize: {xs:'12px',lg:'21px'}, lineHeight: '163%', letterSpacing: '0.03em', color: '#000000'}}
  expires={150}
onDecline={()=>navigate('/privacy')}
>
We have placed cookies on your device to help make this website better. You can use the browser settings to change your cookie settings. Otherwise, we'll assume you're OK to continue.
</CookieConsent>

  )
}

export default CookieBanner