import {takeLatest} from 'redux-saga/effects';
import { handleProperties, handleFeatureProperties, handlePropertiesDetails} from "./handlers/properties/properties";
import { getIndex, getProperties, getPropertiesDetails } from '../slices/propertiesSlices/getProperties'
import { getFeatureProperties } from '../slices/propertiesSlices/getFeatureProperties';
import { getProjects, getProjectsDetail} from '../slices/projectSlice/getProject'
import {handleProjects, handleFeatureProjects, handleProjectDetails} from '../sagas/handlers/projects/project'
import { getFutureProjects } from '../slices/projectSlice/getFutureProject'
import { getAgents } from '../slices/agentSlice/getAgent';
import { handleAgents } from './handlers/agents/agents';
import { getCurrency } from '../slices/unitConversion/unitConversion';
import { handleCurrency } from './handlers/unitConversion/unitCoversion';
import { handleContactUs } from './handlers/contactUs/contactUs'
import { postContactUs } from '../slices/contactUs/contactUsSlices';
import { setSearch } from '../slices/herosearch/herosearchSlice';
import { handleSearch } from './handlers/herosearch/herosearch';
import { getComments } from '../slices/testimonials/testimonialSlice';
import { handleTestimonial } from './handlers/testimonials/testimonial';
import { handlePressCenter, handlePressCenterDetail } from './handlers/pressCenter/pressCenter';
import { getPressCenter, getPressCenterDetail } from '../slices/pressCenter/pressCenter';
import { setProjectSearch } from '../slices/heroProjectSearch/heroProjectSearchSlices';
import { postPreference } from '../slices/preferenceSlice.js/preferenceSlice';
import { handlePreference } from './handlers/preference/preference';
import { postSellPreference } from '../slices/SellPreferenceSlice.js/SellPreferenceSlice';
import { handleSellPreference } from './handlers/SellPreference/SellPreference';
import { getLocation } from '../slices/location/locationSlice';
import { handleLocation } from './handlers/location/location';
import { getDeveloper } from '../slices/developers/developerSlice';
import { handleDeveloper } from './handlers/developer/developer';

export function* watchSaga() {
    yield takeLatest(getProperties, handleProperties);
    yield takeLatest(getFeatureProperties, handleFeatureProperties);
    yield takeLatest(getProjects, handleProjects);
    yield takeLatest(getFutureProjects, handleFeatureProjects);
    yield takeLatest(getAgents, handleAgents);
    yield takeLatest(getIndex, handleProperties);
    yield takeLatest(getCurrency, handleCurrency);
    yield takeLatest(postContactUs, handleContactUs);
    yield takeLatest(postPreference, handlePreference);
    yield takeLatest(postSellPreference, handleSellPreference);
    yield takeLatest(setSearch, handleSearch);
    yield takeLatest(getComments, handleTestimonial);
    yield takeLatest(getPressCenter, handlePressCenter);
    yield takeLatest(getPressCenterDetail, handlePressCenterDetail);
    yield takeLatest(getPropertiesDetails, handlePropertiesDetails);
    yield takeLatest(setSearch, handleProperties);
    yield takeLatest(setProjectSearch, handleProjects);
    yield takeLatest(getProjectsDetail, handleProjectDetails );
    yield takeLatest(getLocation, handleLocation );
    yield takeLatest(getDeveloper, handleDeveloper );
}