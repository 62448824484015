import React, { useEffect } from "react";
import AgencyYouCanTrust from "../components/AgencyYouCanTrust/AgencyYouCanTrust";
import HeroAboutUs from "../components/CompanyHero/HeroAboutUs";
import WhoWeAre from "../components/Vision/WhoWeAre";
import WhyInvestInDubai from "../components/WhyInvestInDubai/WhyInvestInDubai";
import Contact from "../components/ContactUs/Contact";
import FooterMobile from "../components/Footer/FooterMobile";
import Footer from "../components/Footer/Footer";
// import Video from "../components/Video/Video";
import aboutImage from "../assets/images/abouts.jpeg";
import { useState } from "react";
import Navbar from "../components/Navbar/Navbar";
import { ContactDiv } from "../components/ContactUs/ContactUs.styled";
import AboutComponent from "../components/MeetTheTeam.js/AboutComponent";
import { useDispatch, useSelector } from "react-redux";
import CustomizedSnackbar from "../components/CustomizedSnackbars/CustomizedSnackbar";
import { getAgents } from "../redux/slices/agentSlice/getAgent";
import Video from "../components/Video/Video";
import MeetTheTeam from "../components/MeetTheTeam.js/MeetTheTeam";
// import Videoplay from 'https://modrenliving.s3.eu-central-1.amazonaws.com/Our+Team+Moments.mov'

const TabletBreakpoint = 1024;
const text = ['Sale and resale of real estate',
  'Long term leases',
  'Short term leases',
  'Property management',
  'Legal assistance Mortgage assistance',
  'Customized solutions',
  'Furniture and decoration'];

  const text2 = ['No. Of Buyers Trusted Modern Living',
    'No. of Projects Sold',
    'No. of Properties Listed',
    'No. of Multilingual Property Consultants'
    ]
const CompanyAboutUs = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [offset, setOffset] = useState(0);
  const [background, setBackground] = useState("transparent");
  const dispatch = useDispatch();

  const { agents } = useSelector((state) => state.agents);
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    window.scrollTo(0, 0);
    dispatch(getAgents(8));
  }, [dispatch]);
  useEffect(() => {
    if (offset > 100) setBackground("#12284C");
    else setBackground("transparent");
  }, [offset]);
  useEffect(() => {
    window.scrollTo(0, 0);
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div>
      <Navbar background={background} />
      <HeroAboutUs header='We Sell Luxury With Integrity.' desc='Founded by innovators and game-changers, we are the future of real estate.'/>
      <WhoWeAre button="background" header='About Us' 
      desc = 'With our multilingual team (more than 15 spoken languages), we can provide unmatched support that ensures unsurpassed quality.$We provide full essential services prior to listing property, during the selling and buying process, up to the completion of the sale, and after-sales services.'
      cta='Our Story'
      image={aboutImage}
      />
      <AgencyYouCanTrust header='Company You can Trust'
      desc='Backed by an organization with a strong business portfolio, trust that Modern Living Real Estate will adhere to a high standard of excellence and will always be dedicated towards your success. Our comprehensive services vary from real estate advisory to company formation – we make sure that everything is taken care of.'
      data={text2}
      number='display'
      />
      <WhyInvestInDubai data={text}/>
      {agents.length > 0 && <MeetTheTeam agents={agents} rows={1} detail = 'false'/>}
      <Video
        header="Our team moments!"
        headerDisplay="block"
        descDisplay="none"
        align="center"
        marginTop="false"
        videoContent='https://modrenliving.s3.eu-central-1.amazonaws.com/Our+Team+Moments.mov'
      />
      <ContactDiv>
        <Contact
          title="Contact Us"
          shrink="true"
          displaymsg="block"
          displayupload="none"
        />
      </ContactDiv>

      {width < TabletBreakpoint ? <FooterMobile /> : <Footer />}
      <CustomizedSnackbar />
    </div>
  );
};

export default CompanyAboutUs;
