import React, { useState, useEffect } from "react";
import { BsDownload } from "react-icons/bs";
import { MobileDownload } from "../PropertyDetail/PropertyDetail.styled";
import {
  AboutProjectDescription,
  AboutProjectDiv,
  AboutProjectHeading,
  MobileDiv,
  MobileDownloadDiv,
} from "./projects.styled";
import Modal from "@mui/material/Modal";
import { BoxStyle } from "../PropertyListing.js/PropertyListing.styled";
import BrochuerPopUp from "../PopUps/BrochuerPopUp";

export const AboutProject = (details) => {
  if (details.details) {
    var data = details.details;
  }
  const [setWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      {data && data.heading2 && data.description2 && (
        <AboutProjectDiv>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <BoxStyle>
              <BrochuerPopUp
                open={open}
                setOpen={setOpen}
                brochure={data.brochure}
                parameter="Brochure"
                parameterone="brochure"
                button="Download"
                msg="The message is received from Downloading project brochure"
                para="Like 10,000+ subscribers, stay up to date with the latest
              developments in real estate"
              />
            </BoxStyle>
          </Modal>
          <AboutProjectHeading>{data.heading2}</AboutProjectHeading>
          <AboutProjectDescription>{data.description2}</AboutProjectDescription>
          {data && data.brochure && data.brochure.data && (
            <MobileDiv>
              <MobileDownloadDiv onClick={handleOpen}>
                <BsDownload
                  color="white"
                  style={{ cursor: "pointer" }}
                  size={15}
                />
                <MobileDownload>Download Brochure</MobileDownload>
              </MobileDownloadDiv>
            </MobileDiv>
          )}
        </AboutProjectDiv>
      )}
    </>
  );
};
