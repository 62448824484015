import React from 'react'
import { HeroBusinessSection1, HeroNftButton, HeroNftContentContainer, HeroNftSubText, NftHeaderText} from './Hero.styled'
import HeroIcon from '../../assets/images/nftcontacticon.svg'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Overlay } from '../CompanyHero/Hero.styled'
const HeroBusiness = ({heroImage, header, desc}) => {
  const { t } = useTranslation();
  return (
    <HeroBusinessSection1 img={heroImage}>
       <Overlay/>
      <HeroNftContentContainer>
        <NftHeaderText>{t(header)}</NftHeaderText>
        <HeroNftSubText>{t(desc)}</HeroNftSubText>
        <Link to = '/contactus'>
        <HeroNftButton><img src={HeroIcon} alt=''/> <h4>{t('Contact us')}</h4></HeroNftButton></Link>
      </HeroNftContentContainer>
    </HeroBusinessSection1>
  )
}

export default HeroBusiness