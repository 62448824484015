import {put, call} from "redux-saga/effects";
import { setCurrency } from "../../../slices/unitConversion/unitConversion";
import { requestGetCurrency } from "../../request/unitConversion/unitConversion";

export function* handleCurrency(action) {
   
    try {
        const response = yield call(requestGetCurrency);
        const {data} = response;
        yield put(setCurrency(data.rates));
    } catch (error) {
       
    }
}