import { createSlice } from '@reduxjs/toolkit';

const unitConversionSlice = createSlice({
    name: 'unitConversion',
    initialState: {
        rates: [],
        convertValue:1,
        currency:'AED',
        convertUnit:1,
        unit:'sqft'
       },
    reducers: {
        getCurrency(state, action) {},
        setCurrency(state, action) {
            state.rates = action.payload;
        },
        getUserCurrency(state, action) {
            state.currency = action.payload;
            state.convertValue = state.rates[action.payload];
        },
        getUserUnit(state,action){
            const unit = action.payload;
      if (unit === 'SQFT') {
        state.convertUnit = 1;
        state.unit = 'sqft'
      }
      if (unit === 'SQM') {
        state.convertUnit = 0.092;
        state.unit = 'sqm'
      }
        }
    },
});



export const { setCurrency, getCurrency,getUserCurrency, getUserUnit } = unitConversionSlice.actions;

export default unitConversionSlice.reducer;