import React, { useEffect, useState } from "react";
import HeroImage from "../../assets/images/hero-image.png";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import {
  BedButton,
  CommunityButton,
  DropdownContainerArea,
  DropdownContainerBeds,
  DropdownContainerPrice,
  DropdownContainerUnit,
  DropDownWrapper,
  HeroButtonContainer,
  HeroButtonText,
  HeroDropdownText,
  HeroHeaderText,
  HeroInputText,
  HeroPreferenceText,
  HeroSection,
  HeroSubText,
  HeroSubTextContainer,
  PriceButton,
  RentSaleButton,
  RentSaleText,
  SearchButton,
  SearchText,
  SingleButtonContainer,
  UnitButton,
} from "./Hero.styled";
import { useDispatch, useSelector } from "react-redux";
import { setSearch } from "../../redux/slices/herosearch/herosearchSlice";
import { useNavigate } from "react-router-dom";
import { getLocation } from "../../redux/slices/location/locationSlice";
import { useTranslation } from "react-i18next";

const Hero = () => {
  const [activeSlider, setActiveSlider] = useState(false);
  const [type, setType] = useState("buy");
  const [community, setCommunity] = useState("");
  const [area, setArea] = useState("");
  const [areaActive, setAreaActive] = useState(false);
  const [unit, setUnit] = useState("Unit Type");
  const [unitActive, setUnitActive] = useState(false);
  const [bed, setBed] = useState("Beds");
  const [bedActive, setBedActive] = useState(false);
  const [price, setPrice] = useState("Price");
  const [priceActive, setPriceActive] = useState(false);
  const {t} = useTranslation()

  const data = useSelector((state) => state.location);

  const priceRange = {
    Price: {
      lower: 0,
      higher: 0,
    },
    "0-100K": {
      lower: 0,
      higher: 100000,
    },
    "100K-500K": {
      lower: 100000,
      higher: 500000,
    },
    "500K-1M": {
      lower: 500000,
      higher: 1000000,
    },
    "1M-2M": {
      lower: 1000000,
      higher: 2000000,
    },
    "2M-5M": {
      lower: 2000000,
      higher: 5000000,
    },
    "5M+": {
      lower: 5000000,
      higher: 5000000,
    },
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleInput = (event) => {
    setAreaActive(true);
    const newValue = event.target.value;
    if (newValue.trim() === "") {
      setCommunity("");
    } else {
      setCommunity(newValue);
    }
  };

  useEffect(() => {
    let words = community.split(' ');
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
    const joinedWords = capitalizedWords.join(' ')
    setArea(joinedWords);
    dispatch(getLocation(joinedWords));
  }, [community, dispatch]);

  const HandleSearch = (e) => {
    e.preventDefault();
    const searchObject = {
      type: type,
      community: area,
      unittype: unit === "Unit Type" ? "" : unit,
      bed: bed === "Beds" ? "" : bed.split("-")[0],
      price: price === "Price" ? "" : priceRange[price],
      filter: "newest",
      page: 1,
    };
    dispatch(setSearch(searchObject));
    navigate(`/properties/${type}`);
  };
  
  return (
    <HeroSection img={HeroImage}>
  <HeroHeaderText>{t('Begin Your Property Journey')}</HeroHeaderText>
  <HeroButtonContainer>
    <SingleButtonContainer>
      <RentSaleButton left={activeSlider} />
      <RentSaleText
        activeSlider={!activeSlider}
        onClick={() => {
          setActiveSlider(false);
          setType('buy');
        }}
      >
        {t('Buy')}
      </RentSaleText>
      <RentSaleText
        activeSlider={activeSlider}
        onClick={() => {
          setActiveSlider(true);
          setType('rent');
        }}
      >
        {t('Rent')}
      </RentSaleText>
    </SingleButtonContainer>
    <DropDownWrapper onMouseLeave={() => setAreaActive(false)}>
      <CommunityButton onClick={() => setAreaActive(true)}>
        <LocationOnOutlinedIcon />
        <HeroInputText
          placeholder={t('Enter area or community')}
          onChange={handleInput}
          value={community}
        ></HeroInputText>
      </CommunityButton>
      {data && data.locationData && (
        <DropdownContainerArea areaActive={areaActive}>
          {data.locationData.length === 0 ? (
            <HeroButtonText>{t('No Data Found')}</HeroButtonText>
          ) : (
            data.locationData.map((i, id) => {
              return (
                <HeroDropdownText
                  key={id}
                  onClick={() => {
                    setCommunity(i.attributes.area);
                    setAreaActive(false);
                  }}
                >
                  {i.attributes.area}
                </HeroDropdownText>
              );
            })
          )}
        </DropdownContainerArea>
      )}
    </DropDownWrapper>
    <DropDownWrapper onMouseLeave={() => setUnitActive(false)}>
      <UnitButton onClick={() => setUnitActive(true)}>
        <HeroButtonText>{unit}</HeroButtonText>
        <KeyboardArrowDownOutlinedIcon />
      </UnitButton>
      <DropdownContainerUnit unitActive={unitActive}>
        <HeroDropdownText
          onClick={() => {
            setUnit('All');
            setUnitActive(false);
          }}
        >
          {t('All')}
        </HeroDropdownText>
        <HeroDropdownText
          onClick={() => {
            setUnit('Apartment');
            setUnitActive(false);
          }}
        >
          {t('Apartment')}
        </HeroDropdownText>
        <HeroDropdownText
          onClick={() => {
            setUnit('Villa');
            setUnitActive(false);
          }}
        >
          {t('Villa')}
        </HeroDropdownText>
        <HeroDropdownText
          onClick={() => {
            setUnit('Townhouse');
            setUnitActive(false);
          }}
        >
          {t('Townhouse')}
        </HeroDropdownText>
        <HeroDropdownText
          onClick={() => {
            setUnit('Penthouse');
            setUnitActive(false);
          }}
        >
          {t('Penthouse')}
        </HeroDropdownText>
        <HeroDropdownText
          onClick={() => {
            setUnit('Commercial');
            setUnitActive(false);
          }}
        >
          {t('Commercial')}
        </HeroDropdownText>
      </DropdownContainerUnit>
    </DropDownWrapper>
        <DropDownWrapper onMouseLeave={() => setBedActive(false)}>
          <BedButton onClick={() => setBedActive(true)}>
          <HeroButtonText>{bed}</HeroButtonText>
            <KeyboardArrowDownOutlinedIcon />
          </BedButton>
          <DropdownContainerBeds bedActive={bedActive}>
            <HeroDropdownText
              onClick={() => {
                setBed("Studio");
                setBedActive(false);
              }}
            >
              Studio
            </HeroDropdownText>
            <HeroDropdownText
              onClick={() => {
                setBed("1-BR");
                setBedActive(false);
              }}
            >
              1-BR
            </HeroDropdownText>
            <HeroDropdownText
              onClick={() => {
                setBed("2-BR");
                setBedActive(false);
              }}
            >
              2-BR
            </HeroDropdownText>
            <HeroDropdownText
              onClick={() => {
                setBed("3-BR and more");
                setBedActive(false);
              }}
            >
              3-BR and more
            </HeroDropdownText>
          </DropdownContainerBeds>
        </DropDownWrapper>
        <DropDownWrapper onMouseLeave={() => setPriceActive(false)}>
          <PriceButton onClick={() => setPriceActive(true)}>
            <HeroButtonText>{price}</HeroButtonText>
            <KeyboardArrowDownOutlinedIcon />
          </PriceButton>
          <DropdownContainerPrice priceActive={priceActive}>
            <HeroDropdownText
              onClick={() => {
                setPrice("0-100K");
                setPriceActive(false);
              }}
            >
              0-100K
            </HeroDropdownText>
            <HeroDropdownText
              onClick={() => {
                setPrice("100K-500K");
                setPriceActive(false);
              }}
            >
              100K-500K
            </HeroDropdownText>
            <HeroDropdownText
              onClick={() => {
                setPrice("500K-1M");
                setPriceActive(false);
              }}
            >
              500K-1M
            </HeroDropdownText>
            <HeroDropdownText
              onClick={() => {
                setPrice("1M-2M");
                setPriceActive(false);
              }}
            >
              1M-2M
            </HeroDropdownText>
            <HeroDropdownText
              onClick={() => {
                setPrice("2M-5M");
                setPriceActive(false);
              }}
            >
              2M-5M
            </HeroDropdownText>
            <HeroDropdownText
              onClick={() => {
                setPrice("5M+");
                setPriceActive(false);
              }}
            >
              5M+
            </HeroDropdownText>
          </DropdownContainerPrice>
        </DropDownWrapper>
        <SearchButton onClick={HandleSearch}>
          <SearchText>SEARCH</SearchText>
        </SearchButton>
      </HeroButtonContainer>
      <HeroSubTextContainer>
      <HeroSubText>{t('Looking for something unique?')}</HeroSubText>
<br />
<p>
<HeroPreferenceText href="/preferences">
{t('Set your preferences')}
</HeroPreferenceText>
<HeroSubText>{t(' and our specialists will help you.')}</HeroSubText>
        </p>
      </HeroSubTextContainer>
    </HeroSection>
  );
};

export default Hero;
