import React, { useEffect, useState } from "react";
import HeroAboutUs from "../components/CompanyHero/HeroAboutUs";
import Contact from "../components/ContactUs/Contact";
import CustomizedSnackbar from "../components/CustomizedSnackbars/CustomizedSnackbar";
import Footer from "../components/Footer/Footer";
import FooterMobile from "../components/Footer/FooterMobile";
import Navbar from "../components/Navbar/Navbar";
// import Video from "../components/Video/Video";
// import Videoplay from '../assets/images/careermlvideo.mov'
import { scroller } from "react-scroll";

import {
  WhoAreWeSection,
  WhoWeAreContainer,
  WhoWeAreTextContainer,
} from "../components/Vision/Vision.styled";

const CompanyCareer = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [offset, setOffset] = useState(0);
  const [background, setBackground] = useState("transparent");
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (offset > 100) setBackground("#12284C");
    else setBackground("transparent");
  }, [offset]);
  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  const handleClick = () => {
    scroller.scrollTo('Contact', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    })
  }
  return (
    <div>
      <Navbar background={background} />
      <HeroAboutUs
        header="We are always looking for talented people to join our team."
        desc="At Modern Living, we love to discover new talent and challenge our employees to grow professionally."
      />
      <WhoAreWeSection>
        <WhoWeAreContainer>
          <WhoWeAreTextContainer image="none" button="background">
            <h1>Are you ready for the challenge?</h1>
            <p>
              In Modern Living, our doors are always open to new talents in all
              areas.
            </p>
            <p style={{ marginTop: "0px" }}>
              If you are interested in the real estate niche, want to grow and
              are not afraid of challenges, Modern Living is your place!
            </p>
            <button style={{ marginTop: "20px" }} onClick={handleClick}>
              <h4>APPLY NOW</h4>
            </button>
          </WhoWeAreTextContainer>
        </WhoWeAreContainer>
      </WhoAreWeSection>
      {/* <Video
        header="Our team moments!"
        headerDisplay="block"
        descDisplay="none"
        align="center"
        marginTop="false"
        videoContent={Videoplay}
      /> */}
      <div name="Contact">
      <Contact displaymsg="none" />
      </div>
      {width < 1204 ? <FooterMobile /> : <Footer />}
      <CustomizedSnackbar />
    </div>
  );
};

export default CompanyCareer;
