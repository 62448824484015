import { Skeleton } from '@mui/material';
import React from 'react'
import { Link } from 'react-router-dom';
import {
    PressReleaseContainer,
    ReleaseImage,
    ReleaseHeaderText,
    PressReleaseDate,
    ReadMoreButton
  } from "./PressCenter.styled";

const PressReleaseCard = ({data, scrollTop}) => {
  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
  const p = data.attributes && data.attributes.date.split('T')[0].split('-');
  const date = p[2] + ' ' + monthNames[Number(p[1]).toString()]  + ' ' + p[0]
  return (
    <PressReleaseContainer>
        {data.attributes.featured_image.data && 
        <Link to = {`/press_release/${data.id}`}>
        <ReleaseImage img={data.attributes.featured_image.data.attributes.url}></ReleaseImage>
        </Link>}
        <PressReleaseDate>{date}</PressReleaseDate>
        <ReleaseHeaderText height={data.attributes.title}>
          {(data.attributes.title) ? data.attributes.title :<Skeleton
            animation="wave"
            variant="rectangular"
            width="100%"
            height="100%"
          />}
        </ReleaseHeaderText>
        
        {/* <ReleaseDesc height={data.attributes.short_description}>
          {(data.attributes.short_description) ? data.attributes.short_description :<Skeleton
            animation="wave"
            variant="rectangular"
            width="100%"
            height="100%"
          />}
        </ReleaseDesc> */}
        <Link to = {`/press_release/${data.id}`}>
        <ReadMoreButton onClick={scrollTop}>
        {(data.attributes)? 'Read more' :<Skeleton
            animation="wave"
            variant="rectangular"
            width="100%"
            height="100%"
          />}
        </ReadMoreButton>
        </Link>
      </PressReleaseContainer>
    );
}

export default PressReleaseCard
