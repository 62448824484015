import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Aboutus from "../components/AboutUs/Aboutus";
import Contact from "../components/ContactUs/Contact";
import Footer from "../components/Footer/Footer";
import FooterMobile from "../components/Footer/FooterMobile";
import Hero from "../components/HeroLandingPage/Hero";
import Latestprojects from "../components/LatestProjects/Latestprojects";
import MeetTheTeam from "../components/MeetTheTeam.js/MeetTheTeam";
import Navbar from "../components/Navbar/Navbar";
import News from "../components/News/News";
import Ourservices from "../components/OurService/Ourservices";
import Propertylisting from "../components/PropertyListing.js/Propertylisting";
import Trustedclients from "../components/TrustedClients/Trustedclients";
import Video from "../components/Video/Video";
import { getFeatureProperties } from "../redux/slices/propertiesSlices/getFeatureProperties";
import { getFutureProjects } from "../redux/slices/projectSlice/getFutureProject";
import { getAgents } from "../redux/slices/agentSlice/getAgent";
import { getComments } from "../redux/slices/testimonials/testimonialSlice";
import CustomizedSnackbar from "../components/CustomizedSnackbars/CustomizedSnackbar";
import { getPressCenter } from "../redux/slices/pressCenter/pressCenter";
import CookieBanner from "../components/CookieBanner";
import { useTranslation } from "react-i18next";
import ContactButton from "../components/contactPopup/ContactButton";
import { PressFilterButton } from "../components/PressCenter/PressCenter.styled";

const TabletBreakpoint = 1200;
const Videoplay = "https://youtu.be/vh01cOqK8tY";

const Landingpage = () => {
  const [offset, setOffset] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [background, setBackground] = useState("transparent");
  const { t } = useTranslation();
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  useEffect(() => {
    if (offset > 10) setBackground("#12284C");
    else setBackground("transparent");
  }, [offset]);

  // add dispatcher for requesting data from Properties API

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAgents(8));
    dispatch(getFeatureProperties());
    dispatch(getFutureProjects("offplan"));
    dispatch(getComments());
    const pressObject = {
      property: "blog",
      page: 1,
    };
    dispatch(getPressCenter(pressObject));
  }, []);

  const { propertiesData } = useSelector((state) => state.properties);
  const { projectData } = useSelector((state) => state.project);
  const { agents } = useSelector((state) => state.agents);
  const { comments } = useSelector((state) => state.testimonial);
  const { pressCenterData } = useSelector((state) => state.presentReducer);
  return (
    <div>
      <Navbar background={background} />
      <Hero />
      {propertiesData.length > 0 && (
        <Propertylisting properties={propertiesData} />
      )}
      <Aboutus />
      <Ourservices />
      <Video headerDisplay="none" descDisplay="none" videoContent="https://modrenliving.s3.eu-central-1.amazonaws.com/Our+Team+Moments.mov"/>
      {agents.length > 0 && <MeetTheTeam agents={agents} rows={1} detail = 'false'/>}
      <Trustedclients comments={comments} offset={offset} />
      {projectData.length > 0 && <Latestprojects nineteen="160px" thirteen="124px" nine="127px" four="77px" background="#f7f9fa" projects={projectData} />}

      {pressCenterData.length > 1 && (
        <News data={pressCenterData} width={width} />
      )}
      <Contact
        title={t("Reach Out To Us")}
        desc="true"
        shrink="false"
        displaymsg="block"
        displayupload="none"
      />
      {width < TabletBreakpoint ? <FooterMobile width={width} /> : <Footer />}
      <CustomizedSnackbar />
      <CookieBanner />
      <ContactButton/>
    </div>
  );
};

export default Landingpage;
