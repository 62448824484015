import styled from "styled-components";
import "./Navbar.css";
import { keyframes } from "styled-components";

const RotateClockwise = keyframes`
100%{
  transform:rotate(45deg);
  top:20px;
}
`;
const RotateAntiClockwise = keyframes`
100%{
  transform:rotate(-45deg);
  top:20px;
}
`;

export const NavbarHeader = styled.div`
  display: flex;
  align-items: top;
  width: 100%;
  background: ${(props) => props.background};
  backdrop-filter: ${(props) => props.backdrop};
  height: auto;
  position: fixed;
  top: 0;
  z-index: 9999;

  @media only screen and (min-width: 1920px) {
    padding: 25px 25px;
  }
  @media only screen and (min-width: 1366px) and (max-width: 1920px) {
    padding: 20px 20px;
  }
  @media only screen and (min-width: 1204px) and (max-width: 1366px) {
    padding: 20px;
  }
  @media only screen and (max-width: 1204px) {
    padding: 25px 18px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    padding: 25px 18px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    padding: 25px 18px;
  }
`;

export const UnitSpan = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  color: #ffffff;
  box-sizing: border-box;
  @media only screen and (min-width: 1920px) {
    font-weight: 300;
    font-size: 16px;
    line-height: 127%;
  }
  @media only screen and (min-width: 1366px) and (max-width: 1920px) {
    font-weight: 300;
    font-size: 14px;
    line-height: 127%;
  }
  @media only screen and (min-width: 1204px) and (max-width: 1366px) {
    font-weight: 300;
    font-size: 13px;
    line-height: 127%;
  }
  @media only screen and (max-width: 1204px) {
    font-weight: 300;
    font-size: 11px;
    line-height: 127%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 300;
    font-size: 11px;
    line-height: 127%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 300;
    font-size: 11px;
    line-height: 127%;
  }
`;
export const NavbarText = styled.button`
  font-family: "Montserrat";
  font-style: normal;
  color: #ffffff;
  box-sizing: border-box;
  @media only screen and (min-width: 1920px) {
    font-weight: 300;
    font-size: 16px;
    line-height: 127%;
  }
  @media only screen and (min-width: 1366px) and (max-width: 1920px) {
    font-weight: 300;
    font-size: 14px;
    line-height: 127%;
  }
  @media only screen and (min-width: 1204px) and (max-width: 1366px) {
    font-weight: 300;
    font-size: 13px;
    line-height: 127%;
  }

  @media only screen and (max-width: 1204px) {
    font-weight: 300;
    font-size: 11px;
    line-height: 127%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 300;
    font-size: 11px;
    line-height: 127%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 300;
    font-size: 11px;
    line-height: 127%;
  }
`;

export const NavbarButton = styled(NavbarText)`
  display: flex;
  gap: 10px;
  background: transparent;
  border: none;
  align-items: center;
  justify-content:space-between;
  border-radius: 20px;
  text-transform:uppercase;
  z-index: 3;
  padding: 11px 10px;
  width: 100%;
  text-align: left;
  &:hover {
    background: #decdad;
    box-sizing: border-box;
    color: black;
    ${UnitSpan} {
      color: black;
    }
  }
`;

export const NavbarButtonDefault = styled(NavbarButton)`
  position: sticky;
  width: 86px;
  background: transparent;
  opacity: 1;
  top: 0px;
  backdrop-filter: blur(90px);
  &:hover {
    background: #12284c;
    color: white;
  }
`;

export const CenterDiv = styled.div`
  display: flex;
  margin-left: auto;
  gap: 5px;
  box-sizing: border-box;
`;

export const DropdownContainer = styled.div`
  border: 0.75px solid transparent;
  border-radius: 20px;
  height: 50px;
  transition: 0.3s;
  overflow: hidden;
  position: relative;
  &:hover {
    backdrop-filter: blur(90px);
    border: 0.75px solid #ffffff;
    box-sizing: border-box;
    @media only screen and (min-width: 1920px) {
      height: 133px;
    }
    @media only screen and (min-width: 1300px) and (max-width: 1920px) {
      height: 127px;
    }
    @media only screen and (min-width: 900px) and (max-width: 1300px) {
      height: 121px;
    }
    @media only screen and (max-width: 900px) {
      height: 121px;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
      height: 121px;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
      height: 121px;
    }
  }
`;

export const SideBarContactDiv = styled.div`
  max-height: 700px;
  overflow-y: auto;
`;

export const DropdownDiv = styled.div`
  display: ${(props) => (props.active ? "block" : "none")};
  background: #12284c;
  height: fit-content;
  width: 100%;
  backdrop-filter: blur(90px);
  z-index: 99;
`;
export const PressCenterDropdown = styled(DropdownContainer)`
  &:hover {
    @media only screen and (min-width: 1920px) {
      height: 220px;
    }
    @media only screen and (min-width: 1300px) and (max-width: 1920px) {
      height: 205px;
    }
  }
`;
export const BusinessDropdown = styled(DropdownContainer)`
 @media only screen and (min-width: 1920px) {
       max-width: 168px;
      
    }
    @media only screen and (min-width: 1300px) and (max-width: 1920px) {
       max-width: 148px;
     
    }
  &:hover {

    @media only screen and (min-width: 1920px) {
       
       height: 441px;
     }
    @media only screen and (min-width: 1900px) and (max-width: 1920px) {
       
      height: 418px;
    }
    @media only screen and (min-width: 1300px) and (max-width: 1900px) {
       
      height: 416px;
    }
  }
`;
export const CompanyDropdown = styled(DropdownContainer)`
 @media only screen and (min-width: 1920px) {
      max-width:157px;
    }
    @media only screen and (min-width: 1300px) and (max-width: 1920px) {
      max-width:132px;
    }
  &:hover {
    @media only screen and (min-width: 1920px) {
      height: 383px;
    }
    @media only screen and (min-width: 1300px) and (max-width: 1920px) {
      height: 360px;
    }
  }
`;
export const PropertyDropdown = styled(DropdownContainer)`
  &:hover {
    @media only screen and (min-width: 1900px) {
      height: 167px;
    }
    @media only screen and (min-width: 1300px) and (max-width: 1900px) {
      height: 166px;
    }
  }
`;
export const ProjectDropdown = styled(DropdownContainer)`
  &:hover {
    @media only screen and (min-width: 1900px) {
      height: 128px;
    }
    @media only screen and (min-width: 1300px) and (max-width: 1900px) {
      height: 128px;
    }
  }
`;

export const CurrencyDropdown = styled(DropdownContainer)`
  min-width: 90px;
  &:hover {
    width: 90px;
    overflow-y: hidden;
    @media only screen and (min-width: 1920px) {
      height: 218px;
    }
    @media only screen and (min-width: 1300px) and (max-width: 1920px) {
      height: 202px;
    }
    @media only screen and (min-width: 900px) and (max-width: 1300px) {
      height: 193px;
    }
    @media only screen and (max-width: 900px) {
      height: 192px;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
      height: 193px;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
      height: 193px;
    }
    ${NavbarButtonDefault} {
      background: #12284c;
      color: white;
    }
  }
`;
export const NavbarLogo = styled.img`
  @media only screen and (min-width: 1920px) {
    width: 264px;
    height: 26px;
    margin: 11px 0px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 208px;
    height: 24px;
    margin: 11px 0px;
  }
  @media only screen and (max-width: 1300px) {
    width: 170px;
    height: 18px;
    margin: 14px 0px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 170px;
    height: 18px;
    margin: 14px 0px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 170px;
    height: 18px;
    margin: 14px 0px;
  }
`;


export const UnitDropdown = styled(DropdownContainer)`
  min-width: 85px;
`;
export const SidebarLogo = styled.img`
  width: 352px;
  height: 30px;
  margin: auto;
`;
export const MobileLogo = styled.img`
  width: 123px;
  height: 34px;
`;
export const Vertical = styled.div`
  border-right: 0.3px solid #ffffff;
  height: 50px;
`;
export const MenuDiv = styled.div`
  width: 40px;
  height: 40px;
  overflow: hidden;
  position: relative;
`;
export const MenuIcon = styled.img`
  width: 100%;
  height: 100%;
`;
export const MenuLine = styled.img`
  position: absolute;
  width: 80%;
  height: auto;
  top: 15px;
  left: 4px;
  animation-name: ${(props) => (props.active ? RotateClockwise : "")};
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
`;
export const MenuLine2 = styled(MenuLine)`
  top: 25px;
  animation-name: ${(props) => (props.active ? RotateAntiClockwise : "")};
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  transform: 0.3s;
`;
export const SearchButtonIcon = styled.img`
  margin: 10px 10px;
  width: 20px;
  height: 20px;
`;
export const SidebarSection = styled.section`
  position: fixed;
  width: ${(props) => (props.active ? "500px" : "0px")};
  height: min(770px, 100vh);
  background: #12284c;
  top: 0px;
  right: 0px;
  z-index: 2;
  transition: 0.3s;
  padding-top: 83px;
  @media only screen and (max-width: 900px) {
    width: ${(props) => (props.active ? "100vw" : "0px")};
  }
`;
export const SidebarDropContainer = styled.div`
  width: 80%;
  margin-top: 25px !important;
  height: fit-content;
  margin: auto;
  overflow-y: auto;
  transition: 0.2s;
  position: relative;
  padding: 0px 10px;
  border: 0.75px solid transparent;
  overflow: visible;
`;
export const SidebarMenuText = styled.h1`
  cursor: pointer;
  display: flex;
  width: 100%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: white;
  background: #12284c;
  border: none;
  justify-content: space-between;
  margin: auto;
  overflow-x: hidden;
  position: sticky;
  top: 0px;
`;
export const SidebarLink = styled.h1`
  cursor: pointer;
  font-size: 15px;
  border: none;
  padding: 7px 0px;
  font-weight: 300;
  letter-spacing: 0.1em;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  color: white;
`;

export const SidebarContactButton = styled.button`
  border: transparent;
  background: #131d2e;
  width: 217.84px;
  height: 61.71px;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: #ece1ce;
  }
  &:hover {
    background: #2771ef;
  }
`;
export const SidebarContainer = styled.div`
  width: 80%;
  margin: auto;
  display: flex;
`;
export const SidebarContentContainer = styled.div`
  height: 400px;
`;

export const SidebarDiv = styled.div`
  display: flex;
  width: 80%;
  margin: auto;
  margin-top: 15px;
`;
