import React from "react";
import Typography from "@mui/material/Typography";
import { Breadcrumbs } from "@mui/material";
import Link from "@mui/material/Link";
import "./style.css";
import Stack from "@mui/material/Stack";
import { BreadCrumbsPadding } from "./PropertyDetail.styled";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export const BreadcrumbsComponent = ({ details }) => {
  function handleClick(event) {
    event.preventDefault();
  }
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/"
      fontSize="medium"
      onClick={handleClick}
    >
      Home
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href={`/properties/${details.attributes.listing_category}`}
    >
      {details.attributes.listing_category.charAt(0).toUpperCase()}{details.attributes.listing_category.slice(1)}
    </Link>,
    <Typography key="3" color="text.primary">
      {details.attributes.title.charAt(0) + details.attributes.title.slice(1).toLowerCase().substring(0,23)}...
    </Typography>,
  ];
  return (
    <BreadCrumbsPadding>
      <Stack spacing={2}>
        {details &&
          details.attributes &&
          details.attributes.title &&
          details.attributes.listing_category && (
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="medium" />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          )}
      </Stack>
    </BreadCrumbsPadding>
  );
};
