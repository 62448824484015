import styled from "styled-components";
import { keyframes } from "styled-components";
import {
  CtaButton,
  FilterButton,
} from "../PropertyListing.js/PropertyListing.styled";

const sliderAnimation = keyframes`
  50% {transform:translateX(-110%);
    opacity:0;
  }
  75% {transform:translateX(110%);
  opacity:1;}
`;

export const PressCenterSection = styled.section`
  width: 100%;
  height: auto;
  background: rgba(238, 242, 244, 0.42);
`;
export const PressCenterSectionContainer = styled.div`
  display: flex;
  gap: 50px;
  @media only screen and (min-width: 1920px) {
    margin: 170px 0px 140px 0px;
    justify-content: center;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    margin: 150px 0px 110px 0px;
    justify-content: center;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    margin: 130px 0px 90px 0px;
    justify-content: center;
  }
  @media only screen and ((min-width: 636px) and (max-width: 900px)) {
    margin: 130px 0px 90px 0px;
    overflow-x:auto;
    justify-content: center;
  }
   @media only screen and (max-width: 636px) {
    margin: 130px 0px 90px 0px;
    overflow-x:auto;
    padding-left:30px;
    padding-right:30px;

  }
  @media only screen and (max-width: 400px) {
    margin: 130px 0px 90px 0px;
    overflow-x:auto;
    padding-left:30px;
    padding-right:30px;

  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    margin: 130px 0px 90px 0px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    margin: 130px 0px 90px 0px;
  }
  &::-webkit-scrollbar {
      display:none;
    }

`;
export const PressContainer = styled.div`
  width: 90%;
  margin: auto;
  padding: 5vw 0px;
  display: flex;
  gap: 5%;
  flex-wrap: wrap;
  animation-name: ${(props) => (props.active ? sliderAnimation : "")};
  animation-duration: 0.5s;
`;
export const PressFilterButton = styled.button`
  display: flex;
  align-items: center;
  border-radius: 40px;
  padding: 10px 40px;
  min-width: fit-content;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 127%;
  letter-spacing: 0.03em;
  background: transparent;
  color: #000000;
  overflow: hidden;
  border: ${(props) =>
    props.border ? "1px solid #000000" : "1px solid #decdad"};
  &:hover {
    border: 1px solid #000000;
  }
`;

export const ResetButton = styled(PressFilterButton)`
  border:none;
  background:#12284c;
  color:#ffffff;
  &:hover {
    background:#004876;
    border:none;
    color:#ffffff;
  }
`

export const PressCenterText = styled.h3`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: ${(props) => (props.active ? "600" : "300")};
  font-size: 20px;
  line-height: 127%;
  color: #000000;
  width:fit-content;
  &:hover {
    font-weight: 600;
  }
  @media only screen and (max-width: 900px) {
    min-width:fit-content;
    }
  @media only screen and (max-width: 400px) {
    min-width:fit-content;
    }
  
`;

export const PressFilterScrollContainer = styled.div`
  display: flex;
  gap: 1%;
  width: 90%;
  overflow-x: auto;
  margin: auto;
  height: 46px;
  &::-webkit-scrollbar {
    display: none;
  }
  /* @media (min-width: 2420px) {
    gap: 0px;
    justify-content: space-between;
  } */
`;

export const PressFilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 1vh;
  border-top: 0.6px solid #131d2e;
  border-bottom: 0.6px solid #131d2e;
`;
// const PressCenterImage = styled.img`
//   width:100%;
//   height:100%
// `

export const PressReleaseContainer = styled.div`
  background: transparent;
  height: auto;
  margin-bottom: 70px;
  transition: 0.3s;
  @media only screen and (min-width: 1300px) {
    width: 47.5%;
  }
  @media only screen and (max-width: 1300px) {
    width: 100%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 100%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 100%;
  }
`;
export const ReleaseImage = styled.div`
  width: 100%;
  background: ${(props) => `url(${props.img})`};
  background-repeat: no-repeat;
  background-size: cover;
  aspect-ratio: 2 / 1;
`;
export const ReleaseHeaderText = styled.h1`
  height: ${(props) => (props.height ? "" : "60px")};
  font-family: "Montserrat";
  font-style: normal;
  color: #12284c;
  @media only screen and (min-width: 1920px) {
    font-weight: 300;
    font-size: 29.478px;
    line-height: 36px;
    margin-top: 21.88px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 300;
    font-size: 22.7108px;
    line-height: 28px;
    margin-top: 21.27px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 300;
    font-size: 28.3345px;
    line-height: 35px;
    margin-top: 16px;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 300;
    font-size: 17.4781px;
    line-height: 21px;
    margin-top: 15px;
  }
  @media only screen and (max-width: 400px) {
    font-weight: 300;
    font-size: 17.4781px;
    line-height: 21px;
    margin-top: 15px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 300;
    font-size: 28.3345px;
    line-height: 35px;
    margin-top: 16px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 300;
    font-size: 28.3345px;
    line-height: 35px;
    margin-top: 16px;
  }
`;

export const PressReleaseDate = styled.h5`
  font-family: "Montserrat";
  font-style: normal;
  color: #decdad;
  @media only screen and (min-width: 1920px) {
    font-weight: 500;
    font-size: 10.4302px;
    line-height: 13px;
    margin-top: 31px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 500;
    font-size: 8.03573px;
    line-height: 10px;
    margin-top: 23px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 500;
    font-size: 10.2031px;
    line-height: 12px;
    margin-top: 29px;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 500;
    font-size: 8.2px;
    line-height: 10px;
    margin-top: 13px;
  }
  @media only screen and (max-width: 400px) {
    font-weight: 500;
    font-size: 8.2px;
    line-height: 10px;
    margin-top: 13px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 500;
    font-size: 10.2031px;
    line-height: 12px;
    margin-top: 29px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 500;
    font-size: 10.2031px;
    line-height: 12px;
    margin-top: 29px;
  }
`;

export const ReleaseDesc = styled.p`
  margin-top: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 19.652px;
  line-height: 24px;
  color: #060606;
  height: ${(props) => (props.height ? "" : "60px")};
`;
export const BlogImage = styled.div`
  width: 100%;
  background: ${(props) => `url(${props.img})`};
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  @media only screen and (min-width: 1920px) {
    height: 395px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    height: 292px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    height: 420px;
  }
  @media only screen and (max-width: 900px) {
    height: 361px;
  }
  @media only screen and (max-width: 400px) {
    height: 361px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    height: 420px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    height: 420px;
  }
`;

export const BlogContainer = styled.div`
  height: auto;
  margin-bottom: 70px;
  transition: 0.3s;
  @media only screen and (min-width: 1920px) {
    min-width: 21.25%;
    width: 21.25%;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    min-width: 21.25%;
    width: 21.25%;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    min-width: 47.5%;
    width: 47.5%;
  }
  @media only screen and (max-width: 900px) {
    min-width: 100%;
    width: 100%;
  }
  @media only screen and (max-width: 400px) {
    min-width: 100%;
    width: 100%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    min-width: 47.5%;
    width: 47.5%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    min-width: 47.5%;
    width: 47.5%;
  }
`;
export const BlogHeader = styled.h1`
  font-family: "Montserrat";
  font-style: normal;
  color: #000000;
  margin-top: 10px;
  height: ${(props) => (props.height ? "" : "30px")};
  @media only screen and (min-width: 1920px) {
    font-weight: 300;
    font-size: 17.3698px;
    line-height: 21px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 300;
    font-size: 13.0449px;
    line-height: 16px;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
  }
  @media only screen and (max-width: 400px) {
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 300;
    font-size: 13.0449px;
    line-height: 16px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 300;
    font-size: 13.0449px;
    line-height: 16px;
  }
`;
export const ReadMoreButton = styled.button`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
  color: #000000;
  margin-top: 10px;
  background: transparent;
  border: none;
  height: 20px;
  text-align: left;
  width: 100%;
  cursor: pointer;
`;

export const PodcastContainer = styled.div`
  display: flex;
  gap: 2.5%;
  margin-bottom: 70px;
  align-items: center;
  background: #ffffff;
  @media only screen and (min-width: 1920px) {
    width: 47.5%;
    height: ${(props) => (props.height ? "" : "280px")};
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 47.5%;
    height: ${(props) => (props.height ? "" : "200px")};
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 47.5%;
    height: ${(props) => (props.height ? "" : "160px")};
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
    height: ${(props) => (props.height ? "" : "130px")};
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 47.5%;
    height: ${(props) => (props.height ? "" : "160px")};
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 47.5%;
    height: ${(props) => (props.height ? "" : "160px")};
  }
`;
export const PodcastImg = styled.div`
  height: 100%;
  min-width: 35%;
  background: ${(props) => `url(${props.img})`};
  background-repeat: no-repeat;
  background-size: cover;
`;
export const PodcastContentContainer = styled.div`
  width: 60%;
`;
export const PodcastDate = styled.h3`
  font-family: "Montserrat";
  font-style: normal;
  color: #004876;
  width: 100%;
  height: 10px;
  @media only screen and (min-width: 1920px) {
    font-weight: 500;
    font-size: 9.31259px;
    line-height: 11px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 500;
    font-size: 6.73151px;
    line-height: 8px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 500;
    font-size: 5.42155px;
    line-height: 7px;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 500;
    font-size: 4.26374px;
    line-height: 5px;
  }
  @media only screen and (max-width: 400px) {
    font-weight: 500;
    font-size: 4.26374px;
    line-height: 5px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 500;
    font-size: 5.42155px;
    line-height: 7px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 500;
    font-size: 5.42155px;
    line-height: 7px;
  }
`;

// I have done upto this point

export const PodcastHeaderText = styled.h1`
  margin-top: 15px;
  font-family: "Montserrat";
  font-style: normal;
  color: #000000;
  width: 100%;
  height: ${(props) => (props.height ? "" : "30px")};
  @media only screen and (min-width: 1920px) {
    font-weight: 300;
    font-size: 29.0746px;
    line-height: 35px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 300;
    font-size: 21.0163px;
    line-height: 26px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 300;
    font-size: 16.9265px;
    line-height: 21px;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 300;
    font-size: 13.3117px;
    line-height: 16px;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 300;
    font-size: 16.9265px;
    line-height: 21px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 300;
    font-size: 16.9265px;
    line-height: 21px;
  }
`;
export const PodcastButton = styled.button`
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  background: transparent;
  border: 0.908581px solid #000000;
  border-radius: 36.3432px;
  font-family: "Montserrat";
  font-style: normal;
  letter-spacing: 0.03em;
  color: #000000;
  &:hover {
    background:#e2e2e3;
  }
  @media only screen and (min-width: 1920px) {
    width: 173px;
    height: 50px;
    font-weight: 400;
    font-size: 18.1716px;
    line-height: 127%;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 125px;
    height: 36px;
    font-weight: 400;
    font-size: 13.1352px;
    line-height: 127%;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 100px;
    height: 29px;
    font-weight: 400;
    font-size: 10.5791px;
    line-height: 127%;
  }
  @media only screen and (max-width: 900px) {
    width: 79px;
    height: 23px;
    font-weight: 400;
    font-size: 8.31981px;
    line-height: 127%;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 100px;
    height: 29px;
    font-weight: 400;
    font-size: 10.5791px;
    line-height: 127%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 100px;
    height: 29px;
    font-weight: 400;
    font-size: 10.5791px;
    line-height: 127%;
  }
`;
// const PodcastButtonText = styled.h6`
//   font-family: 'Montserrat';
// font-style: normal;
// letter-spacing: 0.03em;
// color: #000000;
// width:100%;
// height:20px;
// @media (min-width: 1900px) {
//   font-weight: 400;
// font-size: 18.1716px;
// line-height: 127%;
//   }
// @media ((min-width: 1300px) and (max-width:1900px)){
//   font-weight: 400;
// font-size: 13.1352px;
// line-height: 127%;
//   }
// @media ((min-width: 900px) and (max-width:1300px)){
//   font-weight: 400;
// font-size: 10.5791px;
// line-height: 127%;
//   }
// @media (max-width: 900px) {
//   font-weight: 400;
// font-size: 8.31981px;
// line-height: 127%;
//   }
// `

export const PressPageNavContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 60px 0px;
  button {
    border: 1px solid #000000;
    border-radius: 60px;
    background: transparent;
    border: ${(props) =>
      props.border
        ? "0.647815px solid #000000"
        : "0.647815px solid transparent"};
    font-family: "Montserrat";
    font-style: normal;
    letter-spacing: 0.03em;
    color: #040404;
    @media only screen and (min-width: 1920px) {
      padding: 12px 21px;
      font-weight: 400;
      font-size: 20px;
      line-height: 127%;
    }
    @media only screen and (min-width: 1300px) and (max-width: 1920px) {
      font-weight: 400;
      font-size: 20px;
      line-height: 127%;
      padding: 12px 21px;
    }
    @media only screen and (min-width: 900px) and (max-width: 1300px) {
      font-weight: 400;
      font-size: 15.5476px;
      line-height: 127%;
      padding: 12px 21px;
    }
    @media only screen and (max-width: 900px) {
      font-weight: 400;
      font-size: 14.3136px;
      line-height: 127%;
      padding: 12px 21px;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
      font-weight: 400;
      font-size: 15.5476px;
      line-height: 127%;
      padding: 12px 21px;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
      font-weight: 400;
      font-size: 15.5476px;
      line-height: 127%;
      padding: 12px 21px;
    }
    &:hover,
    :focus {
          background-color: rgba(0, 0, 0, 0.08);
}
    
  }
`;
export const WebinarCardContainer = styled.div`
  width: 90%;
  margin: auto;
  gap: 2.5%;
  margin-top: 50px;
  background: #ffffff;
  /* height:auto; */
  @media only screen and (min-width: 900px) {
    display: flex;
    align-items: center;
  }
  @media only screen and (max-width: 900px) {
    display: flex;
    flex-wrap: wrap-reverse;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    display: flex;
    align-items: center;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    display: flex;
    align-items: center;
  }
`;
export const WebinarContentContainer = styled.div`
  @media only screen and (min-width: 900px) {
    width: 45%;
    margin: auto;
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 45%;
    margin: auto;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 45%;
    margin: auto;
  }
`;
export const WebinarHeaderText = styled.h1`
  font-family: "Montserrat";
  font-style: normal;
  color: #000000;
  @media only screen and (min-width: 1920px) {
    font-weight: 300;
    font-size: 42px;
    line-height: 51px;
    height: 100px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 300;
    font-size: 29.6471px;
    line-height: 36px;
    height: 72px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 300;
    font-size: 23.8051px;
    line-height: 29px;
    height: 60px;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 300;
    font-size: 18.3219px;
    line-height: 22px;
    height: 44px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 300;
    font-size: 23.8051px;
    line-height: 29px;
    height: 60px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 300;
    font-size: 23.8051px;
    line-height: 29px;
    height: 60px;
  }
`;

export const WebinarSubText = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  color: #000000;
  @media only screen and (min-width: 1920px) {
    font-weight: 400;
    font-size: 18px;
    line-height: 167.9%;
    margin-top: 71px;
    height: 141px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 400;
    font-size: 12.7059px;
    line-height: 167.9%;
    margin-top: 41px;
    height: 100px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 400;
    font-size: 10.2022px;
    line-height: 167.9%;
    margin-top: 31px;
    height: 80px;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 300;
    font-size: 13.85px;
    line-height: 167.9%;
    margin-top: 21px;
    height: 62px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 400;
    font-size: 10.2022px;
    line-height: 167.9%;
    margin-top: 31px;
    height: 80px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 400;
    font-size: 10.2022px;
    line-height: 167.9%;
    margin-top: 31px;
    height: 80px;
  }
`;
export const WebinarButtonContainer = styled.div`
  display: flex;
  gap: 5%;
  @media only screen and (min-width: 1920px) {
    margin-top: 50px;
    height: 40px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    margin-top: 40px;
    height: 40px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    margin-top: 30px;
    height: 32px;
  }
  @media only screen and (max-width: 900px) {
    margin-top: 35px;
    height: 32px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    margin-top: 30px;
    height: 32px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    margin-top: 30px;
    height: 32px;
  }
`;

export const WebinarDateTimeContainer = styled.div`
  margin-top: 40px;
  padding: 5px 0px;
  display: flex;
  align-items: center;
  background: rgba(238, 242, 244, 0.5);
  width: fit-content;
`;
export const WebinarIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 10px;
  margin-left: 25px;
`;
export const WebinarDateText = styled.h6`
  font-family: "Montserrat";
  font-style: normal;
  color: #12284c;
  margin-right: 25px;
  @media only screen and (min-width: 1920px) {
    font-weight: 600;
    font-size: 19.3187px;
    line-height: 25px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 600;
    font-size: 13.941px;
    line-height: 18px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 600;
    font-size: 12.0919px;
    line-height: 16px;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 600;
    font-size: 14.4038px;
    line-height: 19px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 600;
    font-size: 12.0919px;
    line-height: 16px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 600;
    font-size: 12.0919px;
    line-height: 16px;
  }
`;
export const VerticalDiv = styled.div`
  border-right: 0.688172px solid #c9c9c9;
  height: 25px;
`;
export const WebinarFilterButton = styled(FilterButton)`
  border: 1px solid #000000;
`;
export const WebinarCtaButton = styled(CtaButton)`
  margin-left: 0px;
`;
export const WebinarExploreButton = styled.div`
  width: fit-content;
  background: #12284c;
  border-radius: 90px;
  font-family: "Montserrat";
  font-style: normal;
  display: flex;
  align-items: center;
  letter-spacing: 0.125em;
  color: #ffffff;
  @media only screen and (min-width: 1920px) {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding: 10px 24px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding: 10px 24px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 500;
    font-size: 12.2626px;
    line-height: 15px;
    padding: 7.83px 18.39px;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 500;
    font-size: 12.2626px;
    line-height: 15px;
    padding: 7.83px 18.39px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 500;
    font-size: 12.2626px;
    line-height: 15px;
    padding: 7.83px 18.39px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 500;
    font-size: 12.2626px;
    line-height: 15px;
    padding: 7.83px 18.39px;
  }
`;
export const WebinarRegisterButton = styled(WebinarExploreButton)`
  color: #000000;
  border: 0.766412px solid #000000;
  border-radius: 68.9771px;
  @media only screen and (min-width: 1920px) {
    padding: 10px 40px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    padding: 10px 40px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    padding: 7.83px 30.66px;
  }
  @media only screen and (max-width: 900px) {
    padding: 7.83px 30.66px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    padding: 7.83px 30.66px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    padding: 7.83px 30.66px;
  }
`;
export const WebinarCardImg = styled.div`
  background: ${(props) => `url(${props.img})`};
  background-repeat: no-repeat;
  background-size: cover;
  aspect-ratio: 1.35/1;
  @media only screen and (min-width: 900px) {
    width: 50%;
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 50%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 50%;
  }
`;
