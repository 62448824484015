import { Skeleton } from '@mui/material';
import React from 'react'
import { Link } from 'react-router-dom';
import { BlogContainer,BlogImage,BlogHeader,ReadMoreButton, PressReleaseDate, PressReleaseContainer} from './PressCenter.styled';

const BlogCard = ({data, scrollTop}) => {
  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
  const p = data ? data.attributes.date.split('T')[0].split('-'):'';
  const date = p[2] + ' ' + monthNames[Number(p[1]).toString()-1]  + ' ' + p[0]
  return (
          <PressReleaseContainer>
          {data.attributes && data.attributes.featured_image.data && 
           <Link to = {`/blog/${data.id}`}>
          <BlogImage img={data.attributes.featured_image.data.attributes.url}>
            </BlogImage>
            </Link>
            }
            <PressReleaseDate>{(data.attributes.featured_image.data.attributes.url)?
        (date):<Skeleton
            animation="wave"
            variant="rectangular"
            width="100%"
            height="100%"
          />}</PressReleaseDate>
            <BlogHeader height={data.attributes.title}>{data.attributes.title? `${data.attributes.title.substring(0,55)}...` :
              <Skeleton
                animation="wave"
                variant="rectangular"
                width="100%"
                height="100%"
              />}
            </BlogHeader>
            {/* <ReleaseDesc height={data.attributes.description}>
          {(data.attributes.description) ? data.attributes.description :<Skeleton
            animation="wave"
            variant="rectangular"
            width="100%"
            height="100%"
          />}
        </ReleaseDesc> */}
        <Link to = {`/blog/${data.id}`}>
        <ReadMoreButton onClick={scrollTop}>
        {(data.attributes.featured_image.data.attributes.url)? 'Read more' :<Skeleton
            animation="wave"
            variant="rectangular"
            width="100%"
            height="100%"
          />}
        </ReadMoreButton>
        </Link>
            
          </PressReleaseContainer>
        );
      };

export default BlogCard