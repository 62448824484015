import React from 'react'
import { AvailableUnitContainer, AvailableUnitPara } from './projects.styled'

export const AvailableUnit = (details) => {
  if (details.details.available_units.data) {
    var data = details.details.available_units.data[0];
  }
 
  return (
    <>
    {data && <AvailableUnitContainer>
        <AvailableUnitPara>Available Unit</AvailableUnitPara>
    </AvailableUnitContainer>}
    </>
  )
}
