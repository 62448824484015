import React, { useState, useEffect } from "react";
import {
  FoundProjects,
  RecentlyAddedDiv,
  RecentlyAddedProjects,
  SearchResultsHeading,
} from "./SearchProject.styled";
import Line from "../../assets/images/Line.svg";
import { LineImage } from "../propertiesListing/propertylisting.styled";
import { RecentButton } from "../propertiesListing/RecentButton";
export const RecentlyAdded = ({data, sortButton}) => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);
  return (
    <>
      {width > 900 && (
        <RecentlyAddedDiv>
          <SearchResultsHeading>Search results</SearchResultsHeading>
          <RecentlyAddedProjects>
            <FoundProjects>Found {data.length} projects</FoundProjects>
            <LineImage src={Line} alt=""></LineImage>
            <RecentButton sortButton={sortButton}/>
          </RecentlyAddedProjects>
        </RecentlyAddedDiv>
      )}
      {width < 900 && (
        <RecentlyAddedDiv>
          <FoundProjects>Found {data.length} projects</FoundProjects>
          <RecentButton />
        </RecentlyAddedDiv>
      )}
    </>
  );
};
