import { createSlice } from '@reduxjs/toolkit';

const futureProjectSlice = createSlice({
    name: 'futureProject',
    initialState: {
        projectData: [],
        projectMeta:{},
        filter:'',
        isLoading: false,
        error: false,
       },
    reducers: {
        startLoading: state => {
            state.isLoading = true;
         },
        hasError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        getFutureProjects(state, action) {
            state.filter = action.payload
        },
        setProjectsContent(state, action) {
            const projectData = action.payload;            
            state.projectData = projectData;
        },
        setProjectsMeta(state, action) {
            const projectMeta = action.payload;            
            state.projectMeta = projectMeta;
        }
    },
});



export const { setProjectsContent, getFutureProjects, hasError, setProjectsMeta } = futureProjectSlice.actions;

export default futureProjectSlice.reducer;