import React from "react";
import picture from "../../assets/images/pexels-brett-sayles-79635721.png";
import { Overlay } from "../CompanyHero/Hero.styled";
import { SingleStepContainer, StepContainer, WhyInvestContentContainer, WhyInvestSection } from "./WhyInvestInDubai.styled";

const WhyInvestInDubai = ({data}) => {
  return (
    <WhyInvestSection img={picture}>
       
        <WhyInvestContentContainer>
      <h1>Why Choose Modern Living?</h1>
      <p>
      Modern Living offers services with excellence in the areas of: 
      </p>
        <StepContainer>
            <SingleStepContainer>
                <h1>01</h1>
                <h4>{data[0]}</h4>
            </SingleStepContainer>
            <SingleStepContainer>
                <h1>02</h1>
                <h4>{data[1]}</h4>
            </SingleStepContainer>
            <SingleStepContainer>
                <h1>03</h1>
                <h4>{data[2]}</h4>
            </SingleStepContainer>
            <SingleStepContainer>
                <h1>04</h1>
                <h4>{data[3]}</h4>
            </SingleStepContainer>
                {data[4]&&
            <SingleStepContainer>
                <h1>05</h1>
                <h4>{data[4]}</h4>
            </SingleStepContainer>}
                {data[5]&&
            <SingleStepContainer>
                <h1>06</h1>
                <h4>{data[5]}</h4>
            </SingleStepContainer>}
                {data[6]&&
            <SingleStepContainer>
                <h1>07</h1>
                <h4>{data[6]}</h4>
            </SingleStepContainer>}
        </StepContainer>
        </WhyInvestContentContainer>
        <Overlay/>
    </WhyInvestSection>
  );
};

export default WhyInvestInDubai;
