import styled from "styled-components";
import { ButtonText } from "../PropertyListing.js/PropertyListing.styled";

export const AboutSection = styled.section`
  background: #12284c;
  width: 100%;
  align-items: center;
  overflow: hidden;
  @media only screen and (min-width: 1920px) {
    height: auto;
    display: flex;
    justify-content: space-evenly;
    padding: 90px 90px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    height: auto;
    display: flex;
    justify-content: space-evenly;
    padding: 90px 90px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    height: auto;
    display: block;
    padding: 90px 90px;
  }
  @media only screen and (max-width: 900px) {
    height: auto;
    display: block;
    padding: 20% 41px;
  }
  @media only screen and (max-width: 400px) {
    height: auto;
    display: block;
    padding: 20% 41px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    height: auto;
    display: block;
    padding: 90px 90px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    height: auto;
    display: block;
    padding: 90px 90px;
  }
`;

export const AboutHeader = styled.h1`
  min-width: fit-content;
  font-family: "Didot";
  font-style: normal;
  color: #ffffff;
  @media only screen and (min-width: 1900px) {
    font-weight: 400;
    font-size: 99px;
    line-height: 125px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    font-weight: 400;
    font-size: 48px;
    line-height: 61px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
  }
  @media only screen and (max-width: 400px) {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
  }
`;

export const AboutDescContainer = styled.div`
  @media only screen and (min-width: 1920px) {
    width: 806px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 815px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    margin-top: 30px;
  }
  @media only screen and (max-width: 900px) {
    margin-top: 32px;
  }
  @media only screen and (max-width: 400px) {
    margin-top: 32px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    margin-top: 30px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    margin-top: 30px;
  }
`;
export const AboutDescText = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  color: #ffffff;
  text-align: justify;
  text-justify: inter-word;
  height: fit-content !important;
  div {
    height: fit-content !important;
  }

  @media only screen and (min-width: 1920px) {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
  }
  @media only screen and (max-width: 900px) {
    margin-top: 34px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }
  @media only screen and (max-width: 400px) {
    margin-top: 34px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
  }
`;
export const AboutReadMoreButton = styled.button`
  font-family: "Montserrat";
  font-style: normal;
  color: #ece1ce;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 10px;
  @media only screen and (min-width: 1920px) {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-top: 19px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-top: 19px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-top: 30px;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-top: 32px;
  }
  @media only screen and (max-width: 400px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-top: 32px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-top: 30px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-top: 30px;
  }
`;

export const AboutButton = styled(ButtonText)`
  margin-top: 20px;
  overflow: hidden;
  color: #ece1ce;
  border: 0.75px solid #ece1ce;
  border-radius: 90px;
  background: transparent;
  &:hover {
    border: 0.75px solid transparent;
    background: white;
    color: #12284c;
  }
  @media only screen and (min-width: 1367px) {
    height: 40px;
    padding: 10px 40px;
  }
  @media only screen and (max-width: 1366px) {
    height: 30px;
    padding: 7px 30px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    height: 30px;
    padding: 7px 30px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    height: 30px;
    padding: 7px 30px;
  }
`;
