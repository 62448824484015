import styled from "styled-components";
import {
  HeaderText,
  SubText,
} from "../PropertyListing.js/PropertyListing.styled";

export const ContactSection = styled.section`
  display: flex;
  align-items: center;
  overflow: visible;
  width: ${(props) => (props.shrink === "true" ? "85%" : "100%")};
  gap: 1.5%;
  position: relative;
  z-index: 10;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#f7f9fa"};
  margin: ${(props) => (props.shrink === "true" ? "auto" : "")};
  @media only screen and (min-width: 1920px) {
    height: ${(props) => (props.shrink === "true" ? "auto" : "1065px")};
    padding: ${(props) => (props.shrink === "true" ? "220px 0px" : "")};
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    height: ${(props) => (props.shrink === "true" ? "auto" : "600px")};
    padding: ${(props) => (props.shrink === "true" ? "150px 0px" : "")};
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    height: ${(props) => (props.shrink === "true" ? "auto" : "773px")};
    padding: ${(props) => (props.shrink === "true" ? "100px 0px" : "")};
  }
  @media only screen and (max-width: 900px) {
    height: auto;
    padding: ${(props) => (props.shrink === "true" ? "75px 0px" : "")};
    padding-bottom: 100px;
  }
  @media only screen and (max-width: 400px) {
    padding-bottom: 100px;
    height: auto;
    padding: ${(props) => (props.shrink === "true" ? "75px 0px" : "")};
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    height: ${(props) => (props.shrink === "true" ? "auto" : "773px")};
    padding: ${(props) => (props.shrink === "true" ? "100px 0px" : "")};
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    height: ${(props) => (props.shrink === "true" ? "auto" : "773px")};
    padding: ${(props) => (props.shrink === "true" ? "100px 0px" : "")};
  }
`;

export const ContactImg = styled.img`
  height: 100%;
  width: 50%;
  @media only screen and (max-width: 900px) {
    display: none;
  }
`;

export const ContactFormContainerTwo = styled.div`
  overflow: visible;
  @media only screen and (min-width: 1920px) {
    width: 45%;
    margin-bottom: 200px;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1920px) {
    width: 45%;
    margin-bottom: 200px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1200px) {
    width: 45%;
    margin-bottom: 180px;
  }
  @media only screen and (max-width: 900px) {
    width: 80%;
    margin: auto;
    margin-top: 0px;
    padding-top: 40px;
    margin-bottom: 60px;
  }

`;

export const ContactFormContainer = styled.div`
  overflow: visible;
  @media only screen and (min-width: 900px) {
    width: 45%;
  }

  @media only screen and (max-width: 900px) {
    width: 80%;
    margin: auto;
    margin-top: 0px;
    padding-top: 40px;
  }
`;

export const ContactHeaderText = styled(HeaderText)`
  text-align: left;
  padding-top: ${(props) => props.marginBottom};
`;
export const ContactSubText = styled(SubText)`
  text-align: left;
  @media only screen and (min-width: 1920px) {
    margin-bottom: 20px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    margin-bottom: 20px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    margin-bottom: 22px;
  }
  @media only screen and (max-width: 900px) {
    margin-bottom: 50px;
  }
  @media only screen and (max-width: 400px) {
    margin-bottom: 50px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    margin-bottom: 22px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    margin-bottom: 22px;
  }
`;

export const ContactSpan = styled.label`
  position: absolute;
  top: 25px;
  left: 0px;
  font-family: "Montserrat";
  font-style: normal;
  color: #c0c0c0;
  transition: 0.3s;
  pointer-events: none;

  &:checked {
    font-size: 10px;
    color: black;
    top: 0px;
  }

  @media only screen and (min-width: 1920px) {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }
  @media only screen and (max-width: 400px) {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    &:focus + label {
      font-size: 10px;
      color: black;
      top: 0px;
    }
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }
`;

export const DropdownSpan = styled(ContactSpan)`
  position: relative;
  top: 0;
  text-transform: uppercase;
  &:focus {
    color: black;
  }
`;

export const DropdownContactDiv = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 0.55px solid #c0c0c0;
  align-items: center;
  @media only screen and (min-width: 1920px) {
    margin-top: 35px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    margin-top: 32px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    margin-top: 35px;
  }
  @media only screen and (max-width: 900px) {
    margin-top: 35px;
  }
  @media only screen and (max-width: 400px) {
    margin-top: 30px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    margin-top: 35px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    margin-top: 35px;
  }
  &:focus-within > label {
    color: black !important;
  }
`;

export const ContactInputBox = styled.div`
  display: ${(props) => props.display};
  position: relative;
  height: auto;
  overflow: visible;
  @media only screen and (min-width: 1920px) {
    margin-bottom: 20px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    margin-bottom: 12px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    margin-bottom: 15px;
  }
  @media only screen and (max-width: 900px) {
    margin-bottom: 15px;
  }
  @media only screen and (max-width: 400px) {
    margin-bottom: 15px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    margin-bottom: 15px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    margin-bottom: 15px;
  }
`;

export const ContactInputFeild = styled.input`
  border: none;
  border-bottom: ${(props) =>
    props.border ? props.border : "0.55px solid #c0c0c0"};
  width: 100%;
  border-radius: 0px;
  background: transparent;
  font-family: "Montserrat";
  font-style: normal;
  color: #c0c0c0;
  margin-top: 20px;
  z-index: 99;
  &:focus + label {
    font-size: 10px;
    color: black;
    top: 0px;
  }
  &:focus {
    border-bottom: 0.55px solid #000000;
    color: #000000;
  }
  &:valid + label {
    font-size: 10px;
    color: black;
    top: 0px;
  }

  @media only screen and (min-width: 1920px) {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    padding-bottom: 13px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    padding-bottom: 9px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    padding-bottom: 6px;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    padding-bottom: 6px;
  }
  @media only screen and (max-width: 400px) {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    padding-bottom: 6px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    padding-bottom: 6px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    padding-bottom: 6px;
  }
`;

export const ContactInputDropDown = styled.input`
  border: none;
  border-bottom: ${(props) =>
    props.border ? props.border : "0.55px solid #c0c0c0"};
  width: 100%;
  border-radius: 0px;
  background: transparent;
  font-family: "Montserrat";
  font-style: normal;
  color: #c0c0c0;
  margin-top: 20px;
  z-index: 99;
  &:focus + label {
    font-size: 10px;
    color: black;
    top: 0px;
  }
  &:focus {
    border-bottom: 0.55px solid #000000;
    color: #000000;
  }
  &:valid + label {
    font-size: 10px;
    color: black;
    top: 0px;
  }

  @media only screen and (min-width: 1920px) {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    padding-bottom: 13px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    padding-bottom: 9px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    padding-bottom: 6px;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    padding-bottom: 6px;
  }
  @media only screen and (max-width: 400px) {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    padding-bottom: 6px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    padding-bottom: 6px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    padding-bottom: 6px;
  }
`;

export const ContactMessageInput = styled.textarea`
  border: none;
  border-bottom: 0.55px solid #c0c0c0;
  width: 100%;
  border-radius: 0px;
  background: transparent;
  font-family: "Montserrat";
  font-style: normal;
  color: #c0c0c0;
  margin-top: 20px;
  &:focus + label {
    font-size: 10px;
    color: black;
    top: 0px;
  }
  &:focus {
    border-bottom: 0.55px solid #000000;
    color: #000000;
  }
  &:valid + label {
    font-size: 10px;
    color: black;
    top: 0px;
  }

  @media only screen and (min-width: 1920px) {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    padding-bottom: 19px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    padding-bottom: 13px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    padding-bottom: 11px;
  }
  @media only screen and (max-width: 900px) {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    padding-bottom: 18px;
  }
  @media only screen and (max-width: 400px) {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    padding-bottom: 18px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    padding-bottom: 11px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    padding-bottom: 11px;
  }
`;

export const ContactButton = styled.button`
  text-align: left;
  margin-top: 10px;
  font-family: "Montserrat";
  font-style: normal;
  letter-spacing: 0.125em;
  color: #ffffff;
  background: #12284c;
  border-radius: 69.6992px;
  overflow: hidden;
  border: transparent;
  &:hover {
    background: #004876;
  }

  @media only screen and (min-width: 1920px) {
    padding: 10px 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    height: 40px;
    width: fit-content;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    padding: 10px 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    height: 40px;
    width: fit-content;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    padding: 7.5px 31px;
    font-weight: 500;
    font-size: 12.391px;
    line-height: 15px;
    height: 30px;
    width: fit-content;
  }
  @media only screen and (max-width: 900px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-weight: 500;
    font-size: 12.8722px;
    line-height: 16px;
    height: 33px;
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 400px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-weight: 500;
    font-size: 12.8722px;
    line-height: 16px;
    height: 33px;
    margin-bottom: 40px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    padding: 7.5px 31px;
    font-weight: 500;
    font-size: 12.391px;
    line-height: 15px;
    height: 30px;
    width: fit-content;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    padding: 7.5px 31px;
    font-weight: 500;
    font-size: 12.391px;
    line-height: 15px;
    height: 30px;
    width: fit-content;
  }
`;

export const ErrorField = styled.span`
  position: absolute;
  bottom: -20px;
  left: 0px;
  font-family: "Montserrat";
  font-style: normal;
  color: red;
  font-size: 16px;
`;
export const ContactCheckBox = styled.label`
  display: inline-block;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 151.5%;
  letter-spacing: 0.03em;
  color: #000000;
  width: 100%;
  margin-bottom: 20px;
  &::placeholder {
    color: #000000;
  }
`;
export const ContactDiv = styled.div`
  width: 100%;
  background: #f7f9fa;
`;
