import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar/Navbar";
import FooterMobile from "../components/Footer/FooterMobile";
import Footer from "../components/Footer/Footer";
import PropertyPageDetail from "../components/PropertyDetail/PropertyPageDetail";
import { DetailsBackground } from "../components/PropertyDetail/PropertyDetail.styled";
import { useDispatch, useSelector } from "react-redux";
import { getPropertiesDetails } from "../redux/slices/propertiesSlices/getProperties";
import { useParams } from "react-router-dom";
import CustomizedSnackbar from "../components/CustomizedSnackbars/CustomizedSnackbar";


const TabletBreakpoint = 1024;
export const PropertyDetail = () => {

  const [width, setWidth] = useState(window.innerWidth);
  const {id} = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getPropertiesDetails(id))
      },[dispatch, id]);
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    window.scrollTo(0, 0);
  }, []);

 
  
const data = useSelector((state) => state.properties.propertiesDetail.data);
  return (
    <DetailsBackground>
     <Navbar background='#12284c' />
     <CustomizedSnackbar />
      {data && <PropertyPageDetail propertiesDetails={data}/>}
      {width < TabletBreakpoint ? <FooterMobile /> : <Footer />}
      <CustomizedSnackbar/>
    </DetailsBackground>
  );
};
