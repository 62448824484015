import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import { PropertyCard } from "../components/PropertyListing.js/PropertyCard";
import Line from "../assets/images/Line.svg";
import FooterMobile from "../components/Footer/FooterMobile";
import Footer from "../components/Footer/Footer";
import {
  MainHeading,
  PrimaryButtonsDiv,
  InputField,
  BoxField,
  Preferences,
  PreferencesDivOne,
  PreferenceDivTwo,
  BoxBodyContainer,
  PreferencesSmallScreen,
  PropertyCardCointer,
  PropertyCarDiv,
  FooterPart,
  LinkOne,
  FooterHead,
  PreferenceHead,
  LinkTwo,
  DisplayText,
  BoxDiv,
  BoxText,
  LineImage,
  LocationIcon,
} from "../components/propertiesListing/propertylisting.styled";
import { UnitTypeButton } from "../components/propertiesListing/UnitTypeButton";
import { Price } from "../components/propertiesListing/Price";
import { Beds } from "../components/propertiesListing/Beds";
import Navbar from "../components/Navbar/Navbar";
import { SaleRentSlider } from "../components/propertiesListing/SaleRentSlider";
import { PaginationOutlined } from "../components/propertiesListing/PaginationOutlined";
import { RecentButton } from "../components/propertiesListing/RecentButton";
import { useDispatch, useSelector } from "react-redux";
import { ScrollButtons } from "../components/propertiesListing/ScrollButtons";
import { Link, useParams } from "react-router-dom";
import {
  setSearch,
  setSearchContent,
} from "../redux/slices/herosearch/herosearchSlice";
import { getLocation } from "../redux/slices/location/locationSlice";
import { useTranslation } from "react-i18next";

const TabletBreakpoint = 1024;

const propertyStyles = makeStyles({
  page: {
    alignItems: "center",
    backgroundColor: "rgba(238, 242, 244, 0.5) !important",
  },
});

const PropertyListing = () => {
  const { id } = useParams();
  const property = propertyStyles();
  const data = useSelector((state) => state);
  const [community, setCommunity] = useState(
    data.herosearch.community ? data.herosearch.community : ""
  );
  const [area, setArea] = useState("");
  const [areaActive, setAreaActive] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [sort, setSort] = useState("recent");
  const { t } = useTranslation();
  const dropdownRef = useRef(null);
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    window.scrollTo(0, 0);
  }, []);
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };
  const dispatch = useDispatch();
  const location = useSelector((state) => state.location);
  const HandleSearch = (e) => {
    const searchObject = {
      type: id || data.herosearch.type,
      community: data.herosearch.community ? data.herosearch.community : "",
      unittype:
        data.herosearch.unittype && data.herosearch.unittype !== "All"
          ? data.herosearch.unittype
          : "",
      bed: data.herosearch.beds ? data.herosearch.beds : "",
      price: data.herosearch.price ? data.herosearch.price : "",
      filter: data.herosearch.filter ? data.herosearch.filter : "newest",
      page: 1,
    };
    dispatch(setSearch(searchObject));
  };
  useEffect(() => {
    HandleSearch();
  }, []);
  useEffect(() => {
    HandleSearch();
  }, [id]);
  const handleInput = (event) => {
    setAreaActive(true);
    const newValue = event.target.value;
    if (newValue.trim() === "") {
      setCommunity("");
    } else {
      setCommunity(newValue);
    }
  };

  useEffect(() => {
    let words = community.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
    const joinedWords = capitalizedWords.join(" ");
    setArea(joinedWords);
    dispatch(getLocation(joinedWords));
  }, [community, dispatch]);
  useEffect(() => {
    dispatch(setSearchContent({ key: "community", value: community }));
  }, [community]);

  let sortedData = data.properties.propertiesData;

  switch (sort) {
    case "lowToHigh":
      sortedData = sortedData
        .slice()
        .sort((a, b) => a.attributes.price - b.attributes.price);
      break;
    case "highToLow":
      sortedData = sortedData
        .slice()
        .sort((a, b) => b.attributes.price - a.attributes.price);
      break;
    case "recent":
      sortedData = sortedData
        .slice()
        .sort((a, b) => a.attributes.updatedAt - b.attributes.updatedAt);
      break;
  }
  const sortButton = (value) => {
    setSort(value);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setAreaActive(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef]);
  return (
    <>
      <div className={property.page}>
        <Navbar background="#12284C" />
        <MainHeading>{t("Find your luxury property in Dubai")}</MainHeading>
        <PrimaryButtonsDiv>
          <SaleRentSlider id={id} />
          <div ref={dropdownRef}>
            <BoxField
              onClick={() => setAreaActive(true)}
              border="1px solid #12284c"
            >
              <LocationIcon color="#12284c" />
              <InputField
                placeholder={t("Enter area or community")}
                onChange={handleInput}
                value={community}
              />
            </BoxField>
            <BoxDiv
            border= "1px solid #12284C"
              active={areaActive}
              style={{ borderRadius: "20px", marginTop: "6px" }}
            >
              {location.locationData.length === 0 ? (
                <BoxText>{t("No Data Found")}</BoxText>
              ) : (
                location.locationData.map((i, id) => {
                  return (
                    <BoxText
                      key={id}
                      color="black !important"
                      hoverback = "#12284C !important"
                      hovercolor="white !important"
                      onClick={() => {
                        setCommunity(i.attributes.area);
                        setAreaActive(false);
                      }}
                    >
                      {i.attributes.area}
                    </BoxText>
                  );
                })
              )}
            </BoxDiv>
          </div>
          <UnitTypeButton
            data="property"
            border="1px solid #12284C"
            color="#12284C"
            arrow="#12284C"
            menuback="transparent !important"
            menucolor="black !important"
            menuitemcolor="white"
            menuitembackgroundhover="#12284C !important"
            activecolor="black"
          />
          <Beds
        data="property"
        border="1px solid #12284C"
        color="#12284C"
        arrow="#12284C"
        menuback="transparent !important"
        menucolor="black !important"
        menuitemcolor="white"
        menuitembackgroundhover="#12284C !important"
          />
          <Price
            border="1px solid #12284C"
            color="#12284C"
            arrow="#12284C"
            menuback="transparent !important"
            menucolor="black !important"
            menuitemcolor="white"
            menuitembackgroundhover="#12284C !important"
            activecolor="black"
          />
        </PrimaryButtonsDiv>
        <hr />
        <ScrollButtons />
        <hr />
        <BoxBodyContainer>
          <Preferences>
            {width > 1300 && (
              <PreferencesDivOne>
                <PreferenceHead>
                  {t("Looking for something unique?")} <br />
                  <LinkTwo href="/preferences">
                    <b>{t("Set your preferences")}</b>
                  </LinkTwo>
                  ,&nbsp; {t("and our specialists will help you.")}
                </PreferenceHead>
              </PreferencesDivOne>
            )}
            {width > 900 && (
              <PreferenceDivTwo>
                <h1>
                  Properties for {id} in Dubai: {sortedData.length}
                </h1>
                <LineImage src={Line} alt=""></LineImage>
                <RecentButton sortButton={sortButton} />
              </PreferenceDivTwo>
            )}
            {width <= 900 && (
              <PreferencesSmallScreen>
                <RecentButton sortButton={sortButton} />
                <Link href="/">
                  Properties for sale in Dubai: {sortedData.length}
                </Link>
              </PreferencesSmallScreen>
            )}
          </Preferences>
          <PropertyCardCointer>
            {sortedData.length === 0 && (
              <DisplayText>
                {t("No properties matching your search")}
              </DisplayText>
            )}
            {sortedData.length > 0 &&
              sortedData.map((property) => {
                return (
                  <PropertyCarDiv key={property.id}>
                    <PropertyCard property={property} />
                  </PropertyCarDiv>
                );
              })}
          </PropertyCardCointer>
        </BoxBodyContainer>
        <PaginationOutlined scrollTop={scrollTop} value="property" />
        <FooterPart>
          <FooterHead>
            {t("Looking for something unique?")} <br />{" "}
            <LinkOne href="/preferences">
              <b>{t("Set your preferences")}</b>
            </LinkOne>
            ,&nbsp;{t("and our specialists will help you.")}{" "}
          </FooterHead>
        </FooterPart>
        {width < TabletBreakpoint ? <FooterMobile /> : <Footer />}
      </div>
    </>
  );
};

export default PropertyListing;
